import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";

const Nursingdissertation = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Dissertation Writers",
            Desc: "Hire our professional writers and buy a nursing dissertation. Get professional assistance from your nursing dissertation writers!",
        },
        {
            icon: "fa-solid fa-pen-to-square icon",
            title: "Writing Help",
            Desc: "We provide comprehensive, cheap nursing dissertation writing services for Capella University students!",
        },
        {
            icon: "fa-solid fa-text-height icon",
            title: "Formatting Help",
            Desc: "We have nursing experts to help you through the nursing dissertation proposal writing service.",
        },
        {
            icon: "fa-solid fa-graduation-cap icon",
            title: "Thesis Help",
            Desc: "Get excellent support through a professional nursing dissertation writing service! ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "Our comprehensive nursing dissertation writing services help you in getting high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We can always help you with our Cheap nursing dissertation writing services!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to students in dissertation writing services nursing.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "We will provide the best guidance through a professional nursing dissertation writing service.",
        },
    ]
    return (
        <>
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
                    <div className='sherotop-left'>
                    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
                    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
                    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
                    </div>
                    <div className='cal-box'><Calculator/></div> 
                </div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>NURSING DISSERTATION WRITING SERVICES</span><br />
                                </h1>
                                <h2>ARE YOU LOOKING FOR  <br />NURSING DISSERTATION HELP?</h2>
                            <p className='ocs2-topText'>Buy a nursing dissertation with our cheap nursing dissertation writing services.</p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Buy Nursing Dissertation</h2>
                            <p>Hire our nursing dissertation-writing professionals for exceptional nursing dissertation-writing service.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Get Nursing Proposal</h2>
                            <p>Get a high-scoring nursing dissertation proposal writing service from academic writing professionals. </p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>BUY NURSING DISSERTATION PAPERS!</h2>
                <p>Online Course Services provides professional nursing dissertation writing services!</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Prestigious Services</span>
                    <h2>CAPELLA UNIVERSITY DISSERTATION WRITING SERVICES</h2>
                    <p>Our renowned online course services specialize in presenting top-quality and cheap nursing dissertation writing services. Our team of professional Nursing dissertation writers, tutors, and mentors is dedicated to supporting you in your Nursing dissertation writing journey. They excel in assisting college students with their nursing dissertations, papers, and exam solutions. We offer comprehensive support for college kids with our Cheap nursing dissertation writing services. It earned us their consideration for our incredible services. For dissertation writing services in the nursing program, we offer numerous services. It includes nursing dissertation writing, low-cost alternatives to shop for nursing dissertations, and expert nursing dissertation writing services. Our services cover all components of nursing dissertation writing, including idea writing, ensuring that your dissertation meets the best standards of professionalism.</p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>Avail Comprehensive Nursing Dissertation Writing Services</strong></Tab>
                        <Tab><strong>Cheap Nursing Dissertation Writing Services</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p>Our complete nursing dissertation writing services cater to college students seeking professional aid in crafting notable dissertations. Our team of experienced writers focuses on nursing research and educational writing. We ensure that your dissertation meets the best academic excellence requirements. Whether you want assistance with subject matter choice, literature review, statistics analysis, or average dissertation writing, we are here to help you achieve your academic goals.
                        <br /> <br />
                            <strong>Get the best Nursing Dissertation</strong><br />
                            A nursing dissertation is a vast academic assignment that requires in-intensity studies, vital evaluation, and clear communication of findings. We help nursing college students explore a topic of interest in nursing and contribute new information to their careers. Our nursing dissertation writing services can help you navigate this complex method. Convey a nicely-researched, adequately written dissertation that meets your group's instructional requirements.
                            <br /><br />
                            <strong>Buy Nursing Dissertation</strong>
                            <br />
                            If you want to shop for a nursing dissertation, our professional writing services can provide a custom-written thesis that meets your requirements. Our writers know how to write professional nursing dissertations according to your desires. Whether you need an entire dissertation or help with a selected part, we permit you to attain your academic goals.
                            </p>
                    </TabPanel>
                    <TabPanel>
                        <p>Our cheap nursing dissertation writing offerings provide lower-priced answers for college students on tight finances. Despite our aggressive charges, we in no way compromise on the pleasantness of our paintings. You can agree with us to deliver a nicely researched, well-written dissertation that meets your group's educational standards. <br /><br />
                            <strong>Dissertation Writing Services Nursing</strong><br />
                            Our dissertation writing services for nursing college students are designed to provide comprehensive aid at some stage in the dissertation writing system. From topic selection to the last enhancement, our experienced writers and editors permit you to produce a dissertation worthy of educational success.
                            <br /><br />
                            <strong>Nursing Dissertation Proposal Writing Service</strong>
                            <br />
                            Writing a nursing dissertation requires careful plans and interest in detail. Our nursing dissertation writing service lets you craft a compelling concept outlining your study's objectives, methodology, and anticipated effects. With our assistance, you may ensure that your dissertation inspiration is nicely written, nicely structured, and meets the necessities of your institution.
                            <br /><br />
                            <strong>Professional Nursing Dissertation Writing Service</strong>
                            <br />
                            Our expert nursing dissertation writing service is designed to offer expert guidance and support to nursing college students who use the dissertation writing services. Our experienced writers and editors will let you produce a properly researched, nicely-written dissertation that meets the best academic standards. Whether you want help with topic selection, literature evaluation, statistics evaluation, or common dissertation writing, we're here to help you.
                            </p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Qualities!</span>
                    <h2>WHY CHOOSE US?</h2>
                    <p>Online Course Services provides comprehensive nursing dissertation writing services to nursing college students. We have first-rate teams of writers, intellectuals, and mentors specializing in nursing dissertations to help you. We have assisted thousands of nursing college students in their dissertation-writing adventures. Our professional nursing dissertation writing services are top-rated among nursing students. We have helped hundreds of thousands of nursing students with their dissertation assessments. We have well-known intellectuals for complete assistance in nursing dissertation writing. We will take care of all the factors in your dissertation writing and lots more! Avail excessive rankings for your dissertation with our help now!</p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Do you offer nursing dissertation writing services?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! Our nursing dissertation writers offer complete guidance in writing a high-quality dissertation.  
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I hire a tutor to write my nursing dissertation?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We have excellent writing tutors to help you with your nursing dissertation.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I buy a nursing dissertation? </h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We are offering helpful nursing dissertations to nursing students at affordable prices.   
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you offer cheap nursing dissertation writing services?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We are offering comprehensive nursing dissertation writing sessions to students. Take help from our skilled tutors, writers, and mentors.</div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you help me with dissertation writing services in nursing?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We have a team of highly intellectual nursing dissertation experts to provide significant help. 
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you help with the Nursing dissertation proposal writing service?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>
                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! Our team of competent writers will help you with your nursing dissertation.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you offer a professional nursing dissertation writing service for me?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We will provide flawless and high-quality nursing dissertation solutions for you.</div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I get a personal tutor for my nursing dissertation?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We offer personal tutors for nursing dissertations at a fantastic discount.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>

                        </Accordion>
                    </div>
                </div>
            </Container>
        </>
    )
}


export default Nursingdissertation
