import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {NavDropdownMenu} from "react-bootstrap-submenu";

const Header = () => {

  const toggleModal = (e) =>{
    e.preventDefault()
    window.location.href = 'https://onlinecourseservices.us/free-samples';
}

  return (
    <>
    <div className='ocs2-navbar'>
    <Container>
      <Navbar expand="lg" className="ocs2-navbar-container">
        <Navbar.Brand>
          <Link to='/'>
          <img src="images/logo.png" alt='online course services' height='' width='150'
          /></Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-center'>
          <Nav className="justify-content-center">
            <Nav.Link><Link className='ocs2-nav-link' to='/'>Home</Link></Nav.Link>
            <NavDropdownMenu title='Services' id='collasible-nav-dropdown' alignLeft>
              <div id='displaymenu' >
              <NavDropdown.Item className='sub-menu'><Link to='/take-the-guidance-in-capella-msn-flexpath-courses' className='sub-menu-link'>MSN Flexpath Courses</Link></NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'><Link to='/nursing-dissertation-writing-services' className='sub-menu-link'>Nursing Dissertation Writing Services</Link></NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'><Link to='/seek-capella-university-dnp-flexpath-help' className='sub-menu-link'>DNP FlexPath Help</Link></NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'><Link to='/pay-someone-to-take-an-online-class' className='sub-menu-link'>Take My Online Class</Link></NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'><Link to='/academic-assignment-help' className='sub-menu-link'>Academic Assignment Help</Link></NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'><Link to='/assignment-writing-services' className='sub-menu-link'>Assignment Writing Services</Link></NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'><Link to='/need-help-with-nursing-assignment' className='sub-menu-link'>Nursing Assignment Help</Link></NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'><Link to='/do-my-assignment-online' className='sub-menu-link'>Do My Assignment Online</Link></NavDropdown.Item>
              </div>
              
            </NavDropdownMenu>
            
            {/* <NavDropdownMenu title="Capella University" id="collasible-nav-dropdown" alignRight >
            <DropdownSubmenu title="Nursing" className='sub-menu'>
              <NavDropdown.Item className='sub-menu'><Link to='/RN-to-BSN' className='sub-menu-link'>RN to BSN</Link></NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'><Link to='/MSN' className='sub-menu-link'>MSN - Master of Science in Nursing</Link></NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'><Link to='/DNP' className='sub-menu-link'>DNP - Doctor of Nursing Practice</Link></NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Psychology" className='sub-menu'>
              <NavDropdown.Item className='sub-menu' href='/BS-in-Psychology'>BS in Psychology</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu' href='/BS-in-Psychology-Pre-Counseling'>BS in Psychology Pre - Counseling</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu' href='/MS-in-Applied-Behavior-Analysis'>MS in Applied Behavior Analysis</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu' href='/MS-in-Clinical-Psychology'>MS in Clinical Psychology</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu' href='/MS-in-Psychology'>MS in Psychology</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu' href='/MS-in-School-Psychology'>MS in School Psychology</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu' href='/EdS-in-School-Psychology'>EdS in School Psychology</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu' href='/PhD-in-Behavior-Analysis'>PhD in Behavior Analysis</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu' href='/PhD-in-Psychology'>PhD in Psychology</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu' href='/PsyD-in-Clinical-Psychology'>PsyD in Clinical Psychology</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu' href='/PsyD-in-School-Psychology'>PsyD in School Psychology</NavDropdown.Item>
            </DropdownSubmenu>
            <DropdownSubmenu title="Health Sciences" className='sub-menu'>
              <NavDropdown.Item className='sub-menu'>BS in Public Health</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'>BS in Health Care Administration</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'>MHA - Master of Health Administration</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'>MPH - Master of Public Health</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'>DHA in Health Administration</NavDropdown.Item>
              <NavDropdown.Item className='sub-menu'>DrPH - Doctor of Public Health</NavDropdown.Item>
            </DropdownSubmenu>
          </NavDropdownMenu> */}

            <Nav.Link onClick={toggleModal}>Free Samples</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className='justify-content-end' id="basic-navbar-nav">
            <Link to={"./order"} className='ocs2-orderNow-btnn'><button className='ocs2-nav-order-btn'>Order Now</button></Link>
            <button className='ocs2-nav-phone-btn'>+1(669) 209-0161</button>
        </Navbar.Collapse>
    </Navbar>
    </Container>
    </div>
    </>
  )
}

export default Header
