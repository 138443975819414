import React from 'react'
import { Container } from 'react-bootstrap'
import { FaPhoneAlt, FaRegArrowAltCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Degreedetail = () => {
  return (
    <div className='degree-detail'>
        <Container>
            <div className='degree-details-inner'>
                <div className='degree-uni-details'>
                    <h2>Get Top Notch Services</h2>
                    <div className='bsn-br'></div>
                    <p>Are you on the quest of academic excellence in your Bachelors of science in nursing? Our
                        best nursing assistance platform is committed to offer excellent services regarding nursing
                        coursework, assignment assistance and taking online classes on your behalf. Are you
                        thinking about seeking help in the coursework of rn to bsn? Our expert team is dedicated
                        to provide help in rn to bsn online. Your best academic performance in rn to bsn programs
                        is our top priority! We will assist you at every step of your academic career.
                        <br/><br/>Are you seeking an expert to take my online nursing class for my online rn to bsn programs? 
                        We ensure that you receive the support that you require to achieve the best academic
                        results that you truly deserve. If you are thinking that I should hire someone to take my nursing
                        exam for me. We offer a wide-range of exam services that are designed to relieve your study burden
                        and to assist you in achieving your academic goals. We have highly qualified and experienced nursing
                        professionals who have gone through the same coursework as yours. They have excellent understanding
                        of the subject matter. They are skilled in providing expert guidance and support to you. Feel free
                        to seek help regarding an assignment, essay, research paper and even exam preparation. We are
                        available 24/7 for your service.</p>
                    
                    <div className='ocs2-orderNow-box bsn-order-box'>
                        <Link to={"/order"} className='ocs2-orderNow-btnn'><button className='ocs2-orderNow'>Order Now &nbsp;<FaRegArrowAltCircleRight/></button></Link>
                        <img src='images/bsntime.png' alt='' className='ocs2-orderNow-time'/>
                        <div className='ocs2-bannerone-phoneNo bsn-uni-details'>
                        <FaPhoneAlt className='ocs2-bannerone-phoneNo-icon bsn-uni-phone'/>
                        <span>
                            <p>Call us at</p>
                            <strong>+1(612)200-2655</strong>
                        </span>
                    </div>
                </div>
                </div>
                <div className='degree-details-offer'>
                    <img src='images/saleoffer.png' alt=''/>
                </div>
            </div>
        </Container>
    </div>
  )
}

export default Degreedetail
