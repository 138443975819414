import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx4050assessment2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 4050 Assessment 2: Ethical and Policy Factors in Care Coordination</title>
                <meta name='description'
                    content='Craft your NURS FPX 4050 Assessment 2: Ethical and Policy Factors in Care Coordination with precision! Optimize care coordination plans efficiently. Get started now!' />
                <meta name='keywords' content='nurs fpx 4050 assessment 2' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 4050 Assessment 2:  < br /><span>Ethical and Policy Factors in Care Coordination</span></h1>
                    {/* <p>Hi. I’m (Student), and currently, I want to recommend in-service training that encourages the medical staff < br />to approach patient treatment more proactively. </p>
                    <div className='sample-hero-links'>
                        <a href="#">Online Course Services</a>
                        <a href="#">Free Samples</a>
                        <a href="#">Online Course Services</a>
                        <a href="#">Online Course Services</a>
                    </div> */}
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/NURSFPX4050Assessment2featureimage.png" alt="" />
                    <div className='main-container-sl-content'>
                        {/* <h2>NURS FPX 4020 Assessment 3 Improvement Plan In-service Presentation</h2>
                        <p>Slide 1
                            Hi. I’m (Student), and currently, I want to recommend in-service training that encourages the medical staff to approach patient treatment more proactively. The purpose of this lecture is to raise public awareness of the issue of physicians failing to act quickly enough as patients’ circumstances worsen.
                            < br />
                            Slide 2
                            A strategic effort to improve the quality of care and patient safety is an in-service demonstration of the improvement plan that addresses the matter of delayed reaction to worsening patient events in the healthcare system. This talk will provide a thorough approach to dealing with the difficulties of identifying and acting quickly when a patient’s health deteriorates. Usually, this involves instructing medical personnel on early warning indications, putting in place standard processes for communication and assessments, and encouraging a proactive observation culture. This presentation is focused on reducing the time it takes to detect patients who are deteriorating, which will improve outcomes for patients and lower the number of negative incidents in healthcare settings. It will do this by emphasizing the advantages of quick action and cooperative cooperation. The healthcare sector will need to use it as a key tool for encouraging ongoing patient care and safety enhancement.
                            < br />  < br />
                            <h2> Agenda and Outcomes of In-service Training</h2>
                            < br />
                            Slide 3
                            Attending an in-service session on the specific patient safety concern of delayed response to worse patient conditions in healthcare will help healthcare employees gain the knowledge, abilities, and techniques demanded to recognize and act rapidly and effectively when a patient’s condition deteriorates. Ensuring patient safety and enhancing comprehensive healthcare outcomes are essential.
                            < br />< br />
                            <h2> Goals</h2>
                            < br />
                            Educating medical personnel about the symptoms and warning signs of escalating situations with patients is the primary objective. Participants will learn about the early warning signs of patient deterioration throughout the training course, which include transformed vital signs, elevated pain thresholds, and altered states of consciousness. Medical personnel may avoid additional deterioration by acting quickly to recognize these indicators (Burdeu et al., 2020). Encouraging improved teamwork and communication within the healthcare system is another crucial objective. This includes sharing useful communication techniques and highlighting the need for timely and transparent information exchange—the goal of the in-service. The program aims to foster an environment of open communication so that concerns about patient deterioration may quickly reported to the relevant staff members, which facilitates immediate intervention (Kwame & Petrucka, 2021).
                            < br />
                            The final purpose is practical instruction and modeling. In a supervised environment, healthcare professionals may hone their response to patients’ worsening situations. According to Smith et al. (2021), individuals can boost their capacity for decision-making, reaction, and evaluation skills via simulations. These hands-on activities are crucial to developing competence and confidence in difficult circumstances.
                            < br />< br />
                            <h2>The Need and Process to Improve Safety Outcomes</h2>
                            < br />
                            Slide 4
                            To protect the well-being of patients and ensure adherence to set regulations, safety consequences in healthcare must be improved, specifically by addressing the postponed response to worsening patient situations (Dresser et al., 2023). Standards like those created by The Joint Commission, which stress quick identification and reaction to modifications in a patient’s condition as an essential component of safe treatment, highlight the need for improvement in the USA (Shenoy, 2021).
                            < br />
                            A slow response to a patient’s worsening health can end up in unfavorable outcomes. Reducing the risk of such incidents is important, as specified in the National Patient Safety Goals, another US standard (Burke et al., 2020). In order to achieve these objectives and stop easily avoidable injury, prompt detection and action are key. Furthermore, the Affordable Care Act and other government guidelines have increased the emphasis on patient safety, necessitating improvements to healthcare providers’ reaction systems to decreasing circumstances (Levine et al., 2022). To sum up, strengthening safety results by addressing delayed replies is consistent with US standards, guaranteeing patient-centered therapies and reducing accidental damage.
                        </p> */}
                        <h2>Ethical and Policy Factors in Care Coordination</h2>
                        <p><strong>Looking for guidance with your NURS FPX 4050 Assessment 2? Our experts are here to assist you. Reach out to us for support today.</strong></p>
                        <h3>Slide 1:</h3>
                        <p>Hello, my name is XYZ, and I am here to discuss the ethical and policy issues in&nbsp;care coordination, especially in&nbsp;Chronic Diseases.</p>
                        <h3>Slide 2:</h3>
                        <p>In today's discussion, we indulge in the detailed interaction between ethical players and policy framework in the harmony of care delivery, with insight into chronic diseases, specifically cancer. Through this presentation, we will examine how government policies can encourage care coordination, examine the existing policies and their integral parts, and determine the ethical issues that may arise (Ercia, 2021). Besides evaluating the influence of the nursing code of ethics on care coordination, we consider social determinants of health and evidence-based approaches. Let us go through this together to discover what makes care coordination in chronic diseases distinct in matters of ethics and governance.</p>
                        <h3>Slide 3: Governmental Policies and Their Impact on Care Coordination</h3>
                        <p>Different government policies have a 'regulations' role in the healthcare system in care coordination. National, state, and municipal policies and regulations will always be essential elements that may either speed up or complicate coordination efforts in the healthcare system, focusing on cancer, for example.</p>
                        <p>The current financial regulatory framework and compliance obligations significantly hinder agricultural producers' global competitiveness in the United States. Another primary consideration in the influence of governmental policies on care coordination is the establishment of legal and compliance postulates. Specifically, HIPAA (Health Insurance Portability and Accountability Act) imposes a very restrictive data privacy requirement to protect patient health information (Hanson et al., 2022). HIPAA is critical to protecting patient information and requires providers to comply with the provision, which can sometimes become an obstacle to sharing information among healthcare workers in different institutions or hospitals catering to cancer patients.</p>
                        <h3>Reimbursement Policies and Funding Mechanisms</h3>
                        <p>Reimbursement policies and fund mechanisms are the other crucial points in the governmental policies that significantly influence care coordination. Medicare and Medicaid, essential players in this tune, determine the reimbursements for providers and the coverage criteria for distinct healthcare services (Jim&eacute;nez &amp; Paulo, 2023). Different policies regarding reimbursements or funding might directly determine how resources are distributed to organize care for cancer patients. What is handed out will also affect the quality and comprehensiveness of treatment.</p>
                        <h3>Examples of Specific Policies</h3>
                        <p>Healthcare policies that are implemented explicitly for cancer care coordination could have. A program in which physicians combine their efforts and the quality of their services and save on costs may be shown to have an integral role by the Medicare Shared Savings Program (Iserson, 2023). The main reason is that rewarding the investment in people&rsquo;s health with care coordination initiatives is proper. Similarly, apart from policies in reimbursement for telehealth or licensure requirements, some issues can lead to the favoring of telemedicine services in coordinating care for cancer patients, especially for people residing in rural and underserved areas.</p>
                        <img src="images/NURSFPX4050Assessment2.png" alt="" />
                        <h2>Ethical Dilemmas in Policy Provisions for Care Coordination</h2>
                        <h3>National Policy Provisions:</h3>
                        <p>At the national level, the ACA (Affordable Care Act) has been a critical critical critical player in healthcare policy deliberation regarding enrollment and access to complete care and coverage. Whereas the ACA was supposed to promote the expansion of healthcare services for a specific population, some ethical cases occur regarding the coverage of certain groups of patients, like cancer sufferers (Conover &amp; Bailey, 2020). As an illustration, cases of wildly different health coverage affordability or treating essential needs may exist, leading to inequalities in getting the proper healthcare. Crucial discussions equitably and justly need to be brought to healthcare delivery.</p>
                        <h3>State Policy Provisions:</h3>
                        <p>Localized policy positions of the respective state, such as the expansion plans of Medicaid, are the ethical problems in care coordination. States that decide not to extend Medicaid coverage options and open up to those without health insurance may create a broad group of low-income cancer patients who are without affordable health insurance (Harry, 2023). On the one hand, this raises moral dilemmas when healthcare institutions are required to provide good quality care to all patients, and on the other, limited Medicaid coverage restricts the ability of public health institutions to access the necessary financial resources.</p>
                        <h3>Local Policy Provisions:</h3>
                        <p>To begin with, the establishment and placement of healthcare facilities are governed by zoning guidelines and premise-siting ordinances at the municipal and sectional levels, creating ethical conflicts of care coordination. Establishing new healthcare facilities or expanding existing ones may imply that there will be physical limitations for cancer patients who still use specialty care services within their local communities (Konnoth &amp; Scheffler, 2020). This infers the ethical dilemma of balancing community interests, such as property values and neighborhood aesthetics, and the ethical undertaking of assisting everybody.</p>
                        <h3>Ethical Implications and Consequences:</h3>
                        <p>The moral dimensions associated with these policy motions are much more wide-ranging as they determine what capabilities healthcare providers have to provide their due care to patients. Some policies create unequal access to health care that sometimes raise issues concerning justice in resource allocation or distribution equity. The limitation of healthcare coverage and access hurts the overall healthcare services provision by widening the health inequalities, especially among marginalized groups in society (Nsiah et al., 2019). Cancer patients who do not have access to diagnose and treat financial hardships or geographical constraints could face prolonged delays in diagnosis, treatment, and supportive care, which may interfere with favorable health results and, hence, poor quality of life.</p>
                        <p>These policy implementations may trigger ethical problems for care coordination, which might be done by influencing access to care and resource allocation and distributing healthcare services at the national, state, and local levels (Smith, 2021). Overcoming the ethical problems in telemedicine using three or more (principles) of justice, benevolence, and patient-centered care in policy development and implementation.</p>
                        <h2>Impact of the Code of Ethics for Nurses on Care Coordination</h2>
                        <h3>Influence on Patient-Centered Care:</h3>
                        <p>The nurses' code of ethics has been quite impactful in imparting a governance model for professional lines of care; it also mainly affects cancer patients. Putting patient-centered healthcare first in this code is the principle that pays much attention to patient autonomy, quality of life, and best interest (Olejarczyk &amp; Young, 2022). The proper functioning of the healthcare system as a decentralized network is coordinated by nurses as frontline caregivers. A scenario where patients get holistic and individualized care that reflects their values and preferences is promoted.</p>
                        <h3>Regarding Social Determinants of Health:</h3>
                        <p>My pursuit of a college education has been propelled by various motivations. From the desire to expand my horizon of knowledge and experience to the ambition to make meaningful contributions in my chosen field, these motivations have shaped my academic journey at this institution and have ignited my passion for lifelong learning.</p>
                        <p>The ethics code also signifies the underlying factors like social determinants, such as socioeconomic status, literacy, and access to medical care, while assembling care coordination strategies. Nurses must act as ethically obliged guardians of equity in health and justice in social structures, acknowledging that these determinants have a very influencing effect not only on individuals` health outcomes but also on the level of their access to the service provided (Lee &amp; Bae, 2022). Social context is crucial as people are cultural, community, and family-oriented. By coordinating social services, nurses help patients structure their lives and seek care based on environmental factors.</p>
                        <h3>Evidence-Based Conclusions:</h3>
                        <p>In the nursing code of ethics, evidence-based practice is highlighted very much as it is considered to use the freshest available evidence to guide the decisions about patient care and improve patient outcomes at a similar time. Through the integrated use of evidence-based guidelines and protocols administrated by nurses, the interventions are grounded in the research and thus guided by the latest advancements in cancer care (Flaubert et al., 2021). That improves the quality of care delivery service, which eventually leads to better patient results along the care continuum or, in other words, from prevention and early detection through diagnosis, treatment, and survivorship or progression to an end of life.</p>
                        <h2>Conclusion</h2>
                        <h3>Slide 9:</h3>
                        <p>The issues regarding ethics and policy clearly show the problems entailed in chronic disease care coordination, especially with cancers. We have analyzed this issue from government policies, ethics of health care, and the national code of ethics. We have obtained a deep knowledge of the issues and perspectives of medical caregivers and policymakers. By addressing these concerns in a manner that places the patient first, considering the social determinants of health, and adopting evidence-based practices, we can work toward equitable care coordination that eventually leads to good results among patients affected with chronic diseases. Thank you for being with us at this event, where we shared valuable information.</p>
                        <h2>References</h2>
                        <p class="text-wrap">Conover, C. J., &amp; Bailey, J. (2020). Certificate of need laws: A systematic review and cost-effectiveness analysis. <em>BMC Health Services Research</em>, <em>20</em>(1). <u>https://doi.org/10.1186/s12913-020-05563-1</u></p>
                        <p class="text-wrap">Ercia, A. (2021). The impact of the Affordable Care Act on patient coverage and access to care: Perspectives from FQHC administrators in Arizona, California, and Texas. <em>BMC Health Services Research</em>, <em>21</em>(1), 1&ndash;9. <u>https://doi.org/10.1186/s12913-021-06961-9</u></p>
                        <p class="text-wrap">Flaubert, J. L., Menestrel, S. L., Williams, D. R., &amp; Wakefield, M. K. (2021). Educating nurses for the future. In <em>www.ncbi.nlm.nih.gov</em>. National Academies Press (US). <u>https://www.ncbi.nlm.nih.gov/books/NBK573912/</u></p>
                        <p class="text-wrap">Hanson, K., Brikci, N., Erlangga, D., Alebachew, A., De Allegri, M., Balabanova, D., Blecher, M., Cashin, C., Esperato, A., Hipgrave, D., Kalisa, I., Kurowski, C., Meng, Q., Morgan, D., Mtei, G., Nolte, E., Onoka, C., Powell-Jackson, T., Roland, M., &amp; Sadanandan, R. (2022). The Lancet Global Health Commission on financing primary health care: Putting people at the center. <em>The Lancet Global Health</em>, <em>10</em>(5), e715&ndash;e772. <u>https://doi.org/10.1016/s2214-109x(22)00005-5</u></p>
                        <p class="text-wrap">Harry, K. (2023). <em>ORCA -online research @ Cardiff</em>. <u>https://orca.cardiff.ac.uk/id/eprint/128331/1/Levi</u></p>
                        <p class="text-wrap">Iserson, K. V. (2023). Providing ethical healthcare in resource-poor environments. <em>HEC Forum</em>, <em>5</em>(3). <u>https://doi.org/10.1007/s10730-018-9346-7</u></p>
                        <p class="text-wrap">Jim&eacute;nez, V., &amp; Paulo, A. (2023). Transaction costs in healthcare: Empirical evidence from Portuguese hospitals. <em>BMC</em>, <em>10</em>(2). <u>https://doi.org/10.1080/23311975.2023.2220479</u></p>
                        <p class="text-wrap">Konnoth, C., &amp; Scheffler, G. (2020). Can electronic health records be saved? <em>American Journal of Law &amp; Medicine</em>, <em>46</em>(1), 7&ndash;19. <u>https://doi.org/10.1177/0098858820919552</u></p>
                        <p class="text-wrap">Lee, J. J., &amp; Bae, S. G. (2022). Implementation of a care coordination system for chronic diseases. <em>Yeungnam University Journal of Medicine</em>, <em>36</em>(1), 1&ndash;7. <u>https://doi.org/10.12701/yujm.2019.00073</u></p>
                        <p class="text-wrap">Lin, Y., Monnette, A., &amp; Shi, L. (2021). Effects of Medicaid expansion on poverty disparities in health insurance coverage. <em>International Journal for Equity in Health</em>, <em>20</em>(1). <u>https://doi.org/10.1186/s12939-021-01486-3</u></p>
                        <p class="text-wrap">Nsiah, C., Siakwa, M., &amp; Ninnoni, J. (2019). Registered nurses&rsquo; description of patient advocacy in the clinical setting. <em>Nursing Open</em>, <em>6</em>(3), 1124&ndash;1132. <u>https://doi.org/10.1002/nop2.307</u></p>
                        <p class="text-wrap">Olejarczyk, J. P., &amp; Young, M. (2022, November 28). <em>Patient rights and ethics</em>. National Library of Medicine; StatPearls Publishing. <u>https://www.ncbi.nlm.nih.gov/books/NBK538279/</u></p>
                        <p class="text-wrap">Smith, W. (2021). <em>Environmental health and safety management system</em>. Nih.gov; National Academies Press (US).</p>
                        <p class="text-wrap">Link : <a href="https://www.ncbi.nlm.nih.gov/books/NBK55873/">https://www.ncbi.nlm.nih.gov/books/NBK55873/</a></p>
                        <p class="text-wrap">Require help with your <a href="https://onlinecourseservices.us/nurs-fpx-4050-assessment-1">NURS FPX 4050 Assessment 1?</a> Our expert team is standing by to assist you. Get in touch with us now</p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx4050assessment2
