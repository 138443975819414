import React from 'react';
import {FaRegArrowAltCircleRight, FaPhoneAlt} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Bannerfour = () => {
  return (
    <>
      <div className='ocs2-bannerone'>
        <div className='ocs2-bannerone-image'>
          <img src='images/bannerone.png' alt='' className='ocs2-bannerone-image-box'/>
        </div>
        <div className='ocs2-bannerone-detail'>
          <p className='ocs2-bannerone-detail-title'>EXPERIENCE ACADEMIC EXCELLENCE WITH CAPELLA <br/>RN-BSN PAPERS WRITING SERVICES!</p>
          <p className='ocs2-bannerone-detail-text'>Never compromise on your grades. Get help from our professional assistant for guidance inyour Capella MSN Flexpath program. Secure your future right now!</p>
          <div className='ocs2-orderNow-box'>
          <Link to={"/order"} className='ocs2-orderNow-btnn'><button className='ocs2-orderNow'>Order Now &nbsp;<FaRegArrowAltCircleRight/></button></Link>
          <img src='images/time.png' alt='' className='ocs2-orderNow-time'/>
          <div className='ocs2-bannerone-phoneNo'>
            <FaPhoneAlt className='ocs2-bannerone-phoneNo-icon'/>
            <span>
              <p>Call us at</p>
              <strong>+1(669) 209-0161</strong>
            </span>
          </div>
        </div>
        </div>
      </div>
    </>
  )
}

export default Bannerfour
