import React from 'react'
import Msappliedhero from './Msappliedcomponent/Msappliedhero'
import Msapplieddegreedetail from './Msappliedcomponent/Msapplieddegreedetail'
import Bsnfeatures from '../Nursing/bsnpagecomponent/Bsnfeatures'
import Bannertwo from '../../homepage/Bannertwo'
import Bsnfaqs from '../Nursing/bsnpagecomponent/Bsnfaqs'

const Msapplied = () => {
  return (
    <div>
      <Msappliedhero/>
      <Msapplieddegreedetail/>
      <Bsnfeatures/>
      <Bannertwo/>
      <Bsnfaqs/>
    </div>
  )
}

export default Msapplied
