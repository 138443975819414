import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/authContext';

const Mdadminpage = () => {
    const { currentUser, logout } = useContext(AuthContext);
  return (
    <div className='admin-page'>
        <div className='admin-page-inner'>
            <span>This is admin dashboard</span>
            <div className='user-flex'>
            <div className='user'>
                <span><img src='images/person.png' alt=''/></span><br/>
                <span>User Name<br/>{currentUser?.username}</span>
            </div>

            <ul className='admin-page-links'>
                <li><Link className='c-link' to="/create" preventScrollReset={false}>Create</Link></li>
                <li><Link className='c-link' to="/login">Login</Link></li>
                <li></li>
                <li>{currentUser ? 
                    (<span onClick={logout}>Logout</span> ) : 
                    (<Link className="c-link" to="/login">Login</Link>)}
                </li>
            </ul>
            </div>
        </div>
    </div>
  )
}

export default Mdadminpage
