import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx6612assessment2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 6612 Assessment 2 Quality Improvement Proposal</title>
                <meta name='description'
                    content='Create a strategic quality improvement proposal with insights from NURS FPX 6612 Assessment 2 Quality Improvement Proposal at online course services.' />
                <meta name='keywords' content='NURS FPX 6612 Assessment 2 Quality Improvement Proposal' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 6612 Assessment 2 < br /><span>Quality Improvement Proposal</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/NURSFPX6612Assessment2.png" alt="" />
                    <div className='main-container-sl-content'>
                        <h2><strong>Quality Improvement Proposal</strong></h2>
                        <p>ACOs are bodies of healthcare providers who collaborate to decrease the costs of the customer and enhance on the quality of services. In order to achieve these goals, ACOs aim at integrating care, information and early intervention and prevention (Lewis et al. , 2019). This is a key task of ACOs, to help doctors to cooperate more effectively with each other. To this end, ACOs should establish a complete care management program with usual care procedures, communication between physicians, and exchanging of patient data. This will assist in the achievement of the right care from the appropriate health care provider at the right time as postulated by Trombley et al. (2019).</p>
                        <p>It is very important to involve patients in any healthcare project if it has to bring the desired results. ACOs should ensure that the patient participates in their care through having access to their health records, participating in the decision making on their care, and understanding how to take care of their health. The patients will be compliant with the treatment plans if they have an interest in their care (Trombley et al. , 2019).</p>
                        <p>ACOs should therefore find a method of assessing and monitoring the level of care that is being given by the workers. This approach should contain both the professional and the patient data, and should be used in order to determine areas for improvement. Thus, to achieve their quality goals ACOs are required to do, this will assist in the provision of quality care to the patients (Lewis et al. , 2019).</p>
                        <h2><strong>Recommended Ways to Expand an Organization's Health Information Technology (HIT)</strong></h2>
                        <p>HIT is a way of improving care and results in improved results of care. It assist healthcare companies in managing their work, reduce costs and improve the quality of service delivery to the patients. It is therefore agreeable that the use of quality measures in a company&rsquo;s system is one of the most effective ways of growing its HIT. Quality metrics assist healthcare workers to know their performance and outcomes, identify their areas of strength and weakness and thus improve patient safety and satisfaction (Ratwani et al. , 2019).</p>
                        <p>The first thing is to identify what quality measures are relevant to the company, that is the measures that correspond to the company&rsquo;s vision and mission. Some of the quality measures include: Clinical results, patient satisfaction, patients safety, and the rate of process. According to Harris et al. (2019) these are some of the measures that should be selected as they are relevant and feasible for the company.</p>
                        <p>It is important to educate and teach the healthcare workers and providers to know how to apply quality measures in the course of their work. It may include tasks like how to interpret the data and how to use the data to identify the areas for improvement and tracking progress over the time (Stratton 2019). The HIT system should be associated with a program for enhancing quality which considers and examines the quality parameters on a daily agenda. It should also be possible for the program to search for and correct any gaps or areas of the program that may have weakness in the level of care (Wensing &amp; Grol, 2019).</p>
                        <p>Establish a CQI programme that would involve identifying quality metrics with a view to improving on them and coming up with specific measures to take in the process. All the patients, doctors and other staff should be involved in this program and it should be revised and revised at a certain intervals for it to be effective (Stratton, 2019).</p>
                        <p>Another way to assist the patient is to teach the patient. It is also important that doctors and nurses explain to their patients what medical tests are, how to obtain them and the consequences of not going for them. This can assist people in comprehending the significance of these tests, and they can make an appointment (O&rsquo;Hanlon et al. , 2019).</p>
                        <p>Advance such as emails and videos enable the healthcare workers to communicate with the other members of the health care team including the doctors, pharmacists and other nurses. This has the potential of making people communicate and collaborate effectively hence enhancing better results for the patients.</p>
                        <h2><strong>Information Gathering in Healthcare and Development of Organizational Practice</strong></h2>
                        <p>In healthcare, information gathering is the process of going out there to gather, and analyze information with the aim of enhancing the quality of services being rendered to patients. The information gathered is to identify gaps in the current practice of health care, assess the effectiveness of interventions and inform decisions that will lead to enhanced health care practices (Carayon &amp; Hoonakker, 2019). It can be collected during patient&rsquo;s visit or from electronic health records, through surveys, quality management activities, and exams for professionals. It captures the entire process from the time the patient is assessed to the time he or she is treated and on the road to recovery. Through this data, healthcare workers can identify the patterns and the trends that can assist them in enhancing their own practice (Sousa et al. , 2019).</p>
                        <p>There are various methods of data collection that the healthcare groups can use to identify the areas that need some enhancement, generate ideas on how to address the identified issues and monitor the effectiveness of the solutions in place over time. This results in the formulation of evidence-based business strategies that are intended at enhancing the quality of service delivery to the patients. Such information can also assist the healthcare groups to identify and establish the patterns and trends of their patients care and the performance of their care. It is, therefore, possible for the organizations to prepare for any challenges that may occur and handle them before they turn into major issues. (2019) Carayon and Hoonakker.</p>
                        <p>For instance, the University of Pittsburgh Medical Center (UPMC) employs data analytics to reduce readmissions of patients in hospitals. These patients are patients who are readmitted to the hospital within 30 days of discharge and UPMC analyzes why such patients are readmitted. Then, they employ this information in an effort to reduce readmissions of patients to the hospital, for instance, by spending more time with the patients during the discharge process (Roman et al., 2019).</p>
                        <p>Similarly, the Mayo Clinic employs data analytics in a bid to improve the lives of the patients they attend to. The center maintains information on the well being of the patients, the results of their treatment, and the costs of treatment. The data is utilized to identify areas of improvement and how to enhance the quality of care through, for instance, the provision of clinical pathways and reduction of unnecessary tests (Romero-Brufau et al., 2021). Mount Sinai Hospital conducts focus group discussions to learn from the patients about their experience of care. We then use this data to improve the patient care and design better patient centred business activities (Shin et al., 2021).</p>
                        <h2><strong>Potential Problems with Data-Gathering Systems and Outputs</strong></h2>
                        <p>In healthcare, the techniques and the ways of obtaining data are crucial. They assist the doctors and the nurses in compiling, analyzing and interpreting the patient information in order to arrive at right decisions and provide the right treatment. However, the tools and methods of data collection and data presented have some problems; this can result in wrong findings, wrong conclusions, and poor patient care (Lv &amp; Qiao, 2020).</p>
                        <p>When data is collected but either is not complete or contains error this is referred to as incomplete or faulty data collection. This can lead to wrong research and wrong decisions to be made. There are occasions when it is not easy to distinguish patients or groups of persons when collecting data in different ways. Standardization is needed to interpret facts and to be able to come up with right strategies. In 2020, Jarr&iacute;n et al. Occasionally, it is difficult to gather data because people provide incorrect or insufficient information. Cultural differences make it difficult to gather data when the language of the two cultures is different. This can result in having incomplete or inaccurate data that is being collected. This can be rather challenging to determine whether or not all the necessary data has been gathered (Hariri et al. , 2019).</p>
                        <p>This is because as the healthcare business integral to the information management of patients through the use of various technology, the issue of data breaches is on the rise. When a person who is not authorized to be in possession of private information gets to be in possession of such information, then there is data breach and security threats. It could result in leakage of information and data breach (Seh et al. , 2020). Thus, it is quite probable that data hacks will occur more often in the healthcare business because patients&rsquo; data is rather sensitive. The percentage is also rising because more electronic health data are actually being applied. Cyber threats are dynamic and can only be identified and prevented to some extent due to the ever-evolving cyber threats (Chernyshev et al. , 2018).</p>
                        <p>Healthcare data forms that are not standardized or integrated can lead to numerous issues for the patients like adverse events, delay in treatment or inadequate healthcare systems. When it is difficult to integrate information from two or more sources the forms of the information are not consistent or adequate. These are formatted or stored in a different manner and thus cannot be analyzed in the same study (Wang et al. , 2019).</p>
                        <p>Healthcare disparities and non-inclusive data result in various people not being able to use health care services and healthcare products because of their cultural, economical, or other characteristics. These may lead to disparities in health outcomes because of these differences, and hence worsen health inequalities. When the process of data collection or analysis further creeates or aggravates structural biases or inequalities then it is called as data bias and disparities (Parikh et al. , 2019).</p>
                        <p>Ensure that the methods that you use to collect data are likely to minimise chance of bias. Invest in the education of the healthcare workers and staff on the issues of diversity and inclusion. Monitor data on a daily basis for disparities or bias (Obermeyer et al. , 2019). Thus, poor data handling can produce very negative consequences in the sphere of health. When data is not stored, maintained or used in decision making process, this is known as poor data governance and management. It results in loss of resources and fails to give an opportunity to improve on things (Abraham et al. , 2019). The group bias may occur when the people in the group are not in any way a true reflection of the community. There are directions that are required in the management and control of data that may not be easily defined and implemented especially in the healthcare industry. Discrimination and shame can cause people not seek treatment or reveal their information. This can lead people to avoid reporting some health conditions or behaviours and this is known as data bias (Lv &amp; Qiao, 2020).</p>
                        <h2><strong>Conclusion</strong></h2>
                        <p>In conclusion, getting information is an important part of working in healthcare. Care and duty must be used to gather it. It helps organizations come up with methods that are based on data and improve patient care and healthcare results. They need to use a variety of methods to gather information and use it to guide the growth of their activities. Naturally, if the data is gathered correctly, the outcomes will be good for the whole health sector.</p>
                        <p><em><strong>Explore more related samples by clicking below:</strong></em><br /><em><strong><span><a href="https://onlinecourseservices.us/nurs-fpx-6612-assessment-1-triple-aim-outcome-measures"><span data-sheets-root="1">NURS FPX 6612 Assessment 1 Triple Aim Outcome Measures</span>&nbsp;</a></span></strong></em></p>
                        <h2><strong>References</strong></h2>
                        <p>Abraham, R., Schneider, J., &amp; vom Brocke, J. (2019). Data governance: A conceptual framework, structured review, and research agenda. <em>International Journal of Information Management</em>, <em>49</em>, 424&ndash;438.</p>
                        <p><a href="https://doi.org/10.1016/j.ijinfomgt.2019.07.008">https://doi.org/10.1016/j.ijinfomgt.2019.07.008</a></p>
                        <p>Alexander, G. L., &amp; McMullen, T. (2023). Probing into federal policies and national academies&rsquo; recommendations to adopt health information technology in all U.S. nursing homes. <em>Public Policy &amp; Aging Report</em>, <em>33</em>(Supplement_1), S28&ndash;S34.</p>
                        <p><span><u>https://doi.org/10.1093/ppar/prac026</u></span></p>
                        <p>Carayon, P., &amp; Hoonakker, P. (2019). Human factors and usability for health information technology: Old and new challenges. <em>Yearbook of Medical Informatics</em>, <em>28</em>(01), 071&ndash;077.</p>
                        <p><a href="https://doi.org/10.1055/s-0039-1677907">https://doi.org/10.1055/s-0039-1677907</a></p>
                        <p>Chernyshev, M., Zeadally, S., &amp; Baig, Z. (2018). Healthcare data breaches: Implications for digital forensic readiness. <em>Journal of Medical Systems</em>, <em>43</em>(1). <a href="https://doi.org/10.1007/s10916-018-1123-2">https://doi.org/10.1007/s10916-018-1123-2</a></p>
                        <p>Hariri, R. H., Fredericks, E. M., &amp; Bowers, K. M. (2019). Uncertainty in big data analytics: survey, opportunities, and Challenges. <em>Journal of Big Data</em>, <em>6</em>(1), 1&ndash;16. <a href="https://doi.org/10.1186/s40537-019-0206-3">https://doi.org/10.1186/s40537-019-0206-3</a></p>
                        <p>Harris, P. A., Taylor, R., Minor, B. L., Elliott, V., Fernandez, M., O'Neal, L., McLeod, L., Delacqua, G., Delacqua, F., Kirby, J., &amp; Duda, S. N. (2019). The REDCap consortium: Building an international community of software platform partners. <em>Journal of Biomedical Informatics</em>, <em>95</em>, 103208.</p>
                        <p><a href="https://doi.org/10.1016/j.jbi.2019.103208">https://doi.org/10.1016/j.jbi.2019.103208</a></p>
                        <p>Jarr&iacute;n, O. F., Nyandege, A. N., Grafova, I. B., Dong, X., &amp; Lin, H. (2020). Validity of race and ethnicity codes in medicare administrative data compared with gold-standard self-reported race collected during routine home health care visits. <em>Medical Care</em>, <em>58</em>(1), e1&ndash;e8.</p>
                        <p><a href="https://doi.org/10.1097/mlr.0000000000001216">https://doi.org/10.1097/mlr.0000000000001216</a></p>
                        <p>Kyaw, B. M., Saxena, N., Posadzki, P., Vseteckova, J., Nikolaou, C. K., George, P. P., Divakar, U., Masiello, I., Kononowicz, A. A., Zary, N., &amp; Tudor Car, L. (2019). virtual reality for health professions education: Systematic review and meta-analysis by the digital health education collaboration. <em>Journal of Medical Internet Research</em>, <em>21</em>(1), e12959.</p>
                        <p><span ><u>https://doi.org/10.2196/12959</u></span></p>
                        <p>Lewis, V. A., Schoenherr, K., Fraze, T., &amp; Cunningham, A. (2019). Clinical coordination in accountable care organizations. <em>Health Care Management Review</em>, <em>44</em>(2), 127&ndash;136. <a href="https://doi.org/10.1097/hmr.0000000000000141">https://doi.org/10.1097/hmr.0000000000000141</a></p>
                        <p>Lv, Z., &amp; Qiao, L. (2020). Analysis of healthcare big data. <em>Future Generation Computer Systems</em>, <em>109</em>, 103&ndash;110.</p>
                        <p><a href="https://doi.org/10.1016/j.future.2020.03.039">https://doi.org/10.1016/j.future.2020.03.039</a></p>
                        <p>Obermeyer, Z., Powers, B., Vogeli, C., &amp; Mullainathan, S. (2019). Dissecting racial bias in an algorithm used to manage the health of populations. <em>Science</em>, <em>366</em>(6464), 447&ndash;453. <a href="https://doi.org/10.1126/science.aax2342">https://doi.org/10.1126/science.aax2342</a></p>
                        <p>O&rsquo;Hanlon, C. E., Kranz, A. M., DeYoreo, M., Mahmud, A., Damberg, C. L., &amp; Timbie, J. (2019). Access, quality, and financial performance of rural hospitals following health system affiliation. <em>Health Affairs</em>, <em>38</em>(12), 2095&ndash;2104.</p>
                        <p><span ><u>https://doi.org/10.1377/hlthaff.2019.00918</u></span></p>
                        <p>Parikh, R. B., Teeple, S., &amp; Navathe, A. S. (2019). Addressing bias in artificial intelligence in health care. <em>JAMA</em>, <em>322</em>(24), 2377.</p>
                        <p><a href="https://doi.org/10.1001/jama.2019.18058">https://doi.org/10.1001/jama.2019.18058</a></p>
                        <p>Ratwani, R. M., Reider, J., &amp; Singh, H. (2019). A decade of health information technology usability challenges and the path forward. <em>JAMA</em>, <em>321</em>(8), 743. <a href="https://doi.org/10.1001/jama.2019.0161">https://doi.org/10.1001/jama.2019.0161</a></p>
                        <p>Roman, E., Broom, K., Lyons, A., &amp; Donahoe, M. (2019, April 16). Telemedicine: A race to catch up. D-Scholarship.pitt.edu.</p>
                        <p><a href="http://d-scholarship.pitt.edu/36210/">http://d-scholarship.pitt.edu/36210/</a></p>
                        <p>Romero-Brufau, S., Whitford, D., Johnson, M. G., Hickman, J., Morlan, B. W., Therneau, T., Naessens, J., &amp; Huddleston, J. M. (2021). Using machine learning to improve the accuracy of patient deterioration predictions: Mayo Clinic Early Warning Score (MC-EWS). <em>Journal of the American Medical Informatics Association</em>, <em>28</em>(6), 1207&ndash;1215. <a href="https://doi.org/10.1093/jamia/ocaa347">https://doi.org/10.1093/jamia/ocaa347</a></p>
                        <p>Seh, A. H., Zarour, M., Alenezi, M., Sarkar, A. K., Agrawal, A., Kumar, R., &amp; Khan, R. A. (2020). Healthcare data breaches: Insights and implications. <em>Healthcare</em>, <em>8</em>(2), 133. NCBI.</p>
                        <p><a href="https://doi.org/10.3390/healthcare8020133">https://doi.org/10.3390/healthcare8020133</a></p>
                        <p>Shin, S. J., Pang, J. H., Tiersten, L., Jorge, N., Hirschmann, J., Kutsy, P., Ashley, K., Stein, L., Safer, J. D., &amp; Barnett, B. (2021). The Mount Sinai interdisciplinary approach to perioperative care improved the patient experience for transgender individuals. <em>Transgender Health</em>.</p>
                        <p><a href="https://doi.org/10.1089/trgh.2020.0134">https://doi.org/10.1089/trgh.2020.0134</a></p>
                        <p>Sousa, M. J., Pesqueira, A. M., Lemos, C., Sousa, M., &amp; Rocha, &Aacute;. (2019). Decision-making based on big data analytics for people management in healthcare organizations. <em>Journal of Medical Systems</em>, <em>43</em>(9).</p>
                        <p><a href="https://doi.org/10.1007/s10916-019-1419-x">https://doi.org/10.1007/s10916-019-1419-x</a></p>
                        <p>Stratton, T. D. (2019). Legitimizing continuous quality improvement (CQI): Navigating rationality in undergraduate medical education. <em>Journal of General Internal Medicine</em>, <em>34</em>(5), 758&ndash;761.</p>
                        <p><a href="https://doi.org/10.1007/s11606-019-04875-1">https://doi.org/10.1007/s11606-019-04875-1</a></p>
                        <p>Trombley, M. J., Fout, B., Brodsky, S., McWilliams, J. M., Nyweide, D. J., &amp; Morefield, B. (2019). Early Effects of an Accountable Care Organization Model for Underserved Areas. <em>New England Journal of Medicine</em>, <em>381</em>(6), 543&ndash;551.</p>
                        <p><a href="https://doi.org/10.1056/nejmsa1816660">https://doi.org/10.1056/nejmsa1816660</a></p>
                        <p>Wang, Z., Dagtas, S., Talburt, J., Baghal, A., &amp; Zozus, M. (2019). Rule-based data quality assessment and monitoring system in healthcare facilities. <em>Studies in Health Technology and Informatics</em>, <em>257</em>, 460&ndash;467.</p>
                        <p><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6692115/">https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6692115/</a></p>
                        <p>Wang, Z., Talburt, J. R., Wu, N., Dagtas, S., &amp; Zozus, M. N. (2020). A rule-based data quality assessment system for electronic health record data. <em>Applied Clinical Informatics</em>, <em>11</em>(04), 622&ndash;634.</p>
                        <p><a href="https://doi.org/10.1055/s-0040-1715567">https://doi.org/10.1055/s-0040-1715567</a></p>
                        <p>Wensing, M., &amp; Grol, R. (2019). Knowledge translation in health: How implementation science could contribute more. <em>BMC Medicine</em>, <em>17</em>(1).</p>
                        <p><a href="https://doi.org/10.1186/s12916-019-1322-9">https://doi.org/10.1186/s12916-019-1322-9</a></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Nursfpx6612assessment2
