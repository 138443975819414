import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";

const DNPdissertationhelp = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Research Writers",
            Desc: "Get help from skilled Capella DNP FlexPath research writers. They are always ready for help.",
        },
        {
            icon: "fa-solid fa-school icon",
            title: "Class Help",
            Desc: "Our tutors offer Capella University DNP FlexPath class-taking services for Capella DNP FlexPath students!",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Courses Help",
            Desc: "Take help from Capella DNP FlexPath professionals in their Capella DNP FlexPath courses.",
        },
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Tutoring Help",
            Desc: "Take help from our tutors, who are Capella DNP FlexPath Curriculum professionals. ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a Capella DNP FlexPath intellectuals team to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We can always help you with your Capella DNP FlexPath Curriculum and coursework!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to Capella University DNP FlexPath students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek complete support from Capella University DNP FlexPath nursing experts.",
        },
    ]
  return (
    <>
    <div className='s-hero services'>
        {/* <div className='s-hero-top'>
    <div className='sherotop-left'>
    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
    </div>
    <div className='cal-box'><Calculator/></div> 
</div> */}
        <div className='ocs2-main-box'>

            <div className='ocs2-main' id='hero'>
                <div className='ocs2-mainHeading'>
                    <h1><span className='ocs2-topHeading'>COMPREHENSIVE DNP DISSERTATION HELP SERVICES</span><br />
                        </h1>
                        <h2>ARE YOU STRESSED ABOUT DISSERTATION WRITING?</h2>
                    <p className='ocs2-topText'> Let's cope with your stress with our top-notch and affordable DNP Dissertation Help Services.</p>
                    <ul>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                    </ul>
                    <div className='ocs2-banner-capella'>
                        <div className='ocs2-banner-capella-path'>
                            <span>
                                <h3 className='f-path-pointer'>Flex Path</h3>
                                <p>Use your experience to complete courses.</p>
                            </span>
                        </div>
                        <div className='ocs2-banner-capella-path'>
                            <span>
                                <h3 className='f-path-pointer'>Guided Path</h3>
                                <p>Enjoy the flexibility to study anywhere.</p>
                            </span>
                        </div>
                    </div>
                    <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                    <div className='ocs2-ratting'>
                        <div className='ocs2-ratting-provider'>
                            <SiTrustpilot className='ocs2-trustpilot-icon' />
                            <div>
                                <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                <p>TrustPilot</p>
                            </div>
                        </div>
                        <div className='ocs2-ratting-provider'>
                            <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                            <div>
                                <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                <p>OnlineCourseServices</p>
                            </div>
                        </div>
                        <div className='ocs2-ratting-provider'>
                            <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                            <div>
                                <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                <p>Sitejabber</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='ocs2-form'>
                    <Calculator />
                </div>
            </div>

        </div>
        <div className='s-cta'>
            <div className='s-cta-inner'>
                <div className='s-cta-inner-box'>
                    <h2>Hire Professionals</h2>
                    <p>Take help from professional tutors, mentors, and writers to excel in your Capella University DNP FlexPath coursework.</p>
                </div>
                <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
            </div>
            <div className='s-cta-inner'>
                <div className='s-cta-inner-box'>
                    <h2>Get Nursing Papers</h2>
                    <p>Get excellent and well-researched Capella DNP FlexPath nursing papers!</p>
                </div>
                <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
            </div>
        </div>
    </div>


    <div className='freesamples services'>
        <h2>GET CAPELLA DNP FLEXPATH ASSESSMENT SOLUTIONS!</h2>
        <p>We provide excellent Capella University DNP FlexPath assessment solutions for students in their Capella DNP FlexPath Courses.</p>
        <div className='sampleboxes'>
            {newSamples.map((sample) => {
                return (

                    <div className='singlebox'>
                        <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                        <div className='text'>
                            <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                            <p>{sample.description}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>


    <div className='services s-section'>
        <div className='s-left'>
            {newServices.map((newserivce) => {
                return (
                    <div className='s-single'>
                        <i class={newserivce.icon}></i>
                        <h3>{newserivce.title}</h3>
                        <p>{newserivce.Desc}</p>
                    </div>
                )
            })}
        </div>
        <div className='s-right'>
            <span>Our Educational Services</span>
            <h2>CAPELLA UNIVERSITY DNP DISSERTATION HELP ONLINE SERVICES</h2>
            <p>Online Course Services specialize in providing cutting-edge and affordable DNP Dissertation Help Online Services. Our team of expert dissertation writers, mentors, and intellectuals is dedicated to offering comprehensive solutions for exams, research papers, and dissertations ensuring that your dissertation meets the best standards of professionalism. Online Course Services offers affordable dissertation writing services to Capella University Nursing students. For DNP Dissertation Help Online writing services, we offer compatible and budget-friendly services such as low-cost alternatives to shop for nursing dissertations, and expert nursing dissertation help services. Let's release the stress and comfy your life with our affordable and comprehensive DNP dissertation writing services.</p>
            <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
        </div>
    </div>

    <div className='services cta'>
        <Calltoaction />
    </div>
    <div className='services'>
        <Tabs>
            <TabList>
                <Tab><strong>Avail Comprehensive DNP Dissertation Writing services</strong></Tab>
                <Tab><strong>Cheap Nursing Dissertation Writing Services</strong></Tab>
            </TabList>

            <TabPanel>
                <p>Our comprehensive nursing dissertation help writing services provide the best solutions to students who are looking to seek help in writing competitive dissertations. We have a team of professionals that mainly focus on qualitative and quantitative research in nursing topics. Our team is doing its best to provide DNP dissertation writing services that meet the best academic excellence.
                    <br /> <br />
                    <strong>Get the best Nursing Dissertation</strong><br />
                    Dissertation writing requires deep research, clear facts, and concise writing. We help students explore topics of their interests, find different research papers according to the topic, and give new ideas to make a revolution in the nursing industry. Our writers write dissertations with well-researched facts that meet the academic requirements.
                    <br /><br />
                  
                </p>
            </TabPanel>
            <TabPanel>
                <p>We offer affordable prices for nursing dissertation help writing services. From topic searching to the last chapter of the dissertation Online course services provide everything that you need for your dissertation. Our experienced writers and editors will let you produce a properly researched, nicely-written dissertation that meets the best academic standards.
                 </p>
            </TabPanel>
        </Tabs>
    </div>

    <div className='process services freesamples'>
        <h2>HOW IT WORKS?</h2>
        <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
        <div className='processboxes'>
            {orderprocess.map((order) => {
                return (

                    <div className='singleprocess'>
                        <i class={order.icon}></i>
                        <h3>{order.title}</h3>
                        <p>{order.Desc}</p>
                    </div>
                )
            })}
        </div>
    </div>


    <div className='services s-section'>
        <div className='s-right'>
            <span>Our Features</span>
            <h2>WHY CHOOSE US FOR DISSERTATION HELP</h2>
            <p>We at Online Course Services are comprised of a professional team that provides nursing dissertation writing services to Capella University nursing students. Our team of writers, intellectuals, and mentors are experts in dissertation writing. Their expertise will help you in getting good grades in your dissertations. We are assisting students in dissertation writing over the years. We have a track record of a million successful Dissertation of Capella Students. The researchers at Online Course Services do qualitative and quantitative analysis on Dissertation topics to assist students with the best results. Avail of our affordable Dissertation services to get good grades.</p>
            <div><button>Hire Writer</button></div>
        </div>
        <div className='s-left'>
            {newFeatures.map((newserivce) => {
                return (
                    <div className='s-single'>
                        <i class={newserivce.icon}></i>
                        <h3>{newserivce.title}</h3>
                        <p>{newserivce.Desc}</p>
                    </div>
                )
            })}
        </div>
    </div>
    <Stestimonials />
    <Container>
        <div className='ocs2-faqs'>
            <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
            <div className='ocs2-faqs-boxes'>
                <div className='accordian-box-image'>
                    <img src='images/faqs.png' alt='' />
                </div>

                <Accordion className='accordian-boxes'>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Do Online Course Services provide dissertation writing services?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Yes! Our team of professionals provides complete guidance in writing a top-notch dissertation.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Can I hire a writer to write my dissertation?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Yes, You can hire a writer for your dissertation in just one click for exceptional writing services.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Can I buy a Dissertation?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Yes, We provide custom dissertations to nursing students at affordable prices.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Can you offer offer affordable writing services?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Yes, you can get help from our skilled tutors, writers, and mentors at affordable prices.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Can you help me with dissertation proposal writing services?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Yes, Our team of professional researchers will help you with your dissertation proposal.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Can I get a personal tutor for my nursing dissertation?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>
                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Yes! We offer personal tutors for nursing dissertations at an affordable price.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                   
                </Accordion>
            </div>
        </div>
    </Container>
</>
  )
}

export default DNPdissertationhelp
