import React, { useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import { Helmet } from 'react-helmet'

const Order = () => {

    // const scriptURL = 'https://script.google.com/macros/s/AKfycbwWzX1_U2togeUEm1Em4Q-furmuentzYSbky89tJ-EQ2zd0pjBbOF0oktxRaAVGPda0/exec'
    // 		const form = document.forms['orderform']

    // const handleSubmit=(e)=>{
    //     e.preventDefault()
    //     fetch(scriptURL, { method: 'POST', body: new FormData(form)})
    //       .then(alert("Thank you! your form is submitted successfully." ))
    //       .then(() => {  window.location.reload(); })
    //       .catch(error => console.error('Error!', error.message))
    // }

    const [formData, setFormData] = useState({
        serviceType: "Writing",
        workType: "Essay",
        academicLevel: "Under Graduate",
        projectTitle: "",
        subject: "",
        deadline: "",
        pagesNo: "",
        wordCount: "",
        lineSpacing: "",
        guidelines: "",
    })
    const [buttonText, setButtonText] = useState('Order Now');
    console.log(formData)
    const orderData = async (e) => {
        e.preventDefault()
        if (formData.projectTitle === "" || formData.subject === "" || formData.deadline === "" || formData.pagesNo === "" || formData.wordCount === "" || formData.lineSpacing === "" || formData.guidelines === "") {
            alert("please fill all fields correctly")
        }
        else {
            const res = await fetch("https://slack.onlinecourseservices.us/orderdata", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }, body: JSON.stringify({
                    formData
                })
            })
            console.log(res);
            const data = await res.json();
            if (data.status === "error") {
                console.log("error")
            } else {
                setButtonText(buttonText === 'Order Now' ? 'Submitted Successfully' : 'Order Now');
                setTimeout(function () {
                    setButtonText("Order Now");
                }, 1500);
                setFormData({ ...formData, serviceType: "Writing", workType: "Essay", academicLevel: "Under Graduate", projectTitle: "", subject: "", deadline: "", pagesNo: "", wordCount: "", lineSpacing: "", guidelines: "" })
            }
        }
    }
    return (
        <Container className='order-page'>
            <Helmet>
                <title>Order Now</title>
            </Helmet>
            <div className='order-page-box'>
                <span className='o-p-title'>Fill the form given below</span>
                <form action='' method='post' className='order-form' name='orderform'>
                    <div className='order-form-top'>
                        <span className='order-form-flex'>
                            <label for="services">Type of Service</label>
                            <select required id='services' name='type_of_service' value={formData.serviceType} onChange={(e) => setFormData({ ...formData, serviceType: e.target.value })}>
                                <option value="writing">Writing</option>
                                <option value="Proof Reading">Proof Reading</option>
                            </select>
                        </span>
                        <span className='order-form-flex'>
                            <label for="work">Type of Work</label>
                            <select required id='work' name='type_of_work' value={formData.workType} onChange={(e) => setFormData({ ...formData, workType: e.target.value })}>
                                <option value="essay">Essay</option>
                                <option value="assesment">Assesment</option>
                            </select>
                        </span>
                    </div>

                    <span className='order-form-full'>
                        <label for="level">Academic Level</label>
                        <select required id='level' name='academic_level' value={formData.academicLevel} onChange={(e) => setFormData({ ...formData, academicLevel: e.target.value })}>
                            <option value="under graduate">Under Graduate</option>
                            <option value="master">Masters</option>
                            <option value="phd">Phd</option>
                        </select>
                    </span>

                    <span>
                        <label  for="project-title">Project Title</label>
                        <input required type='text' id='project-title' placeholder='Enter the title of your project' name='project_title' value={formData.projectTitle} onChange={(e) => setFormData({ ...formData, projectTitle: e.target.value })} />
                    </span>

                    <div className='order-form-top'>
                        <span className='order-form-flex'>
                            <label for="subject">Subject</label>
                            <input required id='subject' placeholder='What do you need help with?' name='subject' value={formData.subject} onChange={(e) => setFormData({ ...formData, subject: e.target.value })} />
                        </span>
                        <span className='order-form-flex'>
                            <label for="deadline">Deadline</label>
                            <input required id='deadline' placeholder='Enter deadline' name='deadline' value={formData.deadline} onChange={(e) => setFormData({ ...formData, deadline: e.target.value })} />
                        </span>
                    </div>

                    <div className='order-form-top'>
                        <span className='order-form-flex-3'>
                            <label for="no-of-pages">Number of Pages</label>
                            <input required id='no-of-pages' placeholder='Enter the number of pages' name='number_of_pages' value={formData.pagesNo} onChange={(e) => setFormData({ ...formData, pagesNo: e.target.value })} />
                        </span>
                        <span className='order-form-flex-3'>
                            <label for="word-count">Word Count</label>
                            <input required id='word-count' placeholder='Enter Word Count' name='word_count' value={formData.wordCount} onChange={(e) => setFormData({ ...formData, wordCount: e.target.value })} />
                        </span>

                        <span className='order-form-flex-3'>
                            <label for="line-spacing">Line Spacing</label>
                            <input required id='line-spacing' placeholder='Enter Line Spacing' name='line_spacing' value={formData.lineSpacing} onChange={(e) => setFormData({ ...formData, lineSpacing: e.target.value })} />
                        </span>
                    </div>
                    <div className='textarea'>
                        <label for="project-details">Project Guidelines</label>
                        <textarea required placeholder='Tell us more about project ant its requirements' id='project-details' name='project_guidelines' value={formData.guidelines} onChange={(e) => setFormData({ ...formData, guidelines: e.target.value })}></textarea>
                    </div>
                    <button type='submit' onClick={orderData}>{buttonText}</button>
                </form>
            </div>
        </Container>
    )
}

export default Order
