import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx4050assessment3 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 4050 Assessment 3</title>
                <meta name='description'
                    content='Access detailed support for NURS FPX 4050 Assessment 3 to advance your nursing expertise and achieve academic excellence.' />
                <meta name='keywords' content='NURS FPX 4050 Assessment 3' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 4050 Assessment 3< br /><span>Care Coordination Presentation to Colleagues</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/NURSFPX4050Assessment3.png" alt="" />
                    <div className='main-container-sl-content'>
                        <h2>Care Coordination Presentation to Colleagues</h2>
                        <p>Hello each any one, let me introduce myself, my name is XYZ. Today, I have the privilege of sharing my knowledge on one of the key components of the nursing care in the context of cooperation. Thus, our part as care coordinators becomes more relevant as we work with several clients in the diverse sphere of healthcare needs. That is why in this presentation, I will discuss the following questions: What is care coordination? How does it affect patient outcomes? What are the ethical concerns, and policies that govern care coordination (Karam et al. , 2021)</p>
                        <h2>Effective Strategies for Collaborating with Patients and Families</h2>
                        <p>According to the six care coordination models, patients&rsquo; and families&rsquo; engagement is critical in attaining optimal outcomes in care. One of the most essential actions revolves around respecting the concept that patient care is not only about the exchange of information. To reiterate, which has been explained in the presentation, communication is one of the fundamental elements that cannot be underestimated. This encompasses explaining and introducing oneself and at the same time develop a working relationship with the patients, ensure they trust the doctor. Lastly, The patients are engaged in the plan formulated (Wong et al. , 2022). Engaging the patent and their families and having them as the center of care through the involvement in the care decisions are the primary pattern of collaboration. Explaining the healthcare plans without using what may be termed as complex medical terminology to the patients guarantees proper communication (Wong et al. , 2022).</p>
                        <p>Several conclusions can be made based on the research; one of them is that education modification is necessary to promote maximum health implications: it is crucial to adapt educational interventions, especially drug-related ones. Identifying the literacy level of the patient describing medicine can go a long way in boosting compliance and improving patient&rsquo;s health outcomes (Chau et al. , 2020). This strategy is in concordance with the do no harm approach of patient autonomy, where each patient&rsquo;s needs are respected and the knowledge imparted must be in a format which the patient will understand given his or her circumstances. Moreover, collaborating in the delivery of healthcare education entails formulations of practice teams, community engagements, and incorporating technology in the practice setting with respect to communication (Chau et al. , 2020). Implementing these strategies guarantee culturally appropriate educational programs and ultimately, patient and culturally sensitive education enhancing patients&rsquo; care and health.</p>
                        <p>Cultural competence is another essential factor of collaboration between care providers and other associates. By understanding and going to lengths to acknowledge patients&rsquo; multicultural diversity, there is a fostering of trust between the two camps of healthcare givers and receivers. Therefore, by ensuring that organizes care coordination with respect to cultural differences, the nurses will be able to foster an environment that is welcoming to patients from all backgrounds, thus meaning that they are able to achieve positive results for a number of patients (Nair &amp; Adetayo, 2019).</p>
                        <h2>Change Management in Care Coordination</h2>
                        <p>It is necessary to make a distinction between patient experience and patient satisfaction before transitioning to the discussion of change management in the area of coordinated care. Patient satisfaction includes the patient&rsquo;s total interaction over the entire course of their experience with the health care system including the processes, communication, and physical environment. Patient satisfaction can therefore be defined as a sub-domain of the patient experience, which is centred on the patient&rsquo;s level of satisfaction with services provided in health facilities (Harrison et al. , 2021). It is, therefore, very important to grasp this distinction when leading change in the care coordination process.&nbsp;</p>
                        <p>It refers to the process of implementing new methods, processes, tools, or procedures, and to maintaining organization&rsquo;s ability to react to the changes in industry norms or innovations. The ability to make transition less obtrusive is crucial because patients who are receiving care prefer it when those changes are gradual and do not interrupt their treatment significantly. The flow of the presentation, which focuses on the importance of embracing change, draws attention to the responsibility of the nurses in providing patient &ndash; centered care. In change management like implementation of EHRs, communication and coordination is also enhanced thus providing a coherent patient care (Harrison et al. , 2021).</p>
                        <h2>NURS FPX 4050 Assessment 3 Care Coordination Presentation to Colleagues</h2>
                        <p>To maintain level 3, it is important to integrate change management concepts with patient-centered system since the principles of change focus on preserving valued aspects. Such extra-hospital factors include openness, patients&rsquo; involvement in management decisions, and low interpersonal and care plan interface intrusiveness. In this case, key management in the change process helps in enhancing the perception of the management-employees hence developing a partnership in care. Admitting that patient satisfaction survey results are far from presenting all the details, a good change management plan is one with aspects beyond mere values. In regards to patient feedback, several changes are made to the care plans for the improvement of the patients&rsquo; experience (Kwame &amp; Petrucka, 2021).</p>
                        <p>Changing the healthcare workers&rsquo; communication style and ensuring they understand the importance of empathy, listening, and clear directions are important components of change. Implementations should improve interaction and create a more pleasant environment for the patient and provider. Patient participation also emerges as a central concept, where patients and healthcare providers engage in collaborative dialogues regarding the course of action regarding the treatment and care options to be taken based on the patient&rsquo;s priorities, values or preferences (Kwame &amp; Petrucka, 2021). Change management in the healthcare setting must focus on the direct implications for the healthcare patient, and should seek to improve, patient care and the patient&rsquo;s experience. This includes communication, staff development, work workflows, technology systems, patients&rsquo; engagement, assessment of the quality, and cultural competence.</p>
                        <h2>Rationale for Coordinated Care Plans</h2>
                        <p>The concept of integrity entails that ethical decision-making serves as the set of cardinal directions for effective coordination of caregiving due to the acknowledgement of the fact that such an act always aims at honoring the patient independence, dignity, among other rights. To that end, ethical care coordination entails the patient&rsquo;s participation recognized by the fact that each patient is unique, with a corresponding adherence to the principle of autonomy. This ethical perspective goes beyond the formal level of a procedure-stressing the critical aspects of care effectiveness and the quality of patients&rsquo; experience outcomes. Ethical consequences propose maintainability and consistency within the care provided to avoid cases of compartmentalization that may result into a mediocre care. Ethically informed care management engages key stakeholders to work in unison while focusing on the patient&rsquo;s bio-psychosocial-spiritual-reproductive self (McKeown, 2023). Ethical care coordination is based on four essential principles that include autonomy, beneficence, justice, and equity. The principle of autonomy entails acknowledging the patient&rsquo;s/clients&rsquo; right to make an informed decision regarding their treatment. Beneficence encourages patient care strategies that can positively impact the patient&rsquo;s health. Justice can be said to provide the criteria of allotting resources in a manner that gives regard to conditions such as individual&rsquo;s economic condition. While equity succeeds from justice in that it addresses inequities in practice, it takes further steps in that the solutions are specific to each learner (McKeown, 2023). Together, these principles ensure that the flow of care to the patients is patient involved, integrated, and fair thus respecting the patients&rsquo; dignity.</p>
                        <h2>Potential Impact of Healthcare Policy Provisions</h2>
                        <p>Governing healthcare policies influence the patient care in various ways as it determines the experience and results. Nurses involved in care coordination need to understand provisions regarding policy elements such as reimbursement, scope, and quality. The value-based care policies will cause extensive and preventive intercessions which makes the policies compatible with overall patients&rsquo; impact. It is highly relevant to review the prior works for reaching more effective conclusions based on the provisions given in the policy. This is the policy for integrating technology that requires data on the advantages of telehealth (Hajizadeh et al. , 2021).</p>
                        <p>Some of the significant policies that have critical roles in defining telehealth are the Affordable care act, Health insurance Portability and accountability act (HIPAA), and Medicaid. Telehealth literature supports that telehealth services positively impact the access, decrease the readmissions, and increase patient satisfaction, substantiating the evidence. The characteristics of procedures, like the ACA and HIPAA, which consist of complex structures, also call for insightful interpretation for the enhancement of healthcare systems effectively. The ACA sought to enhance the population&rsquo;s access to healthcare through: expanding the Medicaid population, establishing health insurance exchanges, and eliminating pre-existing conditions as a basis for exclusion of a patient from coverage (Breslau et al. , 2020).</p>
                        <h2><em>Are you Looking for guidance for NURS FPX 4050 Assessment 3? Our experts are here to assist you. Reach out to us for support today.</em></h2>
                        <p>Consumerism has also contributed to more people getting timely preventive, ambulatory, and administrative care, controlling chronic diseases, and visiting the hospital when they need to. One of them was the patient &ndash; centered medical homes, that was also introduced by the ACA as the concept of coordinated and comprehenisive care of the patients (Breslau et al. , 2020). Medical homes can improve a patient&rsquo;s quality of care in that his medical care providers will better communicate with each other and the patient will likely have a say in decisions regarding his medical care.</p>
                        <p>&nbsp;The HIPAA regulations concern itself with the appropriate use of patients&rsquo; information and aims to uphold the privacy of the patients (Edemekong et al. , 2022). These policies need not just be understood in words and their meanings but also need to be interpreted to see how they can be practically implemented in the care of patients. In conclusion, this understanding of policies impacts patients&rsquo; experience positively by enhancing appropriate and focused healthcare services (Xu et al. , 2022).</p>
                        <h2>The Nurse's Crucial Role in Patient Care</h2>
                        <p>Nursing colleagues are in a central position in terms of patient care as they are advocates, communicators, and coordinators. Their importance is well emphasized by demonstrating how well-coordinated care contributes to better patient&rsquo;s health but also reduces the burden of care delivery and increases productivity for the whole healthcare team. This is because nurses are usually in a strategic point of practicing both aspects of the subsystems as they facilitate continuity of care, break communication barriers and give significant inputs culminating in the achievement of a focused, patient-centered approach (Flaubert et al. , 2021). They are valuable to ensure proper coordination of processes and concentrating on the patient. Patients benefit from the efforts of nursing colleagues as they help in care coordination through speaking on the patient&rsquo;s behalf, breaking the communication barrier, and operationalising the patient-centered, coordinated care. Their efficiency reduces workload concerns within the healthcare team, and specialists enhance several processes, positively impacting patients&rsquo; care outcomes in general (Buljac-Samardzic et al. , 2020).</p>
                        <h2>NURS FPX 4050 Assessment 3&nbsp;Conclusion</h2>
                        <p>Concerns related to care coordination prove to be critical to this area of nursing practice. Transformation to patient-centred care is a key area as nurses are identified as pivotal in applying the strategies, dealing with change, and dealing with ethical aspects. As a result, to protect the patient&rsquo;s rights, it is necessary to underline that the patient&rsquo;s needs and preferences should be at the center of the care coordination process. The ethical framework in conjunction with the certain policy provisions allows increasing the level of patient care, and yes, the outcomes are guaranteed by the patient satisfaction.</p>
                        <h2>References</h2>
                        <p>Breslau, J., Han, B., Lai, J., &amp; Yu, H. (2020). Impact of the Affordable Care Act Medicaid expansion on utilization of mental health care.&nbsp;<em>Medical Care</em>,&nbsp;<em>58</em>(9), 757&ndash;762.</p>
                        <p><a href="https://doi.org/10.1097/mlr.0000000000001373">https://doi.org/10.1097/mlr.0000000000001373</a></p>
                        <p>Buljac-Samardzic, M., Doekhie, K. D., &amp; van Wijngaarden, J. D. H. (2020). Interventions to improve team effectiveness within health care: A systematic review of the past decade.&nbsp;<em>Human Resources for Health</em>,&nbsp;<em>18</em>(1).</p>
                        <p><a href="https://doi.org/10.1186/s12960-019-0411-3">https://doi.org/10.1186/s12960-019-0411-3</a></p>
                        <p>Chau, J. P. C., Lo, S. H. S., Lee, V. W. Y., Yiu, W. M., Chiang, H. C. Y., Thompson, D. R., &amp; Lau, A. Y. L. (2020). Fostering gerontology students&rsquo; competence in Interprofessional collaborative practice.&nbsp;<em>BioMed Central Medical Education</em>,&nbsp;<em>20</em>(1).</p>
                        <p><a href="https://doi.org/10.1186/s12909-020-02273-4">https://doi.org/10.1186/s12909-020-02273-4</a></p>
                        <p>Edemekong, P. F., Haydel, M. J., &amp; Annamaraju, P. (2022, February 3).&nbsp;<em>Health insurance portability and accountability act (HIPAA)</em>. Nih.gov; StatPearls Publishing.</p>
                        <p><a href="https://www.ncbi.nlm.nih.gov/books/NBK500019/">https://www.ncbi.nlm.nih.gov/books/NBK500019/</a></p>
                        <p>Flaubert, J. L., Menestrel, S. L., Williams, D. R., &amp; Wakefield, M. K. (2021). The role of nurses in improving health care access and quality. In&nbsp;<em>www.ncbi.nlm.nih.gov</em>. National Academies Press (US).</p>
                        <p><u>https://www.ncbi.nlm.nih.gov/books/NBK573910/</u></p>
                        <p>Hajizadeh, A., Zamanzadeh, V., Kakemam, E., Bahreini, R., &amp; Khodayari-Zarnaq, R. (2021). Factors influencing nurses participation in the health policy-making process: A systematic review.&nbsp;<em>BioMed Central Nursing</em>,&nbsp;<em>20</em>(1).</p>
                        <h2>NURS FPX 4050 Assessment 3</h2>
                        <p><u>https://doi.org/10.1186/s12912-021-00648-6</u></p>
                        <p>Harrison, R., Fischer, S., Walpola, R. L., Chauhan, A., Babalola, T., Mears, S., &amp; Le-Dao, H. (2021). Where do models for change management, improvement and implementation meet? A systematic review of the applications of change management models in healthcare.&nbsp;<em>Journal of Healthcare Leadership</em>,&nbsp;<em>Volume 13</em>(13), 85&ndash;108.&nbsp;<em>National Center for Biotechnology Information. NCBI.</em></p>
                        <p><a href="https://doi.org/10.2147/JHL.S289176">https://doi.org/10.2147/JHL.S289176</a></p>
                        <p>Karam, M., Chouinard, M.-C., Poitras, M.-E., Couturier, Y., Vedel, I., Grgurevic, N., &amp; Hudon, C. (2021). Nursing care coordination for patients with complex needs in primary healthcare: A scoping review.&nbsp;<em>International Journal of Integrated Care</em>,&nbsp;<em>21</em>(1), 1&ndash;21.</p>
                        <p><a href="https://doi.org/10.5334/ijic.5518">https://doi.org/10.5334/ijic.5518</a></p>
                        <p>Kwame, A., &amp; Petrucka, P. M. (2021). A literature-based study of patient-centred care and communication in nurse-patient interactions: Barriers, facilitators, and the way forward.&nbsp;<em>BioMed Central Nursing</em>,&nbsp;<em>20</em>(158), 1&ndash;10.</p>
                        <p><u>https://doi.org/10.1186/s12912-021-00684-2</u></p>
                        <p>McKeown, A. (2023). Ethical challenges and principles in integrated care.&nbsp;<em>Br Med Bull</em>,&nbsp;<em>146(1)</em>.</p>
                        <p><a href="https://doi.org/10.1093/bmb/ldac030">https://doi.org/10.1093/bmb/ldac030</a></p>
                        <p>Nair, L., &amp; Adetayo, O. A. (2019). Cultural competence and ethnic diversity in healthcare.&nbsp;<em>Plastic and Reconstructive Surgery - Global Open</em>,&nbsp;<em>7</em>(5).</p>
                        <p><a href="https://doi.org/10.1097/gox.0000000000002219">https://doi.org/10.1097/gox.0000000000002219</a></p>
                        <p>Restall, G. J., &amp; Egan, M. Y. (2021). Collaborative relationship-focused occupational therapy: Evolving lexicon and practice.&nbsp;<em>Canadian Journal of Occupational Therapy</em>,&nbsp;<em>88</em>(3), 220&ndash;230.</p>
                        <p><u>https://doi.org/10.1177/00084174211022889</u></p>
                        <p>Wong, E., Mavondo, F., Horvat, L., McKinlay, L., &amp; Fisher, J. (2022). Healthcare professionals' perspective on delivering personalized and holistic care: Using the theoretical domains framework.&nbsp;<em>BioMed Central Health Services Research</em>,&nbsp;<em>22</em>(1).</p>
                        <p><u>https://doi.org/10.1186/s12913-022-07630-1</u></p>
                        <p>Xu, H., Granger, B. B., Drake, C. D., Peterson, E. D., &amp; Dupre, M. E. (2022). Effectiveness of telemedicine visits in reducing 30‐day readmissions among patients with heart failure during the COVID‐19 pandemic.&nbsp;<em>Journal of the American Heart Association</em>,&nbsp;<em>11</em>(7).</p>
                        <p><a href="https://doi.org/10.1161/jaha.121.023935">https://doi.org/10.1161/jaha.121.023935</a></p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Nursfpx4050assessment3
