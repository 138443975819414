import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";
// import { Helmet } from 'react-helmet';

const Takemyclassonline = () => {
    const newSamples = [
        {
          image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
          title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
          description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
          samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
          image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
          title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
          description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
          samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
          image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
          title: "NURS FPX 4060 Assessment 2 Community Resources...",
          description: "Community ResourcesPublic health systems and community resources are critical in...",
          samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
          image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
          title: "NURS FPX 6016 Assessment 1...",
          description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
          samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }
    
      ]
    
      const newServices = [
        {
          icon: "fa-solid fa-user-pen icon",
          title: "American Writers",
          Desc: "Hire our competent writers who are masterminds in crafting high-scoring and excellent assignment solutions.",
        },
        {
          icon: "fa-solid fa-arrow-trend-up icon",
          title: "High-Scoring Solutions",
          Desc: "Get excellent solutions for nursing coursework. Our writing experts enable you to become successful.",
        },
        {
          icon: "fa-solid fa-book-open icon",
          title: "Research Experts",
          Desc: "TTake well-researched material and enjoy good marks with the praise of professors.",
        },
        {
          icon: "fa-solid fa-text-height icon",
          title: "Good Formatting",
          Desc: "Buy assignment solutions that are well-formatted, well-cited, and well-written. ",
        },
      ]
    
      const orderprocess = [
        {
          icon: "fa-solid fa-chalkboard-user icon",
          title: "Provide Guidelines",
          Desc: "Fill out the form with all the details.",
        },
        {
          icon: "fa-regular fa-money-bill-1 icon",
          title: "Make Payment",
          Desc: "Make your payment through a credit card and bank account.",
        },
        {
          icon: "fa-solid fa-person-chalkboard icon",
          title: "Get experts",
          Desc: "Our writers will contact you and assist you in your assessments!",
        },
      ]
    
      const newFeatures = [
        {
          icon: "fa-solid fa-chart-line icon",
          title: "Good Performance",
          Desc: "Our assignment solutions promote the student’s academic performance. We are devoted to helping you!",
        },
        {
          icon: "fa-solid fa-pen-to-square icon",
          title: "Professional Writers",
          Desc: "Hire our highly experienced writers to offer excellent assignment writing help services.",
        },
        {
          icon: "fa-solid fa-headset icon",
          title: "Complete Support",
          Desc: "Our academic experts offer full support in the assignment writing process. Students only receive the quality-written solutions.",
        },
        {
          icon: "fa-solid fa-house-laptop icon",
          title: "Flawless Services",
          Desc: "We offer high-quality assignment writing help services. We offer plagiarism-free and revised content.",
        },
      ]
      return (
        <>
          {/* <Helmet>
                <title>Do My Assignment Online</title>
                <meta name='description'
                    content='Professional do my assignment online for your assignments. Achieve top grades with our expert help. Reliable and confidential service.' />
                <meta name='keywords' content='pay someone to do assignments, do my assignment for me cheap,pay someone to do my homework online, do my assignment online' />
            </Helmet> */}
          <div className='s-hero services'>
            {/* <div className='s-hero-top'>
    <div className='sherotop-left'>
    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
    </div>
    <div className='cal-box'><Calculator/></div> 
    </div> */}
            <div className='ocs2-main-box'>
    
              <div className='ocs2-main' id='hero'>
                <div className='ocs2-mainHeading'>
                  <h1><span className='ocs2-topHeading'>TAKE MY CLASS ONLINE FOR ME</span></h1>
                  <p className='ocs2-topText'>Welcome to Online Course Services, where you can request, "take online classes for me," and watch as professionals seamlessly handle your academic tasks. </p>
                  <ul>
                    <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                    <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free proofreading and editing</li>
                    <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Punctuality</li>
                    <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; High-quality content</li>
                  </ul>
                  <div className='ocs2-banner-capella'>
                    <div className='ocs2-banner-capella-path'>
                      <span>
                        <h3 className='f-path-pointer'>Flex Path</h3>
                        <p>Use your experience to complete courses.</p>
                      </span>
                    </div>
                    <div className='ocs2-banner-capella-path'>
                      <span>
                        <h3 className='f-path-pointer'>Guided Path</h3>
                        <p>Enjoy the flexibility to study anywhere.</p>
                      </span>
                    </div>
                  </div>
                  <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                  <div className='ocs2-ratting'>
                    <div className='ocs2-ratting-provider'>
                      <SiTrustpilot className='ocs2-trustpilot-icon' />
                      <div>
                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                        <p>TrustPilot</p>
                      </div>
                    </div>
                    <div className='ocs2-ratting-provider'>
                      <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                      <div>
                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                        <p>OnlineCourseServices</p>
                      </div>
                    </div>
                    <div className='ocs2-ratting-provider'>
                      <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                      <div>
                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                        <p>Sitejabber</p>
                      </div>
                    </div>
                  </div>
    
                </div>
                <div className='ocs2-form'>
                  <Calculator />
                </div>
              </div>
    
            </div>
            <div className='s-cta'>
              <div className='s-cta-inner'>
                <div className='s-cta-inner-box'>
                  <h2>Hire Assignment Writers</h2>
                  <p>Our professional writers offer the best assignment solutions for your success.</p>
                </div>
                <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
              </div>
              <div className='s-cta-inner'>
                <div className='s-cta-inner-box'>
                  <h2>Best Writing Help</h2>
                  <p>Get help from our competent assignment writers to write quality assignments.</p>
                </div>
                <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
              </div>
            </div>
          </div>
    
    
          <div className='freesamples services'>
            <h2>GET CAPELLA DNP FLEXPATH ASSESSMENT SOLUTIONS!</h2>
            <p>We provide excellent Capella University DNP FlexPath assessment solutions for students in their Capella DNP FlexPath Courses.</p>
            <div className='sampleboxes'>
              {newSamples.map((sample) => {
                return (
    
                  <div className='singlebox'>
                    <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                    <div className='text'>
                      <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                      <p>{sample.description}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
    
    
          <div className='services s-section'>
            <div className='s-left'>
              {newServices.map((newserivce) => {
                return (
                  <div className='s-single'>
                    <i class={newserivce.icon}></i>
                    <h3>{newserivce.title}</h3>
                    <p>{newserivce.Desc}</p>
                  </div>
                )
              })}
            </div>
            <div className='s-right'>
              <span>Our Professional Writing Services!</span>
              <h2>SERVICES WE OFFER FOR “TAKE MY CLASS ONLINE FOR ME”</h2>
              <p>We offer a pool of qualified experts, each proficient in their fields, providing tailored academic assistance for your take online classes for me queries. Our prompt service operates 24/7, ensuring no delays in addressing your concerns. Enjoy exclusive discounts, easing financial burdens while excelling academically. With guaranteed grades, your success is assured with our take my classes online service. Our active support team provides friendly and responsive assistance, ensuring a seamless experience. Rest assured, our 100% verified service prioritizes your academic integrity, facilitating your journey towards academic excellence.</p>
              <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
            </div>
          </div>
    
          <div className='services cta'>
            <Calltoaction />
          </div>
          <div className='services'>
            <Tabs>
              <TabList>
                <Tab><strong>Take My Classes Online: Your Key to Academic Success</strong></Tab>
                <Tab><strong>Ensuring Success for Every "Take My Classes Online" Inquiry</strong></Tab>
              </TabList>
    
              <TabPanel>
                <p>
                In the busy life of a student in the USA, managing academics alongside personal life can be challenging. That's where we step in, promising to make your academic journey smoother. We're always available to assist you in finding solutions to all your online class assignments and questions!</p>
              </TabPanel>
              <TabPanel>
                <p>We understand the diversity and individuality of each course and student. That's why when you ask us to "take an online class for me," we develop a tailored strategy that meets your specific needs, guaranteeing a personalized approach to every task.</p>
              </TabPanel>
            </Tabs>
          </div>
    
          <div className='process services freesamples'>
            <h2>HOW IT WORKS?</h2>
            <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
            <div className='processboxes'>
              {orderprocess.map((order) => {
                return (
    
                  <div className='singleprocess'>
                    <i class={order.icon}></i>
                    <h3>{order.title}</h3>
                    <p>{order.Desc}</p>
                  </div>
                )
              })}
            </div>
          </div>
    
    
          <div className='services s-section'>
            <div className='s-right'>
              <span>Our Features</span>
              <h2>WHY CHOOSE US?</h2>
              <p>Our holistic approach to learning ensures that our scholars will take your class, leading to improved grades. Their impressive answers are sure to impress professors, enhancing your overall academic performance. Rest assured, your identity remains confidential when our experts handle quizzes or tests on your behalf. With our online class help, you can better manage your time and focus on other academic commitments while we handle tests for you.</p>
              <div><button>Hire Writer</button></div>
            </div>
            <div className='s-left'>
              {newFeatures.map((newserivce) => {
                return (
                  <div className='s-single'>
                    <i class={newserivce.icon}></i>
                    <h3>{newserivce.title}</h3>
                    <p>{newserivce.Desc}</p>
                  </div>
                )
              })}
            </div>
          </div>
          <Stestimonials />
          <Container>
            <div className='ocs2-faqs'>
              <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
              <div className='ocs2-faqs-boxes'>
                <div className='accordian-box-image'>
                  <img src='images/faqs.png' alt='' />
                </div>
    
                <Accordion className='accordian-boxes'>
                  <AccordionItem>
                    <AccordionHeader className='accordian-header'>
                      <h3 className={`accordion-title`}>Who Will Take My Class Online For Me? Is My Identity Confidential?</h3>
                      <TiArrowSortedDown className='accordian-header-icon' />
                    </AccordionHeader>
    
                    <AccordionBody className='accordian-body-ans'>
                      <div className="accordion-body">
                      We have a team of over hundreds of online class helpers based in the USA ready to assist with your class. This ensures you receive professional expertise for your online class needs. You can securely connect with the expert through our platform, and rest assured, your identity remains confidential with advanced security measures in place to protect your information.
                      </div>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader className='accordian-header'>
                      <h3 className={`accordion-title`}>Is Take An Online Class For Me Service Legal?</h3>
                      <TiArrowSortedDown className='accordian-header-icon' />
                    </AccordionHeader>
    
                    <AccordionBody className='accordian-body-ans'>
                      <div className="accordion-body">
                      Our online class helpers offer solutions for your weekly submissions, which can serve as reference material for completing your online class.
                      </div>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader className='accordian-header'>
                      <h3 className={`accordion-title`}>What Are The Guarantees Of Your ‘Take Online Classes For Me’ Services?</h3>
                      <TiArrowSortedDown className='accordian-header-icon' />
                    </AccordionHeader>
    
                    <AccordionBody className='accordian-body-ans'>
                      <div className="accordion-body">
                      If you need someone to take your online class, you're in the right spot for reliable help. We make sure to understand your program and coursework needs to give you the best service possible. Here's what we offer: complete confidentiality, assistance in every subject, friendly customer support available all the time, comprehensive guidance for your program, and a money-back guarantee.
                      </div>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader className='accordian-header'>
                      <h3 className={`accordion-title`}>What Services Are Included When I Request "Take My Class Online For Me"?</h3>
                      <TiArrowSortedDown className='accordian-header-icon' />
                    </AccordionHeader>
    
                    <AccordionBody className='accordian-body-ans'>
                      <div className="accordion-body">
                      We handle all academic tasks, from attending classes to completing assignments and quizzes on time, ensuring you have a stress-free academic experience.
                      </div>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader className='accordian-header'>
                      <h3 className={`accordion-title`}>What Happens If I'm Not Happy With The Work Provided After You Take My Class Online For Me?</h3>
                      <TiArrowSortedDown className='accordian-header-icon' />
                    </AccordionHeader>
    
                    <AccordionBody className='accordian-body-ans'>
                      <div className="accordion-body">
                      We aim for total satisfaction with our services. If you're not happy with the work, we have a 100% refund policy. Our goal is to deliver the best service and outcomes possible.
                      </div>
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </Container>
        </>
    
      )
    
}

export default Takemyclassonline
