import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";
import { Helmet } from 'react-helmet';

const Takemyonlineclass = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Research Writers",
            Desc: "Get help from skilled Capella DNP FlexPath research writers. They are always ready for help.",
        },
        {
            icon: "fa-solid fa-school icon",
            title: "Class Help",
            Desc: "Our tutors offer Capella University DNP FlexPath class-taking services for Capella DNP FlexPath students!",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Courses Help",
            Desc: "Take help from Capella DNP FlexPath professionals in their Capella DNP FlexPath courses.",
        },
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Tutoring Help",
            Desc: "Take help from our tutors, who are Capella DNP FlexPath Curriculum professionals. ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a Capella DNP FlexPath intellectuals team to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We can always help you with your Capella DNP FlexPath Curriculum and coursework!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to Capella University DNP FlexPath students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek complete support from Capella University DNP FlexPath nursing experts.",
        },
    ]
    return (
        <>
            <Helmet>
                <title>Pay Someone to Take an Online Class</title>
                <meta name='description'
                    content='Stop looking to pay someone to take an online class for you. Contact Online Course Services now to get A-Grade!' />
                <meta name='keywords' content='paying someone to take an online class for you, pay someone to take my class online, paying someone to take your online classes, pay someone to take an online class' />
            </Helmet>
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
    <div className='sherotop-left'>
    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
    </div>
    <div className='cal-box'><Calculator/></div> 
</div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>PAY SOMEONE TO TAKE AN ONLINE CLASS </span></h1>
                            <p className='ocs2-topText'>Our mission at Online Course Services is to deliver excellent results with your “pay someone to take an online class” order. </p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Professionals</h2>
                            <p>Take help from professional tutors, mentors, and writers to excel in your Capella University DNP FlexPath coursework.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Get Nursing Papers</h2>
                            <p>Get excellent and well-researched Capella DNP FlexPath nursing papers!</p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET CAPELLA DNP FLEXPATH ASSESSMENT SOLUTIONS!</h2>
                <p>We provide excellent Capella University DNP FlexPath assessment solutions for students in their Capella DNP FlexPath Courses.</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Academic Services</span>
                    <h2>SERVICES WE PROVIDE FOR “PAYING SOMEONE TO TAKE YOUR ONLINE CLASSES” ORDERS</h2>
                    <p>Balancing online classes with a hectic schedule? Your answer lies in our paying someone to take your online classes service. Our professionals are prepared to guarantee your success when you want to pay someone to take an online class. Whether you require help for a single exam, a particular homework project, or comprehensive support for the entire class.
                        <br />We promise you will receive A or B grades in every course you sign up for.<br />
                        Are you forced to take a boring or unrelated class for your major? At Online Course Service, our staff is available to offer you the committed and concentrated assistance you require to succeed. We are prepared to assist you with your “pay someone to take my class online” order in overcoming the particular difficulties that come with learning online.
                    </p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>End Your Quest of “Paying Someone to Take an Online Class for You”</strong></Tab>
                        <Tab><strong>Pay Someone to Take my Class Online</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p>You don't have to worry about the standard of support you receive for your online coursework when you work with Online Course Service. So stop looking to pay someone to take an online class.<br /> <br />
                            <strong>Why Do You Need to Pay Someone to Take an Online Class?</strong><br />
                            In the US, a large number of universities provide online courses and degrees. Because of this, we have made the decision to close the knowledge gap that exists when students are looking to pay someone to take my class online. We offer 100% USA-based help with classes, examinations, and coursework to take care of the issue for you when you are paying someone to take your online classes.
                        </p>
                    </TabPanel>
                    <TabPanel>
                        <p>Have you been finding it difficult to stay up to date with your online classes, or have your grades declined in spite of your best efforts to attend them regularly? You should now pay someone to take an online class to help you get better grades quickly, so stop worrying about it. They can help you rise from the bottom of the grade pool.
                            <br /> <br />
                            <strong>Our Guarantee</strong><br />
                            Our staff of knowledgeable instructors at Online Course Services is the best in the business for all academic disciplines. Most of them are highly qualified university graduates who have worked with a wide variety of students. Our knowledgeable tutors can help you with a variety of issues and offer excellent online class services when you order “pay someone to take an online class”.
                        </p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Features</span>
                    <h2>WHY CHOOSE US?</h2>
                    <p>These days' students understand the distinction between working hard and working smart. Hardly any students succeed just by concentrating on their coursework and assignments. If you're someone who excels in extracurricular activities like sports, you may find it difficult to find the time to finish your class tasks. It’s time for you to pay someone to take my class online.
                        <br />
                        And here's where the magic of Online Course Service happens. If you've ever questioned if you can pay someone to take my class online, the answer to your questions is definitely yes. When you are paying someone to take your online classes, our tutors will take care of your online class and complete your tasks. We ensure that you get good grades without putting in any effort by helping you with your essays and labs!
                        <br />
                        We are a customer-focused organization that understands that scholastic achievement is essential for a prosperous career. We enable students to excel academically while also giving them the chance to develop other abilities.
                        <br />
                        We are here for your “pay someone to take an online class” order.<br />

                    </p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}> Is It Safe To Pay Someone To Take An Online Class?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    It’s completely safe to pay someone to take my class online. At Online Course Services we assign our clients to one professional tutor as part of our Cyber Security Policy. This implies that the only person with access to your online class is your tutor.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>What Happens If I'm Not Happy With The Job That Was Done?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Our goal is for our services to leave you completely satisfied. We have a 100% return policy in the event that you are not happy with the work. Our goal is to deliver the finest service and outcomes we can.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I Pay Someone To Take My Class Online?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes, you can pay someone to take an online class. We at Online Course Service are available to help you! Helping students succeed in their online degree programs is our area of expertise.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Where Can I Pay Someone To Take My Online Nursing Class?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    You can always count on Online Course Service for excellent service. Our goal is to provide you with an outstanding experience. Don't fight alone; get in touch with us now, and let's be successful together!
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}> Is Paying Someone To Take Your Online Classes Legal?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Because our assistance cannot be interpreted as cheating, it is legal. Assisting students with difficult tasks and giving security when they are unable to attend an online course for legitimate personal reasons is an important part of our company.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                        
                        </Accordion>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Takemyonlineclass
