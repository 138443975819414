import React from 'react';
import Hero from '../homepage/Hero';
import Bannerone from '../homepage/Bannerone';
import Features from '../homepage/Features';
import Services from '../homepage/Services';
import Orderingprocess from '../homepage/Orderingprocess';
import Testimonials from '../homepage/Testimonials';
import Comparison from '../homepage/Comparison';
import Faqs from '../homepage/Faqs';
import Bannertwo from '../homepage/Bannertwo';
import Bannerfour from '../homepage/Bannerfour';
import Blogsection from '../homepage/Blogsection';
import Contentone from '../homepage/Contentone';
import Contenttwo from '../homepage/Contenttwo';

const Home = () => {
  return (
    <>
      <Hero />
      <Services/>
      <Bannerone/>
      <Features/>
      <Contentone/>
      <Bannertwo/>
      <Orderingprocess/>
      <Testimonials/>
      <Contenttwo/>
      <Bannerfour/>
      <Comparison/>
      <Faqs/>
    </>
  )
}

export default Home
