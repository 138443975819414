import React from 'react';
import { Rating } from 'react-simple-star-rating';
import { TbCalendarDollar, TbStarsFilled } from "react-icons/tb";
import { AiOutlineTeam } from "react-icons/ai";
import { BsJournalText } from "react-icons/bs";
import { LiaUserClockSolid } from "react-icons/lia";
import { VscServerProcess, VscLock } from "react-icons/vsc";
import Container from 'react-bootstrap/esm/Container';


const Comparison = () => {
  return (
    <>
    <Container>
        <div className='ocs2-comparison'>
            <h2 className='ocs2-comparison-title'><strong>COMPARATIVE</strong><span> EVALUATION</span></h2>
            <p className='ocs2-comparison-details'>We provide the best academic assistance services to Capella University Flex Path students. Wehave an experienced and Capella DNP program-qualified faculty to guide you!</p>
            <table>
                <tr className='ocs2-comparison-table-head'>
                    <th className='features-t'>Features</th>
                    <th>Bidding Websites</th>
                    <th className='free'>Freelancers</th>
                    <th>Online Course Services</th>
                </tr>
                <tr>
                    <td className='ocs2-comparison-table-icon'><AiOutlineTeam className='ocs2-comparison-table-icons'/> <p>Dedicated Team for Quality Assurance</p></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={1} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={2} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={5} fillColor='#000000' size={20} readonly/></td>
                </tr>
                <tr>
                    <td className='ocs2-comparison-table-icon'><BsJournalText className='ocs2-comparison-table-icons'/> <p>Backup Writers</p></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={2} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={0} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={5} fillColor='#000000' size={20} readonly/></td>
                </tr>
                <tr>
                    <td className='ocs2-comparison-table-icon'><LiaUserClockSolid className='ocs2-comparison-table-icons'/> <p>24/7 Available Writing Team</p></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={1} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={2} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={5} fillColor='#000000' size={20} readonly/></td>
                </tr>
                <tr>
                    <td className='ocs2-comparison-table-icon'><VscServerProcess className='ocs2-comparison-table-icons'/> <p>Quick & Quality-Focused Process</p></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={2} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={1} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={5} fillColor='#000000' size={20} readonly/></td>
                </tr>
                <tr>
                    <td className='ocs2-comparison-table-icon'><VscLock className='ocs2-comparison-table-icons'/> <p>Confidentiality</p></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={2} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={1} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={5} fillColor='#000000' size={20} readonly/></td>
                </tr>
                <tr>
                    <td className='ocs2-comparison-table-icon'><TbStarsFilled className='ocs2-comparison-table-icons'/> <p>VIP Service</p></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={1} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={1} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={5} fillColor='#000000' size={20} readonly/></td>
                </tr>
                <tr>
                    <td className='ocs2-comparison-table-icon'><TbCalendarDollar className='ocs2-comparison-table-icons'/> <p>Payment After Delivery</p></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={2} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={1} fillColor='#999999' size={20} readonly/></td>
                    <td><Rating className='rating' name="half-rating-read" initialValue={5} fillColor='#000000' size={20} readonly/></td>
                </tr>
            </table>
        </div>
        </Container>
    </>
  )
}

export default Comparison
