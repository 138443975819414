import React from 'react'
import { ImSearch } from "react-icons/im";
import { BsHeadset } from "react-icons/bs";
import { MdOutlineSendTimeExtension } from "react-icons/md"
import Container from 'react-bootstrap/esm/Container';
const Orderingprocess = () => {
  return (
    <>
    <Container>
        <div className='ocs2-order' id='ordering'>
            <h2><strong>GET NURSING ASSESSMENT HELP IN</strong><span> THREE STEPS!</span></h2>
            <p>Provide all the necessary details, guidelines, and requirements for your work. Our experts will work according to your guidelines and resources. We will provide the high- quality and fantastic content that you truly deserve.</p>
            <div className='ocs2-order-steps'>
                <div className='ocs2-order-card'>
                    <ImSearch className='ocs2-order-icon'/>
                    <h3>1. Place Your Order</h3>
                    <p>Place Your Order: Provide the necessary details of your paper by filling out the order form. Clearly mention all guidelines and requirements, enabling our experts to comprehend your needs and deliver the quality you deserve.</p>
                </div>
                <div className='ocs2-order-card'>
                    <BsHeadset className='ocs2-order-icon'/>
                    <h3>2. Connect with Experts</h3>
                    <p>Connect with a Capella University flex path expert. After submitting your details, get ready to give your Capella DNP program project to a highly intellectual and professional person. Feel free to engage with them to discuss important project information.</p>
                </div>
                <div className='ocs2-order-card'>
                    <MdOutlineSendTimeExtension className='ocs2-order-icon'/>
                    <h3>3. Receive Your Order</h3>
                    <p>When our experts will complete your order, then they will deliver it to you! Get ready to get a high-quality, persuasive, research-based, correctly cited, high-scoring project. It will truly force your professor to give a praise compliment. You can also request for unlimited revisions.</p>
                </div>
            </div>
        </div> 
        </Container>
    </>
  )
}

export default Orderingprocess
