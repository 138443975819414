import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Mbafpx5014assessment1 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>MBA FPX 5014 Assessment 1 Financial Condition Analysis</title>
                <meta name='description'
                    content='Find expert support for MBA FPX 5014 Assessment 1 Financial Condition Analysis at online course services to get distinguished grades in your class.' />
                <meta name='keywords' content='MBA FPX 5014 Assessment 1 Financial Condition Analysis' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>MBA FPX 5014 Assessment 1 < br /><span>Financial Condition Analysis</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/MBAFPX5014Assessment1.png" alt="" />
                    <div className='main-container-sl-content'>
                        <h2>Executive Summary</h2>
                        <p>A lot of information has been shared in this report regarding the performance of ABC Healthcare Corporation's financial status from 2017 to 2019. The purpose of the evaluation was to get insight into the financial health of ABC Healthcare and assess the organization in relation to its strategic objectives and stockholder value. These include the ROI, which was calculated based on the company's revenue, cash, asset usage, and market value, as revealed in ABC's reviewed finance records. The findings indicate a negative trend in the profit position, as evidenced by a decline in net income margins and returns on assets; the company's liquidity position is also poor, as evidenced by a declining current ratio. The following are the strategic plans that are aimed at enhancing the financial health of ABC Healthcare in order to enhance the long-term value of its stock. It serves to demonstrate that things can improve and thus provide people with a certain hope for the company's future.</p>
                        <h2>Company Background</h2>
                        <p>The firm, known as the ABC Healthcare Corporation, was founded in 1980 by Maria Gomez as a small private hospital in Miami, Florida. Over the past forty years, it has developed into one of the largest integrated healthcare delivery systems in the United States. Leveraging a network of more than 30 hospitals, 45 ambulatory surgery centers, and numerous outpatient clinics and urgent care centers in 12 states with a strong presence in the Southeast and Texas, ABC now has a network. Maria Gomez has been recently appointed as president and CEO of the company, and with her leadership, the firm has expanded its services and operations in various areas. This was done through organic growth, that is, the normal growth of the company, and through strategic acquisitions, which have made ABC a formidable force in the healthcare industry.</p>
                        <p>&nbsp;This is because the industry is likely to experience significant changes in the near future, and this will demand more scale, quality, and speed from the company. However, Maria Gomez has conducted a comprehensive analysis of the company's current financial standing because she understands that the organization has to be on a sound financial footing. This is to ensure that ABCffc still performs well and can thus provide its owners with the highest returns possible in the future as it expands. The results of this financial analysis will probably assist ABC to remain a star in the industry through decision-making.</p>
                        <h2>Overall Financial Analysis</h2>
                        <p>Thus, the primary objective of the financial analysis of ABC Healthcare Corporation was the evaluation of the company's financial performance for three years, considering revenue, cash, asset productivity, and market value. The process involved obtaining information from the financial statements of ABC Healthcare that are authorized and approved, as well as communications from the investor relations department. It included financial ratios such as net income margin, return on assets, current ratio, inventory turnover, and key market value ratios such as the P/E and P/B ratios. These percentages were chosen with great care, and the trends for these percentages were analyzed to provide all angles of the business's financial health.</p>
                        <h2>Financial Ratio Analysis</h2>
                        <p>The right data was employed to compare the success of ABC Healthcare Corporation in the years 2017, 2018, and 2019. In order to assess the financial position of ABC over the last three years, the following ratios belonging to several categories have been calculated and compared with HCA Healthcare ratios.</p>
                        <p>&nbsp;PE ratio reveals the cost that investors are willing to pay for each dollar of profit that a company generates. It helps to determine the market value of the company and possible future increase in value. The following are the P/E ratios. The P/E ratio rose from 9. From 14 in the year 2017 to 12. 10 in 2019. Despite this, the market price has remained constant at $83. In the same year, 62 EPS decreased from the previous year at $9. From $15 in 2017, the costs were reduced to $6&mdash;91 in 2019. The ever-rising P/E ratio indicates that investors are willing to pay more for the company's earnings. This shows that the market is good and the expectation of the company's future growth.</p>
                        <p>&nbsp;It is a generally accepted fact that if the P/E ratio is between 10 and 20, then it is good. The P/E ratios of ABC Healthcare range from 9. 14 to 12. 10, which is close to this range for other companies. This is an indication that the stock is fairly priced as compared to the earnings of the company. This means that despite the EPS being on a downward trend, the P/E ratio is on the rise, and this can only mean that the market has the potential for the company's future performance or likes something else about the company.</p>
                        <p>&nbsp;It has been on the rise and drop at some point in the years. It dropped from 14. This reduced from 20 in the year 2017 to 11. It was 37 in 2018, and it came down to 14 in the next year. 47 in 2019. This, therefore, indicates that the perception of the market on economic success is not a constant one. The P/E ratios in HCA Healthcare are also reasonable, ranging from 11. 37 to 14. 47. This implies that what the company is worth is actually what it is worth as far as earnings are concerned. Such high P/E ratios indicate that investors expect HCA Healthcare Inc. to earn more and grow further, which is positive for the company's market standing.</p>
                        <p>&nbsp;From 0. This was 37 in 2017, and it dropped to 0. As of 2019, the P/B ratio has slightly increased to 42. Book value per share has been decreasing since 2017; when it was $226 per share, it has been reduced to $199. 1 in 2019. This is because the P/B ratio is increasing while the book value per share is decreasing, which means that the market is gaining progressively more confidence in the firm's stock. In the common parlance, a stock is considered a good buy when the P/B ratio is less than one. This may mean that the stock may be considerably cheap in relation to its actual net asset value. Results of the P/B ratios of ABC Healthcare (0. 37 &ndash; 0. 42) indicate that the company's stock is relatively cheap. It is therefore advisable to buy at this time if the problems can be solved. Still, the book value per share is decreasing, while the P/B ratio is slightly increasing, which reflects that people have a quite clear perception of the company's assets.</p>
                        <h2>HCA Healthcare Comparison:</h2>
                        <p>It is negative, which may be due to the fact that the book value of the company is negative, indicating a possible reporting issue or that the company has a lot of debt relative to its assets. This implies that as the P/B ratio declines, the managers of the assets or the financial management of the company are in a pathetic state. HCA Healthcare has presented quite negative P/B numbers ranging from -5. 92 to -87. Ten, and this paints a very unfavorable picture of the firm's financials. The negative P/B numbers are an indication that debts are far greater than assets, and this could indicate that the company is struggling to service its debts. Moving from the table above, the P/B ratio has been on the decline, which is an indication that the company's financials are deteriorating and not in line with industry standards. This should be a cause of concern for the investors.</p>
                        <h2>Evaluation of Financial Statements</h2>
                        <p>This is because of the P/E ratio, which was 9. It went from 14 in the year 2017 to 12. As seen in the investors' sentiments in the 10 2019 financial year, investors are slowly regaining confidence in the company's ability to generate profits in the future. The market appears to be in a good mood as investors are willing to shell out more money for each dollar of earnings. As for 2019, the P/E ratio was 12. It means that buyers will have to pay $12 for ten products. It is 10 for each dollar in earnings. The stock's market price remained fairly constant at $83. This is something that has been maintained over the past three years, and therefore, it can be said that buyers still consider it something worth purchasing and, thus, a safe investment.</p>
                        <p>&nbsp;EPS has reduced from $9. The company's revenue decreased from $15 in 2017 to $6. It was found that the business generated USD 91,000 in 2019, which is evidence of the business generating less revenue. EPS was $7 for the previous year, and it has been reduced. 87 in 2018 to $6. 91 in 2019. This negative trend in wages is very alarming and could be a result of an increase in costs, increased competition, or inefficiency in work. If the P/E ratio increases while earnings decrease, it would imply that the stock is overpriced. In 2019, the company's EPS was reduced; however, the P/E ratio increased to 12. 10. If there are no alterations to the current trend and the results cannot be improved, then the stock price may decrease.</p>
                        <h2>Financial Statements:</h2>
                        <p>The price-to-book ratios are very poor, ranging between 0. 37 and 0. 42, thus putting into question the value of the stock. Shareholders invest in a company's net asset at a lower cost than the book value. If the company can increase its earnings, then this might be the right time to invest in the company's stock. For example, this means that buyers will have to pay $0. The ratio stood at 42 for every $1 of book value in the year 2019. Despite the fact that it has decreased, the book value per share is still quite high at $199. 1 in 2019, which is evidence of the fact that the company holds a lot of valuable assets. For example, the book value per share in 2017 was $226, which indicates that the company has good assets. It can also be useful to have some amount of money saved up for the rainy days and to support new business initiatives.</p>
                        <p>&nbsp;As for the indicator of book value per share, it has also been on the decline in recent years, at $226 in 2017, while currently, it stands at $199. 1 in 2019. It actually reduced to $209, for instance. It was $205 in 2018, and then it reduced to $199. 1 in 2019. This could be due to raised debt, asset depreciation, or the company not reinvesting enough profits back into the company. This means that the financial situation is not improving; hence, it is deteriorating. The only problem is that the P/B numbers are not too high, indicating that the market has not fully appreciated or recognized the value of the company's assets. For instance, if a company has a P/B ratio of 0. This shows that the company's assets have been undervalued more than usual in 2018 to the tune of 40. Such concerns may include how much revenue the business is likely to generate in the future, the ability to manage the company, or the market environment outside the organization.</p>
                        <p>&nbsp;The price tag that the company has set is $83. 62 helps to identify that buyers' valuation of the company is the same at present and in the future. This can be an indication of investor's confidence in the company's future. If the P/B ratio is less than one, then it simply means that the company may not be worth the price that has been attached to it. If the company can raise its EPS and asset efficiency, then it can likely get value buyers. The disadvantage is that EPS is decreasing, which proves the difficulty of operating with profitability. We have to put a stop to this right away in order to regain back the confidence of the investors and enhance value measures. Example: From 9. From 15 in the year 2017, the numbers sharply declined to 6. Currently, the number is 91 in 2019, and it keeps on decreasing.</p>
                        <p>&nbsp;This is because the decrease in book value per share might be a result of inefficiency in managing the assets or making the right financial decisions. Consequently, the book value per share was at $226 in the year 2017, while in 2019, the book value was at $199. 1. In order to increase private ownership, the company needs to improve its balance sheet. There are some areas that the management of ABC Healthcare Corporation must consider to ensure that the worth of its shares rises in the future. First, one has to increase the profitability of the business. This can be done by cutting down on costs, streamlining the industry, and seeking other sources of revenue in order to raise the EPS. Second, there is the area of asset management, which the company needs to enhance. It should do a much better job managing its assets, reduce debt, and consider thoughtful reinvestments that will increase the book value per share. Lastly, it is crucial to enhance communication with investors in a way that can help retain them and attract more of them. It can be done by being more specific on the strategic plans and growth opportunities for the market and being more transparent. These are the things that, if implemented by ABC Healthcare Corporation, can enhance the company's long-term financial position and the value of its shares.</p>
                        <h2>Current Financial Story</h2>
                        <p>By analyzing the financial statistics for the last three years of the ABC Healthcare Corporation, I would like to note that the company is neither in very good shape nor in very poor shape. The organization is steady and has potential for growth, yet it has issues that require to be addressed in order to boost the long-term worth of the organization.</p>
                        <h2>Financial Health Analysis</h2>
                        <h2>Price/Earnings (P/E) Ratio:</h2>
                        <p>Between the years 2017 and 2019, the P/E ratio was 9. 14 to 12. 10. This implies that investors are willing to pay more for the earnings of this company. This means that despite the fact that the EPS is declining, the market has a favorable view and expectations of future improvement. It has reduced EPS (from 9. 15 in 2017 to 6. 91 in 2019), so the company is generating less profit. This may be for a number of reasons, such as rising prices or increased competition.</p>
                        <h2>Price/Book (P/B) Ratio:</h2>
                        <h2>Focus Areas for Enhancing Shareholder Value:</h2>
                        <p>In order to enhance the worth of its shares, ABC Healthcare Corporation has to apply the following strategies. Namely, it is possible to streamline the process and reduce expenses with the help of lean management (Wrike, 2023). To increase revenue, one can offer value-added services such as different procedures in the healthcare sector, new technologies in healthcare, and the expenditure incurred on telemedicine and outpatient centers (Bestsennyy et al., 2021). Debt can also be well managed through reducing the debt levels, which will improve the balance sheet and debt refinancing where interest rates have dropped. This may reduce interest expenses and, thus, increase cash flow (Maverick, 2021).</p>
                        <p>&nbsp;Lastly, it is necessary to increase the market's transparency and customers' confidence. It can be done by enhancing communication with the investors by providing them with clear and constant information, such as news calls and well-detailed financial reports (Tarver, 2023) that indicate the economic status and strategic plans of the business. It is more useful to talk with investors about the long-term future of the company and the opportunities for it so that they might invest and remain with the company. Another way of increasing investor confidence is through proving that growth projects have been well implemented.</p>
                        <p>&nbsp;First and foremost, there are some short-term measures that ABC Healthcare Corporation should take in order to attain long-term benefits. Some of these steps include conducting operational audits to find ways of cutting costs in the short term, for example, by re-negotiating supplier contracts, reducing overheads, and implementing technology to reduce the need for labor and increase focus on patient care (Forbes, 2024; SAP, n. d. ). The following can be done while enhancing outpatient services, presenting better ways of care that are cheaper, and improving the number of patients and their satisfaction (Mayo Clinic, 2022). One of the ways of using its cash flow effectively is by refinancing its existing debt to take advantage of lower interest rates since this frees up funds for other investment purposes. This will also free up cash flow to pay down high-interest debt, which will reduce financial risk and increase the company's credit score (Bhansali, 2022). Creating benchmarks for the work being done in cutting costs, improving profits, and optimizing assets' use is also very significant. These measures, when assessed from time to time, ensure that the company is on the right track to achieve the financial objectives that it has set for itself (Team, 202).</p>
                        <p>&nbsp;Thus, it is possible to state that by directing its efforts to these fields and undertaking the short-term measures mentioned above, the company will be able to enhance its financial standing, boost its worth, and consequently, the value of its shares.</p>
                        <h2>Recommendations</h2>
                        <p>From the first recommendation, it is possible to state that companies can earn more if they pay attention to cost management and sales growth. This is where the application of lean management is important to streamline its processes, eliminate waste, and reduce costs in order to address these concerns. The incorporation of virtual services, care facilities, and fitness programs can also assist in increasing the revenue of the business by attracting more patients and increasing the frequency of visits (Lin, 2022).</p>
                        <p>&nbsp;The second concept is to enhance the management of cash since the current ratio has been declining in the recent past. This suggests that the company may face difficulties in meeting its short-term debt obligations because it wants to grow. Various ways can assist you in improving the cash flow of your business, such as ensuring that product levels are well optimized, avoiding accumulating debts, and ensuring that you get better payment terms with the sellers. Establishing or expanding funds would also create a safety net, for instance, in instances of economic downturn or when the economy is weak (Hardie &amp; Rethel, 2019).</p>
                        <p>&nbsp;The third suggestion is to increase the worth of the market through positive conversations and positive relationships with the investors. Despite the fact that the price-to-earnings ratio of ABC Healthcare is improving and the inventory turnover ratio is marginally improving, the market value could be higher. It is crucial to establish a good investor relations program that informs the existing and potential investors on the company's strategy vision, successes, and opportunities.</p>
                        <h2>Conclusion</h2>
                        <p>Despite the company's current problems in terms of decreasing profits and cash flows, the firm is able to utilize its assets more effectively, and its market value has been improving. Comparative analysis of indicators has revealed that some of them can be brought to the absolute leaders in their categories.</p>
                        <h2>References</h2>
                        <p>Al-Harbi, A. (2019). The determinants of conventional banks profitability in developing and underdeveloped OIC countries. <em>Journal of Economics, Finance, and Administrative Science, 24</em>(47), 4&ndash;28. <a href="https://doi.org/10.1108/JEFAS-05-2018-0043">https://doi.org/10.1108/JEFAS-05-2018-0043</a></p>
                        <p>Bestsennyy, O., Gilbert, G., Harris, A., &amp; Rost, J. (2021, July 9). <em>Telehealth: A quarter-trillion-dollar post-covid-19 reality?</em> McKinsey &amp; Company. <a href="https://www.mckinsey.com/industries/healthcare/our-insights/telehealth-a-quarter-trillion-dollar-post-covid-19-reality">https://www.mckinsey.com/industries/healthcare/our-insights/telehealth-a-quarter-trillion-dollar-post-covid-19-reality</a>&nbsp;</p>
                        <p>Bhansali, S. (2022, April 7). <em>Debt refinancing</em>. Financial Edge. <a href="https://www.fe.training/free-resources/valuation/debt-refinancing/">https://www.fe.training/free-resources/valuation/debt-refinancing/</a></p>
                        <p>Charkha, P. C., Jaju, S. B., &amp; Chatterjee, P. (2024). Supply chain performance measurement in Textile Enterprises. <em>CRC Press.</em><a href="https://www.researchgate.net/publication/285134891_Supply_chain_management_in_textile">https://www.researchgate.net/publication/285134891_Supply_chain_management_in_textile</a></p>
                        <p>Forbes. (2024, January 19). <em>Council post: 18 cost-cutting business strategies to leverage in 2024</em>. Forbes. <a href="https://www.forbes.com/sites/forbesbusinesscouncil/2024/01/19/18-cost-cutting-business-strategies-to-leverage-in-2024/">https://www.forbes.com/sites/forbesbusinesscouncil/2024/01/19/18-cost-cutting-business-strategies-to-leverage-in-2024/</a></p>
                        <p>Hardie, I., &amp; Rethel, L. (2019). Financial structure and the development of domestic bond markets in emerging economies. <em>Business and politics, 21</em>(1), 86-112. <a href="https://doi.org/10.1017/bap.2018.11">https://doi.org/10.1017/bap.2018.11</a></p>
                        <p>Lin, W. L. (2023). Corporate social responsibility and irresponsibility: Effects on supply chain performance in the automotive industry under environmental turbulence. <em>Journal of Cleaner Production, 428</em>, 139033. <a href="https://doi.org/10.1016/j.jclepro.2023.139033">https://doi.org/10.1016/j.jclepro.2023.139033</a></p>
                        <p>Maverick, J. B. (2021, May 3). <em>Strategies used to reduce a company's debt-to-capital ratio</em>. Investopedia. <a href="https://www.investopedia.com/ask/answers/040715/what-are-some-strategies-companies-commonly-use-reduce-their-debt-capital-ratio.asp">https://www.investopedia.com/ask/answers/040715/what-are-some-strategies-companies-commonly-use-reduce-their-debt-capital-ratio.asp</a></p>
                        <p>Mayo Foundation for Medical Education and Research. (2022, June 18). <em>Telehealth: Technology meets health care</em>. Mayo Clinic. <a href="https://www.mayoclinic.org/healthy-lifestyle/consumer-health/in-depth/telehealth/art-20044878">https://www.mayoclinic.org/healthy-lifestyle/consumer-health/in-depth/telehealth/art-20044878</a></p>
                        <p>Mohamadi, N., Niaki, S. T. A., Taher, M., &amp; Shavandi, A. (2024). Applying deep reinforcement learning and vendor-managed inventory in perishable supply chain management. <em>Engineering Applications of Artificial Intelligence, 127</em>, 107403. <a href="https://doi.org/10.1016/j.engappai.2023.107403">https://doi.org/10.1016/j.engappai.2023.107403</a></p>
                        <p>Naya, F., Rrustemi, J., &amp; Tuchschmid, N. S. (2023). Alternative Risk Premia and Market Drawdowns: A Performance Review. <em>Journal of Beta Investment Strategies, 14</em>(2). <a href="https://doi.org/10.3905/jbis.2023.1.024">https://doi.org/10.3905/jbis.2023.1.024</a></p>
                        <p>Purnama, O., Hanitha, V., &amp; Angreni, T. A. (2020). Financial statement analysis to assess company financial performance.&nbsp;<em>ECo-Fin,&nbsp;2</em>(3), 168&ndash;174. <a href="https://jurnal.kdi.or.id/index.php/ef/article/view/537">https://jurnal.kdi.or.id/index.php/ef/article/view/537</a></p>
                        <p>SAP. (n.d.). <em>Process automation: The key to efficiency</em>. <a href="https://www.sap.com/products/technology-platform/process-automation.html">https://www.sap.com/products/technology-platform/process-automation.html</a></p>
                        <p>Sensemore. (2024, April 29). <em>Improving asset utilization in your business</em>. <a href="https://sensemore.io/improving-asset-utilization-in-your-business/">https://sensemore.io/improving-asset-utilization-in-your-business/</a>&nbsp;</p>
                        <p>Syriopoulos, T., Tsatsaronis, M., &amp; Gorila, M. (2022). The global cruise industry: Financial performance evaluation. <em>Research in Transportation Business &amp; Management, 45</em>, 100558. <a href="https://doi.org/10.1016/j.rtbm.2020.100558">https://doi.org/10.1016/j.rtbm.2020.100558</a><a href="https://s100.copyright.com/AppDispatchServlet?publisherName=ELS&amp;contentID=S2210539520301000&amp;orderBeanReset=true">Get rights and content</a></p>
                        <p>Tarver, E. (2023, May 5). <em>Investor relations (IR): Definition, career path, and example</em>. Investopedia. <a href="https://www.investopedia.com/terms/i/investorrelations.asp">https://www.investopedia.com/terms/i/investorrelations.asp</a></p>
                        <p>Team, C. (2024, February 19). <em>Key Performance Indicators (KPIs)</em>. Corporate Finance Institute. <a href="https://corporatefinanceinstitute.com/resources/management/key-performance-indicators-kpis/">https://corporatefinanceinstitute.com/resources/management/key-performance-indicators-kpis/</a></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Mbafpx5014assessment1
