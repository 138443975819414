import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';

const Samples = () => {

  const [posts, setPosts] = useState([]);

  const cat = useLocation().search
  console.log(cat)

  useEffect(()=>{
    const fetchData = async ()=>{
      try{
        const res = await axios.get(`/posts${cat}`)
        setPosts(res.data)
      }catch(err){
        console.log(err)
      }
    };
    fetchData()
  }, [cat]);


  const getText = (html) =>{
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent
  }

// pagination
  const [currentPage, setCurrentPage] = useState(1)
  const postsPerPage = 6;
  const lastIndex = currentPage * postsPerPage;
  const firstIndex = lastIndex - postsPerPage;
  const pagePosts = posts.slice(firstIndex, lastIndex);

  const npages = Math.ceil(posts.length / postsPerPage);

  const numbers = [...Array(npages + 1).keys()].slice(1);

  return (
    <Container>
    <Helmet>
      <title>Free Samples</title>
      <meta name='description'
      content='Online course services offer the best educational assistance in the United States via its tutoring services and free samples is also provided just for reference purposes. Our free sample page commitment to upholding academic integrity. By using our services student get easily online exam help to get good grades.'/>
    </Helmet>
    <div className='samples'>
      <div className='sidebar'>
         <span className='title'>Capella Free Samples</span>
         <hr></hr>
         <span className='subtitle'>Nursing</span><br/>
         <Link className='menu-link' to="/samples?cat=bsn">BSN</Link><br/>
         <Link className='menu-link' to="/samples?cat=msn">MSN</Link><br/>
         <Link className='menu-link' to="/samples?cat=dnp">DNP</Link>
      </div>
      <div className='posts-wrap'>
        <div className='posts'>
          {pagePosts.map((post)=>(
            <div className='post' key={post.id}>
              <div className='img'>
              <img src={`../upload/${post.img}`} alt=''/>
              </div>
              <div className='content'>
                <Link to={`/post/${post.id}`} className='postlink'>
                <h1 className='posttitle'>{post.title}</h1>
                <p className='postdesc'>{getText(post.desc)}</p>
                <button>Read More</button>
                </Link>
                <hr className='hline'></hr>
              </div>
            </div>
          ))}
        </div>
        <nav>
          <ul className='pagination'>
            <li className='page-items'>
              <a href='#' className='page-link' onClick={prePage}
              >Prev</a>
            </li>
            {
              numbers.map((n, i)=>(
                <li className={`page-items ${currentPage === n ? 'active' : ``} `} key={i}>
                  <a href='#' className='page-link'
                  onClick={()=> changeCPage(n)} >{n}</a>
                </li>
              ))
            }
            <li className='page-items'>
              <a href='#' className='page-link' onClick={nextPage}
              >Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    </Container>
  )
function prePage(){
  if(currentPage !== 1) {
    setCurrentPage(currentPage-1)
  }
}
function changeCPage(id){
  setCurrentPage(id)
}
  function nextPage () {
    if(currentPage !== npages){
      setCurrentPage(currentPage + 1)
    }
  }
}

export default Samples
