import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import { FaPencil } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import axios from 'axios';
import { AuthContext } from '../context/authContext';
import DOMPurify from "dompurify";
import { Helmet } from 'react-helmet';
import Popup from '../components/Popup';




const Post = () => {

  const [post, setPost] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  const postId = location.pathname.split("/")[2]

  const {currentUser} = useContext(AuthContext)

  useEffect(()=>{
    const fetchData = async ()=>{
      try{
        const res = await axios.get(`/posts/${postId}`)
        setPost(res.data)
      }catch(err){
        console.log(err)
      }
    };
    fetchData()
  }, [postId]);

  const handleDelete = async ()=>{
    try{
      await axios.delete(`/posts/${postId}`)
      navigate("/samples")
    }catch(err){
      console.log(err)
    }
  }

  // const getText = (html) =>{
  //   const doc = new DOMParser().parseFromString(html, "text/html")
  //   return doc.body.textContent
  // }

  return (
  
    <div className='post-blur'>
      <Popup/>
    <Container>
      <Helmet>
        <title>{post.title}</title>
        <meta name='description'
        content={`${post.matadesc}`}/>
      </Helmet>
    <div className='singlepost'>
      <div className='content'>
        <img src={`../upload/${post?.img}`} alt={`${post.title}`}/>
        {currentUser.username === post.username &&
         <span className='postaction'>
          <Link to={`/write?edit=2`} state={post}>
            <FaPencil className='postedit'/>
          </Link>
            <MdDelete className='postdelete' onClick={handleDelete}/>
        </span>}
        <h1>{post.title}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(post.desc),
          }}
        ></p>
      </div>
      <div className='menu'>
        <Sidebar cat={post.cat}/>
      </div>
    </div>
    </Container>
    </div>
  )
}

export default Post