import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx4000assessment2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/images/NURSFPX4060A2FI.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Looking for guidance with your NURS FPX 4060 Assessment 2 ....",
            samplelink: "https://onlinecourseservices.us/nurs-fpx-4060-assessment-2",
        },
        {
            image: "https://onlinecourseservices.us/images/NURSFPX4060Assessment4.png",
            title: "NURS FPX 4060 Assessment 4 Health Promotion Plan..",
            description: "Hello to everyone! I attend school. I will go over the health promotion plan that is outlined...",
            samplelink: "https://onlinecourseservices.us/nurs-fpx-4060-assessment-4",
        },
        {
            image: "https://onlinecourseservices.us/images/Nursfpx4050.png",
            title: "NURS FPX 4050 Assessment 1 Preliminary Care...",
            description: "oking for guidance with your NURS FPX 4050 Assessment 1?...",
            samplelink: "https://onlinecourseservices.us/nurs-fpx-4050-assessment-1",
        },
        {
            image: "https://onlinecourseservices.us/images/NURSFPX4050Assessment2featureimage.png",
            title: "NURS FPX 4050 Assessment 2 Ethical and Policy Factors..",
            description: "Looking for guidance with your NURS FPX 4050 Assessment 2 ....",
            samplelink: "https://onlinecourseservices.us/nurs-fpx-4050-assessment-2",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 4000 Assessment 02: Applying Research Skills</title>
                <meta name='description'
                    content='Craft your NURS FPX 4000 Assessment 02: Applying Research Skills with precision! Optimize care coordination plans efficiently. Get started now!' />
                <meta name='keywords' content='nurs fpx 4000 assessment 2' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 4000 Assessment 02:  < br /><span>Applying Research Skills</span></h1>
                    {/* <p>Hi. I’m (Student), and currently, I want to recommend in-service training that encourages the medical staff < br />to approach patient treatment more proactively. </p>
                    <div className='sample-hero-links'>
                        <a href="#">Online Course Services</a>
                        <a href="#">Free Samples</a>
                        <a href="#">Online Course Services</a>
                        <a href="#">Online Course Services</a>
                    </div> */}
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/NURSFPX4000Assessment02FI.png" alt="" />
                    <div className='main-container-sl-content'>
                        {/* <h2>NURS FPX 4020 Assessment 3 Improvement Plan In-service Presentation</h2>
                        <p>Slide 1
                            Hi. I’m (Student), and currently, I want to recommend in-service training that encourages the medical staff to approach patient treatment more proactively. The purpose of this lecture is to raise public awareness of the issue of physicians failing to act quickly enough as patients’ circumstances worsen.
                            < br />
                            Slide 2
                            A strategic effort to improve the quality of care and patient safety is an in-service demonstration of the improvement plan that addresses the matter of delayed reaction to worsening patient events in the healthcare system. This talk will provide a thorough approach to dealing with the difficulties of identifying and acting quickly when a patient’s health deteriorates. Usually, this involves instructing medical personnel on early warning indications, putting in place standard processes for communication and assessments, and encouraging a proactive observation culture. This presentation is focused on reducing the time it takes to detect patients who are deteriorating, which will improve outcomes for patients and lower the number of negative incidents in healthcare settings. It will do this by emphasizing the advantages of quick action and cooperative cooperation. The healthcare sector will need to use it as a key tool for encouraging ongoing patient care and safety enhancement.
                            < br />  < br />
                            <h2> Agenda and Outcomes of In-service Training</h2>
                            < br />
                            Slide 3
                            Attending an in-service session on the specific patient safety concern of delayed response to worse patient conditions in healthcare will help healthcare employees gain the knowledge, abilities, and techniques demanded to recognize and act rapidly and effectively when a patient’s condition deteriorates. Ensuring patient safety and enhancing comprehensive healthcare outcomes are essential.
                            < br />< br />
                            <h2> Goals</h2>
                            < br />
                            Educating medical personnel about the symptoms and warning signs of escalating situations with patients is the primary objective. Participants will learn about the early warning signs of patient deterioration throughout the training course, which include transformed vital signs, elevated pain thresholds, and altered states of consciousness. Medical personnel may avoid additional deterioration by acting quickly to recognize these indicators (Burdeu et al., 2020). Encouraging improved teamwork and communication within the healthcare system is another crucial objective. This includes sharing useful communication techniques and highlighting the need for timely and transparent information exchange—the goal of the in-service. The program aims to foster an environment of open communication so that concerns about patient deterioration may quickly reported to the relevant staff members, which facilitates immediate intervention (Kwame & Petrucka, 2021).
                            < br />
                            The final purpose is practical instruction and modeling. In a supervised environment, healthcare professionals may hone their response to patients’ worsening situations. According to Smith et al. (2021), individuals can boost their capacity for decision-making, reaction, and evaluation skills via simulations. These hands-on activities are crucial to developing competence and confidence in difficult circumstances.
                            < br />< br />
                            <h2>The Need and Process to Improve Safety Outcomes</h2>
                            < br />
                            Slide 4
                            To protect the well-being of patients and ensure adherence to set regulations, safety consequences in healthcare must be improved, specifically by addressing the postponed response to worsening patient situations (Dresser et al., 2023). Standards like those created by The Joint Commission, which stress quick identification and reaction to modifications in a patient’s condition as an essential component of safe treatment, highlight the need for improvement in the USA (Shenoy, 2021).
                            < br />
                            A slow response to a patient’s worsening health can end up in unfavorable outcomes. Reducing the risk of such incidents is important, as specified in the National Patient Safety Goals, another US standard (Burke et al., 2020). In order to achieve these objectives and stop easily avoidable injury, prompt detection and action are key. Furthermore, the Affordable Care Act and other government guidelines have increased the emphasis on patient safety, necessitating improvements to healthcare providers’ reaction systems to decreasing circumstances (Levine et al., 2022). To sum up, strengthening safety results by addressing delayed replies is consistent with US standards, guaranteeing patient-centered therapies and reducing accidental damage.
                        </p> */}
                        <h2>Applying Research Skills</h2>
                        <p><strong>Looking for guidance with your </strong>NURS FPX 4000 Assessment 02<strong>? Our experts are here to assist you. Reach out to us for support today.</strong></p>
                        <p>Burnout among the nurses has been viewed as an essential issue, that is very crucial to the health care industry. The burnout &ndash; defined as emotional exhaustion, depersonalization and the feeling of reduced personal accomplishment &ndash; is not only means the loss of wellbeing for nurses but also lead to negative impact on both the care delivered to patients and organizational results (Maslach et al., 2019). Healthcare professionals in nursing roles, with responsibilities such as working long shifts, dealing with high-volume emergencies, and being exposed to stressful situations on almost a daily basis, are at a significantly higher risk of burnout (Salyers et al., 2020). This research intends to get to the bottom of the complex issues that texture the Burnout syndrome of nurses that include investigation into the prevalence of this syndrome, its causes and possible interventions. Through a better understanding of what causes burnout, healthcare organizations can formulate precision interventions that aim at reducing and even eliminating this syndrome and enhancing the general health nurse staff.</p>
                        <h2>Academic Peer Reviewed Journal Articles</h2>
                        <p>Alharbi, J., Wilson, R., Woods, C., Usher, K., &amp; Jackson, D. (2020). The factors influencing burnout and job satisfaction among critical care nurses: A study of Saudi critical care nurses. Journal of Nursing Management, 28(5), 1050&ndash;1058. <a href="https://doi.org/10.1111/jonm.1300">https://doi.org/10.1111/jonm.1300</a></p>
                        <p>This study investigates the factors influencing burnout and job satisfaction specifically among critical care nurses in Saudi Arabia. By focusing on this specific population, the research sheds light on cultural and organizational factors contributing to burnout among critical care nurses, offering insights into potential interventions tailored to this context.</p>
                        <p>Garrosa, E., &amp; Moreno-Jim&eacute;nez, B. (2021). Pathways between workplace bullying, burnout, and psychological distress in hospital nurses: A longitudinal cross-lagged study. Journal of Interpersonal Violence, 36(1-2), NP6631-NP6653. <a href="https://doi.org/10.1177/088626051985872">https://doi.org/10.1177/088626051985872</a></p>
                        <p>This longitudinal study examines the pathways between workplace bullying, burnout, and psychological distress among hospital nurses. By exploring the temporal relationships between these variables, the research provides valuable insights into the potential mechanisms through which workplace bullying contributes to nurse burnout and psychological well-being over time.</p>
                        <p>Li, P., &amp; Xu, J. (2021). Organizational justice and burnout among Chinese nurses: The mediating role of psychological empowerment. Journal of Nursing Management, 29(5), 1235&ndash;1244. <a href="https://doi.org/10.1111/jonm.1322">https://doi.org/10.1111/jonm.1322</a></p>
                        <p>This study investigates the relationship between organizational justice, burnout, and psychological empowerment among Chinese nurses. By examining the mediating role of psychological empowerment, the research elucidates how perceptions of fairness within the organization may influence nurse burnout, offering insights into potential strategies to promote a supportive work environment.</p>
                        <p>Sasso, L., Bagnasco, A., Catania, G., Zanini, M., Aleo, G., &amp; Watson, R. (2019). Push and pull factors of nurses&rsquo; intention to leave. Journal of Nursing Management, 27(5), 946&ndash;954. <a href="https://doi.org/10.1111/jonm.1279">https://doi.org/10.1111/jonm.1279</a></p>
                        <p>This study explores the push and pull factors influencing nurses' intention to leave their jobs. By examining factors such as burnout, job satisfaction, and organizational support, the research provides insights into the complex interplay of variables influencing nurse retention, offering implications for addressing burnout and promoting nurse retention within healthcare organizations.</p>
                        <img src="images/NURSFPX4000Assessment02MI.png" alt="" />
                        <h2>Credibility of Information and Relevance of the Information Sources</h2>
                        <h3>Peer-Reviewed Journals:</h3>
                        <p>In this sense, the peer-reviewed journals are credible as they undergo thorough review process by experts in the industry before the publications become available. To decide, which article from the peer-reviewed journal to take, one needs to account for factors such as the journal's reputation, the completeness of authorship, and the standard of research methodology used.</p>
                        <h3>Relevance:</h3>
                        <p>The chosen articles are published in notable journals on the subject as everyone can find out Journal of Nursing Management, Journal of Interpersonal Violence and European Journal of Work and Organizational Psychology. These journals are fairly reputed in the nursing and health research community as it show that the research papers that they have published have undergone through much scrutiny and they all meet the defined academic professionalism standards. The value of the referred sources is in the fact that they focus on research on several sides of nurses exhaustion, including the ratio, factors which determine it, and its consequences, and therefore they provide useful data which could be the starting point at battling this important issue in nursing work and state programs.</p>
                        <h3>Research Methodology:</h3>
                        <p>Analysis of the applied research methodology within the researches is considered as a mandatory stage in assessing trustworthiness. Emphasize on researches done with robust methods like cohorts, randomized controlled trials or systematic reviews that points to the validity and authenticity of the information. Consider factors including sample size, data gathering methods, statistical analysis, and imperfections that might lead to biases.</p>
                        <h3>Relevance:</h3>
                        <p>The selected articles integrate various research strategies, such as cross-sectional studies, longitudinal surveys, and meta-analyses. Take for instance the longitudinal study done by J.G. Garrosa and I. Moreno-Jim&eacute;nez that explored the time relationships between the workplace bullying, burnout, as well as psychological distress of hospital nurses, contributing greatly to our understanding of the chain reactions underlying burnout. Moreover, reviews which syntheses the current studies including the one conducted by presents a broader context of burnout among nurses by mainly identifying determinant themes as well as the rates across different specialties of nursing.</p>
                        <h2>Annotated Bibliography</h2>
                        <p>1.Alharbi, J, Wilson, R, Woods, C., Usher, K, Jackson, D. (2020). The factors influencing burnout and job satisfaction among critical care nurses: Evaluating Saudi critical care nurses by conducting research. Journal of Nursing Management, Vol. 28, No. 5, pp. 1050&ndash;1058. <a href="https://doi.org/10.1111/jonm.1300">https://doi.org/10.1111/jonm.1300</a>9</p>
                        <p>A study was performed to identify those major factors involved in burnout as well as job meaning in Nurses in intensive care units in Saudi Arabia. The nursing research targets a particular nursing specialization and a particular cultural setting. As a consequence, it offers our audience a better understanding of the peculiarities of critical care nursing in the Saudi healthcare setup.</p>
                        <p>2.Garc&iacute;a, E. &amp; Moreno-Jim&eacute;nez, B. (2021). Pathways between workplace bullying, burnout, and psychological distress in hospital nurses: Phase-coupled change in a contrast-lag model. Humanize the sentence: Journal &ldquo;Interpersonal Violence,&rdquo; vol. 36, no. 1-2, pp. NP6631-NP6653. <a href="https://doi.org/10.1177/088626051985872">https://doi.org/10.1177/088626051985872</a>2</p>
                        <p>This co-survey conducted in successive years investigates if bullying at work preceding nurse burnout and psychological distress in hospitals. Through this kind of dissecting of the routes and pathways over time, the research sheds light on a variety of possible mechanisms whereby nurse bullying exacerbates burnout and reduces staff psychological well-being. Longitudinal design takes us to the examination of the causal links that exist between the bullying in workplace and nurse burnout which leads to more pointed interventions to be aimed at the workplace bullying and its effects on the otherwise healthy nurses.</p>
                        <h2>Learnings from the Research</h2>
                        <p>Performing the annotated bibliography on nurse burnout allows me to come up with significant observations on the research domain breadth on this topical nursing issue.</p>
                        <h3>Diverse Factors Contributing to Burnout:</h3>
                        <p>Bibliographical taking and more issues related with burnout like enlarging of workloads, low job satisfaction, bullying at job, and influence of cultural traditions. Such as, Alharbi et al. (2020) on burnout among critical care nurses in Saudi Arabia&rsquo;s population focused on the role of cultural factors while Sasso et al. (2019) was centred on organizational factors like job contentment and support as crucial determinants of nurse turnover.</p>
                        <h3>Specialty-specific Challenges:</h3>
                        <p>&nbsp;It seems Burnout is diverse among different infirmaries, where critical care nurses and emergency nurses must cope with a variety of stressors. Adriaenssens had developed a systematic review specific to emergency nurses and burnout and colleagues, the paper discuss the distinctive issues that emergency nurses faced. Subsequently, (Alharbi et al. 2020) still emphasize on the significance of understanding specialty-specific factors in the process of addressing burnout, especially among critical care nurses in the Kingdom of Saudi Arabia.</p>
                        <h3>Longitudinal and Cross-sectional Studies:</h3>
                        <p>&nbsp;The annotated bibliography sample proposed research investigations with different methodological strategies, such as longitudinal designs, cross-section studies, and systematic reviews. For instance, Garrasa and Moreno-Jim&eacute;nez (2021) did a cross-lagged language study to observe the causality of cycles among hospital nurses, occupational bullying, burnout and psychological depression, hence, revealing the relationships that exist overtime.</p>
                        <h3>Implications for Practice and Policy:</h3>
                        <p>Through systematizing results of several textbook studies, bibliography annotation shed light on the effects of burnout on nursing practice and policy. Take for example, the study which lists the variables affecting the intent of nurses to quit their jobs (Sasso et al. 2019). These variables include burnout and organizational support, with clearly underlined importance of responding to them if the goal is to have nurses stay and patients are satisfied.</p>
                        <h2>Conclusion</h2>
                        <p>The Burnout Exploration among Nurses Using Researching Skills furnished profound ideas into this vital issue that healthcare providers converge on daily. Even though we have examined a few scholarly references through the process of constructing the annotated bibliography, several reserved ideas emerged. Fosterly the fact that burnout among nurses is multiply determined by many factors such as workload, job satisfaction, organizational culture, as well as specific on-spot conditions. Studies have proven that in the event of nursing in emergency, intensive care, and other cultural contextual settings, these nurses face distinctive factors that contribute to the burnout syndrome. Recognizing that the factors are critical for launching appropriate actions to curb burnout effectively will be useful.</p>
                        <h2>References</h2>
                        <p class="text-wrap">Aryankhesal, A., Mohammadibakhsh, R., Hamidi, Y., Alidoost, S., Behzadifar, M., Sohrabi, R., &amp; Farhadi, Z. (2019). Interventions on reducing burnout in physicians and nurses: A systematic review. <em>Medical Journal of the Islamic Republic of Iran</em>, <em>33</em>(77), 77. https://doi.org/10.34171/mjiri.33.77</p>
                        <p class="text-wrap">Cheung, E. O., Hernandez, A., Herold, E., &amp; Moskowitz, J. T. (2020). Positive emotion skills intervention to address burnout in critical care nurses. <em>AACN Advanced Critical Care</em>, <em>31</em>(2), 167&ndash;178. https://doi.org/10.4037/aacnacc2020287</p>
                        <p class="text-wrap">Cocchiara, R., Peruzzo, M., Mannocci, A., Ottolenghi, L., Villari, P., Polimeni, A., Guerra, F., &amp; La Torre, G. (2019). The use of yoga to manage stress and burnout in healthcare workers: A systematic review. <em>Journal of Clinical Medicine</em>, <em>8</em>(3), 284. https://doi.org/10.3390/jcm8030284</p>
                        <p class="text-wrap">de Oliveira, S. M., de Alcantara Sousa, L. V., Vieira Gadelha, M. do S., &amp; do Nascimento, V. B. (2019). Prevention actions of burnout syndrome in nurses: An integrating literature review. <em>Clinical Practice &amp; Epidemiology in Mental Health</em>, <em>15</em>(1), 64&ndash;73. https://doi.org/10.217 4/1745017901915010064</p>
                        <p class="text-wrap">Lee, M., &amp; Cha, C. (2023). Interventions to reduce burnout among clinical nurses: Systematic review and meta-analysis. <em>Scientific Reports</em>, <em>13</em>(1), 1&ndash;12. https://doi.org/10.1038/s41598-023-38169-8</p>
                        <p class="text-wrap">Lopes, A. R., &amp; Nihei, O. K. (2020). Burnout among nursing students: Predictors and association with empathy and self-efficacy. <em>Revista Brasileira de Enfermagem</em>, <em>73</em>(1). <a href="https://doi.org/10.1590/0034-7167-2018-0280">https://doi.org/10.1590/0034-7167-2018-0280</a></p>
                        <p class="text-wrap">Shimizu, T., Mizoue, T., Kubota, S., Mishima, N., &amp; Nagata, S. (2003). Relationship between burnout and communication skill training among japanese hospital nurses: A pilot study. <em>Journal of Occupational Health</em>, <em>45</em>(3), 185&ndash;190. https://doi.org/10.1539/joh.45.185</p>
                        <p class="text-wrap"><strong>Require help with your <a href="onlinecourseservices.us/nurs-fpx-4020-assessment-4">Assessments</a>? Our expert team is standing by to assist you. Get in touch with us now</strong></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Nursfpx4000assessment2
