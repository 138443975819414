import React from 'react'
import Edsschoolhero from './Edsschoolcomponent/Edsschoolhero'
import Edsschooldegreedetail from './Edsschoolcomponent/Edsschooldegreedetail'
import Bsnfeatures from '../Nursing/bsnpagecomponent/Bsnfeatures'
import Bannertwo from '../../homepage/Bannertwo'
import Bsnfaqs from '../Nursing/bsnpagecomponent/Bsnfaqs'

const Edsschool = () => {
  return (
    <div>
      <Edsschoolhero/>
      <Edsschooldegreedetail/>
      <Bsnfeatures/>
      <Bannertwo/>
      <Bsnfaqs/>
    </div>
  )
}

export default Edsschool
