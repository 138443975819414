import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";

const Dnpcapstoneprojecthelp = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Research Writers",
            Desc: "Get help from skilled Capella DNP FlexPath research writers. They are always ready for help.",
        },
        {
            icon: "fa-solid fa-school icon",
            title: "Class Help",
            Desc: "Our tutors offer Capella University DNP FlexPath class-taking services for Capella DNP FlexPath students!",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Courses Help",
            Desc: "Take help from Capella DNP FlexPath professionals in their Capella DNP FlexPath courses.",
        },
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Tutoring Help",
            Desc: "Take help from our tutors, who are Capella DNP FlexPath Curriculum professionals. ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a Capella DNP FlexPath intellectuals team to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We can always help you with your Capella DNP FlexPath Curriculum and coursework!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to Capella University DNP FlexPath students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek complete support from Capella University DNP FlexPath nursing experts.",
        },
    ]
    return (
        <>
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
            <div className='sherotop-left'>
            <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
            <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
            <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
            </div>
            <div className='cal-box'><Calculator/></div> 
        </div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>GET DNP CAPSTONE PROJECT HELP  <br />BY ONLINE COURSE SERVICES</span><br />
                                </h1>
                                <h2>ARE YOU LOOKING FOR SOMEONE TO HELP YOU<br /> WITH YOUR DNP CAPSTONE PROJECT?</h2>
                            <p className='ocs2-topText'> Our professionals are providing exceptional services to help Capella University students with the DNP Capstone project.</p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Professionals</h2>
                            <p>Take help from professional tutors, mentors, and writers to excel in your Capella University DNP FlexPath coursework.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Get Nursing Papers</h2>
                            <p>Get excellent and well-researched Capella DNP FlexPath nursing papers!</p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET CAPELLA DNP FLEXPATH ASSESSMENT SOLUTIONS!</h2>
                <p>We provide excellent Capella University DNP FlexPath assessment solutions for students in their Capella DNP FlexPath Courses.</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Educational Services</span>
                    <h2>CAPELLA DNP CAPSTONE PROJECT WRITING SERVICES</h2>
                    <p>We at Online Course Services are committed to providing top-notch DNP capstone project writing services to Capella University students. Our team of DNP capstone project writers, tutors, and mentors is always ready to support your Capella DNP Capstone Project. Our team is relatively professional in assisting students with their Capella DNP Capstone Project. The researchers at Online Course Services do qualitative and quantitative analysis on your project topic to assist students with the best results. Our high-scoring Capella DNP Capstone Project writing services are always available for Capella University students.</p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>Outclass results at DNP Capstone Writing Services</strong></Tab>
                        <Tab><strong>With Our Support Services Improve Your Nursing Capstone Project Experience</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p>Are you a nursing student and ready for your DNP Capstone project at Capella University? In this regard, we at Online Course Services provide you the specialized DNP capstone writing services.
                            <br /> <br />
                            <strong>Support by Capella DNP Capstone Project writers</strong><br />
                            The Capella Capstone Project DNP is the result of your complete nursing schooling which requires you to demonstrate your skills and knowledge in the healthcare sector and we provide DNP capstone writing services.
                            <br /><br />
                            <strong>Professional Nursing Tutors</strong>
                            <br />
                            Our tutors consist of nursing professionals who understand the Capstone Project DNP at Capella University.
                            <br /><br />
                            <strong>Academic Support</strong>
                            <br />
                            Every student can achieve their academic goals at the Online Course Services as our personalized services are here to help you.
                            <br /><br />
                            <strong>Efficient Learning Solutions</strong>
                            <br />
                            We provide efficient solutions for your Capstone Project DNP that are according to your schedule.
                            <br /><br />
                            <strong>Road to Your Academic Goals with Our Services</strong>
                            <br />
                            By availing of our Online Course Services, you can easily tackle any problem that you face in your Capstone Project DNP.
                           
                        </p>
                    </TabPanel>
                    <TabPanel>
                        <p>Preparing for your DNP Capstone Project help at Capella University may be a challenging task but with our support, you can easily overcome it.
                        <br /> <br />
                            <strong>Comprehensive Services</strong><br />
                            We help students develop their research questions to help them to complete their DNP Capstone Project.
                        </p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Features</span>
                    <h2>WHY CHOOSE ONLINE COURSE SERVICES FOR THE DNP CAPSTONE PROJECT</h2>
                    <p>We provide DNP Capstone Project help to Students enrolled in Capella University Nursing Programs. Our Professional team is comprised of DNP capstone project writers, intellectuals, and mentors. These intellectuals are doing their best to support students in their Capstone Projects. The team at online course services has been devoted to helping students in their coursework over the years. Their research skills assist students in Capstone projects to get good grades. We have a track record of a million successful Capstone Projects. Let us handle the stress of the Project and make sure you get great grades. Get in touch with us to improve your overall academic Performance!</p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>From Online Course Services for the DNP Capstone projects who can benefit?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Capella University students enrolled in the DNP programs can benefit from the Online Course Services.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>For DNP Capstone projects what support does Online Course Services offer?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    It offers tutoring, writing, and mentoring services.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>How project success is ensured by Online Course Services?    </h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    With the help of quantitative and qualitative analysis and a record of several projects.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>How can students contact Online Course Services?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Students can contact the website or the contact information provided.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>In which projects Online Course Services can assist?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    It majorly assists with projects related to the DNP Capstone.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>How experienced is the team at Online Course Services?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>
                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Their team has writers, tutors, and mentors who provide help to Capella University students.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can Online Course Services help with specific areas of the DNP Capstone, such as research design?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>
                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes, the team offers tailored assistance, including research design, data analysis, and presentation.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Are the services provided by Online Course Services confidential?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>
                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes, Online Course Services maintains strict confidentiality regarding all client information and project details.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>


                        </Accordion>
                    </div>
                </div>
            </Container>
        </>

    )
}

export default Dnpcapstoneprojecthelp
