import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx4900assessment4 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 4900 Assessment 4</title>
                <meta name='description'
                    content='Complete your NURS FPX 4900 Assessment 4 with our comprehensive guide. Enhance your nursing skills and knowledge today.' />
                <meta name='keywords' content='NURS FPX 4900 Assessment 4' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 4900 Assessment 4< br /><span>Patient, Family, or Population Health Problem Solution</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/NURSFPX4060Assessmen3.png" alt="" />
                    <div className='main-container-sl-content'>
                        <h2>Patient, Family, or Population Health Problem Solution</h2>
                        <p>Evidently, it is forecasted that 78 percent of existing Internet users will purchase items online within the next five years. It is predicted that 4 million persons in the US will be affected by rheumatism by 2040; thus, the provision of ways to improve the treatment&rsquo;s availability and affordability is paramount (Kellahan et al., 2023). As Josephine&rsquo;s nurse, I would advocate for changes in legislation that would lower the cost of prescripted drugs, increase access to generics, and improve Medicaid&rsquo;s coverage of arthritis medications. In addition to such knowledge and tools to manage symptoms, I could also suggest that Josephine exercise to maintain her mobility and correct joint protection and inform her about other charitable organizations that can help with the financial aspects of the disease. Josephine may be able to live a far happier and far more healthy life if she is taken care of properly and given some help.</p>
                        <h2>Role of Leadership and Change Management</h2>
                        <p>As a result, it should be noted that revolutionary changes in the management of rheumatoid arthritis therapy imply the promotion of legislative reforms aimed at improving the availability and affordability of treatment. It is possible to employ the leadership that transforms to solicit a change in the role of Josephine&rsquo;s doctor. This includes applying idealistic power, individual consideration, thinking stimulation, and motivation for the politicians. The collected findings also suggest that the nurse may invite lawmakers to meet Josephine individually to present her charismatic and, finally, more persuasive moral but dynamic case of how expensive prescription drugs ruined Josephine and her economy. The nurse may speak on behalf of the lower prescription costs price rise caps and give Medicaid the power to negotiate rates by example through activism and legislator relations (Skochelak, 2020).</p>
                        <p>&nbsp;Treatment of the condition will also increase through the utilization of effective organizational transformation strategies. The proposed approach will also help improve the treatments for the condition through the expansion of the available treatment options. This type of theory is helpful for nurse leaders who would like to implement changes in their medical contexts: Kotter&rsquo;s eight-step strategy (Fagerstr&ouml;m, 2021). In this context, one needs to raise awareness about the patient&rsquo;s economic troubles and create a group of providers who care about this problem, develop a plan and goal of having more coverage, empower the employees to start new assistance applications and incorporate such changes into the organizational culture. If Kotter&rsquo;s gradual paradigm is applied, improvement will demonstrate impressive results on the questions of economic assistance, medication accessibility, and knowledge of self-management.</p>
                        <p>&nbsp;In this aspect, the healthcare worker may apply revolutionary management on an individual level to educate and empower Josephine. The nurse uses specialized concern in handling the patient since she educates Josephine on how to handle herself, including the use of joint protection techniques and individualized exercise programs. Since Josephine is able to manage her symptoms through intellectual activity, it can be easy to make her more involved in the management of her symptoms; hence, she can easily be compelled to adhere to plans put in place for treatment. Through broad policy advancements and reformulation at the institutional level, the healthcare provider can avail comprehensive treatment for Josephine&rsquo;s rheumatoid arthritis in addition to specific patient advocacy.</p>
                        <h2>Informed Nursing Ethics</h2>
                        <p>&nbsp;The elements of the ethical code associated with the patient&rsquo;s rights and the principles of beneficence, distributive justice, and fairness guided my suggested solution for Josephine. Thus, generosity would mean having Josephine&rsquo;s needs in mind and going the extra mile to take care of her physical and psychological health. Lowering the prices of the glass of pharmaceuticals can be justified under the mutual beneficence concept since it plays a critical role in removing the financial barriers that deny Josephine the necessary medicine for treating her autoimmune condition. Then, in society, people should be treated equally so that justice is valued (Savulescu &amp; Cameron, 2020). Promoting changes in the law to raise insurance and decrease costs would assist Josephine and other patients with issues repaying for their inflammatory arthritis medications. Availabilities also come in handy when treating patients, a concept that fairness also necessitates that nurses ensure that patients have adequate access to facilities in which they receive treatment (Fritz &amp; Cox, 2020). As the circle of playing is leveled and rationalization for non-receipt of a high-quality, reasonably-priced therapy as a disease-appropriate treatment plan amended, Josephine&rsquo;s avails on the financial support initiatives and instruction of self-care display the tenets of equity in the morality of health care.</p>
                        <p>&nbsp;</p>
                        <h2>Communication and Collaboration Strategies</h2>
                        <p>Applying the principles of cooperative procedures, which is a registered nurse policy, Josephine&rsquo;s arthritis-related rheumatoid therapy might be enhanced alongside encouraging engagement levels. Meeting Josephine&rsquo;s need for control in order to manage her health involves time-consuming and conscious respect for her opinions, as well as creating goals of care using a shared decision-making process. The teach-back strategies could ensure that Josephine comprehends such self-management measures as joint preservation (Talevski et al., 2020). In the positive interview, questions that may be vague range from identifying Josephine&rsquo;s adherence to therapy obstacles. Patient-centered cooperation is demonstrated by developing the diet and the fitness program with reference to Josephine&rsquo;s preferences and routines.</p>
                        <p>&nbsp;Besides, proper working of Josephine requires appropriate interpersonal communication in order to enhance her results. To improve the level of medical literacy, nurse educators should speak slowly, avoid the use of complex terms, tear down information, and use examples. The educators can use the teach-to-goal method and Josephine&rsquo;s elaboration of the concepts to assess the students&rsquo; understanding during the class. Affordable and empathetic communication could reduce the chances of the use of medical terms (Tan et al., 2021). Feedback may be given by the nurse when performing self-care tasks.&rsquo; Th. The demonstration is done several times to some extent. More elaborate messaging focused on care for rheumatoid arthritis treatment is conducive to increasing Josephine&rsquo;s engagement level.</p>
                        <p><strong>Advantage of Facing Input from Patients of Family </strong></p>
                        <p>&nbsp;In approaching Josephine in this manner, the nurse can acquire further information regarding Josephine&rsquo;s perspectives and goals, including how to address her status. This way, the healthcare provider may run into problems that would otherwise go unnoticed when simply focusing on questions, challenges, and opportunities related to Josephine&rsquo;s treatment by engaging in inspiring interviewing techniques to understand her treatment challenges, questions, and goals (Fritz &amp; Cox). It becomes possible to integrate Josephine&rsquo;s behavior and cultural traditions into managing your strategies, including workout and meal plans. Josephine&rsquo;s participation ensures that instruction and the development of skills are communicated clearly and that time is not wasted. Involving the patient in engagement and teaching with Josephine&rsquo;s input increases the therapy&rsquo;s assertiveness.</p>
                        <h2>Guidance by State Board Nursing Practice or Policies</h2>
                        <p>To avert breaching the set standards, I maintained the American Nurses Association&rsquo;s Code of Ethics, which provides moral and professional standards for nurses as I was nursing Josephine. Provision 3 had particular instructions for me regarding lobbying for bills to reduce the cost of drugs, particularly RA medications, for indigent individuals such as Josephine. 4 on openness and fairly distributing the available funds was also observed (Kpienbaareh et al., 2020). This includes copay support, which is funded by the donor, with prices containing a range of fees with income-based price changes; charity health care funds for underinsured patients cover both patient visits and prescription drugs in case of qualified patients.</p>
                        <p>&nbsp;Josephine&rsquo;s management was based on the Healthy People 2030 guidelines included in the central government&rsquo;s regulation as guidelines for research-based recommendations for the reduction of health inequalities that I followed. Insuring that Josephine is able to receive quality treatment is therefore essential as this individual is an at-risk humanity, and this is why AH-06 strategy aims at increasing the capacity of individuals that are able to access quality treatment. My focus in this objective relates to this emphasis on information and instruction concerning self-management.</p>
                        <p>&nbsp;Utility of Standards and Policies</p>
                        <p>&nbsp;Next and lastly, the pregnant, organizational, and administrative rules and standards that I have consulted while designing the strategies for Josephine&rsquo;s arthritis care seem to be beneficial, as per the latest studies. Studies on medical financial aid for underprivileged patients show they serve an admirable purpose when clinics offer sizable discounted fees based on a patient&rsquo;s income, and nurses help identify eligible individuals because they see improved compliance, a lower rate of hospitalization, and higher reported happiness among patients. In existing literature, it was noted that reasons for human rights promotion, such as Justice and Fairness, positively impact a nurse&rsquo;s practice: Nurses who adhere to the third clause of Justice and Fairness when nursing are more likely to promote equality in nursing patient economics than those who do not (Copeland, 2020). In addition, initiated subset trials in one&rsquo;s rationality on arthritis depicted in Healthy People 2030 have indicated that government provisions for equal amounts of information on how to manage emotions reduce the rigidity of rheumatism and improve the quality of life.</p>
                        <p>&nbsp;</p>
                        <h2>Improvement in Care, Cost, and Safety</h2>
                        <p>Struggling for new laws may change the application of pharmaceutical prices and improve the quality of treatment, converting financial barriers into history by meeting Josephine&rsquo;s needs for essential medications. The study shows that policies and changes to medication prices promote patients&rsquo; treatment and engagement for chronic disediseasesarnstr&ouml;m et al., 2021). Decreased threat of illnesses and the completely unexpected joint maladies corresponding to rheumatoid arthritis also advance patient protection when compliance is applied. Making the prices of some of these medicines come down may eventually have a ripple effect of decreasing overall health bills that are related to unpredictable diseases. At-risk individuals will be provided with necessary, timely relief by executing financial support and institutional help programs to start and sustain treatment procedures. The efficiency of these systems is also a significant advantage since they help in reducing treatment stops and disturbances that affect the patient&rsquo;s safety. Patients are given specific treatment for the disorder, and the training in self-management with a nurse could result in a better outcome. Specific to systematic reviews, enhanced the quality of life through the reduction of pain and functional limitations by providing one-on-one education on osteoarthritis that involves management of its symptoms, protective strategies for the joint, and rehabilitative activities (Kvarnstr&ouml;m et al., 2021).</p>
                        <h2>Conclusion</h2>
                        <p>&nbsp;Josephine&rsquo;s case demonstrates these potential effects of the disease on the quality of existence and pertinently explains the commonly overriding challenges patients face in the search for quite reasonable treatments. In recommended nursing interventions for G, a multimodal approach is employed, and means to control a person without bureaucratic and organizational restraints are used. The advocacy for formulations that will lower the cost of prescription drugs aims at establishing care, justice, and fairness. Josephine&rsquo;s budget issues can initially be solved by making connections between her and institutional scholarship programs and thus pursuing a scholarship as the immediate goal. Therefore, Josephine will achieve the most remarkable wellness outcome by participating in individualized instruction and self-management help. Integrating campaigning for anti-inflammatory medications at the organizational level and supervision of care provided with the nursing ethical principles at the individual level might enable healthcare workers to ensure that patients receive comprehensive treatment for chronic inflammation-related ailments.</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <h2>References</h2>
                        <p>Copeland, D. (2020). A Critical Analysis of the American Nurses Association Position Statement on Workplace Violence. <em>Advances in Nursing Science</em>, <em>Publish Ahead of Print</em>(2). <a href="https://doi.org/10.1097/ans.0000000000000345">https://doi.org/10.1097/ans.0000000000000345</a></p>
                        <p>Fagerstr&ouml;m, L. M. (2021). Leading Change When Implementing Advanced Practice Nursing. <em>A Caring Advanced Practice Nursing Model</em>, 219&ndash;233. <a href="https://doi.org/10.1007/978-3-030-53555-1_12">https://doi.org/10.1007/978-3-030-53555-1_12</a></p>
                        <p>Fritz, Z., &amp; Cox, C. L. (2020). Integrating philosophy, policy, and practice to create a just and fair health service. <em>Journal of Medical Ethics</em>, <em>46</em>(12), 797&ndash;802. <a href="https://doi.org/10.1136/medethics-2020-106853">https://doi.org/10.1136/medethics-2020-106853</a></p>
                        <p>Kellahan, S., Burnett, L., Eisen, S., Curtis, M. P., &amp; Parks, D. (2023). Development of a structured musculoskeletal and rheumatic disease continuing education program for nurse practitioners. <em>Journal of the American Association of Nurse Practitioners</em>, <em>Publish Ahead of Print</em>. <a href="https://doi.org/10.1097/jxx.0000000000000832">https://doi.org/10.1097/jxx.0000000000000832</a></p>
                        <p>Kong, E., Shepard, M., &amp; McIntyre, A. (2022). Turnover in Zero-Premium Status Among Health Insurance Marketplace Plans Available to Low-Income Enrollees. <em>JAMA Health Forum</em>, <em>3</em>(4), e220674. <a href="https://doi.org/10.1001/jamahealthforum.2022.0674">https://doi.org/10.1001/jamahealthforum.2022.0674</a></p>
                        <p>Kpienbaareh, D., Bezner Kerr, R., Luginaah, I., Wang, J., Lupafya, E., Dakishoni, L., &amp; Shumba, L. (2020). Spatial and Ecological Farmer Knowledge and Decision-Making about Ecosystem Services and Biodiversity. <em>Land</em>, <em>9</em>(10), 356. <a href="https://doi.org/10.3390/land9100356">https://doi.org/10.3390/land9100356</a></p>
                        <p>Kvarnstr&ouml;m, K., Westerholm, A., Airaksinen, M., &amp; Liira, H. (2021). Factors Contributing to Medication Adherence in Patients with a Chronic Condition: A Scoping Review of Qualitative Research. <em>Pharmaceutics</em>, <em>13</em>(7), 1100. <a href="https://doi.org/10.3390/pharmaceutics13071100">https://doi.org/10.3390/pharmaceutics13071100</a></p>
                        <p>Ochiai, E., Kigenyi, T., Sondik, E., Pronk, N., Kleinman, D. V., Blakey, C., Fromknecht, C. Q., Heffernan, M., &amp; Brewer, K. H. (2021). Healthy People 2030 Leading Health Indicators and Overall Health and Well-being Measures: Opportunities to Assess and Improve the Health and Well-being of the Nation. <em>Journal of Public Health Management and Practice</em>, <em>27</em>(6), S235&ndash;S241. <a href="https://doi.org/10.1097/phh.0000000000001424">https://doi.org/10.1097/phh.0000000000001424</a></p>
                        <p>Raghavan, M. V., Swartz, S., Clark, M., Lo, M., Gupta, A., Chino, F., &amp; Tracy Kuo Lin. (2024). Access to Financial Assistance Programs and Their Impact on Overall Spending on Oral Anticancer Medications at an Integrated Specialty Pharmacy. <em>JCO Oncology Practice</em>. <a href="https://doi.org/10.1200/op.23.00446">https://doi.org/10.1200/op.23.00446</a></p>
                        <p>Savulescu, J., &amp; Cameron, J. (2020). Why lockdown of older people is not ageist and why leveling down equality is wrong. <em>Journal of Medical Ethics</em>, <em>46</em>(11), 717&ndash;721. <a href="https://doi.org/10.1136/medethics-2020-106336">https://doi.org/10.1136/medethics-2020-106336</a></p>
                        <p>Skochelak, S. E. (2020). Health Systems Science E-Book: Health Systems Science E-Book. In <em>Google Books</em>. Elsevier Health Sciences. <a href="https://books.google.com/books?hl=en&amp;lr=&amp;id=1sjhDwAAQBAJ&amp;oi=fnd&amp;pg=PA243&amp;dq=The+nurse+may+advocate+for+lower+prescription+costs+price+rise+caps">https://books.google.com/books?hl=en&amp;lr=&amp;id=1sjhDwAAQBAJ&amp;oi=fnd&amp;pg=PA243&amp;dq=The+nurse+may+advocate+for+lower+prescription+costs+price+rise+caps</a></p>
                        <p>Talevski, J., Wong Shee, A., Rasmussen, B., Kemp, G., &amp; Beauchamp, A. (2020). Teach-back: A systematic review of implementation and impacts. <em>PLOS ONE</em>, <em>15</em>(4), 1&ndash;18. <a href="https://doi.org/10.1371/journal.pone.0231350">https://doi.org/10.1371/journal.pone.0231350</a></p>
                        <p>Tan, L., Le, M. K., Yu, C. C., Liaw, S. Y., Tierney, T., Ho, Y. Y., Lim, E., Lim, D., Ng, R., Ngeow, C., &amp; Low, J. (2021). Defining clinical empathy: a grounded theory approach from the perspective of healthcare workers and patients in a multicultural setting. <em>BMJ Open</em>, <em>11</em>(9), e045224. <a href="https://doi.org/10.1136/bmjopen-2020-045224">https://doi.org/10.1136/bmjopen-2020-045224</a></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Nursfpx4900assessment4
