import React from 'react';
import { Container } from 'react-bootstrap';

const Dnphero = () => {
  return (
    <div className='bsn-hero'>
      <Container>
        <div className='bsn-hero-title'>
            <h1>DNP</h1>
            <div className='bsn-line'></div>
            <h2>Doctor of Nursing Practice</h2>
        </div>
      </Container>
    </div>
  )
}

export default Dnphero