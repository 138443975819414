import axios from 'axios';
import React, { useContext, useState } from 'react'
import Container from 'react-bootstrap/Container';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/authContext';
import { Helmet } from 'react-helmet';


const Login = () => {

  const [inputs, setInputs] = useState({
    username:"",
    password:"",
  })
  const [err, setError] = useState(null)

  const navigate =useNavigate();

  const { login } = useContext(AuthContext);

  const handleChange = e =>{
    setInputs(prev=>({...prev, [e.target.name]: e.target.value}))
  }

  const handleSubmit = async e =>{
    e.preventDefault()
    try{
      await login(inputs)
      navigate("/md-admin-page");
  
    }catch(err){
      setError(err.response.data);
    }
    
  };

  return (
    <>
    <div className='ocs2-login-register'>
    <Helmet>
      <title>Login</title>
    </Helmet>
      <Container>
        <div className='ocs2-login-register-auth'>
        <h1>Login</h1>
        <form>
          <input type='text' placeholder='username' name='username' onChange={handleChange}/>
          <input type='password' placeholder='password' name='password' onChange={handleChange}/>
          <button onClick={handleSubmit}>Login</button>
          {err && <p>{err}</p>}
          <span>Don't you have an account?
            <Link to='/register' className='link'> Register</Link>
          </span>
        </form>
        </div>
      </Container>
    </div>
    </>
  )
}

export default Login
