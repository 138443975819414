import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Bhafpx4020assessment2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>BHA FPX 4020 Assessment 2 Health Care Professional Feedback</title>
                <meta name='description'
                    content='Prepare your BHA FPX 4020 Assessment 2 Health Care Professional Feedback at online course services. Drop your email now for full support.' />
                <meta name='keywords' content='BHA FPX 4020 Assessment 2 Health Care Professional Feedback' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>BHA FPX 4020 Assessment 2 < br /><span>Health Care Professional Feedback</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/BHAFPX4020Assessment2.png" alt="" />
                    <div className='main-container-sl-content'>
                        <h2>Health Care Professional Feedback</h2>
                        <p>The project mentioned above on Hospital-Acquired Infections (HAIs) had to go to the right healthcare worker, Deanna Sisman, Clinical Operations Manager II (COM), in the proper way to explain how crucial the project was and get her attention. This is comprised of getting in touch with the right professionals, doing basic research, and having effective communication skills when presenting the project. Some useful feedback was obtained from the COM, and changes were made, which helped in making the project more in line with the COM's goals and objectives. This made it possible to get the best answer that would suit everybody.</p>
                        <h2>Identifying the Appropriate Health Care Professional</h2>
                        <p>After analyzing the possible healthcare group, the Clinical Operational Manager position or COM (Monegro et al., 2022). The COM is in charge of such things as monitoring medical practice, quality standards, growth, and even control of infection, among others. This means that the COM's objectives are in sync with those of the planned project (Sreeramoju et al., 2020). This is because the COM is in a vantage position to initiate and give feedback on the project, hence the need to address the problem of HAIs.</p>
                        <p>Before the meeting, information was gathered on what particular policies and procedures were put in place by the group to minimize cases of infections. This planning assisted in knowing some of the factors that may be at play and the questions to ask at the meeting (Hammerschmidt &amp; Manser, 2019). The Practicing Health Care Professional Meeting and the Project Approval Guide were also helpful because they provided a way of organizing the meeting and gave some indication of the kinds of questions, answers, or issues that might arise.</p>
                        <h3>BHA FPX 4020 Assessment 2 Health Care Professional Feedback</h3>
                        <p>The meeting was well prepared as a proposal was sent with details regarding the topic, the tentative agenda, and the objectives of the conference. The first message is playing out a conscious process that results in effective communication (Ellemers et al., 2020). In the meeting, interpersonal communication was employed to ensure that the plan was understood and to get the COM's interest. This proposal has taken time to explain what HAls are, how the plan incorporates evidence of what this implies for patients, and how the organization operates (Indeed, 2021).</p>
                        <p>In the process of the meeting, interpersonal skills were applied to make sure, for example, the COM were comfortable with sharing their comments or ideas. The talk aimed to involve the COM in giving ideas and feedback on the project and, at the same time, highlighting that it is a collaborative project. This made the players happy and eager, which was great for feedback and bargaining.</p>
                        <p>Such recommendations and discussions that were made during the meeting of the COM were useful in altering some aspects of the project plan. The COM could then have several suggestions as to what the success measures might be altered to, what other procedures may be employed, and how much time is taken to change things. The discussion was about the best ways of engaging the other healthcare workers and ensuring that the intended project aligns with the goals and objectives of the organization (Ellemers et al., 2020). Thus, feedback is an important part of the process, and when applied, it can significantly improve the quality of the outcome of the project.</p>
                        <ul>
                            <li>
                                <h3>Letter of Initial Contact</h3>
                            </li>
                        </ul>
                        <p>I want to take a moment and check on you and see how you are doing. For my final project, I selected the main problem of hospital-acquired infections (HAIs) in healthcare settings. Reading through the job description, one could argue that as a Clinical Operations Manager, you are supposed to manage medical practices, enhance the quality of services, and prevent the spread of infections. You work in health care. Your knowledge is quite relevant to the objectives of the proposed project to address the rising menace of HAIs.</p>
                        <h3>BHA FPX 4020 Assessment 2 Health Care Professional Feedback</h3>
                        <p>Let me elaborate on the idea and its details for a few minutes. Here, I can mention that during this meeting, the patient safety project goal, specific goals, and the organization's speed improvements are expected to be discussed. The project is about infections that are contracted in the hospital (HAIs), and it aims to help reduce the incidences of these infections as well as to help people become more conscious about hand hygiene. HAIs are dangerous because they threaten the lives of patients and are expensive for healthcare facilities. What are the implications of the project? The project's findings may assist healthcare organizations in achieving their patient safety and financial objectives.</p>
                        <p>&nbsp;Below is a project plan that outlines the project scope, objectives, goals, and expected outcomes. This will give us more details, and therefore, we can keep on with the conversation. It will be of great assistance to fine-tune this plan based on your opinion. I want to request that you schedule a meeting to be held at your earliest convenience since I am flexible on the time to plan for the meeting.</p>
                        <h2>Project Proposal Presentation</h2>
                        <p>This project proposal made to the COM was on Hospital Acquired Infections (HAIs), which is a vital issues in the healthcare industry. This problem statement was developed with regard to the impact of HAIs on patient safety and the costs. HAIs have many causes, such as patients who have weak immune systems, invasive procedures, contaminated surfaces and objects, and growth of drug tolerance (Sartelli et al., 2020). The plan also highlighted the fact that HAIs are an important concern in the context of the several healthcare objectives of providing safe and efficient care.</p>
                        <p>The purpose of this study was to reduce the incidences of HAIs that occurred within the building, which was achieved as stated below. The aim was to reduce the incidences that happened for every thousand patient days in order to prove that the battle against infections was being won (Maki &amp; Zervos, 2021). Another goal that was set was to realize a compliance level of hand cleanliness of at least 80%, which is the WHO's recommendation. The plan that was stressed was the one that focused on reducing the cost of HAIs, and this is because the primary objective of any organization is to minimize its costs.</p>
                        <p>&nbsp;I was able to comprehend the value proposition, and the sources that were used to support the arguments were relevant and credible. The approach to the suggested answer to the problem of HAIs was supported by WHO guidelines, the works of Dadi et al. (2021), and The Joint Commission's requirements. According to Gidey et al. (2023), the prices of HAIs are projected to be between $28 billion and $55 billion per year. This means that patients, healthcare organizations, and society as a whole lose a lot of money. The plan was centered on two main points: Due to increased concern for patient safety and to enhance the financial status of the project.</p>
                        <h2>Negotiating Changes for Mutual Benefit</h2>
                        <p>Some inputs from the Clinical Operational Manager (COM) made the suggested measures of success to be scrutinized. Individuals were supportive of the COM's proposal to reduce these measures so that they are more in line with the organization's current data sets. Having success indicators that are related to the organization's data sets is a more specific and organization-oriented way of measuring (Zilembo, 2021). This way, the company can also prevent situations where measures that have been chosen are either ineffective or irrelevant. This is an important factor in the eyes of the COM since it helps compare and analyze the data.</p>
                        <p>The response also indicates that the action plan that is being proposed requires the group to also consider previous measures that the group has taken in preventing and controlling infections (Abalkhail &amp; Alslamah, 2022). This proposal was supposed to be very important as the details of the project plan were altered to enhance the organization's current infection control measures. This change also promotes and does not undermine previous practice. It also assures that these changes will not be difficult to implement into the current work process, and this will make the staff more willing to embrace them and implement them.</p>
                        <p>Because the COM requested further details about the schedule for the execution process, several measures were taken. Since the schedule has been adjusted, there are many more details about the critical activities and the objectives of the project. This change makes all the stakeholders more accountable and specific, which aligns with the COM's concerns and ensures that there is a better plan of action and accountability when it comes to the implementation and assessment of the project (Anand et al., 2022). The project has different phases, which makes it quite possible to analyze the key success factors in general.</p>
                        <p>The idea of including more stakeholders, including the patients, was very useful in getting all the possible suggestions on how to prevent HAIs. Based on this feedback, it was decided that to the project plan, it would be necessary to include a more effective way of dealing with stakeholders (Blot et al., 2022). This approach guarantees that the project incorporates the views of both the researchers and the patients in the provision of HAI avoidance strategies that are, therefore, more helpful in the general sense.</p>
                        <h3>BHA FPX 4020 Assessment 2 Health Care Professional Feedback</h3>
                        <p>The CDC's National Healthcare Safety Network (NHSN) was also made use of in answering the COM's challenge as well as to establish that the approach to data collection is consistent with how the organization usually operates. This has created a section in the project plan that ensures that the tools and methods applied in gathering data conform with the organization's policies, with special emphasis on the CDC's NHSN. This change ensures that the standards are adhered to and makes it easy to link with other data recording apparatus.</p>
                        <p>It is useful to compare and align with the organization's standard practices for data collection, especially NHSN from the CDC, as this enhances compliance with the norms. This change also ensures that the project's data collection is in harmony with the company's standard procedures so that it is compatible with the tools used for data collection (Dudeck et al., n. d. ). This is also in agreement with the COM's request on the need to be correct and to follow procedures. This leads to better quality of the project and how the organization is managing its information, and therefore, the results obtained are more precise and valuable.</p>
                        <p>This made the project in line with the goals of the organization by making the Clinical Operational Manager (COM) the project's approving authority. The COM is very important as it supervises all medical activities and decisions that enhance the quality of services (Mukhopadhyay et al., 2021). Measures that were taken to prevent infections helped ensure that the project was relevant to the important issues in healthcare, thus being in line with the organization's mission.</p>
                        <p>The decision to contact the COM was not made without a lot of considerations to make sure that proper language is used. This included spending a lot of time in doing some basic research on the organization's infection control. The Health Care Professional Meetings and Project Approval Guide worked effectively in helping facilitate the meeting since it provided a clear purpose for the meeting, the agenda, and the expected outcome (Habboush &amp; Guzman, 2020). This professional approach laid down the foundation for proper working relationships and made it known that everyone was in the facility to fight HAIs.</p>
                        <p>The interpersonal skills were applied in the meeting to demonstrate how the project would be in real life. It was a good communication experience because there was active listening, the COM's authority was recognized, and talk for criticism was encouraged (Zingg et al., 2019). We all contributed to the project, and therefore, it seemed like a common concern. To enhance patient safety and organizational effectiveness, HAIs had to be prevented or reduced. Some of the critical aspects of the plan that were presented to the COM included the effects of HAIs on the patients, and the financial impact on the organization was well explained and concisely. To ensure that the care delivered is safe and least expensive, the problem statement, the expected results, and the value proposition were also clear and consistent with each other.</p>
                        <p>Approved by: Name</p>
                        <p>Signature:</p>
                        <h2>Conclusion</h2>
                        <p>The process of seeking approval for the suggested project emphasizes the need to work with the right professionals, the practical social skills, and the feedback received. It encompasses improved ways of evaluating results, incorporation of business strategies, clearer timelines, involving all parties, establishing organizational norms, and understanding who does what lays a strong foundation for good project delivery. This is to support the fact that healthcare projects require teamwork and freedom.</p>
                        <h2>References</h2>
                        <p>Abalkhail, A., &amp; Alslamah, T. (2022). Institutional factors associated with infection prevention</p>
                        <p>and control practices globally during the infectious pandemics in resource-limited</p>
                        <p>settings. <em>Vaccines,</em> 10(11).</p>
                        <p>&nbsp;<a href="https://doi.org/10.3390/vaccines10111811">https://doi.org/10.3390/vaccines10111811</a></p>
                        <p>Anand, U., Dey, A., Chandel, A. K. S., Sanyal, R., Mishra, A., Pandey, D. K., De Falco, V., Upadhyay, A., Kandimalla, R., Chaudhary, A., Dhanjal, J. K., Dewanjee, S., Vallamkondu, J., &amp; P&eacute;rez de la Lastra, J. M. (2022). Cancer chemotherapy and Beyond Current status, drug candidates, associated risks, and progress in targeted therapeutics. Genes &amp; Diseases, 10(4).</p>
                        <p><a href="https://doi.org/10.1016/j.gendis.2022.02.007">https://doi.org/10.1016/j.gendis.2022.02.007</a></p>
                        <p>Blot, S., Rupp&eacute;, E., Harbarth, S., Asehnoune, K., Poulakou, G., Luyt, C.-E., Rello, J., Klompas, M., Depuydt, P., Eckmann, C., Martin-Loeches, I., Povoa, P., Bouadma, L., Timsit, J.-F., &amp; Zahar, J.-R. (2022). Healthcare-associated infections in adult intensive care unit patients: Changes in epidemiology, diagnosis, prevention and contributions of new technologies. <em>Intensive and Critical Care Nursing</em>, 70.</p>
                        <p><a href="https://doi.org/10.1016/j.iccn.2022.103227">https://doi.org/10.1016/j.iccn.2022.103227</a></p>
                        <p>Coles, E., Anderson, J., Maxwell, M., Harris, F. M., Gray, N. M., Milner, G., &amp; MacGillivray, S.</p>
                        <p>(2020). The influence of contextual factors on healthcare quality improvement initiatives: A realist review. <em>Systematic Reviews</em>, 9(1).</p>
                        <p><a href="https://doi.org/10.1186/s13643-020-01344-3">https://doi.org/10.1186/s13643-020-01344-3</a></p>
                        <p>Dadi, N. C. T., Radochov&aacute;, B., Vargov&aacute;, J., &amp; Bujd&aacute;kov&aacute;, H. (2021). Impact of healthcare-associated infections connected to medical devices update. <em>Microorganisms,</em> 9(11), 2332.</p>
                        <p><a href="https://doi.org/10.3390/microorganisms9112332">https://doi.org/10.3390/microorganisms9112332</a></p>
                        <p>Dudeck, M. A., Horan, T. C., Peterson, K. D., Allen-Bridson, K., Morrell, G., Pollock, D. A., &amp; Edwards, J. R. (n.d.). National Healthcare Safety Network (NHSN) Report, data summary for 2010, device-associated module. <em>American Journal of Infection Control</em>, 39(10), 798-816.</p>
                        <p><a href="https://doi.org/10.1016/j.ajic.2011.10.001">https://doi.org/10.1016/j.ajic.2011.10.001</a></p>
                        <p>Ellemers, N., Fiske, S. T., Abele, A. E., Koch, A., &amp; Yzerbyt, V. (2020). Adversarial alignment enables competing models to engage in cooperative theory building toward cumulative science<em>&mdash;proceedings</em><em>&nbsp;of the National Academy of Sciences</em>, 117(14), 75617567.</p>
                        <p><a href="https://doi.org/10.1073/pnas.1906720117">https://doi.org/10.1073/pnas.1906720117</a></p>
                        <p>Habboush, Y., &amp; Guzman, N. (2020, September 12<em>). Infection control</em>. PubMed; StatPearls Publishing.</p>
                        <p><a href="https://www.ncbi.nlm.nih.gov/books/NBK519017/">https://www.ncbi.nlm.nih.gov/books/NBK519017/</a></p>
                        <p>Hammerschmidt, J., &amp; Manser, T. (2019). Nurses' knowledge, behavior, and compliance</p>
                        <p>concerning hand hygiene in nursing homes: A cross-sectional mixed-methods study. <em>BioMed Central BMC Health Services Research</em>, 19(1), 1-13.</p>
                        <p><a href="https://doi.org/10.1186/s12913-019-4347-z">https://doi.org/10.1186/s12913-019-4347-z</a></p>
                        <p>Indeed. (2021, March 19). <em>Everything you need to know about the importance of interpersonal communication at work.</em> Indeed Career Guide.</p>
                        <p><a href="https://www.indeed.com/career-advice/career-development/importance-of-interpersonal-communication">https://www.indeed.com/career-advice/career-development/importance-of-interpersonal-communication</a></p>
                        <p>Maki, G., &amp; Zervos, M. (2021). Health care-acquired infections in low- and middle-income countries and the role of infection prevention and control. <em>Infectious Disease Clinics of North America</em>, 35(3), 827-839.</p>
                        <p><a href="https://doi.org/10.1016/j.idc.2021.04.014">https://doi.org/10.1016/j.idc.2021.04.014</a></p>
                        <p>Monegro, A. F., Muppidi, V., &amp; Regunath, H. (2022). <em>Hospital-acquired infections</em>. Nih.gov;</p>
                        <p>StatPearls Publishing.</p>
                        <p><a href="https://www.ncbi.nlm.nih.gov/books/NBK441857/">https://www.ncbi.nlm.nih.gov/books/NBK441857/</a></p>
                        <p>Mukhopadhyay, R., Arora, N. K., Sharma, P. K., Dalpath, S., Limbu, P., Kataria, G., Singh, R.</p>
                        <p>K., Poluru, R., Malik, Y., Khera, A., Prabhakar, P. K., Kumar, S., Gupta, R., Chellani, H., Aggarwal, K. C., Gupta, R., Arya, S., Aboubaker, S., Bahl, R., &amp; Nisar, Y. B. (2021). Lessons from implementation research on community management of Possible Serious Bacterial Infection (PSBI) in young infants (0-59 days) when the referral is not feasible in Palwal district of Haryana, India. <em>PLOS ONE</em>, 16(7).</p>
                        <p><a href="https://doi.org/10.1371/journal.pone.0252700">https://doi.org/10.1371/journal.pone.0252700</a></p>
                        <p>Sartelli, M., Pagani, L., Iannazzo, S., Moro, M. L., Viale, P., Pan, A., Ansaloni, L., Coccolini, F., D'Errico, M. M., Agreiter, I., Amadio Nespola, G., Barchiesi, F., Benigni, V., Binazzi, R., Cappanera, S., Chiodera, A., Cola, V., Corsi, D., Cortese, F., &amp; Crapis, M. (2020). A proposal for a comprehensive approach to infections across the surgical pathway. <em>World Journal of Emergency Surgery</em>, 15(1).</p>
                        <p><a href="https://doi.org/10.1186/s13017-020-00295-3">https://doi.org/10.1186/s13017-020-00295-3</a></p>
                        <p>Sreeramoju, P. V., Palmore, T. N., Lee, G. M., Edmond, M. B., Patterson, J. E., Sepkowitz, K. A., Goldmann, D. A., Henderson, D. K., &amp; Kaye, K. S. (2020). Institutional quality and patient safety programs: An overview for the healthcare epidemiologist<em>. Infection Control &amp; Hospital Epidemiology</em>, 42(1), 6-17.</p>
                        <p><a href="https://doi.org/10.1017/ice.2020.409">https://doi.org/10.1017/ice.2020.409</a></p>
                        <p>Zilembo, G. (2021, June 1). <em>A systematic approach for identifying fundamental successes and</em></p>
                        <p><em>failures in complex product development programs</em>. Spectrum .library.concordia.ca.</p>
                        <p><a href="https://spectrum.library.concordia.ca/id/eprint/988470/">https://spectrum.library.concordia.ca/id/eprint/988470/</a></p>
                        <p>Zingg, W., Storr, J., Park, B. J., Ahmad, R., Tarrant, C., Castro-Sanchez, E., Tomczyk, S., Kilpatrick, C., Allegranzi, B., Cardo, D., &amp; Pittet, D. (2019). Implementation research to prevent antimicrobial resistance and healthcare-associated infections; 2017 Geneva Infection Prevention and Control (IPC)-think tank (part 1). <em>Antimicrobial Resistance &amp; Infection Control</em>, 8(1).</p>
                        <p><a href="https://doi.org/10.1186/s13756-019-0527-1">https://doi.org/10.1186/s13756-019-0527-1</a></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Bhafpx4020assessment2
