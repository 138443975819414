import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";
// import { Helmet } from 'react-helmet';

const Domyclass = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "American Writers",
            Desc: "Hire our competent writers who are masterminds in crafting high-scoring and excellent assignment solutions.",
        },
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "High-Scoring Solutions",
            Desc: "Get excellent solutions for nursing coursework. Our writing experts enable you to become successful.",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Research Experts",
            Desc: "TTake well-researched material and enjoy good marks with the praise of professors.",
        },
        {
            icon: "fa-solid fa-text-height icon",
            title: "Good Formatting",
            Desc: "Buy assignment solutions that are well-formatted, well-cited, and well-written. ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-chart-line icon",
            title: "Good Performance",
            Desc: "Our assignment solutions promote the student’s academic performance. We are devoted to helping you!",
        },
        {
            icon: "fa-solid fa-pen-to-square icon",
            title: "Professional Writers",
            Desc: "Hire our highly experienced writers to offer excellent assignment writing help services.",
        },
        {
            icon: "fa-solid fa-headset icon",
            title: "Complete Support",
            Desc: "Our academic experts offer full support in the assignment writing process. Students only receive the quality-written solutions.",
        },
        {
            icon: "fa-solid fa-house-laptop icon",
            title: "Flawless Services",
            Desc: "We offer high-quality assignment writing help services. We offer plagiarism-free and revised content.",
        },
    ]
    return (
        <>
            {/* <Helmet>
                <title>Do My Assignment Online</title>
                <meta name='description'
                    content='Professional do my assignment online for your assignments. Achieve top grades with our expert help. Reliable and confidential service.' />
                <meta name='keywords' content='pay someone to do assignments, do my assignment for me cheap,pay someone to do my homework online, do my assignment online' />
            </Helmet> */}
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
    <div className='sherotop-left'>
    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
    </div>
    <div className='cal-box'><Calculator/></div> 
</div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>LET’S PAY SOMEONE TO DO MY CLASS</span></h1>
                            <p className='ocs2-topText'>Thinking of paying someone to take my online class? We've got you covered! Our tutors provide personalized assistance to help you succeed academically. </p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free proofreading and editing</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Punctuality</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; High-quality content</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Assignment Writers</h2>
                            <p>Our professional writers offer the best assignment solutions for your success.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Best Writing Help</h2>
                            <p>Get help from our competent assignment writers to write quality assignments.</p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET CAPELLA DNP FLEXPATH ASSESSMENT SOLUTIONS!</h2>
                <p>We provide excellent Capella University DNP FlexPath assessment solutions for students in their Capella DNP FlexPath Courses.</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Professional Writing Services!</span>
                    <h2>SERVICES WE PROVIDE FOR “DO MY CLASSES FOR ME”</h2>
                    <p>We have tutors who can help you with your online classes. You don't have to worry about keeping up with the schedule or attending class. Whether you want someone to take your online class or need help with assignments and tests, we're here for you. Our tutors are experts and will make sure you do well in your exams too. They'll give you lectures and help with assignments and quizzes. We make sure everything is done on time so you don't have to worry about missing deadlines.</p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>Time To Pay Someone To Do My Class For Me </strong></Tab>
                        <Tab><strong>We're Here to End Your Search for "Do My Classes for Me"</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p>
                        Feeling overwhelmed with coursework? Let us handle it. Whether it's paying someone to take my online class or seeking help with assignments, we're here to support you.
                        </p>
                    </TabPanel>
                    <TabPanel>
                        <p>Stop searching endlessly. Our team is ready to assist with your academic needs. From paying someone to do my class to ensuring your assignments are completed, we're dedicated to your success</p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Features</span>
                    <h2>WHY CHOOSE US?</h2>
                    <p>At Online Course Services, we specialize in helping students with their classes. Our skilled writers have helped countless students seeking assistance with paying someone to take my online class. We're also renowned for offering comprehensive support in do my classes for me. We handle everything from assignments to coursework, ensuring you receive top-notch assistance. Don't wait any longer – let us help you achieve success in your classes today!</p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I Pay Someone To Do My Class For Me At A Reasonable Cost?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes, you can hire us to take your online class at a reasonable rate.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I Talk To The Person Who Will Do My Classes for Me?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    When you are paying someone to take my online class, you can directly communicate with them. Clear communication helps ensure your needs are understood.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Who Takes My Online Class When I Pay Someone To Do My Class?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Your online class is in good hands with our experienced team at Online Course Services. We have qualified professionals who specialize in various subjects and are familiar with online education platforms. Count on us for timely completion of assignments, active participation in discussions, and success in exams.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>How Do I Get Help With My Online Class?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    We are here for your “do my class for me” worries. To pay someone to do my class just follow these simple steps: provide your class details for a free quote, we'll connect you with an expert, you review and approve the plan, pay securely, then relax while we handle your coursework.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Do You Guarantee Success When I Pay Someone To Do My Class?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes, we offer a guaranteed success rate. Since the start of our website, we have helped many students with their online courses.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Will Your Tutors Plagiarize if They Do My Classes for Me?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Our tutors never copy from others when they do your online class. We always provide original work, free from plagiarism. Unlike some others who might use materials from public sources, we create everything ourselves. We make sure to check for plagiarism, and you'll get a report with each project we deliver.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                           
                        </Accordion>
                    </div>
                </div>
            </Container>
        </>

    )
}

export default Domyclass
