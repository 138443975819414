import React from 'react'
import Bspsyprehero from './Bspsyprecomponent/Bspsyprehero'
import Bspsydegreedetail from './Bspsycomponent/Bspsydegreedetail'
import Bsnfeatures from '../Nursing/bsnpagecomponent/Bsnfeatures'
import Bannertwo from '../../homepage/Bannertwo'
import Bsnfaqs from '../Nursing/bsnpagecomponent/Bsnfaqs'

const Bspsypre = () => {
  return (
    <div>
      <Bspsyprehero/>
      <Bspsydegreedetail/>
      <Bsnfeatures/>
      <Bannertwo/>
      <Bsnfaqs/>
    </div>
  )
}

export default Bspsypre
