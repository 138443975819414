import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Bhafpx4102assessment4 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>BHA FPX 4102 Assessment 4</title>
                <meta name='description'
                    content='Complete BHA FPX 4102 Assessment 4 with comprehensive guidelines. Achieve top marks in your healthcare course.' />
                <meta name='keywords' content='BHA FPX 4102 Assessment 4' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>BHA FPX 4102 Assessment 4< br /><span>Conflict Resolution</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/BHAFPX4102Assessment4.png" alt="" />
                    <div className='main-container-sl-content'>
                        <h2>Conflict Resolution</h2>
                        <p>Greetings and salutations. I would like to present my opinion and actions regarding the conflict which was witnessed between housekeeper, Gabriela and a PICU nurse, Donald and the actions of the hospital administration to resolve the conflict. My name is (Student).</p>
                        <p>Operations such as in the emergency department of a hospital or the critical care unit are stressful, and therefore, disagreements between the staff are bound to occur. This is caused by the sheer fact that disagreements misunderstandings, and even personality confrontations are inevitable under the correlations and obligations that encompass patient care particularly where time is of essence.</p>
                        <p>Conflict in the health sector is not only disadvantageous to the welfare of the employees through their health and well-being but also the outcomes of health care to the patients. In order to promote a working culture that is supportive, collaborative, and effective in the area of health care the sources and impact of the above kinds of disputes needs to be comprehended (Modaresnezhad et al. , 2021). In this lesson, we will analize the actions of the administration of the hospital when facing the conflict between Donald and Gabriela.</p>
                        <h2>Case Study Background</h2>
                        <p>Conflict management involves identification, assessment and, therefore, handling of conflicts that prevail between parties (Sinskey et al. , 2019). Donald and Gabriela in the PICU corridor after a train crash that produced a toxic cloud; as detailed in the Vila Health case study. The case adds knowledge of how disagreements in a healthcare environment might harm the patients. He gave Gabriela the direction to rearrange an area so that he could move from one youngster from ER to PICU.</p>
                        <p>Gabriela took Donald head on and explained to him that she cannot go against her word and do assignments different from her superior instructed her. It worsens to name calling and then they compete over their jobs. The Nurse Officer In Chief, Jackie Sandoval, talks to Gabriela about the event after the latter complains of being offended by Donald before giving her a meeting with the PICU Nurse Manager, Phoebe Hemsworth. During the session, Gabriela complained to Jackie who assured her and reported her concerns to Donald&rsquo;s supervisor.</p>
                        <p>Though leadership did not follow all the best practices for conflict resolution, such as not meeting with the persons in person or not hearing all the parties &ndash; they did apply some of the seven C&rsquo;s of communication where talking to Gabriella. When one complies with these standards, the capacities to effectively transverse a professional environment can be developed, and the chance to have one&rsquo;s message received and understood will increase.</p>
                        <h2>Cultural Competence and Its Relevance</h2>
                        <p>Cultural competence doesn&rsquo;t necessarily refer to your ability to produce cultural cues but simply put, it means that you can read cultures from all sorts of sources. Perhaps in critical care or emergency then the absence of cultural competency might be disastrous for the healthcare suppliers. The following potential risks usually accompany the use of the proposed solution: new conflicts and misunderstandings, as well as the deterioration of already critical situations (Sinskey et al. , 2019).</p>
                        <p>An example of Donald&rsquo;s culturally sensitive or insensitive remark evident in the Vila Health case study was in telling Gabriela, &ldquo;while you may be used to putting breaks first south of the border here in America, the patient come first not your break. &rdquo; Such remarks make team members hostile because they are cruel as well as rude. An ill-advised comment that Donald made stunned Gabriela, who is of Mexican-American origin, and then the confrontation ensued. In a study, immigrant, minority and those connected to immigrants are more likely targets of bullying than those from the majority, Bergbom and Vartia found. In addition, patient-centered care involve knowledge or recognition of cultural differences.</p>
                        <p>In essence, in delivery of a treatment that is appropriate, healthcare practitioners need to have regard of cultural beliefs and practices of their diverse clients. Cultural incompetency leads to faulty diagnosis, misunderstanding and lack of trust which all have implications for the health outcomes and quality of care offered. Health are professionals may be able to alleviate the shortage of cultural competence by enhancing their own cultural knowledge and skills, through professional development (Sinskey et al. , 2019).</p>
                        <h2>Communication Techniques to Avoid</h2>
                        <p>It is crucial to be aware of certain communication strategies to avoid when interacting with people from varied cultural backgrounds in order to avoid miscommunication or offence. Slang and jargon that the other person does not understand may hinder communication and lead to misunderstandings, thus speakers should refrain from using them. Another source of misunderstanding is culturally distinctive language and idioms (Narzoles &amp; Palermo, 2021).</p>
                        <p><br /> Making assumptions about someone's values, beliefs, or practices based on their culture is another crucial tactic to avoid. The ability to communicate and work together effectively is severely impaired when such presumptions cause bias and stereotyping. Recognizing that each person is an individual and avoiding generalizations based on superficial characteristics is critical (Steketee et al., 2021).</p>
                        <h2>BHA FPX 4104 Assessment 1 Strategic Planning and Leadership</h2>
                        <p>Furthermore, it is crucial to refrain from criticizing cultural differences or making value judgments, since doing so might be seen as disrespectful and lead to relationship harm. To the contrary, dealing with cultural differences calls for more research, more curiosity, more openness to new ideas, and more respect for other ways of life (Ramos, 2022).</p>
                        <p><br /> An example of cultural insensitivity is Donald's statement in the Vila Health case study; for example, the phrase "south of the border" may be insulting to some Latinos.<br /> One must use extreme caution when employing language that might be seen as disparaging or insulting towards people of diverse ethnic origins (Ramos, 2022). As a result, cultural competency is crucial in healthcare, as the Vila Health case study shows.</p>
                        <h2>Relationship Roles in Communication</h2>
                        <p>Recommendations The management of Vila Health should encourage great listening in order to ensure that communication is effective as well as disputes are efficiently solved. Imparting good communication measures in a staff increases active listening since the staff can be encouraged to listen attentively and ask questions when something did not quite sink in. With reference to Zhang et al. , (2020), adopting the active listening strategy ensures that everyone is involved and that what they are saying is well understood creating a platform for understanding and co-operations.</p>
                        <p>For example, in the case, when Jackie Sandoval listened to Gabriela and acknowledged her concerns the problem was automatically defused.</p>
                        <p>Besides that, it can be stated that it is essential to be clearly understood which is achieved through the plain language usage. Two ways in which hospital leadership could promote clear message: not using technical terms and ensuring that they do not use complex language (Narzoles &amp; Palermo, 2021). It was important for Donald, in the case study, to explain the reasons for cleaning requirement of the room to avoid a confrontation.</p>
                        <p>Equal emphasis has to be placed on the aspect of ensuring that free and frank communication are practiced; especially in matters that affects conflict resolution.</p>
                        <p>To ensure that people come out freely and express their concerns in the hospital, those in charge of the administration should ensure that employees and members of the hospital are comfortable when reporting issues as well as expressing themselves. Sinskey et al. (2019) observe that communication may be useful in getting to the root of the problems with a with an aim of solving them. For instance, in the case study, it is shown that Jackie Sandoval assisted in the identification of the issue and joining the employees, especially Gabriela and Phoebe Hemsworth, to address the issue.</p>
                        <p>To prevent the emergence of conflicts and tensions due to decision making which has been done rather hastily and without necessary precautions, leadership might have provided certain information and answered questions or concerns (Bachynsky, 2019). Lack of communication and understanding within the team could have been eliminated if everyone in the team was made to understand that change is in process.</p>
                        <h3><strong>Communication in Teamwork &amp; Collaboration</strong></h3>
                        <p>This paper establishes that relationships constitute a critical aspect of communicating since the density of the connections among the persons has a massive impact on how efficiently communication is conducted. They are likely to be more communicative and willing to share information reciprocally as well as collaborate in the accomplishment of goals that are branded to them when they enjoy good relations with others. Wei et al. (2019) highlighted that people will potentially experiences misunderstanding and disputes if relations are strained or hostile since communication is challenging.</p>
                        <p>To illustrate, cooperation and teamwork also presuppose good communication. Individual members of the group should therefore be able to communicate clearly in order for the group to deliver its tasks on time. Employees in the team are able to freely communicate and share information and also give comments. Indeed, the breakdown of communications may occur from good communications therefore there may be confusions and mistakes hence hindering the performance of the team for the better.</p>
                        <h3><strong>Rationale</strong></h3>
                        <p>To be able to create trust, it is very important to be able to communicate among team members. The fact that all the individuals are trustworthy, and that they respect each other as well as being honest to each other also make the communication process in the team easier. It has been established that less hidden communication may enhance collaboration, output and even quality of life (Wei et al. , 2019).</p>
                        <h2>Strategies to Improve Relationships</h2>
                        <p>The care environment could be improved to reflect new ideas such as enhanced communication between clean and nursing staff. Thus, conflicts and misconceptions can be prevented and better understanding can be had if the pipeline is kept open and people exchange information frequently. For managing the flow of communication, there might be established daily, weekly or monthly meetings, e. g. check-ups. Also from the communication it is easy to foster admiration and respect between people in a given team. As Başoğul (2020) enshrines that the housekeeping crew has the capacity to maintain the cleanliness of the facility necessary to the safety of both workers and patients.</p>
                        <p>The current cross-training opportunities may also enhance how employees comprehend each other&rsquo;s functions in the organization. In their study, Roy and coworkers highlight that cross-training may bring benevolent changes that not only help in promoting mutual empathy but also in the process smooth the walls between the departments.</p>
                        <p>Another tactic is to set up social interactions during which people from different teams will be able to define each other and become friends. Celebration of birthdays, anniversaries or having lunch together or going on a business trip or any other outing that requires the participants to cooperate and mingle would fall under this category (Cappelucci et al. , 2019).</p>
                        <h3><strong>Obstacles to Implementing Strategies</strong></h3>
                        <p>Some of the issues that may be met on the way to employing strategies for addressing the issues of communication and cooperation are resistance to change, lack of resources, and different cultural perception or values. Informality of communication and trust lie at the heart of workplace relations and practicing leaders should support their subordinates&rsquo; endeavours in attaining knowledge (Tataw &amp; Stokes, 2023). Junior and senior management must also play a part in the promotion of positive relations about departmental relationships.</p>
                        <h2>BHA FPX 4104 Assessment 1&nbsp;Summary</h2>
                        <p>The case of Donald and Gabriela&rsquo;s conflict at Vila Health depicts how crucial it is for healthcare workers to maintain good relationship and how to end their conflict. The worker morale and patients suffer most of the time when there are misunderstandings or misinterpretation between the two. It is thus apparent the need of clear and courteous communication, bearing in mind situations of misunderstanding.</p>
                        <p>Among all the conflict resolution skills, the most effective they are likely to use involve acknowledging the other party&rsquo;s perception and expressing thoughts, feelings and ideas in a non-defensive manner to avoid exacerbating the conflict and promote improved working relationships. In this respect, the leaders in charge of healthcare settings should place conflict solving and relations among the primary objectives with an eye on keeping the respective care settings happy and productive.</p>
                        <b><em>If you need complete information about class 4104, click below to view a related sample:&nbsp;</em></b>
                        <p><a href="https://onlinecourseservices.us/bha-fpx-4140-assessment-1-strategic-planning-and-leadership"><strong><em>BHA FPX 4104 Assessment 1 Strategic Planning and Leadership</em></strong></a></p>
                        <h2>References</h2>
                        <p>Bachynsky, N. (2019). Implications for policy: The triple aim, quadruple aim, and interprofessional collaboration.&nbsp;<em>Nursing Forum</em>,&nbsp;<em>55(1)</em>, 54&ndash;64.</p>
                        <p><a href="https://doi.org/10.1111/nuf.12382">https://doi.org/10.1111/nuf.12382</a></p>
                        <p>Başoğul, C. (2020). Conflict Management and teamwork in the workplace from the perspective of nurses.&nbsp;<em>Perspectives in Psychiatric Care</em>,&nbsp;<em>57(2)</em>, 610&ndash;619.</p>
                        <p><a href="https://doi.org/10.1111/ppc.12584">https://doi.org/10.1111/ppc.12584</a></p>
                        <p>Bergbom, B., &amp; Vartia, M. (2021). Ethnicity and workplace bullying.&nbsp;<em>Handbooks of Workplace Bullying, Emotional Abuse, and Harassment</em>,&nbsp;<em>3</em>, 393&ndash;432.</p>
                        <p><a href="https://doi.org/10.1007/978-981-13-0218-3_14">https://doi.org/10.1007/978-981-13-0218-3_14</a></p>
                        <p>Cappelucci, K., Zindel, M., Knight, H. C., Busis, N., &amp; Alexander, C. (2019). Clinician well-being at the Ohio State University: A case study.&nbsp;<em>National Academy of Medicine (NAM) Perspectives</em>,&nbsp;<em>2019</em>.</p>
                        <p><a href="https://doi.org/10.31478/201908b">https://doi.org/10.31478/201908b</a></p>
                        <p>Modaresnezhad, M., Andrews, M., Mesmer‐Magnus, J., Viswesvaran, C., &amp; Deshpande, S. (2021). Anxiety, job satisfaction, supervisor support and turnover intentions of mid‐career nurses: A structural equation model analysis.&nbsp;<em>Journal of Nursing Management</em>,&nbsp;<em>29(5)</em>, 931&ndash;942.</p>
                        <p><a href="https://doi.org/10.1111/jonm.13229">https://doi.org/10.1111/jonm.13229</a></p>
                        <p>Narzoles, D. T. G., &amp; Palermo, D. T. G. (2021). Workplace English language needs and their pedagogical implications in English for specific purposes (ESP).&nbsp;<em>International Journal of English Language and Literature Studies</em>,&nbsp;<em>10(3)</em>, 202&ndash;212.</p>
                        <p><a href="https://doi.org/10.18488/journal.23.2021.103.202.212">https://doi.org/10.18488/journal.23.2021.103.202.212</a></p>
                        <p>Ramos, S. L. (2022).&nbsp;<em>The experience of immigration and aging on the health of older adults in the El Paso, Texas, border region.</em>&nbsp;Proquest.com.</p>
                        <p><a href="https://www.proquest.com/openview/bad48e313f3c68e138b0efe7b2a16363/1?pq-origsite=gscholar&amp;cbl=18750&amp;diss=y">https://www.proquest.com/openview/bad48e313f3c68e138b0efe7b2a16363/1?pq-origsite=gscholar&amp;cbl=18750&amp;diss=y</a></p>
                        <p>Roy, L., Lemieux, A. J., Gagnon, J.-P., &amp; Crocker, A. G. (2023). Lessons learned from the implementation of an intersectoral cross-training approach to improve justice outcomes for homeless adults with mental illness.&nbsp;<em>Journal of Interprofessional Education &amp; Practice</em>,&nbsp;<em>32</em>, 100641.</p>
                        <p><a href="https://doi.org/10.1016/j.xjep.2023.100641">https://doi.org/10.1016/j.xjep.2023.100641</a></p>
                        <h2>BHA FPX 4104 Assessment 1</h2>
                        <p>Sinskey, J. L., Chang, J. M., Shibata, G. S., Infosino, A. J., &amp; Rouine-Rapp, K. (2019). Applying conflict management strategies to the pediatric operating room.&nbsp;<em>Anesthesia &amp; Analgesia</em>,&nbsp;<em>129(4)</em>, 1109&ndash;1117.</p>
                        <p><a href="https://doi.org/10.1213/ane.0000000000003991">https://doi.org/10.1213/ane.0000000000003991</a></p>
                        <p>Steketee, A., Williams, M. T., Valencia, B. T., Printz, D., &amp; Hooper, L. M. (2021). Racial and language microaggressions in the school ecology.&nbsp;<em>Perspectives on Psychological Science</em>,&nbsp;<em>16(5),</em>&nbsp;1075&ndash;1098.</p>
                        <p><a href="https://doi.org/10.1177/1745691621995740">https://doi.org/10.1177/1745691621995740</a></p>
                        <p>Tataw, D. B., &amp; Stokes, E. W. (2023). Leadership in interprofessional healthcare practice (IPHP): Readiness, roles, and competencies for healthcare managers and human resource professionals.&nbsp;<em>Journal of Interprofessional Education &amp; Practice</em>,&nbsp;<em>32</em>, 100635.</p>
                        <p><a href="https://doi.org/10.1016/j.xjep.2023.100635">https://doi.org/10.1016/j.xjep.2023.100635</a></p>
                        <p>Wei, H., Corbett, R. W., Ray, J., &amp; Wei, T. L. (2019). A culture of caring: The essence of healthcare interprofessional collaboration.&nbsp;<em>Journal of Interprofessional Care</em>,&nbsp;<em>34(3),</em>&nbsp;324&ndash;331.</p>
                        <p><a href="https://doi.org/10.1080/13561820.2019.1641476">https://doi.org/10.1080/13561820.2019.1641476</a></p>
                        <p>Zhang, R. P., Lingard, H., &amp; Oswald, D. (2020). Impact of supervisory safety communication on safety climate and behavior in construction workgroups.&nbsp;<em>Journal of Construction Engineering and Management</em>,&nbsp;<em>146(8)</em>, 1881.</p>
                        <p><a href="https://doi.org/10.1061/(asce)co.1943-7862.000188">https://doi.org/10.1061/(asce)co.1943-7862.000188</a></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Bhafpx4102assessment4
