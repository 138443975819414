import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx6216assessment2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 6216 Assessment 2 Preparing and Managing an Operating Budget</title>
                <meta name='description'
                    content='Explore NURS FPX 6216 Assessment 2 Preparing and Managing an Operating Budget at online course services to boost your grades. Contact us now!' />
                <meta name='keywords' content='NURS FPX 6216 Assessment 2 Preparing and Managing an Operating Budget' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 6216 Assessment 2 < br /><span>Preparing and Managing an Operating Budget</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/NURSFPX6216Assessment2.png" alt="" />
                    <div className='main-container-sl-content'>
                        <h2><strong>Preparing and Managing an Operating Budget</strong></h2>
                        <p>The operating budget is to give a picture of how the organization is going to run on a day to day basis. This will help the healthcare organizations to identify the revenue expected to be generated as well as the total expenditure for a whole year. This budget will be developed in order to meet the goals of the healthcare system as listed in this paper. Some of my duties as the nurse&rsquo;s manager at Anthony Medical Center are; preparation of an operating budget for the day to day running of the facility and putting in place ways of managing the budget. In this evaluation, we will prepare the organizational budget for the next year as well as to show the nurses the daily resources needed to enhance patient safety and care. Furthermore, we shall also discuss on the design of the budget, the approach that will be used and the level of control of the previous year&rsquo;s budget. In essence, hospitals are designed with three types of operating budgets: This includes the capital, the supply, the equipment and the staff. Some elements will not be presented in this section since they are planned for significant products &ndash; capital. Personnel, materials, and medical equipment depend on the number of patients per day (PPD) or full&ndash;time equivalent (FTE) personnel (Owens et al., 2019).</p>
                        <h2>Preparing Operating Budget</h2>
                        <p>The operating budget is that one-year plan which can be further sub-divided into six months or three equal quarters. The budget for the nursing unit will be formulated by preparing the copy of the last year&rsquo;s budget and adding the new progressive expenses. The operating budget is presented in the normal style as is the format used in most organizations. Given below is the operating budget of Anthony Medical Center (Anderson et al., 2020).</p>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <td colspan="2" width="623">
                                        <p><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operating Budget for the Coming Year&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p><strong><u>Revenues</u></strong></p>
                                    </td>
                                    <td width="312">
                                        <p><strong><u>Amount in $</u></strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p>Inpatient Revenue</p>
                                    </td>
                                    <td width="312">
                                        <p>$800,0000</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p>Outpatient Revenue</p>
                                    </td>
                                    <td width="312">
                                        <p>$600,0000</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p><strong>Total Patient Service Revenue &nbsp;</strong></p>
                                    </td>
                                    <td width="312">
                                        <p>$14,000,000</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p>Contractual Adjustments</p>
                                    </td>
                                    <td width="312">
                                        <p>($98,0000)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p>Charity and Uncompensated Care</p>
                                    </td>
                                    <td width="312">
                                        <p>($16,0000)</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p><strong>Net Patient Services Revenue </strong></p>
                                    </td>
                                    <td width="312">
                                        <p><strong>$12860000</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p><strong>Total Budget Revenue</strong></p>
                                    </td>
                                    <td width="312">
                                        <p><strong>$26860000</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p><strong><u>Expenses </u></strong></p>
                                    </td>
                                    <td width="312">
                                        <p>&nbsp;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p>Salaries and Wages</p>
                                    </td>
                                    <td width="312">
                                        <p>$99,000,000</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p>Supplies</p>
                                    </td>
                                    <td width="312">
                                        <p>$54,000,000</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p>Rentals and Leases</p>
                                    </td>
                                    <td width="312">
                                        <p>$11,000,000</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p>Purchased Services - Utilities</p>
                                    </td>
                                    <td width="312">
                                        <p>$10,000,000</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p>Depreciation Expense</p>
                                    </td>
                                    <td width="312">
                                        <p>$17,000,000</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p><strong>Total Budget Expense </strong></p>
                                    </td>
                                    <td width="312">
                                        <p><strong>$191,000,000</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p><strong>Excess over Revenue </strong></p>
                                    </td>
                                    <td width="312">
                                        <p>$-77,600,000</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Areas of Uncertainty and Additional Information Required</p>
                        <p>The operating budget is bound to consist of some contingency due to the fact that it is a forecast of the future and can be influenced by different future conditions. This means that the there is a possibility of the budget being imprecise. To prepare the budget with the most advanced technologies in the next year&rsquo;s operating expenses, more information is required (Guo et al., 2020).</p>
                        <h2>Designing the Budget</h2>
                        <p>The proposed budget will present such information which will help to understand the need for major spending. Therefore the budget will always consider these expenses since they are fundamental. Because this unit&rsquo;s budget is crucial, a large number of products are needed in 35-bed units that have about 20 FTE staff members. The BODs has the responsibility of developing a budget of an organization (Bhatt &amp; Bathija, 2018). The budget design will be simple to understand and will include all the information that will be useful for the evaluation of the organization&rsquo;s performance (Moro Visconti &amp; Morea, 2020). The operating budget should engage all the departments in the organization such as the nursing department, Human Resource Manager (HRM), Information Technology (IT), management and administration as it covers all aspects of the organization (Siirala et al. , 2020). It means the quantity of funds that is to be made available to each department in the coming year. Quality control, radiology, IT, Pathology and salary department are some of the departments there. These in mind, the budget will be developed (Love &amp; Ika, 2021).</p>
                        <p>Efficiency on the other hand is the extent to which the budget is able to achieve the purpose we want to accomplish or our objectives. They would be able to see through the budget when it comes to the products. Some of the items that can be examined in order to find out which departments of the hospital are not making any revenue but are rather supporting the entire regions and contributing to the initial costs include cost center classification procedures (Moro Visconti &amp; Morea, 2020).</p>
                        <p>Budget for staffing requirements and workload calculation is also considered as efficiency. Working behavior will be measured via surveys and multi-moment data collection as also suggested by Moro Visconti and Morea (2020). Operating budget will encompass the following major sections: revenue or earned income which include net patient service revenue, outpatient/inpatient revenue, operating expenses salaries, bad debts, supplies, rental, leases and depreciation (Love &amp; Ika, 2021). Sources of Budgetary InformationThe income statement, staff appraisal and assessment of total compensation will be the major sources of the budgeting data of the organization (Love &amp; Ika, 2021).</p>
                        <ul>
                            <li>Suppose that patients&rsquo; number is an inverse function of the usage of all the supplies.</li>
                            <li>At the onset of the next year, Anthony Medical Center may consider dedicating $2 million for purchasing new diabetes instruments to ensure quality care of the patients. It will automatically be refunded as soon as it is bought.</li>
                        </ul>
                        <p>It is to note that Anthony Medical Center uses straight-line depreciation, which means that the depreciation will remain the same in the future year (Prioli et al. , 2018).</p>
                        <p>Charity and Uncompensated Care has to be included in the budget because the patients and the staff require such assistance (Prioli et al. , 2018).</p>
                        <p>The policy issues include policy regulation, competition for funding and the overall objectives of the organization in the case of our overall operating budget system. Each organization will have its polices on how it will allocate its resources, the resources it will use and the amount it will spend. Thus, if our budget is not in line with these policies, then it will affect the performance of the system to a great extent. Third, the budget is sensitive to competition for resources which means there are several departments that compete for the available resources. If other organizations engage in fundraising through medical camps and campaigns more than you would also have to engage in fundraising through campaigns and free medical services which would affect your expense budget. Each organization has set some purpose or aim for instance, controlling the financial resources to provide enhanced services to the patients within the specified budget. Thus, it will be possible to state that the overall performance of the organization will be affected if the operating budget is above the set ceiling (Lu et al. , 2018).</p>
                        <p>Some of the equipment that is required for the operations at Anthony Medical hospitals and the recurrent costs include the following; Electronical Health Record technology, Radiology machines,Cancer treatment machines. Diabetes monitoring tools, Physiotherapy equipment and others as prescribed by the management from time to time (Lu et al. , 2018). For these machinery or equipment, one has to source for the required funds from the ongoing expenses or resources.</p>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <td width="312">
                                        <p><strong>Required Resources </strong></p>
                                    </td>
                                    <td width="312">
                                        <p><strong>Amount $</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p>Supplies</p>
                                    </td>
                                    <td width="312">
                                        <p>52,000,010</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p>Salaries and Wages</p>
                                    </td>
                                    <td width="312">
                                        <p>98,000,010</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p>Rentals and Leases</p>
                                    </td>
                                    <td width="312">
                                        <p>12,000,100</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <td width="312">
                                        <p>Purchased Services - Utilities</p>
                                    </td>
                                    <td width="312">
                                        <p>12,000,100</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="312">
                                        <p>Depreciation Expense</p>
                                    </td>
                                    <td width="312">
                                        <p>18,000,100</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>The data is reliable and proper for the analysis since it has been gathered from all the departments. Also, the budget is relevant, because it requires an expenditure from the current year and presents a budget plan for the next year (Dauda, 2019). The budget does not have information on some of the technologies that the organization requires and which are useful such as EHR, telehealth and other technologies that will reduce the workload of nurses. Moreover, these technologies will have costs that need to be considered and these are a significant amount of money (Dauda, 2019).</p>
                        <h2>Strategic Plan</h2>
                        <p>It will be easier to evaluate the objectives of the Anthony Medical Center through the operating budget. It is imperative for the performance of this organization that we supervise financial management and expenditure of resources that are not necessary for the institutions. For the better organization and for the better health of patients, it is essential to implement new technologies that can enhance the whole functioning of the system and make patients&rsquo; lives safer. Also, the strategic plan entails the hiring of more employees who are competent and skilled and have the ability to work on multiple tasks and operate apparatus as it aims at enhancing the organizations performance. There are two methods to achieve this: The first one is to hire qualified workers and the second one is the training of existing workers in new technologies and resources for their effectiveness. The goals of Anthony Medical Center are patient care enhancement, patient protection, the development of a new care model, the enhancement of patient satisfaction, staff workload reduction, increased organizational efficiency, competitor outperformance, and the reduction of mortality at the facility (Bryson et al. , 2018).</p>
                        <p>The hospital is generally focusing on patients and employees to make improvements in the hospital for the better outcome of the next year. The operating budget should therefore meet the goals and mission of the organization and the employees in the healthcare sector should agree with it (Bryson et al. , 2018). The budget document contains the most recent technologies that are experiencing the current world such as telehealth, and the Electronic Health Record technology that is expected to enhance the well-being of patients and decrease the working pressure of the nurses. Thus, using this method we can reduce the time required to examine the patients and hence enhance the efficiency of our staff and thus the revenue of the organization. This system has to be put in place within the organization to ensure that the budget is well monitored (Bryson et al. , 2018).</p>
                        <p>The organization should focus on the integration of new technologies that will enable it achieve better results in the future in order to avoid the future outcomes. The proposed plan is relevant to the organization&rsquo;s mission because new technology will enhance the care of patients as well as their monitoring, and the work load of the staff. Therefore, the organization&rsquo;s image and revenue will also be boosted as a result. In the near future, the organization will also decrease rates of illness through application of technologies (Bryson et al., 2018).</p>
                        <h2>Approach for Ongoing Budget Management</h2>
                        <p>An organization can decide to adopt either a critics approach or a supportive approach in the management of an on going budget. This approach helps to find out the level of satisfaction of the budget system. The following are the recommendations that can help the organizations to control and minimize on the high cost of the hospital; Also, the organization will be in a position to ascertain the amount of resources which are going to be assigned to each of the projects through the use of capital budgeting approach. Some of the strategies include; limiting the number of hours that an employee can work, putting measures on overtime, controlling the cost of overtime, and controlling expenditures on technology and supplies (Ibrahim, Mukdad Mohammad, 2019).</p>
                        <p>Limit the number of hours that employees work above standard hoursAn organisation may decide to have fewer working hours by ensuring that the employees are given a reasonable number of patients to attend to. The staff will also be able to deliver efficient treatment while at the same time they will be relieved of some burden and time. Further, this gives the organization a profit as it does not require the organization to spend more time and money.</p>
                        <p>Another way to control the organization&rsquo;s expenses is to regulate the non-productive spends and time. This will lead to enhancement of the quality of patient care, increased efficiency of the workforce, and efficient use of resources that will in the long run cut down on the costs of the organization. As for our case, we will use the critic&rsquo;s approach to find out why our expenses are greater than our revenue and how to prevent it in the future (Ibrahim, Mukdad Mohammad, 2019).&nbsp; This claim is supported by the fact that the spending on hospital procurement networks forms about a third of overall operational costs of US hospitals (Ibrahim, Mukdad Mohammad, 2019). Formulate your overtime policy. To control the amount of overtime that workers are putting in, there is the need for managers to set polities that support work-life balance. The provision of flexible office hours, remote work, and holiday leave are all good ways of expanding an employee&rsquo;s freedom when it comes to his or her interactions with colleagues.</p>
                        <p>The assumption on which these approaches are founded is that there is a particular department in an organization that has high costs and that these costs can be managed through different means like the Zero approach or regulation of staff overtime which will automatically control costs. The implementation of new technologies will lead to higher efficiency and improved patient satisfaction and as a result cut down on unnecessary costs and boost the revenue of the organization (Kenno et al., 2018).</p>
                        <h2>Conclusion</h2>
                        <p>The operating budget is supposed to detail out the entire expenditure of an organization on a day to day basis. It will enable the hospital to know the present condition of the health care system and the goals it wants to achieve in the next one year by coming up with an operating budget. Thus, by creating a budget, one is able to determine the effectiveness of the budget in meeting the set hospital policies and mission and areas that may need some improvement. In addition, the strategic plan will contain information about certain aspects or elements that should be included into the operating budget system in order to enhance the organization&rsquo;s performance and to ensure the effective budgetary control in the future.</p>
                        <h2>References</h2>
                        <p>Anderson, D. M., Cronk, R., Best, L., Radin, M., Schram, H., Tracy, J. W., &amp; Bartram, J. (2020). Budgeting for environmental health services in healthcare facilities: a ten-step model for planning and costing. <em>International Journal of Environmental Research and Public Health</em>, <em>17</em>(<em>6</em>).</p>
                        <p><a href="https://doi.org/10.3390/ijerph17062075">https://doi.org/10.3390/ijerph17062075</a></p>
                        <p>Bhatt, J., &amp; Bathija, P. (2018). Ensuring access to quality health care in vulnerable communities. <em>Academic Medicine</em>, <em>93</em>(<em>9</em>), 1271&ndash;1275.</p>
                        <p><a href="https://doi.org/10.1097/acm.0000000000002254">https://doi.org/10.1097/acm.0000000000002254</a></p>
                        <p>Bryson, J. M., Edwards, L. H., &amp; Van Slyke, D. M. (2018). Getting strategic about strategic planning research. <em>Public Management Review</em>, <em>20</em>(<em>3</em>), 317&ndash;339. Tandfonline.</p>
                        <p><a href="https://doi.org/10.1080/14719037.2017.1285111">https://doi.org/10.1080/14719037.2017.1285111</a></p>
                        <p>Dauda, H. (2019). Examining the role of budgeting and budgetary control in achieving objectives of business organisations http://internationalpolicybrief.org/journals/international-scientic-research-consortium-journals/intl-jrnl-of-operational-research-in-mgmt-soc-sci-edu-vol5-no1-feb-2019. <em>International Journal of Operational Research in Management</em>, <em>5</em>.</p>
                        <p><a href="http://www.internationalpolicybrief.org/images/2019/FEBRUARY/IJORMSSE/ARTICLE2.pdf">http://www.internationalpolicybrief.org/images/2019/FEBRUARY/IJORMSSE/ARTICLE2.pdf</a></p>
                        <p>Guo, F., Gregory, J., &amp; Kirchain, R. (2020). Incorporating cost uncertainty and path dependence into treatment selection for pavement networks. <em>Transportation Research Part C: Emerging Technologies</em>, <em>110</em>, 40&ndash;55.</p>
                        <p><a href="https://doi.org/10.1016/j.trc.2019.11.015">https://doi.org/10.1016/j.trc.2019.11.015</a></p>
                        <p>Ibrahim, Mukdad Mohammad. (2019). <em>Designing Zero-based Budgeting for Public Organizations</em>. Ssrn.com.</p>
                        <p><a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3402663">https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3402663</a></p>
                        <p>Kenno, S. A., Lau, M. C., &amp; Sainty, B. J. (2018). In search of a theory of budgeting: a literature review. <em>Accounting Perspectives</em>, <em>17</em>(<em>4</em>), 507&ndash;553.</p>
                        <p><a href="https://doi.org/10.1111/1911-3838.12186">https://doi.org/10.1111/1911-3838.12186</a></p>
                        <p>Love, P. E. D., &amp; Ika, L. A. (2021). Making sense of hospital project (mis)performance: over budget, late, time and time again&mdash;why? and what can be done about it? <em>Engineering</em>.</p>
                        <p><a href="https://doi.org/10.1016/j.eng.2021.10.012">https://doi.org/10.1016/j.eng.2021.10.012</a></p>
                        <p>Lu, G., Ding, X. D., Peng, D. X., &amp; Hao-Chun Chuang, H. (2018). Addressing endogeneity in operations management research: Recent developments, common problems, and directions for future research. <em>Journal of Operations Management</em>, <em>64</em>(<em>1</em>), 53&ndash;64.</p>
                        <p><a href="https://doi.org/10.1016/j.jom.2018.10.001">https://doi.org/10.1016/j.jom.2018.10.001</a></p>
                        <p>Moro Visconti, R., &amp; Morea, D. (2020). Healthcare digitalization and pay-for-performance incentives in smart hospital project financing. <em>International Journal of Environmental Research and Public Health</em>, <em>17</em>(<em>7</em>), 2318.</p>
                        <p><a href="https://doi.org/10.3390/ijerph17072318">https://doi.org/10.3390/ijerph17072318</a></p>
                        <p>Owens, J., Singh, G., &amp; Cribb, A. (2019). Austerity and professionalism: being a good healthcare professional in bad conditions. <em>Health Care Analysis</em>, <em>27</em>(<em>3</em>), 157&ndash;170.</p>
                        <p><a href="https://doi.org/10.1007/s10728-019-00372-y">https://doi.org/10.1007/s10728-019-00372-y</a>.</p>
                        <p>Prioli, K. M., Karp, J. K., Lyons, N. M., Chrebtow, V., Herman, J. H., &amp; Pizzi, L. T. (2018). Economic implications of pathogen reduced and bacterially tested platelet components: a us hospital budget impact model. <em>Applied Health Economics and Health Policy</em>, <em>16</em>(<em>6</em>), 889&ndash;899.</p>
                        <p><a href="https://doi.org/10.1007/s40258-018-0409-3">https://doi.org/10.1007/s40258-018-0409-3</a></p>
                        <p>Siirala, E., Salanter&auml;, S., Lundgr&eacute;n‐Laine, H., Peltonen, L., Engblom, J., &amp; Junttila, K. (2020). Identifying nurse managers&rsquo; essential information needs in daily unit operation in perioperative settings. <em>Nursing Open</em>, <em>7</em>(<em>3</em>), 793&ndash;803.</p>
                        <p><a href="https://doi.org/10.1002/nop2.454">https://doi.org/10.1002/nop2.454</a></p>
                        <p>&nbsp;</p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Nursfpx6216assessment2
