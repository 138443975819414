import React from 'react'
import {FaRegArrowAltCircleRight, FaPhoneAlt} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Bannerone = () => {
  return (
    <>
      <div className='ocs2-bannerone'>
        <div className='ocs2-bannerone-image'>
          <img src='images/bannerone.png' alt='' className='ocs2-bannerone-image-box'/>
        </div>
        <div className='ocs2-bannerone-detail'>
          <p className='ocs2-bannerone-detail-title'>ENJOY OUR EXCELLENT CAPELLA RN-MSN <br/>FLEXPATH PAPER WRITING SERVICES</p>
          <p className='ocs2-bannerone-detail-text'>Experience success with our Capella University flex path assistance services. Get flawless and high-quality Capella University RN to BSN flex path papers at a fantastic discount. Achieve Capella Flexpath RN -BSN academic help to enhance your grades.</p>
          <div className='ocs2-orderNow-box'>
            <Link to={"/order"} className='ocs2-orderNow-btnn'><button className='ocs2-orderNow'>Order Now &nbsp;<FaRegArrowAltCircleRight/></button></Link>
            <img src='images/time.png' alt='' className='ocs2-orderNow-time'/>
            <div className='ocs2-bannerone-phoneNo'>
              <FaPhoneAlt className='ocs2-bannerone-phoneNo-icon'/>
              <span>
                <p>Call us at</p>
                <strong>+1(669) 209-0161</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Bannerone
