import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";
// import { Helmet } from 'react-helmet';

const Paysomeonetotakeonlineclasses = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "American Writers",
            Desc: "Hire our competent writers who are masterminds in crafting high-scoring and excellent assignment solutions.",
        },
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "High-Scoring Solutions",
            Desc: "Get excellent solutions for nursing coursework. Our writing experts enable you to become successful.",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Research Experts",
            Desc: "TTake well-researched material and enjoy good marks with the praise of professors.",
        },
        {
            icon: "fa-solid fa-text-height icon",
            title: "Good Formatting",
            Desc: "Buy assignment solutions that are well-formatted, well-cited, and well-written. ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-chart-line icon",
            title: "Good Performance",
            Desc: "Our assignment solutions promote the student’s academic performance. We are devoted to helping you!",
        },
        {
            icon: "fa-solid fa-pen-to-square icon",
            title: "Professional Writers",
            Desc: "Hire our highly experienced writers to offer excellent assignment writing help services.",
        },
        {
            icon: "fa-solid fa-headset icon",
            title: "Complete Support",
            Desc: "Our academic experts offer full support in the assignment writing process. Students only receive the quality-written solutions.",
        },
        {
            icon: "fa-solid fa-house-laptop icon",
            title: "Flawless Services",
            Desc: "We offer high-quality assignment writing help services. We offer plagiarism-free and revised content.",
        },
    ]
    return (
        <>
            {/* <Helmet>
                <title>Do My Assignment Online</title>
                <meta name='description'
                    content='Professional do my assignment online for your assignments. Achieve top grades with our expert help. Reliable and confidential service.' />
                <meta name='keywords' content='pay someone to do assignments, do my assignment for me cheap,pay someone to do my homework online, do my assignment online' />
            </Helmet> */}
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
    <div className='sherotop-left'>
    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
    </div>
    <div className='cal-box'><Calculator/></div> 
    </div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>PAY SOMEONE TO TAKE ONLINE CLASSES</span></h1>
                            <h2>ARE YOU LOOKING TO ”PAY SOMEONE TO <br />TAKE ONLINE CLASS FOR ME”?</h2>
                            <p className='ocs2-topText'>Choose Online Course Services as we provide online services for students to excel in their studies. </p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free proofreading and editing</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Punctuality</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; High-quality content</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Assignment Writers</h2>
                            <p>Our professional writers offer the best assignment solutions for your success.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Best Writing Help</h2>
                            <p>Get help from our competent assignment writers to write quality assignments.</p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET CAPELLA DNP FLEXPATH ASSESSMENT SOLUTIONS!</h2>
                <p>We provide excellent Capella University DNP FlexPath assessment solutions for students in their Capella DNP FlexPath Courses.</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Academic Services</span>
                    <h2>“PAY FOR SOMEONE TO TAKE ONLINE CLASS” SERVICES BY US</h2>
                    <p>If your concern is “can i pay someone to take my online class”, then Online Course Services is the best available choice for you. We have a team of professionals who assist the students so that they can overcome the difficulties related to their studies and perform better in their nursing careers at Capella University. Our professionals assist students in their coursework, helping them to understand the concepts they find difficult so they can get good grades.</p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>Services Covering Various Aspects</strong></Tab>
                        <Tab><strong>Our Comprehensive Services</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p>
                        For students who are looking for guidance and support for their online courses and are looking to “pay someone to take online classes”, the best option that they can choose is Online Course Services. Our professionals cover various aspects of students' courses including helping with assignment writing, preparation for quizzes and presentations, and guiding them to understand the complex concepts of their coursework. With our support, students can focus on their nursing careers at Capella University and start their journey toward a prosperous future. “Pay for someone to take online class” and avail of our services.<br /><br />
                            <strong>Grasp Concepts with Our Services</strong><br />
                            For students who are facing difficulties in their online courses and are willing to “pay someone to take online classes”, the best platform that they can consider is Online Course Services. For students who find difficulties understanding complex concepts on their own and need professional assistance, we provide assistance and personalized support to them so they can continue their nursing career at Capella Univeristy efficiently. Our professionals are experts in assignment writing and help students complete their work on time. Students can avail of our services and get professional assistance for their online courses by “pay someone to take online class for me”.
                             </p>
                    </TabPanel>
                    <TabPanel>
                        <p>“Can i pay someone to take my online class” is the question that is the concern of most of the students. Online Course Services provides detailed guidance to the students so that they can easily continue their study at the Capella Univeristy. Our professionals help students get done with their assignments, prepare for the quizzes, and speak confidently in their presentations. Our professionals play a key role in student’s academic performance and help them to achieve success at Capella University.</p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Features</span>
                    <h2>WHY PREFER ONLINE COURSE SERVICES</h2>
                    <p>Online Course Services is the best platform that provides professional services to students so that they can excel in their studies and focus on their careers. Our professional team guides the students so they can study without stress at Capella University and pursue their nursing field. We provide different services to the students regarding their course so they are well equipped and better prepared to take their assessments at Capella University.</p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Are the Online Course Services providing affordable services?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes, we are providing affordable services to the students of Capella University.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>For online classes what services are offered by Online Course Services?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    We provide help for assignments, quizzes, and presentations.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>How do Online Course Services help students to understand difficult concepts?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    We provide support to students so that they can understand difficult subjects.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>What makes Online Course Services different from others?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes,Online Course Service provides personalized guidance for the students at Capella University.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I trust Online Course Services for my online classes?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Students can trust us to provide guidance related to their studies as we have years of experience.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </Container>
        </>

    )
}

export default Paysomeonetotakeonlineclasses
