import React from 'react'
import Msnhero from './msnpagecomponent/Msnhero'
import Msndegreedetail from './msnpagecomponent/Msndegreedetail'
import Bsnfeatures from './bsnpagecomponent/Bsnfeatures'
import Bannertwo from '../../homepage/Bannertwo'
import Bsnfaqs from './bsnpagecomponent/Bsnfaqs'


const Msn = () => {
  return (
    <>
    <Msnhero/>
    <Msndegreedetail/>
    <Bsnfeatures/>
    <Bannertwo/>
    <Bsnfaqs/>
    </>
  )
}

export default Msn
