import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";

const CapellaFlexpath = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Assessment Writers",
            Desc: "Hire our professional Capella flex path writers. Enjoy high-scoring nursing assessments!",
        },
        {
            icon: "fa-solid fa-pen-to-square icon",
            title: "Writing Help",
            Desc: "We provide comprehensive Flex path writing services for Capella students!",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Courses Help",
            Desc: "Seek help from academic professionals in your Capella Flex path assessments.",
        },
        {
            icon: "fa-solid fa-file-shield icon",
            title: "Assessment Help",
            Desc: "Get professional help through Capella Flex Path Assessment Writing services!",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a team of Capella University Flexpath assessment writers to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We are available 24/7 to assist you in your Capella Flexpath assessments!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to our students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek comprehensive guidance from Capella FlexPath nursing experts.",
        },
    ]
    return (
        <>
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
                    <div className='sherotop-left'>
                    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
                    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
                    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
                    </div>
                    <div className='cal-box'><Calculator/></div> 
                </div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>TAKE COMPREHENSIVE HELP IN <br />CAPELLA FLEX PATH ASSESSMENTS</span><br />
                              </h1>
                                <h2>DO YOU WANT CAPELLA FLEX PATH<br /> ASSESSMENT HELP?</h2>
                            <p className='ocs2-topText'>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Expert</h2>
                            <p>We have an ocean of Capella Flex Path writers to assist you in your assessments.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Get Free Assignments</h2>
                            <p>We have a team of Capella University Flex path assessment writers to help you!</p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET CAPELLA FLEX PATH PAPERS!</h2>
                <p>Online course services provide excellent Flex Path writing services for Capella students in their Capella Flex Path Courses.</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Prestigious Services</span>
                    <h2>CAPELLA UNIVERSITY FLEX PATH ASSESSMENT WRITING SERVICES</h2>
                    <p>Online course services are the world’s best platform to offer excellent, high-quality help with Capella flex path assessment writing. We have a pool of academic writers, tutors, and mentors. Our intellectuals assist the students in their Capella Flex path assessments. Students receive comprehensive help in their online coursework, exams, and assessments. Students enjoy our high-scoring Capella Flex Path nursing assessment solutions.</p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>RN-BSN Capella FlexPath Classes Help Capella Students</strong></Tab>
                        <Tab><strong>We Cover All Types Of Assessments Through Capella FlexPath Tuition</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p>Online Course Services provides the best Flex path writing services for Capella students. We have an ocean of academic writers to help you in your Capella Flex Path nursing courses. Our tutors will assist you in your Capella University Flex path assessment on your behalf. Our Capella flex path writers will deliver the best solutions for nursing assessments, exams, quizzes, and papers.</p>
                    </TabPanel>
                    <TabPanel>
                        <p>We are honored to offer nursing students the best Capella Flex Path tuition. We have a track record of assisting students with high-scoring assessments. Our students mostly score high marks on their assessments. The reason is the meticulous efforts of our highly educated and experienced writers. They are always ready to assist you in your courses and assessments of Capella Flex path programs. Experience a positive change in your academic performance by getting help from Capella Flex path assessment writers. </p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Features</span>
                    <h2>WHY CHOOSE US?</h2>
                    <p>Online Course Services offers the best Flex Path Writing services for Capella students. We have great teams of Capella Flex path writers and mentors to help you in your Capella University Flex path assessment. Our professional Capella Flex Path nursing writers have helped millions of nursing students with their Capella Flex path assessments. We have well-known Capella University Flex path assessment writers who can provide complete assistance in Capella Flex path assessments. We will handle your online assessments, quizzes, academic papers, and more! Seek help from the best professionals through Capella Flex Path tuition sessions. Seek high-scoring Capella Flex path assessments in all your courses!</p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Do you offer Flexpath writing services for Capella students?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! Our Capella Flex path writers offer comprehensive guidance to nursing students in their Capella Flex path assessments.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I hire a writer for my Capella Flexpath assessments?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We have excellent Capella Flexpath nursing writers to help you with high-quality assessments.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I get Tutoring sessions for Capella FlexPath nursing courses?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We are offering helpful tutoring sessions for Capella Flexpath nursing students
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I avail of Capella FlexPath tuition at a discount?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We are offering comprehensive tutoring sessions to students in their courses. Take help from our skilled tutors, writers, and mentors.                               </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you help me with my Capella Flexpath exams?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We have a team of highly educated Capella FlexPath exam experts to help you with your exams on your behalf.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you help with the Capella flexpath assessment?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>
                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We have a team of capable writers to help you with your assessments.                               </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you write the Capella University Flexpath assessment for me?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We will provide flawless and high-quality assessment solutions for you.                                </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I get a personal tutor for my Capella FlexPath courses?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We offer personal tutors for Capella FlexPath nursing students at a fantastic discount.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>

                        </Accordion>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default CapellaFlexpath
