import React from 'react'
import Psydclinicalhero from './Psydclinicalcomponent/Psydclinicalhero'
import Psydclinicaldegreedetail from './Psydclinicalcomponent/Psydclinicaldegreedetail'
import Bsnfeatures from '../Nursing/bsnpagecomponent/Bsnfeatures'
import Bannertwo from '../../homepage/Bannertwo'
import Bsnfaqs from '../Nursing/bsnpagecomponent/Bsnfaqs'

const Psydclinical = () => {
  return (
    <div>
      <Psydclinicalhero/>
      <Psydclinicaldegreedetail/>
      <Bsnfeatures/>
      <Bannertwo/>
      <Bsnfaqs/>
    </div>
  )
}

export default Psydclinical
