import React from 'react'
import { Container } from 'react-bootstrap'
import { FaPhoneAlt, FaRegArrowAltCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Bspsydegreedetail = () => {
  return (
    <div className='degree-detail'>
        <Container>
            <div className='degree-details-inner'>
                <div className='degree-uni-details'>
                    <h2>Get Top Notch Services</h2>
                    <div className='bsn-br'></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Velit aliquet sagittis id consectetur purus ut faucibus. Egestas dui id ornare arcu odio ut sem. Amet tellus cras adipiscing enim eu turpis. In nisl nisi scelerisque eu. Risus quis varius quam quisque id diam vel quam. Fermentum odio eu feugiat pretium. Hac habitasse platea dictumst quisque sagittis purus. Purus sit amet luctus venenatis lectus magna.
                        <br/><br/>Id nibh tortor id aliquet lectus proin nibh. Urna cursus eget nunc scelerisque. Massa massa ultricies mi quis. Tellus elementum sagittis vitae et leo duis ut diam quam. Ut enim blandit volutpat maecenas volutpat blandit aliquam etiam. Ut consequat semper viverra nam libero justo.</p>
                    
                    <div className='ocs2-orderNow-box bsn-order-box'>
                        <Link to={"/order"} className='ocs2-orderNow-btnn'><button className='ocs2-orderNow'>Order Now &nbsp;<FaRegArrowAltCircleRight/></button></Link>
                        <img src='images/bsntime.png' alt='' className='ocs2-orderNow-time'/>
                        <div className='ocs2-bannerone-phoneNo bsn-uni-details'>
                        <FaPhoneAlt className='ocs2-bannerone-phoneNo-icon bsn-uni-phone'/>
                        <span>
                            <p>Call us at</p>
                            <strong>+1(612)200-2655</strong>
                        </span>
                    </div>
                </div>
                </div>
                <div className='degree-details-offer'>
                    <img src='images/saleoffer.png' alt=''/>
                </div>
            </div>
        </Container>
    </div>
  )
}

export default Bspsydegreedetail
