import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx9904assessment2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 9904 Assessment 2</title>
                <meta name='description'
                    content='Receive expert assistance for NURS FPX 9904 Assessment 2. Improve your skills and achieve excellent grade with professional support' />
                <meta name='keywords' content='NURS FPX 9904 Assessment 2' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 9904 Assessment 2< br /><span>Project Summary Report</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/NURSFPX9904Assessment2.png" alt="" />
                    <div className='main-container-sl-content'>
                        <h2>Project Summary Report</h2>
                        <p>Obstructive Sleep Apnea (OSA) is one of the most common categories of Sleep disorders which is characterised by repeated pauses in breathing during sleep due to the narrowing or complete obstruction of the upper airway. These are the factors that cause sleep fragmentation and episodic hypoxia, which, if unaddressed, can culminate in multiple health risks ranging from cardiovascular diseases, metabolic disorders, cognitive impairment, and overall reduced quality of life (Benjafield et al., 2019). There is a significant proportion of the population that suffers from OSA, but few have received a correct diagnosis and even fewer are treated properly because there is a lack of knowledge among clinicians and primary care physicians as to how to effectively screen and manage OSA (Benjafield et al., 2019; Isetta et al., 2020). OSA is increasingly diagnosed in primary care practices, so physicians are often on the front line in the first assessment and treatment of the condition. This shortfall in provider education is a major factor observed to be slowing down the achievement of diagnostic accuracy for OSA and, consequently, a timely treatment for the affected persons.</p>
                        <h2>Background and Organization Gap Analysis</h2>
                        <p>It is important to understand that Obstructive Sleep Apnea (OSA) is a common public health problem that affects millions of people around the globe, specifically a condition that occurs when individuals have a partial or complete blockage during sleep. These episodes affect interrupted sleep and lead to IH, which accounts for several negative health consequences such as hypertension, cardiovascular diseases, metabolic syndrome, cognitive decline, and reduced QOL (Benjafield et al., 2019). Consequently, OSA is a highly underrated health issue with severe health consequences and its underdiagnosis and undertreatment worsen the situation in multiple folds.</p>
                        <p>The most common cause of continued underdiagnosis of OSA is the impaired healthcare professionals and, hence, poor understanding of screening and proper management of the condition. It is noteworthy that other physicians, such as primary care providers (PCPs), are the first-line clinicians to screen patients for symptoms compatible with OSA, including daytime sleepiness, snoring, and episodes of apnea. However, research findings indicate that most PCPs may not screen for OSA on a regular basis because many are not trained for it, and they may be unaware of both the screening tools and the guidelines useful for the same (Kapur et al., 2021). Thus, PCPs&rsquo; lack of updated knowledge in this area, their general knowledge about OSA, as well as their approach to screening and diagnosing OSA patients contribute to this gap and serve as significant obstacles to enhancing the identification and treatment of OSA in ambulatory practices.</p>
                        <h2>Organization Gap Analysis</h2>
                        <p>The organisation gap analysis entails determining the existing awareness, perception, and behaviour of health care providers in the specific organisation regarding OSA screening and the prevention and control measures within the context. It also assesses the gaps and challenges to be faced and the intervention plan to effect change.</p>
                        <h2>Knowledge Gap</h2>
                        <p><strong>Current State:</strong> Despite these developments, many healthcare professionals remain insufficiently informed about OSA, its prevalence, and its importance, as well as health outcomes associated with its existence. Disparagingly, data also show that there is poor knowledge on the screening tools and protocols for diagnosing OSA (Lee &amp; Hillman, 2021).</p>
                        <p><strong>Identified Gap:</strong> Lack of awareness and education on OSA by HCPs, resulting in low screening and lat therapeutic intervention.</p>
                        <p><strong>Proposed Strategy:</strong> Ensure that education consist of face-to-face practice improvement discussions or presentations, web-based or computer courses, and access to new guidelines/other resources for screening/managing OSA.</p>
                        <h2>Attitudes Gap</h2>
                        <p><strong>Current State:</strong> Medical doctors and other healthcare practitioners might not fully appreciate its role in patient care or might consider screening for OSA as beyond their professional jurisdiction. Also, there may be low self-efficacy in the discussion of OSA with the patients, perhaps because they are not well-trained (Shan et al. , 2021).</p>
                        <p><strong>Identified Gap:</strong> Several respondents mentioned the following concerns; Lack of knowledge of the necessity to screen for OSA and self-efficacy to discuss it with patients.</p>
                        <p><strong>Proposed Strategy:</strong> Ensure that elements of training provide awareness of the need for OSA screening for the general well-being of the patient such that they are aware of success stories, case studies and patient relation skills.</p>
                        <h2>Practices Gap</h2>
                        <p><strong>Current State:</strong> Alternatively, although providers can apply screening practices even if they know OSA, there could be disparities in the usage depending on the time constraints, absence of protocols and poor organisational support (Kapur et al., 2021).</p>
                        <p><strong>Identified Gap:</strong> Some key findings included the following: OSA screening practices may not be uniformly implemented, and units may not use standardised protocols.</p>
                        <p><strong>Proposed Strategy:</strong> Generate proven materialised procedures for OSA screening and distribute them extensively, incorporate the OSA questionnaire into patients&rsquo; check-ups, and provide easy-to-use tools that can be easily incorporated into primary care practices.</p>
                        <h2>Significance of the Practice Problem</h2>
                        <p>OSA is one of the most important public health issues on the grounds of such a high rate of the prevalence and possible serious consequences for the health of the affected patient. Using the GAD 7 questionnaire, OSA has been estimated to affect at least 1 billion people worldwide, with many of these cases remaining undiagnosed and therefore untreated, as stated by Benjafield et al. (2019). The condition is defined by episodes of reduced airway patency during sleep causing disrupted sleep and reversive hypoxia. These disruptions may precipitate or worsen a number of severe other health complications such as high blood pressure, heart ailments, cerebrovascular accidents, diabetes and neurological disorders. Furthermore, untreated OSA has quality-of-life implications and increases the probability of accidental gestural descent and overall mishaps, including traffic mishaps because of sleepiness during the day (Peppard et al., 2021).</p>
                        <h2>Impact on Healthcare Systems</h2>
                        <p>Concerning OSA, it is underdiagnosed and undertreated, therefore posing a significant load to the health systems. There is usually the presence of other associated diseases in patients with untreated OSA, which results in over and frequent health service use and even higher expenditure. Such patients often have higher levels of utilisation of healthcare services (Outpatient visits, hospitalisations and Interventions) than the general population that do not have OSA (Kapur et al., 2021). Also, the absence of sufficient ASCSP programs, as well as a lack of adequate screening and management strategies in primary care, makes it difficult to identify and address ailments in early stages, thus worsening health and prolonging life diseases and, at the same time, elevating overall health costs.</p>
                        <h2>Summary of the Evidence</h2>
                        <p>More efforts are needed in provider-based education programs to address knowledge and practice deficits regarding OSA screening and management. These programs mainly increase healthcare providers' knowledge of OSA, its predictors, and screening instruments. The perceived capability of education programs contributes to providers&rsquo; ability to screen and treat OSA, thus enhancing patient outcomes and decreasing the cost of managing untreated OSA (Lee et al., 2021).</p>
                        <h2>Evidence Supporting Education Programs</h2>
                        <p>Many works in the literature have advanced the notion of convenience in increasing clinicians' understanding and utilisation of OSA. For example, tutorials like face-to-face sessions, web-based sessions, or accessed guidelines have advocated for comprehensiveness and proficiency in screening for and managing OSA among providers (Shan et al., 2021).</p>
                        <p><strong>Knowledge Enhancement:</strong> Thus, educational programs improve providers' knowledge regarding the incidence of OSA, its predisposing factors, and the consequences of untreated OSA on overall health. They also equip the provider with screening tools and a diagnostic criterion common in the early diagnosis and management of these conditions (Lee et al. , 2021).</p>
                        <p><strong>Attitudinal Shifts:</strong> Some of the educative awareness interventions that can be incorporated into a program include; changing healthcare providers&rsquo; perception towards OSA screening by ensuring they understand the implications of screening to the health of the entire patient. The domesticity of this research leads to an increased chance of providers identifying concerns about OSA and being assured of communicating with patients about it (Shan et al., 2021).</p>
                        <h2>Quality Improvement Framework</h2>
                        <p>The quality improvement framework for improving the Management of Obstructive Sleep Apnea (OSA) among healthcare providers aims to address concerning acquaintance paucity, behavioural predispositions, and organisational practices. Its strength lies with creating a methodical and directed approach to enhancing the competencies of healthcare providers along with the implementation of optimal practices concerning OSA in primary clinical responsibilities. It builds on other quality improvement strategies applicable to the context of primary care services honed to meet those receptors&rsquo; needs.</p>
                        <h2>Model for Improvement</h2>
                        <p><strong>APPI:</strong> The Model for Improvement that is used for the basis for this quality improvement framework. There are three key questions of improvement: the five why&rsquo;s, and the PDSA cycle, which enables testing of changes to effect large improvements. The overall aim is to improve HCPs&rsquo; MOA knowledge, attitudes and behaviours regarding OSA screening and management. Others include the promotion of screening for OSA among the population, particularly in primary care practices, enhanced diagnostic algorithms and accuracies of OSA diagnosis, and better overall management and referral for patients with OSA. Milestones will be used in tracking the performance improvement intervention outcomes using KPIs.</p>
                        <p>Looking at the evidence and the nature of practice that have been made in similar settings, the following modifications will be made. Some of these include workshops, online courses, and other education resources for healthcare providers, continuing medical education (CME) for OSA and sleep disorders guidelines, developing and distributing standardised protocols for OSA screening, implementing OSA screening tools into EHR systems to make screening easier, and having follow-up sessions that are often structured for feedback and troubleshooting any issues concerning the screening process.</p>
                        <h2>Plan-Do-Study-Act (PDSA) Cycle</h2>
                        <p>This brings us to the PDSA cycle, which will be used to introduce the changes and maintain and enhance improvements continually. More specifically, in the planning phase of the interventions, these may include creating educational tools, training programmes, screening procedures. Milestones and goals will be established as several goals for each intervention will be set, such as the approximate number of providers to be trained and the approximate level of screening rates that will be achieved in the pr&oacute;ximo six months. Toolkit assessment instruments that include pre- and post-intervention surveys, screening logs and patient outcome tracking tools will be designed.</p>
                        <p>In the implementation stage, activities to be performed are likely to involve conducting training, providing other education materials, and incorporating screening tools into the EHR. Information will also be collected regarding the observed number of screens performed, the opinions of providers engaged in that process, and the effects on patients. The final phase of applying the AIDET is exploring results, where the results of the comparison of data before and after implementation of the interventions are evaluated concerning the effectiveness of the interventions to be applied. To this end, differences in terms of screening rates, diagnostic reliability, and patient outcomes will be assessed to highlight key findings and potential for enhancements.</p>
                        <h2>Sustainability and Scalability</h2>
                        <p>Another key learning area for quality improvement is to always consider how sustainable a programme might be and, more specifically, how sustainable any change is likely to be on a larger scale in the future. The above policies will be pursued in conjunction with regular continuing education activities aimed at enhancing the healthcare provider&rsquo;s knowledge on current OSA screening and management protocols. The modifications to the policy promoting OSA screenings will be made, and the specific tools will be accredited as the standard of care. Universality in healthcare policies and services provision will also be achieved through stakeholders&rsquo; engagement in healthcare providers, administrators as well as end-users or patients.</p>
                        <h2>Project Description</h2>
                        <p>This project seeks to eradicate a huge healthcare issue of underdiagnosis and undertreatment of Obstructive Sleep Apnea OSA by meeting a key provider-based education intervention. This is in line with certain global objectives, the primary being the improvement of knowledge, attitude and practices of PCPs on OSA screening and management (Kapur et al. , 2021). To this end, the project seeks to enhance adequate training of PCPs through ongoing educative training session on epidemiology and risk factors of OSA, screening tools, diagnostic criteria, and management of OSA patients, and overall discovery that can optimise patient care and reduce the burden experienced in the healthcare system (Lee et al., 2021; Peppard et al., 2021). Implementation will include creating educational materials based on the identified patient population, the inclusion of the screener into the EHRs and the determination of policies for referral to sleep medicine physicians (Shan et al. , 2021). The outcomes of the intervention will be realized utilising a pre-intervention and post-intervention survey, and a comparison of the findings will be done on parameters such as increments in screening, accurate diagnosis, and confidence enhancement in providers. Using the Model for improvement and the PDSA cycle, this study is both intended to augment the level of the first mode of care for patients with OSA as well as to build the infrastructure for sustainable systems for the identification and management of OSA in primary care, which will provide long-term benefits for the health of the population.</p>
                        <h2>Project Evaluation Results</h2>
                        <p>During the project evaluation, the relevance and efficiency of a provider-based education program that can help to enhance the diagnosis and management of Obstructive Sleep Apnea (OSA) by primary care providers (PCPs) was to be examined. To achieve the objectives of the study, a mixed-methods design was used and quantitative data were complemented by the qualitative feedback from the involved PCPs. More knowledge about OSA was seen among PCPs after the intervention, yet pre-intervention data pointed to the fact that those PCPs had several degrees of uncertainty in regards to OSA screening and management, including diagnostic criteria and treatment options available to them (Kapur et al., 2021). Thus, the questionnaire results supplemented by online surveys after the intervention demonstrated an increase in the knowledge of PCPs by 18% and an increase in confidence levels to diagnose OSA symptoms and to perform screenings as well as make referrals.</p>
                        <p>An assessment revealed that there was a significant improvement in OSA hospital screening in relation to the new general screening guidelines. There was also an increased rate of confirmatory testing and compliance with the diagnostic criteria among PCPs for OSA, which contributed to the accuracy of diagnoses. In fact, there was an improvement in the accuracy of diagnosis with high sensitivity towards confirmatory testing among the PCPs (Lee et al., 2021; Peppard et al., 2021). Research data on patients&rsquo; outcomes showed that time-sensitive diagnosis of OSA allowed for the necessary actions to be taken immediately with the ultimate goal of patient well-being and the improvement of quality of life among patients diagnosed with the condition. Further, the findings showed that timely OSA management was associated with decreased healthcare service demand and hospitalisation, yielding savings in the health setting.</p>
                        <h2>Discussion and Implication for Nursing and Health Care</h2>
                        <p>The provider-based education programme, which has been given an evaluation in this project, has some bearing on the practice of nursing in the various centres especially the primary care settings. But tellingly, nurses are significantly involved in the teaching and monitoring of patients with OSA or other chronic diseases (Kapur et al. , 2021). Thus, using the knowledge and skills acquired within the framework of the education program, nurses can play an important role in helping the primary care provider (PCP) to improve the identification of patients with OSA, as well as engage patients in condition awareness and, if necessary, in the organization of follow-up care with a sleep specialist. This means of working together also guarantees the delivery of holistic care and positively impacts the patients&rsquo; care results because difficulties are identified and tackled in the initial stages.</p>
                        <h2>Impact on Patient Care</h2>
                        <p>These results support the need to continue developing systematic screening and increase the awareness of providers for CVD in patients. Enhancing OSA diagnostic sensitivity and time-appropriate intervention ultimately translates into better patient symptom relief, minimal healthcare consumption and enhanced health-related quality of life (Lee et al., 2021). In these regards, because nurses are knowledgeable and have updated skills, they have the potential to facilitate these outcomes through educating patients and or families regarding compliance with the treatment regimens as well as constantly assessing the progress of the disease or illness. This proactive approach not only improves the patient&rsquo;s condition but also delivers coherent care coordination across the healthcare institutions.</p>
                        <h2>Healthcare System Benefits</h2>
                        <p>From the overall healthcare system standpoint, the outcomes of successful practices involving proper OSA screening and management are highly advantageous. The patient&rsquo;s reduced hospitalisation and emergency room attendance due to untreated OSA decreased the facilities&rsquo; utilisation and costs (Benjafield et al., 2019; Shan et al., 2021). Based on the theoretical framework, implications for evidence-based nursing practice involve nurses, as frontline caregivers, championing population-level screening guidelines as well as enhanced collaboration with other members in the CIN, supporting the implementation of evidence-based practices in line with the PCPCC agenda.</p>
                        <h2>Summary and Conclusion</h2>
                        <p>The evaluation of the provider-based education program to enhance the screening and management of Obstructive Sleep Apnea has provided empirical evidence for the enhancement of the instructional system through provider-based models and integral components for PCP education to improve delivery of health care. As a result of the program, educational endeavors and the successful development and enrollment into the program screening algorithm, the knowledge and practices of PCPs were improved regarding OSA. Acceptability assessment before and after the intervention showed a marked increase in the identification of OSA and its treatment, with prompt diagnosis and adherence to guidelines. Such outcomes enhance general patient care as well as the quality of life of patients suffering from OSA since their symptoms would be well managed by use of a effective hi tec CCM solution. It also explains why the newly created program of cooperation between PCPs, nurses, and specialists contributes effectively to the integrated approach and patient-centered healthcare delivery. In the future, continued focus on education, availability, and the incorporation of more technologies will have to be directed to carry forward these outcomes in various sectors of healthcare and electrical for the benefit of other communities of patients and in relation to the overall use of healthcare resources.</p>
                        <p><strong>References</strong></p>
                        <p>Andry, J. M., Toban, G., Chafin, C., &amp; Noah, W. (2021). Positive airway pressure therapy supplied by an integrated sleep practice is associated with greater adherence among Pre-Medicare-aged patients with sleep-disordered breathing. <em>Journal of Clinical Sleep Medicine</em>, <em>17</em>(1), 31&ndash;36. <a href="https://doi.org/10.5664/jcsm.8786">https://doi.org/10.5664/jcsm.8786</a></p>
                        <p>Brohman, K., Addas, S., Dixon, J., &amp; Pinsonneault, A. (2020, March 1). <em>CASCADING FEEDBACK: A LONGITUDINAL STUDY OF A FEEDBACK ECOSYSTEM FOR TELEMONITORING PATIENTS WITH CHRONIC DISEASE. | MIS quarterly | ebscohost</em>. Openurl.ebsco.com. <a href="https://openurl.ebsco.com/EPDB%3Agcd%3A1%3A14199345/detailv2?sid=ebsco%25%20Aplink%3Ascholar&amp;id=ebsco%3Agcd%3A141995178&amp;crl=c">https://openurl.ebsco.com/EPDB%3Agcd%3A1%3A14199345/detailv2?sid=ebsco% Aplink%3Ascholar&amp;id=ebsco%3Agcd%3A141995178&amp;crl=c</a></p>
                        <p>Folmer, R. L., Boudreau, E., Atwood, C. W., Smith, C., Totten, A. M., Tock, J., Priyanka Chilakamarri, &amp; Sarmiento, K. (2022). Study protocol to assess de-implementation of the initial provider encounter for diagnosis and treatment of obstructive sleep apnea: The DREAM (direct referral for apnea monitoring) project. <em>BMC Pulmonary Medicine</em>, <em>22</em>(1). <a href="https://doi.org/10.1186/s12890-022-01899-y">https://doi.org/10.1186/s12890-022-01899-y</a></p>
                        <p>Geier, T., Simske, N. M., Sarah J.H. Melin, Trevino, C., Murphy, P. J., Schroeder, M., Brandolino, A., deRoon-Cassini, T., &amp; Schramm, A. T. (2023). Psychiatric comorbidity in emergency general surgery patients: A prospective observational study. <em>Trauma Surgery &amp; Acute Care Open</em>, <em>8</em>(1), e001117&ndash;e001117. <a href="https://doi.org/10.1136/tsaco-2023-001117">https://doi.org/10.1136/tsaco-2023-001117</a></p>
                        <p>Huang, A. E., Chan, E. P., Stave, C. M., Patel, Z. M., Hwang, P. H., &amp; Chang, M. T. (2023). Social media utilization in otolaryngology: A scoping review. <em>the Laryngoscope</em>, <em>133</em>(10), 2447&ndash;2456. <a href="https://doi.org/10.1002/lary.30619">https://doi.org/10.1002/lary.30619</a></p>
                        <p>Souza, S., &amp; Carolina, S. (2022, January 7). <em>Do behavioural and family-related factors influence the likelihood of meeting gestational weight gain recommendations, and can the smartmoms canada application assist with weight gain management and improve behaviours during pregnancy?</em> Ruor.uottawa.ca. <a href="https://ruor.uottawa.ca/items/40a0acaf-82f1-4288-bd41-867d47b9a3cc">https://ruor.uottawa.ca/items/40a0acaf-82f1-4288-bd41-867d47b9a3cc</a></p>
                        <p>Sutherland, J. R., Conway, A., &amp; Sanderson, E. L. (2020). Preprocedural assessment for patients anticipating sedation. <em>Current Anesthesiology Reports</em>, <em>10</em>(1), 35&ndash;42. <a href="https://doi.org/10.1007/s40140-020-00368-8">https://doi.org/10.1007/s40140-020-00368-8</a></p>
                        <p>Unger, S. (2023, June 1). <em>Analysis of respiratory time series data for breathing discomfort detection prior to sleep onset during APAP therapy</em>. Dspace.mit.edu. <a href="https://dspace.mit.edu/handle/1721.1/151711">https://dspace.mit.edu/handle/1721.1/151711</a></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Nursfpx9904assessment2
