import React from 'react'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sfaqs from './components/Sfaqs';
import { FaRegHandPointRight } from "react-icons/fa";
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";

const Service = () => {

    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Nursing Writers",
            Desc: "Hire our professional Capella flexpath rn-bsn writers. Enjoy high-scoring nursing papers!",
        },
        {
            icon: "fa-solid fa-graduation-cap icon",
            title: "Classes Help",
            Desc: "We provide comprehensive Rn-bsn Capella flexpath classes to help Capella students!",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Courses Help",
            Desc: "Seek help from academic professionals in your Capella RN to BSN flexpath courses.",
        },
        {
            icon: "fa-solid fa-file-shield icon",
            title: "Assessment Help",
            Desc: "Get high-scoring Capella rn-bsn flexpath assessment solutions written by expert writers!",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the requirements and details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "You can make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our intellectuals will contact you and assist you in your coursework!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% A+ Grades",
            Desc: "We have a team of Capella rn-bsn flexpath mentors to get you an A+.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We are available around the clock to assist you in your coursework!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free and unique content to our students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek comprehensive guidance from Capella Flexpath rn-bsn experts.",
        },
    ]

    return (
        <>
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
                    <div className='sherotop-left'>
                        <span className='stop'>Capella flexpath rn-bsn classes help</span>
                        <h1>ARE YOU LOOKING <br />FOR FLEXPATH COURSE HELP?</h1>
                        <p>We have a team of academic professionals to help you in your Capella RN to BSN flexpath courses!</p>
                        <ul>
                            <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                            <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                            <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                            <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                        </ul>
                        <div className='ocs2-banner-capella'>
                            <div className='ocs2-banner-capella-path'>
                                <span>
                                    <h3 className='f-path-pointer'>Flex Path</h3>
                                    <p>Use your experience to complete courses.</p>
                                </span>
                            </div>
                            <div className='ocs2-banner-capella-path'>
                                <span>
                                    <h3 className='f-path-pointer'>Guided Path</h3>
                                    <p>Enjoy the flexibility to study anywhere.</p>
                                </span>
                            </div>
                        </div>
                        <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                        <div className='ocs2-ratting'>
                            <div className='ocs2-ratting-provider'>
                                <SiTrustpilot className='ocs2-trustpilot-icon' />
                                <div>
                                    <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                    <p>TrustPilot</p>
                                </div>
                            </div>
                            <div className='ocs2-ratting-provider'>
                                <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                <div>
                                    <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                    <p>OnlineCourseServices</p>
                                </div>
                            </div>
                            <div className='ocs2-ratting-provider'>
                                <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                <div>
                                    <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                    <p>Sitejabber</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cal-box'><Calculator /></div>
                </div> */}

                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>CAPELLA FLEXPATH RN-BSN CLASSES HELP</span><br />
                                </h1>
                                <h2>ARE YOU LOOKING FOR FLEXPATH COURSE HELP?</h2>
                            <p className='ocs2-topText'>We have a team of academic professionals to help you in your Capella RN to BSN flexpath courses!</p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Expert</h2>
                            <p>We have an ocean of intellectuals to assist you professionally in your coursework.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Get Free Assignments</h2>
                            <p>We have a team of skilled Capella RN-BSN flexpath writers to help you!</p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET CAPELLA REFERENCE PAPERS</h2>
                <p>Online course services have the world’s best writers to help you in your Capella RN-BSN FlexPath Courses.</p>

                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Prestigious Services</span>
                    <h2>CAPELLA FLEXPATH RN-BSN WRITING SERVICES</h2>
                    <p>Online course services are the world’s best platform to offer excellent and high-quality writing
                        services for Capella rn-bsn flexpath students. We have a pool of academic professionals,
                        including tutors, writers and mentors. Our intellectuals assist the students in their Capella RN to
                        BSN flexpath courses. Students receive comprehensive help in their online classes,
                        coursework, exams and assessments. Students enjoy our high-scoring Rn-bsn flexpath
                        assessment solutions.</p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>RN-BSN Capella Flexpath Classes Help Capella Students</strong></Tab>
                        <Tab><strong>We Cover All Types Of Capella RN To BSN Flexpath Courses</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p>Online Course Services provides the best class-taking services to the students of the Capella
                            BSN program. We have an ocean of academic professionals to help you in your Capella RN to
                            BSN flexpath courses. Our tutors will take your online classes in Capella Flexpath programs on
                            your behalf. Our RN-BSN Flexpath writers will deliver the best solutions for your nursing
                            assessments, exams, quizzes and nursing papers.</p>
                    </TabPanel>
                    <TabPanel>
                        <p>We are honoured to offer nursing students the best RN-BSN flexpath course help. We have a
                            track record of assisting students comprehensively. Our students mostly score A+ grades in
                            their courses. The reason is the uncompromising efforts of our highly educated and experienced
                            intellectuals. They are always ready to assist you in your courses and online classes of Capella
                            Flexpath programs. Experience a positive change in your academic performance by getting help
                            from nursing professionals.</p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>It is effortless to hire our experts. We help comprehensive Rn-bsn Capella flexpath classes for Capella students.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Features</span>
                    <h2>WHY CHOOSE US?</h2>
                    <p>Online Course Services offers the best classes to help the students of Capella University. We
                        have great teams of intellectuals who are experts in the Capella Flexpath rn-bsn program.
                        Our professional Capella rn-bsn flexpath writers have helped millions of nursing students in
                        their Capella rn to bsn flexpath courses. We are also well known for providing complete
                        assistance in Rn-bsn flexpath courses. We will handle your online assignments, coursework,
                        academic papers, and more! Seek help from the best professionals in Capella Flexpath
                        programs. So, what are you waiting for? Seek A+ grades in all your courses in the Capella BSN
                        program!</p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Sfaqs />
        </>
    )
}

export default Service
