import React from 'react'
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";



const Faqs = () => {
    return (
        <>
        <Container>
            <div className='ocs2-faqs'>
                <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                <div className='ocs2-faqs-boxes'>
                    <div className='accordian-box-image'>
                        <img src='images/faqs.png' alt='' />
                    </div>

                    <Accordion className='accordian-boxes'>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Can I get assistance in Capella University flex path courses?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                    Yes, sure. We have a team of Capella DNP program-qualified professionals to take yourcourses   on   your   behalf.   We   follow   a   particular   methodology   to   complete   your   coursecomponents on your behalf. We have a team of highly experienced Capella University flexpathtutors, mentors, and writers to assist you. They will take your classes and courses for you. Theywill provide you with exceptional assessment solutions.
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Can I get assessment solutions for Capella University RN-BSN flexpath courses?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                    Yes, Absolutely! We offer the best and high-scoring assessment solutions to nursing students.Seek comprehensive nursing assessment help from Capella Flexpath nursing writers. We willprovide you with complete and well-researched assessment solutions. We will provide you withthe most experienced and professional writers. Our help guarantees 100% A+ grades in yourcourses.
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Can I hire a Capella University flex path nursing writer?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Yes, absolutely. We have a pool of highly experienced writers for all Capella University Flexpath degree programs. They are PhD-qualified writers having experience in their fields. They are well-versed in the Capella DNP Flexpath curriculum. You are welcome to seek help from professional writers for your future.
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Can you offer discounted services for Capella Flexpath nursing students?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Yes! We offer discounted services for Capella University Flexpath students. You can avail of our wide range of academic help services at amazing discounts. We offer one free service within a bundle   of   discounted   services.   We   provide   academic   help   to   nursing   students   of   all backgrounds, regardless of their locations.                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Can you take my Capella Flexpath RN-BSN course?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Yes! We have a team of highly skilled and qualified intellectuals to take your online courses on your behalf. We have an exceptional staff to handle your Capella RN-BSN flex path courses for you! They will provide comprehensive guidance in your coursework assessments, classes, quizzes, and exams. We are ready to take your online courses for your excellent academic performance.
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Can you take my Capella RN-BSN flexpath classes?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>
                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Yes! We offer comprehensive help to nursing students in their online classes. We have a team of skilled tutors to take your courses on your behalf. They will handle class discussions, assignments, activities, daily quizzes, and attendance. You only have to submit the daily task from the comfort of your home. We will take care of the rest.                               </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Can you take my Capella RN-MSN flexpath exams?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Sure! We offer exam-taking services on behalf of students. We have a team of intellectual tutors and writers. They will assist you in your online exams. We also guide students in their exam preparation. Our mentors will provide you with a particular schedule for quick revisions. We offer flexible exam-taking services for students with good results.                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Can I get urgent Nursing assessment help from professionals?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Yes! We provide exceptional nursing assessment help to nursing students. Our professional capella flex path nursing writers know the requirements and specific university standards. Our nursing assessment solutions are enough to delight your professors. Our writers will compel them to give you the most memorable high scores.                            
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Can I get a Capella MSN flexpath dissertation solution?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                You can get a top-rated and outclass dissertation solution written by experts. We have a pool of professional capella university flexpath nursing students to help you. They will provide a fantastic solution that will compel your professors to give you an A+ grade. Get a well-researched and comprehensive Capella MSN flexpath dissertation solution.                                </div>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            </Container>
        </>
    )
}

export default Faqs
