import React from 'react'
import { Container } from 'react-bootstrap'
import { FiTarget } from 'react-icons/fi'
import { LuBookCopy, LuClock4, LuFolderLock } from 'react-icons/lu'

const Bsnfeatures = () => {
  return (
    <div className='bsn-features'>
      <Container>
        <div className='bsn-features-detail'>
            <h2>Why Choose Us</h2>
            <p>Choose us to experience success in your nursing journey.  Our expert nursing professionals are dedicated to offer personalized, 24/7 support for your academic success. Let us guide you on your path towards academic excellence!</p>
        </div>
        <div className='bsn-features-list'>
            <div className='ocs2-single-feature'><span><LuBookCopy className='ocs2-features-icon'/></span><span className='ocs2-feature-icon-title'>Uncompromising Quality</span></div>
            <div className='ocs2-single-feature'><span><LuClock4 className='ocs2-features-icon'/></span><span className='ocs2-feature-icon-title'>Timely Delivery</span></div>
            <div className='ocs2-single-feature'><span><FiTarget className='ocs2-features-icon'/></span><span className='ocs2-feature-icon-title'>Client-Centric Approach</span></div>
            <div className='ocs2-single-feature'><span><LuFolderLock className='ocs2-features-icon'/></span><span className='ocs2-feature-icon-title'>Your Privacy Matters</span></div>
        </div>
      </Container>
    </div>
  )
}

export default Bsnfeatures
