import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";

const Capellauniversityhelp = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Research Writers",
            Desc: "Get help from skilled Capella DNP FlexPath research writers. They are always ready for help.",
        },
        {
            icon: "fa-solid fa-school icon",
            title: "Class Help",
            Desc: "Our tutors offer Capella University DNP FlexPath class-taking services for Capella DNP FlexPath students!",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Courses Help",
            Desc: "Take help from Capella DNP FlexPath professionals in their Capella DNP FlexPath courses.",
        },
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Tutoring Help",
            Desc: "Take help from our tutors, who are Capella DNP FlexPath Curriculum professionals. ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a Capella DNP FlexPath intellectuals team to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We can always help you with your Capella DNP FlexPath Curriculum and coursework!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to Capella University DNP FlexPath students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek complete support from Capella University DNP FlexPath nursing experts.",
        },
    ]
  return (
    <>
    <div className='s-hero services'>
        {/* <div className='s-hero-top'>
    <div className='sherotop-left'>
    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
    </div>
    <div className='cal-box'><Calculator/></div> 
</div> */}
        <div className='ocs2-main-box'>

            <div className='ocs2-main' id='hero'>
                <div className='ocs2-mainHeading'>
                    <h1><span className='ocs2-topHeading'>DO MY COURSE FOR ME: CAPELLA UNIVERSITY HELP</span><br /></h1>
                       <h2>ARE YOU WILLING TO PAY SOMEONE TO<br/> DO YOUR ONLINE COURSE?</h2> 
                    <p className='ocs2-topText'>The professionals and experts at Online Course Services are ready to help you throughout your course.</p>
                    <ul>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                    </ul>
                    <div className='ocs2-banner-capella'>
                        <div className='ocs2-banner-capella-path'>
                            <span>
                                <h3 className='f-path-pointer'>Flex Path</h3>
                                <p>Use your experience to complete courses.</p>
                            </span>
                        </div>
                        <div className='ocs2-banner-capella-path'>
                            <span>
                                <h3 className='f-path-pointer'>Guided Path</h3>
                                <p>Enjoy the flexibility to study anywhere.</p>
                            </span>
                        </div>
                    </div>
                    <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                    <div className='ocs2-ratting'>
                        <div className='ocs2-ratting-provider'>
                            <SiTrustpilot className='ocs2-trustpilot-icon' />
                            <div>
                                <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                <p>TrustPilot</p>
                            </div>
                        </div>
                        <div className='ocs2-ratting-provider'>
                            <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                            <div>
                                <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                <p>OnlineCourseServices</p>
                            </div>
                        </div>
                        <div className='ocs2-ratting-provider'>
                            <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                            <div>
                                <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                <p>Sitejabber</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='ocs2-form'>
                    <Calculator />
                </div>
            </div>

        </div>
        <div className='s-cta'>
            <div className='s-cta-inner'>
                <div className='s-cta-inner-box'>
                    <h2>Hire Professionals</h2>
                    <p>Take help from professional tutors, mentors, and writers to excel in your Capella University DNP FlexPath coursework.</p>
                </div>
                <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
            </div>
            <div className='s-cta-inner'>
                <div className='s-cta-inner-box'>
                    <h2>Get Nursing Papers</h2>
                    <p>Get excellent and well-researched Capella DNP FlexPath nursing papers!</p>
                </div>
                <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
            </div>
        </div>
    </div>


    <div className='freesamples services'>
        <h2>GET CAPELLA DNP FLEXPATH ASSESSMENT SOLUTIONS!</h2>
        <p>We provide excellent Capella University DNP FlexPath assessment solutions for students in their Capella DNP FlexPath Courses.</p>
        <div className='sampleboxes'>
            {newSamples.map((sample) => {
                return (

                    <div className='singlebox'>
                        <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                        <div className='text'>
                            <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                            <p>{sample.description}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>


    <div className='services s-section'>
        <div className='s-left'>
            {newServices.map((newserivce) => {
                return (
                    <div className='s-single'>
                        <i class={newserivce.icon}></i>
                        <h3>{newserivce.title}</h3>
                        <p>{newserivce.Desc}</p>
                    </div>
                )
            })}
        </div>
        <div className='s-right'>
            <span>Our Educational Services</span>
            <h2>CAPELLA UNIVERSITY COURSE ASSISTING SERVICES</h2>
            <p>Are you stressed about your Capella University course and willing to pay someone to do an online course? Online course service is here to assist you. We have a team of professionals and experts who are committed to helping Capella University students in their academic courses. Our mentors and intellectuals will guide you throughout the course and assist you in getting good grades in your course.</p>
            <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
        </div>
    </div>

    <div className='services cta'>
        <Calltoaction />
    </div>
    <div className='services'>
        <Tabs>
            <TabList>
                <Tab><strong>Providing Excellence in Capella University Course</strong></Tab>
                <Tab><strong>Online Course Services will provide you with assistance in all courses</strong></Tab>
            </TabList>

            <TabPanel>
                <p>Are you a Capella University student and finding some for “ take my online nursing class?” Our Online Course Services team is here to help you with all your academic courses whether they include assignments, presentations, case studies, dissertation assessments, or anything related to your course. You can tackle all the challenges and difficulties in Capella University Courses with the assistance of our intellectuals. <br /> <br />
                    <strong>Flexible Learning Solutions</strong><br />
                    Are you looking to “pay someone to do online course”? We at Online Course Services understand that everyone has a different schedule and different challenges. Our intellectuals will provide you with solutions in academics and manage your schedule for the best results. They will provide you with complete assistance throughout your course to fulfill your academic needs and make you able to manage all academic challenges confidently. <br /><br />
                  
                </p>
            </TabPanel>
            <TabPanel>
                <p>Our team is comprised of professionals in all Capella University Courses. Our professionals help students perform great in their academic courses. Our professionals set the learning standards so high for you that you can be a top student in class and also get high scores on exams. If you need someone who can assist you with “Do my Course for me”. Look no further. Online course services are always here to help you.</p>
            </TabPanel>
        </Tabs>
    </div>

    <div className='process services freesamples'>
        <h2>HOW IT WORKS?</h2>
        <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
        <div className='processboxes'>
            {orderprocess.map((order) => {
                return (

                    <div className='singleprocess'>
                        <i class={order.icon}></i>
                        <h3>{order.title}</h3>
                        <p>{order.Desc}</p>
                    </div>
                )
            })}
        </div>
    </div>


    <div className='services s-section'>
        <div className='s-right'>
            <span>Our Features</span>
            <h2>WHY CHOOSE ONLINE COURSE SERVICES FOR YOUR COURSE</h2>
            <p>Online Course services assist in every course for Capella University Students. Our team of intellectuals and mentors is dedicated to assisting you with all your academic courses. We have a track record of helping millions of students at Capella University. Our professionals will help you with everything related to your course whether it is your assessments, quizzes, academic papers, case studies, or presentations. You can avail of our services for all your concerns in just one click.</p>
            <div><button>Hire Writer</button></div>
        </div>
        <div className='s-left'>
            {newFeatures.map((newserivce) => {
                return (
                    <div className='s-single'>
                        <i class={newserivce.icon}></i>
                        <h3>{newserivce.title}</h3>
                        <p>{newserivce.Desc}</p>
                    </div>
                )
            })}
        </div>
    </div>
    <Stestimonials />
    <Container>
        <div className='ocs2-faqs'>
            <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
            <div className='ocs2-faqs-boxes'>
                <div className='accordian-box-image'>
                    <img src='images/faqs.png' alt='' />
                </div>

                <Accordion className='accordian-boxes'>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>For Capella University students what services do Online Course Services offer?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            We at Online Course Services help Capella University students with their presentations, academic tasks, and assignments. Their expert team helps the students to make sure they are the best in their courses.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>How do the Online Course Services for Capella University help course workload management?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            We provide students with solutions and also help them with their schedules.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>For Capella University courses why choose Online Course Services?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Because they offer support for all courses ensure success and provide support for every type of work.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Can Capella University students depend on Online Course Services?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            With a history of assisting millions of students, we cover every aspect of the course.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Are Online Course Services personalized to individual student needs?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Yes, personalized assistance is provided by the Online Course Services to every student so that support is ensured for quizzes and papers.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Is it ethical for the Capella University courses to seek assistance from Online Course Services?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>
                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Yes, it is acceptable. But the academic integrity is also important. Online Course Services gives importance to the ethical guidelines while supporting the students.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                   
                </Accordion>
            </div>
        </div>
    </Container>
</>

  )
}

export default Capellauniversityhelp
