import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import { Helmet } from 'react-helmet';

const Register = () => {

  const [inputs, setInputs] = useState({
    username:"",
    email:"",
    password:"",
  })
  const [err, setError] = useState(null)

  const navigate =useNavigate()

  const handleChange = e =>{
    setInputs(prev=>({...prev, [e.target.name]: e.target.value}))
  }

  const handleSubmit = async e =>{
    e.preventDefault()
    try{
      await axios.post("/auth/register", inputs)
      navigate("/login");
  
    }catch(err){
      setError(err.response.data);
    }
    
  }
  return (
    <>
    <div className='ocs2-login-register'>
    <Helmet>
      <title>Register</title>
    </Helmet>
      <Container>
        <div className='ocs2-login-register-auth'>
        <h1>Register</h1>
        <form>
          <input required type='text' placeholder='username' name='username' onChange={handleChange}/>
          <input required type='email' placeholder='email' name='email' onChange={handleChange}/>
          <input required type='password' placeholder='password' name='password' onChange={handleChange}/>
          <button onClick={handleSubmit}>Register</button>
          {err && <p>{err}</p>}
          <span>Do you have an account?
            <Link to='/login' className='link'> Login</Link>
          </span>
        </form>
        </div>
      </Container>
    </div>
    </>
  )
}

export default Register
