import React from 'react'
import Container from 'react-bootstrap/esm/Container';
import Carousel from 'react-grid-carousel';
import { FaStar, FaRegArrowAltCircleRight } from "react-icons/fa";
import { Link } from 'react-router-dom';


const satestimonials = () => {
  return (
    <>
    <Container>
      <div className='ocs2-testimonials' id='testimonial'>
        <h2><span>LISTEN TO</span><strong> WHAT OUR SATISFIED STUDENTS SAY<br/>ABOUT US</strong></h2>
        <div className='ocs2-testimpnials-list'>
        <Carousel cols={3} rows={1} gap={25} hideArrow={true} showDots={true} autoplay={5000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
            <Carousel.Item>
                <img className='ocs2-img' src='images/usericon.png' alt=''/>
                <div className='ocs2-user-card'>
                    <p className='c-id'>Student ID: #432</p>
                    <hr/>
                    <span className='ocs2-user-card-icon'><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/></span>
                    <p><span>Writer: </span><strong>Alina</strong></p>
                    <h3>Nursing Assessment</h3>
                    <p className='ocs2-user-card-details'>The tutors were beneficial in my Capella's FlexPath program. They provided splendid tutoring sessions! I graduated feeling empowered and prepared to tackle new challenges in the nursing area.</p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
            <img className='ocs2-img' src='images/usericon.png' alt=''/>
                <div className='ocs2-user-card'>
                    <p className='c-id'>Student ID: #452</p>
                    <hr/>
                    <span className='ocs2-user-card-icon'><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/></span>
                    <p><span>Writer: </span><strong>Michael</strong></p>
                    <h3>Nursing Paper</h3>
                    <p className='ocs2-user-card-details'>I am thankful to the team of online course services for providing comprehensive help. They 
enabled me to pass Capella's RN to BSN FlexPath degree with 90% marks. </p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
            <img className='ocs2-img' src='images/usericon.png' alt=''/>
                <div className='ocs2-user-card'>
                    <p className='c-id'>Student ID: #632</p>
                    <hr/>
                    <span className='ocs2-user-card-icon'><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/></span>
                    <p><span>Writer: </span><strong>James</strong></p>
                    <h3>Nursing Thesis</h3>
                    <p className='ocs2-user-card-details'>I managed to pass Capella's FlexPath program with excellent grades. It was possible due to 
the help of their RN to BSN FlexPath writers. </p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
            <img className='ocs2-img' src='images/usericon.png' alt=''/>
                <div className='ocs2-user-card'>
                    <p className='c-id'>Student ID: #535</p>
                    <hr/>
                    <span className='ocs2-user-card-icon'><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/></span>
                    <p><span>Writer: </span><strong>Viviane Sousa</strong></p>
                    <h3>Capstone Project</h3>
                    <p className='ocs2-user-card-details'>I am very happy to receive 95% grades in my Nursing Capstone Project. I am very thankful to the team of Capella Flexpath nursing writers.</p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
            <img className='ocs2-img' src='images/usericon.png' alt=''/>
                <div className='ocs2-user-card'>
                    <p className='c-id'>Student ID: #574</p>
                    <hr/>
                    <span className='ocs2-user-card-icon'><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/></span>
                    <p><span>Writer: </span><strong>Leslie Alexander</strong></p>
                    <h3>Research Paper</h3>
                    <p className='ocs2-user-card-details'>Online Course Services provide the best research paper writing services for Capella University flex path students. I am happy to get 98% marks on my research paper.</p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
            <img className='ocs2-img' src='images/usericon.png' alt=''/>
                <div className='ocs2-user-card'>
                    <p className='c-id'>Student ID: #956</p>
                    <hr/>
                    <span className='ocs2-user-card-icon'><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/></span>
                    <p><span>Writer: </span><strong>Robert Fox</strong></p>
                    <h3>Dissertation</h3>
                    <p className='ocs2-user-card-details'>I am thankful to Capella MSN Flex path writers for helping me with my dissertation. Online Course Services is the best platform for nursing students. I recommend their academic help services to everyone!</p>
                </div>
            </Carousel.Item>
        </Carousel>
        <div className='ocs2-testimonials-btn'>
           <Link to={"/order"} className='ocs2-orderNow-btnn'> <button className='ocs2-orderNow'>Order Now &nbsp;<FaRegArrowAltCircleRight/></button></Link>
        </div>
        </div>
      </div>
      </Container>
    </>
  )
}

export default satestimonials
