import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import { Helmet } from 'react-helmet'

const Terms = () => {
  return (
    <div className='terms-main'>
      <Helmet>
      <title>Terms And Conditions</title>
    </Helmet>
      <Container>
        <h2>Terms and Conditions</h2>
        <p>
        We are wholeheartedly committed to ensuring the highest level of customer satisfaction. Our services are meticulously tailored to align with the unique requirements and preferences of our valued users.  
        </p>
        <strong>Revision Policies:</strong>
        <p>
        Your security is of paramount importance to us. To safeguard your personal information and the details pertaining to your orders, we have prepared this notification outlining our online data protection protocols. This notification also empowers you with choices concerning the collection and utilization of your information.
        <br/>1st Revision: Completed within 24 hours
        <br/>2nd Revision: Completed within 48 hours
        <br/>3rd Revision (and onwards): Completed within 72 hours
        </p>
        <strong>Urgent Revisions:</strong>
        <p>
        In instances where urgent revisions are required, a nominal adjustment fee applies. This fee accounts for the urgency of your request, as our experts may not always be immediately available.
        </p>
        <strong>Cancellation and Refund Policy:</strong>
        <p>We provide a full refund of your payment under the following circumstances:</p>
        <ul>
            <li>Double Payment</li>
            <li>Identical Order</li>
            <li>Expert Unavailability</li>
        </ul>
        <p>There may be situations where you receive a partial refund instead of a complete reimbursement. In line with our cancellation policy, the following conditions apply to cancellation requests submitted by our customers:</p>
        <ul>
            <li>You will receive a 50% refund of your order amount if an expert has been assigned to your order.</li>
            <li>You will receive a 90% refund of your order amount if no expert has been assigned to your order.</li>
            <li>You will receive a 95% refund of your order amount if your order details are missing and you cancel your order immediately after placing it.</li>
        </ul>
        <strong>Disclaimer:</strong>
        <p>Our custom writing services are purposefully designed for study and research purposes. We do not endorse the direct submission of our content to institutions,
organizations, or similar entities that prohibit the use of custom writing services. The information provided on third-party platforms and our website serves purely
informational purposes. Some sections may not offer precise details, as their purpose is to enhance visitors' and users' understanding of the services offered
by our website. We hold the utmost regard for client satisfaction and consistently exceed expectations through our outstanding compensation plans, discount
opportunities, and complimentary revision services.</p>
      </Container>
    </div>
  )
}

export default Terms
