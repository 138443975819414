import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";

const Capellaflexpathassisstance = () => {
  const newSamples = [
    {
        image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
        title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
        description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
        samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
    },
    {
        image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
        title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
        description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
        samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
    },
    {
        image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
        title: "NURS FPX 4060 Assessment 2 Community Resources...",
        description: "Community ResourcesPublic health systems and community resources are critical in...",
        samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
    },
    {
        image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
        title: "NURS FPX 6016 Assessment 1...",
        description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
        samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
    }

]

const newServices = [
    {
        icon: "fa-solid fa-user-pen icon",
        title: "Research Writers",
        Desc: "Get help from skilled Capella DNP FlexPath research writers. They are always ready for help.",
    },
    {
        icon: "fa-solid fa-school icon",
        title: "Class Help",
        Desc: "Our tutors offer Capella University DNP FlexPath class-taking services for Capella DNP FlexPath students!",
    },
    {
        icon: "fa-solid fa-book-open icon",
        title: "Courses Help",
        Desc: "Take help from Capella DNP FlexPath professionals in their Capella DNP FlexPath courses.",
    },
    {
        icon: "fa-solid fa-chalkboard-user icon",
        title: "Tutoring Help",
        Desc: "Take help from our tutors, who are Capella DNP FlexPath Curriculum professionals. ",
    },
]

const orderprocess = [
    {
        icon: "fa-solid fa-chalkboard-user icon",
        title: "Provide Guidelines",
        Desc: "Fill out the form with all the details.",
    },
    {
        icon: "fa-regular fa-money-bill-1 icon",
        title: "Make Payment",
        Desc: "Make your payment through a credit card and bank account.",
    },
    {
        icon: "fa-solid fa-person-chalkboard icon",
        title: "Get experts",
        Desc: "Our writers will contact you and assist you in your assessments!",
    },
]

const newFeatures = [
    {
        icon: "fa-solid fa-arrow-trend-up icon",
        title: "100% High Scores",
        Desc: "We have a Capella DNP FlexPath intellectuals team to help you get high scores.",
    },
    {
        icon: "fa-solid fa-clock-rotate-left icon",
        title: "24/7 Help",
        Desc: "We can always help you with your Capella DNP FlexPath Curriculum and coursework!",
    },
    {
        icon: "fa-solid fa-check-double icon",
        title: "No Plagiarism",
        Desc: "We offer plagiarism-free assessments to Capella University DNP FlexPath students.",
    },
    {
        icon: "fa-solid fa-person-chalkboard icon",
        title: "Best Guidance",
        Desc: "Seek complete support from Capella University DNP FlexPath nursing experts.",
    },
]
  return (
    <>
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
                    <div className='sherotop-left'>
                    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
                    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
                    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
                    </div>
                    <div className='cal-box'><Calculator/></div> 
                </div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>NEED ASSISTANCE WITH <br />CAPELLA FLEXPATH NURSING?</span><br />
                                </h1>
                                <h2>GET GREAT HELP WITH NURSING ASSESSMENTS</h2>
                            <p className='ocs2-topText'>We at online course services are assisting in assessments of Capella Flexpath nursing with our team of professional writers.</p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Professionals</h2>
                            <p>Take help from professional tutors, mentors, and writers to excel in your Capella University DNP FlexPath coursework.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Get Nursing Papers</h2>
                            <p>Get excellent and well-researched Capella DNP FlexPath nursing papers!</p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET CAPELLA DNP FLEXPATH ASSESSMENT SOLUTIONS!</h2>
                <p>We provide excellent Capella University DNP FlexPath assessment solutions for students in their Capella DNP FlexPath Courses.</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Educational Services</span>
                    <h2>CAPELLA UNIVERSITY FLEXPATH NURSING WRITING SERVICES</h2>
                    <p>Our team of professional writers at online course services is dedicated to providing you with high-quality assistance with Capella University RN to MSN and Flexpath nursing writing. We have an expert team of academic writers, tutors, and mentors. Our mentors extensively support students with their exams, coursework, tests, and evaluations. The students of Capella MSN value our high-scoring Capella Flex Path nursing writing solutions. We provide detailed Capella University nursing informatics to assist the students.</p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>FlexPath Nursing Services to Help Capella MSN Students</strong></Tab>
                        <Tab><strong>Comprehensive FlexPath Writing Services</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p>At Online Course Services, we recognize that each student has his own Capella FlexPath writing requirements. Our team of professionals helps to provide cutting-edge services that are designed to suit the requirements of Capella University FlexPath students with Capella msn. We will help you with Capella FlexPath research, scholarly writing, and tutoring sessions of nursing. You can achieve your professional goals by overcoming barriers with FlexPath educational help services with Capella University RN to MSN and Capella University nursing informatics.
                            </p>
                    </TabPanel>
                    <TabPanel>
                        <p>Online Course Services offers a wide range of Capella University FlexPath educational help services with Capella University RN to MSN. Or intellectuals and mentors aim to guide you throughout your Capella FlexPath path. We will help you with Capella FlexPath Curriculum writing and studies by providing Capella University Nursing informatics. Contact us to learn more about how we can assist you in your journey to the Capella University FlexPath program. </p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Features</span>
                    <h2>WHY CHOOSE ONLINE COURSE SERVICES?</h2>
                    <p>We provide the best writing solutions for the students of Capella University. We have an expert team of writers to assist you in every written task related to nursing. Our intellectuals have helped millions of students in their writing assessments. Whether you need to write online assessments, quizzes, or academic papers, online course services are here to offer you the best writing services. You can get our services in all your courses in just one click with Capella msn.</p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>What is Capella University FlexPath Nursing?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Capella University FlexPath Nursing is a program that is specifically designed for nursing students who want assistance in their education. Students can make significant progress at their own pace as a self-paced learning environment is provided. 
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>How can Online Course Services assist with the Capella FlexPath nursing assignments?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    The experienced team of writers provides professional assistance for the Capella University FlexPath nursing. For nursing students, they provide support for multiple tasks such as tests, exams, and coursework.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>For Capella FlexPath nursing writing why should I choose the Online Course Services?  </h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Online Course Services has a dedicated team that provides writing services for the Capella University Students. They have a history of helping students to achieve success whether it’s in online assessments, quizzes, or papers.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>What is the feedback of the students regarding the services of the Online Course Services?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    During their time in the Capella’s FlexPath nursing programs, students have provided feedback that provides reviews about the support that they received from the Online Course Services.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>In student success, how Capella FlexPath nursing writers are playing their role?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    The major role in student success is played by the Capella FlexPath nursing writers who are from the Online Course Services as they provide support for multiple tasks such as research papers, nursing projects, and many more.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>For FlexPath programs at Capella University, are online course services recommended for nursing students?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>
                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes, the online course services have been proven good for helping nursing students and providing academic support. 
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            

                        </Accordion>
                    </div>
                </div>
            </Container>
        </>
  )
}

export default Capellaflexpathassisstance
