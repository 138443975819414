import React from 'react'
import Phdpsyhero from './Phdpsycomponent/Phdpsyhero'
import Phdpsydegreedetail from './Phdpsycomponent/Phdpsydegreedetail'
import Bsnfeatures from '../Nursing/bsnpagecomponent/Bsnfeatures'
import Bannertwo from '../../homepage/Bannertwo'
import Bsnfaqs from '../Nursing/bsnpagecomponent/Bsnfaqs'

const Phdpsyc = () => {
  return (
    <div>
      <Phdpsyhero/>
      <Phdpsydegreedetail/>
      <Bsnfeatures/>
      <Bannertwo/>
      <Bsnfaqs/>
    </div>
  )
}

export default Phdpsyc
