import React from 'react'
import Msclinicalhero from './Msclinicalcomponent/Msclinicalhero'
import Msclinicaldegreedetail from './Msclinicalcomponent/Msclinicaldegreedetail'
import Bsnfeatures from '../Nursing/bsnpagecomponent/Bsnfeatures'
import Bannertwo from '../../homepage/Bannertwo'
import Bsnfaqs from '../Nursing/bsnpagecomponent/Bsnfaqs'

const Msclinical = () => {
  return (
    <div>
      <Msclinicalhero/>
      <Msclinicaldegreedetail/>
      <Bsnfeatures/>
      <Bannertwo/>
      <Bsnfaqs/>
    </div>
  )
}

export default Msclinical
