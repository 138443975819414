import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import { Helmet } from 'react-helmet'

const Privacypolicy = () => {
  return (
    <div className='terms-main'>
      <Helmet>
      <title>Privacy Policy</title>
    </Helmet>
      <Container>
        <h2>Privacy Policy</h2>
        <p>We kindly request your thorough review of these policies and encourage you to reach out if you have any uncertainties about specific clauses.</p>
        <strong>Committed to Safeguarding Your Privacy:</strong>
        <p>We prioritize your security above all else. To protect your personal information
        and the details associated with your orders, we present this notice outlining our
        online data protection procedures. It also offers you choices regarding how we
        collect and utilize your information.</p>
        <strong>Information Collection Procedures:</strong>
        <p>The information you willingly provide about yourself when placing an order for
        our services is used with care and consideration. Your identifiable details are
        requested   for   service-related   purposes,   such   as   subscribing   to   newsletters,
        availing our services, receiving periodic emails, and staying informed about
        discount offers.<br/>
        We adhere to the principle of not sharing your data with third parties unless you
        explicitly grant permission. Typically, we only share necessary information with
        our team members who are directly involved in providing you with the requested
        services. In such instances, your data is shared solely with the person assigned
        to your specific task.</p>
        <strong>Required Information:</strong>
        <p>This provision pertains to all essential information collected or provided on this
        website. Our website enables you to place service orders, submit requests, and
        register for updates. This information encompasses:</p>
        <p>Name<br/>
        Address<br/>
        Email ID<br/>
        Phone number<br/>
        Credit/debit card data</p>
        <strong>Use of "Cookies":</strong>
        <p>
        We employ cookies to gather information about website interactions and traffic,
        enhancing the website experience for our clients. You have the option to disable
        cookies through your browser settings. Please be assured that we do not collect
        personal information through cookies.
        </p>
        <strong>Your Consent:</strong>
        <p>We may offer you the opportunity to participate in challenges hosted on our
        website. Should you choose to take part, we will request specific details from
        you. This requested information includes contact details (such as the customer's
        name and billing address) and demographic data (including zip code).
        <br/>For more details, please don't hesitate to initiate a chat, contact us via phone, or
        send us an email.</p>
      </Container>
    </div>
  )
}

export default Privacypolicy
