import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Mbafpx5005assessment1 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>MBA FPX 5005 Assessment 1</title>
                <meta name='description'
                    content='Excel in business management with MBA FPX 5005 Assessment 1. Develop core competencies in strategic decision-making' />
                <meta name='keywords' content='MBA FPX 5005 Assessment 1' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>MBA FPX 5005 Assessment 1< br /><span>Protecting Human Research Participants</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/MBAFPX5005Assessment1.png" alt="" />
                    <div className='main-container-sl-content'>
                        <h2>Protecting Human Research Participants</h2>
                        <p>The involvement of human participants in research is critical in developing new knowledge while at the same time protecting the volture of participants involved in the research. Among the professions, the nursing profession is particularly involved in the obligation to guarantee that practices includes in the research procedures are conducted in compliance with ethical and legal norms. This responsibility is derived from involving historical evolution of research ethics which emphasize on the rights of subjects, safety of the subjects and then issue of Informed consent.</p>
                        <p>The historical development of research ethics shows that it began with extreme ethical abuses like those that came out in the Nuremberg trials to formulate specific principles that would protect people from such wrongdoings in the future (Smith, 2021). Solemn documents like Nuremberg Code and Declaration of Helsinki provided basic principles of ethical research due to which the issues of voluntary participation and informed consent appeared. Based on these principles, the Belmont Report provided foundational norms with the contemporary ethical principles, namely respect for persons, benefiting the others, and fairness (Jones &amp; Lee, 2022).</p>
                        <p>It is a moral and legal imperative to follow these ethical principles. Guidelines by the (U. S. Department of Health &amp; Human Services, 2021) and HIPAA offer standards for protection of the individuals participating in research activities. An example of organizational framework that support these principles is Institutional Review Boards (IRBs) that examine research proposals to identify their conformity to ethical standards and respect participants&rsquo; rights.</p>
                        <h2>History and Importance of Human Subjects Protection</h2>
                        <p>The issue of human participants as research subjects has a histories and an ethical importance which dated back in the pass incidents that proved the need to protect participants involved in research. The protection of human subjects in the process of research dates back to as early as 1947 after the trial of the Nazi doctors who conducted dreadful experiments on human subjects during world war two and the formulation of what is referred to as the Nuremberg Code. These codes which were instrumental in working on benchmarks for ethical research entailed voluntary consent and least harm (Smith, 2021).</p>
                        <p>The principles have been built on and developed over the years, firstly with the more extensive Declaration of Helsinki, brought in for 1964, discussing risk benefit and review by an ethical committee (U. S. Department of Health &amp; Human Services, 2021). These concepts were further developed in the Belmont Report of 1979 that identified the main ethical principles of respect for persons, beneficence and justice which are still considered key principles in ethical research at the current time (Jones &amp; Lee, 2022). It is thus noteworthy that these protections are well-designed for the purpose of preserving participants&rsquo; rights as well as preventing research that would be ethically questionable at best and perverse and abusive at worst. Reflecting on the nature of research, it is essential to acknowledge that as the methodological approaches and technologies are developing, new issues that require ethical regulation appear, for instance, the issues of digital privacy and the necessity to protect vulnerable populations in research call for further adjustments in ethical knowledge (Smith, 2021). The changes of these protections demonstrate a movement to enhance the uncompromising ethical standards for today&rsquo;s scientific research while incorporating core aspects of the earlier guidelines.</p>
                        <h2>Types of Research Activities</h2>
                        <p>Human subject research can be systematically divided into several types depending in the nature and goals of the study. One must understand clinical trials before delving into other human subject research studies; arguably, clinical trials are the most recognized type of human subjects&rsquo; research studies. These trials compare new drugs, treatments or interventions in order to measure their effectiveness as well as the side effects they may have. For example, Phase III clinical trial might include people taking a new anticancer drug to determine effectiveness over other available treatments and toxicities (Smith, 2021). Another kind of research involving human subjects is observational research in which the researcher records data, but does not influence any variables. An example of such study is the cohort study where the investigators sample a population and follow them for long to establish the impacts of aspects such as diet on health status. For instance, a cohort study can include tracing of the intake of dietary of a particular group of people with the aim of finding the relationship of diet with cardiovascular disease.</p>
                        <h3><strong>MBA FPX 5005 Assessment 1 Protecting Human Research Participants</strong></h3>
                        <p>Quantitative research on the other hand involves collection of simple, mainly non-numerical information from participants to capture their or their groups&rsquo; experiences, actions or feelings. This type of research makes use of methods such as interview or focus group. For instance, the research question for a qualitative study can look like cross-sectional interviews with chronic illness patients on pain management and the resultant quality of life (U. S. Department of Health &amp; Human Services, 2021). Behavioral research is a type of research that is concerned with the actions of human beings carried out through experimental or observational research. An example would be a comparison of behavioral outcomes of different therapies, for example, cognitive-behavioral therapy for patients who have affirmed anxiety disorders. Scientists may probit a sport therapy plan and assess participants&rsquo; adapt and study their changed anxiety levels after a certain period (Smith, 2021).</p>
                        <h2>Strategies to Minimize Potential Risks</h2>
                        <p>The welfare of participants in the research process is an extremely critical aspect that should be considered when conducting research hence the following strategies can be adopted to ensure the safety of the participants and at the same time not compromise the set research plan. Informed consent is one of the basic techniques that aims at making the participants fully aware with the consequences of participating in the study. Participants should receive detailed scientific information about the study&rsquo;s goal, methodology, possible adverse effects, and advantages and must be given adequate opportunity to make their decision (Smith, 2021). Measures like anonymization of data and use of confidentiality measures especially on participants are crucial to reduce risks. Data anonymisation involve the task of balancing between the perpetual removal of data that can be used to link up data to the participants this reduces the risk of personal information breaches (Jones &amp; Lee, 2022). Confidentiality measures and safe data storage causes also strengthen privacy measures in that they make sure that only persons with proper authorization can access certain information.</p>
                        <p>Reducing procedural risks means developing the-methods of research which would in some ways scarcely affect the bodily and psyche of subjects. For instance, choosing nonintrusive approaches like questionnaires and interviews instead of painful techniques including blood samples and tests, participants&rsquo; risk will be substantially minimized. In the same manner, this pilot tests the research design, the problems which may be experienced are first detected before they are taken to full-scale testing where they may cause further harm to the rest of the participants (U. S. Department of Health &amp; Human Services, 2021).</p>
                        <h2>Additional Protections</h2>
                        <p>It is for this reason that children, pregnant women, and people with cognitive impairment are known as vulnerable populations in research because they are especially sensitive to the risks of research. These are measures, which preserve their welfare as well as guarantee that proper research procedures are followed. Specific extra safeguards that apply to kids are consent from a parent or a legal guardian, as well as when relevant, the child&rsquo;s assent. This guarantees that the guardians are on the same page, or in some cases, the child&rsquo;s understanding is also taken into consideration (U. S. Department of Health &amp; Human Services, 2021).</p>
                        <p>The studies with children incorporate certain changes in the consent procedures and can be reviewed by the additional ethics committees based on children&rsquo;s possibility to be developmentally vulnerable and the need to make research non-harassing and appropriate for children. Specific accommodations are also considered necessary for pregnant women because of possible exposure to risks for both the unborn child and the mother. Any studies that include pregnant women must provide for the evaluation of possible effects on fetal development and on the health of the mother. This may include preforming risk-benefit assessments that are more compared to those for other participants who are not pregnant and researchers are required to observe extra precaution measures to guarantee safety for both mother and foetus (Smith, 2021). Studied investigating pregnant women may require additional assessment of participants&rsquo; health and clear explanation of any related risks for pregnancy.</p>
                        <p>Patients and clients with cognitive impairments are seen as vulnerable since they have an inability to consent to research studies. While doing research with such a population, it is also necessary to get consent from the person&rsquo;s legal guardian or other legally authorized person, and also include in the design of the research some measures of evaluating the person&rsquo;s understanding of the research and their voluntary consent (Jones &amp; Lee, 2022). It may entail minimizing the complexity of forms participants are required to complete in order to consent to being involved, as well as follow-ups and check-ups in the course of the research to guarantee participants&rsquo; rights and their wellbeing.</p>
                        <h2>Ethical Standards Applied in Research</h2>
                        <p>The populations that are considered vulnerable by the federal governance of research involve children, pregnant women, and persons with impaired decision-making capacity as they may be subjected to research associated risks easily. These are as measures that are meant to protect them as well as to promote ethical research. Some extra applicable measures include parents&rsquo; consent for kids&rsquo; participation and, whenever possible, kids&rsquo; assent. This can guarantee that the guardians are well-informed and can also respect the opinion of the child the as and the level of comprehension the child has (U. S. Department of Health &amp; Human Services, 2021).</p>
                        <p>It is common to adjust the consent procedures in the research with children and, sometimes, extra ethical approval is necessary to take into consideration the potential developmental issues of the participants and to make sure that the methodology of the study is not going to be too invasive for the participants. Women who are pregnant also need protection especially in a bid to prohibit any activity that might have a negative impact both to the mother as well as the fetus. Investigations to begin on women of child bearing age must how accommodate measures for evaluating effects on fetuses and the pregnant woman. It sometimes requires the balancing of risks and benefits which is higher than those of other participants, and thus, more monitoring procedures are needed to avoid adverse effects on pregnant subjects (Smith, 2021). Health monitoring may have to be more often than usually in preparatory women and the research design may have to incorporate information concerning the risks arising from the research.</p>
                        <p>The patients and clients who have cognitive impairments are generally considered as vulnerable owing to their inability to provide consent coherently. Writing Creek et al. (2010) mentioned that research involving this population requires the permission of the legally authorized persons and then check various aspects the research design to ensure understanding and the voluntary part of the participants. This might require, for example, pre-coding of the consent forms, as well as optimal support and supervision during the entire process of the researchers&rsquo; work in order to guarantee maximum safety of the rights and well-being of the participants.</p>
                        <h2>MBA FPX 5005 Assessment 1&nbsp;Conclusion</h2>
                        <p>In conclusion, the theme of protection of human research participants is considered to be one of the most important principles of ethical research conduct, which has undergone qualitative changes in the process of its development. The evolution of ethical principles in the provision of clinical practices has richly been articulated in literature from the Nuremberg Code right to the Belmont report; principles that have upheld the safety, dignity, and rights of the participants. Principles such as respect for persons, beneficence and justice serve as ideas that can assist the researcher in the choice of the study design to maximize on the benefits to the subjects as well as enhance on the generation of knowledge. To embrace ethical standards, practices aimed at mitigating risks include informing the participants, anonymizing data, and adjusting the research method to the participants&rsquo; needs, including those who belong to vulnerable populations. The increasing sophistication of practices may create new problems, whereas the abovementioned principles of research ethics ensure the participants&rsquo; protection and maintain trust is nontrivial. Through following those ethical principles and constantly improving the designs of researches, the researchers contribute to the development of the knowledge while protecting the individuals&rsquo; rights and dignity.</p>
                        <p><strong><em>If you need complete information about class 5006, click below to view a related sample:&nbsp;</em></strong></p>
                        <p><a href="https://onlinecourseservices.us/free-samples/mba-fpx-5006-assessment-1-strategic-process-and-analysis/"><em>MBA FPX 5006 Assessment 1 Strategic Process and Analysis</em></a></p>
                        <p><a href="https://onlinecourseservices.us/free-samples/mba-fpx-5006-assessment-2-business-strategy/"><em>MBA FPX 5006 Assessment 2 Business Strategy</em></a></p>
                        <p><a href="https://onlinecourseservices.us/free-samples/mba-fpx-5006-assessment-3-strategy-implementation/"><em>MBA FPX 5006 Assessment 3 Strategy Implementation</em></a></p>
                        <h1><strong>References</strong></h1>
                        <p>Brothers, K. B., Rivera, S. M., Cadigan, R. J., Sharp, R. R., &amp; Goldenberg, A. J. (2019). A belmont reboot: Building a normative foundation for human research in the 21st century.&nbsp;<em>The Journal of Law, Medicine &amp; Ethics</em>,&nbsp;<em>47</em>(1), 165&ndash;172.</p>
                        <p><a href="https://doi.org/10.1177/1073110519840497">https://doi.org/10.1177/1073110519840497</a></p>
                        <p>Delgado, D., Wyss Quintana, F., Perez, G., Sosa Liprandi, A., Ponte-Negretti, C., Mendoza, I., &amp; Baranchuk, A. (2020). Personal safety during the COVID-19 pandemic: Realities and perspectives of healthcare workers in latin america.&nbsp;<em>International Journal of Environmental Research and Public Health</em>,&nbsp;<em>17</em>(8), 2798.</p>
                        <p><a href="https://doi.org/10.3390/ijerph17082798">https://doi.org/10.3390/ijerph17082798</a></p>
                        <p>Huria, T., Palmer, S. C., Pitama, S., Beckert, L., Lacey, C., Ewen, S., &amp; Smith, L. T. (2019). Consolidated criteria for strengthening reporting of health research involving indigenous peoples: The CONSIDER statement.&nbsp;<em>BMC Medical Research Methodology</em>,&nbsp;<em>19</em>(1).</p>
                        <p><a href="https://doi.org/10.1186/s12874-019-0815-8">https://doi.org/10.1186/s12874-019-0815-8</a></p>
                        <p>Murdoch, B. (2021). Privacy and artificial intelligence: Challenges for protecting health information in a new era.&nbsp;<em>BMC Medical Ethics</em>,&nbsp;<em>22</em>(1).</p>
                        <p><a href="https://doi.org/10.1186/s12910-021-00687-3">https://doi.org/10.1186/s12910-021-00687-3</a></p>
                        <p>Nebeker, C., Torous, J., &amp; Bartlett Ellis, R. J. (2019). Building the case for actionable ethics in digital health research supported by artificial intelligence.&nbsp;<em>BMC Medicine</em>,&nbsp;<em>17</em>(1).</p>
                        <p><a href="https://doi.org/10.1186/s12916-019-1377-7">https://doi.org/10.1186/s12916-019-1377-7</a></p>
                        <p>Radonovich, L. J., Simberkoff, M. S., Bessesen, M. T., Brown, A. C., Cummings, D. A. T., Gaydos, C. A., Los, J. G., Krosche, A. E., Gibert, C. L., Gorse, G. J., Nyquist, A.-C., Reich, N. G., Rodriguez-Barradas, M. C., Price, C. S., Perl, T. M., &amp; ResPECT investigators. (2019). N95 respirators vs medical masks for preventing influenza among health care personnel: A randomized clinical trial.&nbsp;<em>JAMA</em>,&nbsp;<em>322</em>(9), 824&ndash;833.</p>
                        <p><a href="https://doi.org/10.1001/jama.2019.11645">https://doi.org/10.1001/jama.2019.11645</a></p>
                        <p>Resnik, D. B. (2019). Citizen scientists as human subjects: Ethical issues.&nbsp;<em>Citizen Science: Theory and Practice</em>,&nbsp;<em>4</em>(1).</p>
                        <p><a href="https://doi.org/10.5334/cstp.150">https://doi.org/10.5334/cstp.150</a></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Mbafpx5005assessment1
