import React from 'react'
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";



const Sfaqs = () => {
    return (
        <>
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Do you offer RN-BSN Capella flex path classes help for Capella students?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We offer comprehensive guidance to nursing students by taking their classes on their
                                        behalf.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I hire a writer for my Capella Flexpath RN-BSN assessments?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We have excellent nursing writers to help you with high-scoring content assessments.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I get Tutoring sessions for Capella RN-BSN flexpath courses?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We are offering comprehensive tutoring sessions for nursing students. Our intellectual
                                        tutors are ready to help you!
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you do my Capella RN to BSN flex path courses for me?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We are offering comprehensive guidance to students in their courses. Take help from our
                                        skilled tutors, writers and mentors. They will assist you in your online Capella Flex path courses
                                        with guaranteed success.                                </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you help me with my RN-BSN flexpath exams?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We have a team of highly educated experts to help you with your exams on your behalf.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you help me in exam preparation for my Capella Flexpath RN-BSN courses?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>
                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We have a team of capable tutors to help you prepare for exams.                               </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Do you write a thesis for Capella Flexpath programs?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We provide flawless and high-quality thesis solutions to nursing students. It will bring
                                        compliments from your professors.                                </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I get a personal tutor for my Capella BSN program?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Yes! We offer personal tutors for students' convenience at a fantastic discount.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>

                        </Accordion>
                    </div>
                </div>
            </Container>    
        </>
    )
}
export default Sfaqs
