import React from 'react'
import Bsnfaqs from './bsnpagecomponent/Bsnfaqs'
import Bannerone from '../../homepage/Bannerone'
import Bsnfeatures from './bsnpagecomponent/Bsnfeatures'
import Dnpdegreedetail from './dnppagecomponent/Dnpdegreedetail'
import Dnphero from './dnppagecomponent/Dnphero'
const Dnp = () => {
  return (
    <>
    <Dnphero/>
    <Dnpdegreedetail/>
    <Bsnfeatures/>
    <Bannerone/>
    <Bsnfaqs/>
    </>
  )
}

export default Dnp
