import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/satestimonials';
import Callta from './components/Callta';
import Calculator from '../../homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";
import { Helmet } from 'react-helmet';

const Nursfpx4060 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Research Writers",
            Desc: "Get help from skilled Capella BSN FlexPath research writers. They are always ready for help.",
        },
        {
            icon: "fa-solid fa-school icon",
            title: "Class Help",
            Desc: "Our tutors offer Capella University BSN FlexPath class-taking services for Capella BSN FlexPath students!",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Courses Help",
            Desc: "Take help from Capella BSN FlexPath professionals in their Capella BSN FlexPath courses.",
        },
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Tutoring Help",
            Desc: "Take help from our tutors, who are Capella BSN FlexPath Curriculum professionals. ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a Capella BSN FlexPath intellectuals team to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We can always help you with your Capella BSN FlexPath Curriculum and coursework!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to Capella University BSN FlexPath students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek complete support from Capella University BSN FlexPath nursing experts.",
        },
    ]
    return (
        <>
            <Helmet>
                <title>NURS FPX 4060 Assessment 3 Disaster Recovery Plan</title>
                <meta name='description'
                    content='Explore key strategies in NURS FPX 4060 Assessment 3 for crafting a robust Disaster Recovery Plan in healthcare settings. Contact us now' />
                <meta name='keywords' content='NURS FPX 4060 Assessment 3, NURS FPX 4060 Assessment 3 Disaster Recovery Plan' />
            </Helmet>
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
<div className='sherotop-left'>
<span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
<h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
<p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
</div>
<div className='cal-box'><Calculator/></div> 
</div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>NURS FPX 4060 ASSESSMENT 3 <br />DISASTER RECOVERY PLAN</span></h1>
                            <p className='ocs2-topText'>Having attained the position of a senior nurse at Carterdale Regional Hospital, today I face the stark reality of leading our community in tackling healthcare issues. </p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Professionals</h2>
                            <p>Take help from professional tutors, mentors, and writers to excel in your Capella University BSN FlexPath coursework.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Get Nursing Papers</h2>
                            <p>Get excellent and well-researched Capella BSN FlexPath nursing papers!</p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET CAPELLA BSN FLEXPATH ASSESSMENT SOLUTIONS!</h2>
                <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Top-Notch Services</span>
                    <h2>THE BEST BSN FLEXPATH ASSESSMENT SERVICES</h2>
                    <p>
                        <ul>
                            <b>
                                <li>Distinguished Grade Promised</li>
                                <li>Unlimited Revisions</li>
                                <li>100 Plagiarism Free</li>
                                <li>Professional Support</li>
                                <li>On Time Delivery</li>
                                <li>Reliable One Billing Cycle</li>
                            </b>

                        </ul>
                    </p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Callta />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><h2>NURS FPX 4060 Assessment 3 Disaster Recovery Plan</h2></Tab>

                    </TabList>

                    <TabPanel>
                        <p>
                            <h3>Disaster Recovery Plan</h3><br />
                            Having attained the position of a senior nurse at Carterdale Regional Hospital, today I face the stark reality of leading our community in tackling healthcare issues. As a hospital along with the entire community, the town of Carterdale had to battle challenges we had never encountered before in the wake of the destructive tornado. The EF4 on the Enhanced Fujita scale, followed a trail of devastation, directly affecting the residents and testing the strength of the healthcare education system. From this point, immediate response is essential for the relief of catastrophe needs but long-term planning for a thorough disaster recovery plan becomes significant also. This recovery plan will also be crucial in providing actions towards rebuilding our communities and preparing for future unexpected natural disasters (Smith, 2020). We wish to build a plan following the MAP-IT model and collaborate with all the stakeholders in community for the disaster recovery to suit the specific needs of the Carterdale area. This model is designed around the key pillars: the engagement of individuals and organizations, needs and resources evaluation, well-developed strategic plan, effective implementation and the results measurement.
                            <br /><br />
                            <h3>Health, Cultural, Social, and Economic Barriers</h3><br />
                            The determinants of health latitude extends relatively wide, as it covers various factors shaping the health and well-being of individuals and the community at large (WHO, 2019). These factors go together into 5 biological, behavioural, social, economic, and environmental dimensions (CDC (Centers for Disease Control and Prevention), 2020). Biological factors take into account predisposing genes, age and other tidal conditions that can influence whether one is likely to be sick or get injured (Gates et al., 2021). Participation in lifestyle characteristics like diet, exercise, drug use and the acceptance of health warnings might influence a misfortune or good health outcome (Smith & Johnson, 2020).
                            <br />
                            Social determinants of health such as socioeconomic status, education, housing, and access to healthcare play a very important role in the community’s understanding, recovery effort, and safety. The NIH (2022) notes their impact accordingly. There are greater hurdles for individuals from marginalized or disadvantaged backgrounds to access healthcare, safe lodging, and disaster recovery services, than people from privileged communities do (Robertson et al., 2019). Health factors also considerably play an influencing part in the beliefs, ethics and trends within the community (Jones & Lee, 2021). Cultural norms and values bring about varying risk perceptions, attitudes to healthcare usage, and their preference for home remedies (Brown & Garcia, 2020).
                            <br />
                            Socio-economical determinants, which comprise inequality in income, access to job opportunities, as well as insurance coverage, can only make the inequalities in safety, health, and disaster distribution even worse (Anderson & Williams, 2019). Shortage of resources in the communities will prevent them from getting over the disasters as their infrastructure proves to be inadequate, social support systems are not strong enough and the disparities found in the access to healthcare services may affect their recovery (Thomas et al., 2023). These factors directly or indirectly connect, indicating their complex and multifaced relationships (Choi et al., 2020). To illustrate, workers with lower socioeconomic status can experience more exposure to environmental hazards like stinking from pollution or mistreatment at work making them prone to accidents and illness to a larger extent (Jackson & Patel, 2022). It is also possible that the lack of healthcare services is causing delayed diagnoses and treatments, which in turn is making the healthcare disparities in the community even more severe.
                            <br /><br />
                            <h3>Proposed Disaster Recovery Plan</h3><br />
                            The damage recovery plan for Carterdale is aimed at becoming a starting point to ease the health disparities and to increase the living standards during emergencies (World Health Organization [WHO], 2020). The strategy uses the foundation of social justice and cultural sensitivity to deal with the deep-rooted causes, such as health determinants, that contribute to health disparities in the community (ANA, 2021; IOM, 2020). Using focused interventions and collaboration efforts, the plan calls for making sure that the vulnerable groups, which comprises such people as low-income citizens, ethnic and racial minorities, and individuals with any disability, benefit equally from the provision of healthcare, social assistance, and other necessary services. Subsequently, the plan acts to recognize and value the customs and norms that characterize our community, thus uplifting the folks and families involved as they strive to mend their lives (American Public Health Association [APHA], 2022). Through its commitment to health equity, the disaster recovery plan aims to build a more resilient and warm-hearted community that understands and appreciates the fact that people’s welfare is the ultimate aim, and hence all the residents must have the opportunity to thrive.
                            <br /><br />
                            <h3>Health and Governmental Policy</h3><br />
                            Being a national or local authority; policies regulating health and government play an important role in recovery after a disaster, determining through which mechanism the resources will be allocated, who will be responsible for them and how the affected communities will receive essential services. They allocate the resources in a way that funding, personnel and equipment are well distributed. They are very essential for a swift response and business recovery (Federal Emergency Management Agency [FEMA], 2020). Additionally, they provide frameworks for inter-agency collaboration including respective entities that are usually tasked with meeting the different needs of disaster victims (2019). Hence, harmonized policies that engage with different levels and sectors are the essence of making sure that resources are optimized and collaboration is fostered (U.S. Department of Health and Human Services).
                            <br />

                            Health policy also plays a crucial role in providing people with healthcare services throughout the disaster cycle that comprehend pre-, during, and post-disaster phases (American Hospital Association [AHA], 2022). They determine the quantity and quality of medical services, medications, and mental health assistance that are available, and indirectly, that affect a patient`s way of recovering from physical and psychological wounds. Various policies such as insurance coverage, Medicaid expansion and reimbursement rates are immensely engaged in assuring the affordability of healthcare for the population during crises as demonstrated by the (CMS 2019). Next, policies about social services, including housing assistance, benefits, and unemployment, become indispensable in that they provide such support at that moment.
                            <br /> <br />
                            <h3>Evidence-Based Strategies to Overcome Communication Barriers and Enhance Interprofessional Collaboration</h3><br />
                            It is critical to ensure that evidence-based measures that correct the linguistic barriers and help to support interprofessional collaboration are implemented to be able to optimize disaster recovery. Clear communication codes that facilitate easy information sharing between all those parties involved in disaster recovery procedures should be drafted. Conventional formats of meetings, and also digital platforms, result in the coordination and exchange of information that exists between healthcare providers, emergency responders, and community organizations (Lurie et al., 2020). what is more, interdisciplinary instruction and strategic application of the simulation exercises are of utmost importance for boosting the cohesion and communication skills of team players who represent different professions. Studies have proven that simulation-based training personifies decision-making and cohesion of teamwork through the scenarios of a simulated disaster for which one gets to practice (Sjölqvist et al, 2020). Application of technology encompassing telemedicine and mobile health apps/applications will facilitate real-time sharing of information, communication, and resources in health within resource-constraints contexts (Kvedar et al., 2020). As well, fostering the execution of cultural competence and diversity training creates understanding and inclusivity in the team members, key events that are necessary for effective communication among culturally diverse communities (Lie et al., 2020). These strategic proofs have not only helped in increasing disaster recovery efficiency and effectiveness but also patient safety, social justice, and community resilience and preparedness.<br /><br />
                        </p>
                    </TabPanel>

                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <h2>NURS FPX 4060 Assessment 3 Disaster Recovery Plan</h2>
                    <h3>CONCLUSION</h3>
                    <p>
                        In conclusion, the design and execution of a holistic reconstruction strategy for Carterdale is not only important to reduce the effects of catastrophes, but also to build resilience and availability of vital resources and services in every inhabiting citizen. Based on the MAP-IT model and ensuring social justice combined with cultural sensitivity, the proposed strategy is aimed at engaging all stakeholders, conducting a community study, drawing a strategic plan, performing specific activities, and monitoring the development over time. This strategic plan intends to understand and solve communication issues and boost interprofessional collaboration. Consequently, the plan will enhance the disaster response and recovery measures, which will in turn result in strengthening the individuals and communities involved in disasters. By focusing on the deserts that involve health disparities, community services, and the poor, our plan puts together the work to do with the objectives of promoting equity in health and the development of a caring, strong population. Continuing into the future, creating a multi-stakeholder collaboration should be the first need. A proactive response approach with explicit backing from the planners or leaders of the recovery effort may be the link to getting things done and could be the future step in saving the residents of Carterdale‘s health and safety when another disaster comes up.
                    </p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>REFERENCES</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/ref.jpg' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}> Finucane, M. L., Acosta, J., Wicker, A., & Whipkey, K. (2020). </h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        Short-Term solutions to a long-term challenge: Rethinking disaster recovery planning to reduce vulnerabilities and inequities. International Journal of Environmental Research and Public Health, 17(2), 482.
                                        <br />
                                        <a href="https://doi.org/10.3390/ijerph17020482">https://doi.org/10.3390/ijerph17020482</a>
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Hilton, S. T. (2022).</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Using the pandemic as a driver for innovation in research. Nature Reviews Methods Primers, 2(1), 1–2.
                                    <br />
                                        <a href=" https://doi.org/10.1038/s43586-022-00106-w"> https://doi.org/10.1038/s43586-022-00106-w</a>
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Lucie, P., & Brian, B. (2021a).</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    COVID-19 and the generation of novel scientific knowledge: Evaluating and reporting novel scientific knowledge. Journal of Evaluation in Clinical Practice, 27(3), 694–707
                                    <br />
                                        <a href="  https://philpapers.org/rec/LUCCAT-2">  https://philpapers.org/rec/LUCCAT-2</a>
                                    
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Lucie, P., & Brian, B. (2021b).</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Covid-19 and the generation of novel scientific knowledge: Research questions and study designs. Journal of Evaluation in Clinical Practice, 27(3), 708–715.
                                    <br />
                                        <a href=" https://philpapers.org/rec/LUCCAT">   https://philpapers.org/rec/LUCCAT</a>
                                    
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Rouhanizadeh, B., Kermanshachi, S., & Nipa, T. J. (2020).</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Exploratory analysis of barriers to effective post-disaster recovery. International Journal of Disaster Risk Reduction, 50(1).
                                    <br />
                                        <a href="https://doi.org/10.1016/j.ijdrr.2020.101735">https://doi.org/10.1016/j.ijdrr.2020.101735</a>
                                    
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Sheykhmousa, M., Kerle, N., Kuffer, M., & Ghaffarian, S. (2019).</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Post-disaster recovery assessment with machine learning-derived land cover and land use information. Remote Sensing, 11(10), 1174.
                                    <br />
                                        <a href="https://doi.org/10.3390/rs11101174">https://doi.org/10.3390/rs11101174</a>
                                    
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Nursfpx4060
