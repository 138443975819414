import React, { useState, useEffect } from 'react'


const Calculator = () => {

    // const scriptURL = 'https://script.google.com/macros/s/AKfycbwbP_L4P2bs6HdQCtkMDVtiovdK7iFsr0UQ2DLiaojJJj0hvS1pk7Ii6PpwrdPT3LVD3w/exec'
    // const form = document.forms['leadform']

    // const [uname, setUname] = useState("")
    // const [email, setEmail] = useState("")
    // const [phone, setPhone] = useState("")
    // const [subject, setSubject] = useState("")

    // const handleSubmit=(e)=>{
    //   e.preventDefault()
    //   fetch(scriptURL, { method: 'POST', body: new FormData(form)})
    // 	.then(alert("Thank you! your form is submitted successfully." ))
    // 	// .then(() => {  window.location.reload(); })
    //   .then(setUname(""), setEmail(""), setPhone(""), setSubject(""))
    // 	.catch(error => console.error('Error!', error.message))
    // }

    const [modal, setModal] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setModal(true);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    const toggleModal = () => {
        setModal(false);
    }

    const [formData, setFormData] = useState({
        userName: "",
        userEmail: "",
        userPhone: "",
        userSubject: "",
        ip: "",
    })

    const getIp = async () => {
        const response = await fetch('https://api.ipify.org');
        const data = await response.text();
        setFormData({ ...formData, ip: data })
    }
    useEffect(() => {
        getIp()
    }, [])

    const [buttonText, setButtonText] = useState('Proceed');

    const sendData = async (e) => {
        e.preventDefault()
        if (formData.userName === "" || formData.userEmail === "" || formData.userPhone === "" || formData.userSubject === "") {
            alert("please fill all fields correctly")
        }
        else {
            const res = await fetch("https://slack.onlinecourseservices.us/formdata", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }, body: JSON.stringify({
                    formData
                })
            })

            console.log(res);

            const data = await res.json();
            if (data.status === "error") {
                console.log("error")
            } else {
                setButtonText(buttonText === 'Proceed' ? 'Submitted Successfully' : 'Proceed');
                setTimeout(function () {
                    setButtonText("Proceed");
                    toggleModal();
                }, 1500);
                setFormData({ ...formData, userName: "", userEmail: "", userPhone: "", userSubject: "" })
            }
        }

    }


    return (
        <>
            <div className='sample-form-outerbox'>
                <div className='sidebar-top'>
                    <p className='discount-desc'>You're progressing on the path to ultimate achievement.</p>

                </div>
                <div className='sidebar-bottom'>
                    <p className='bottom-text'>Guaranteed Distinguish grades !</p>
                    <form name='leadform' className='contact-form' method='post' action=''>

                        <input type='text' placeholder='Full Name*' id='u-name' name='name' value={formData.userName} onChange={(e) => setFormData({ ...formData, userName: e.target.value })} />

                        <input type='email' placeholder='Email*' id='u-email' name='email' value={formData.userEmail} onChange={(e) => setFormData({ ...formData, userEmail: e.target.value })} />

                        <input type='text' placeholder='Phone Number*' id='u-number' name='phone' value={formData.userPhone} onChange={(e) => setFormData({ ...formData, userPhone: e.target.value })} />

                        <input type='text' placeholder='What do you need help with?' id='u-subject' name='subject' value={formData.userSubject} onChange={(e) => setFormData({ ...formData, userSubject: e.target.value })} />
                        <button type='submit' onClick={sendData}>{buttonText}</button>
                    </form>
                </div>
            </div>
            {modal && (
                <div className='the-pop-up'>
                    <div className='tpu-box'>
                        <p className='bottom-text'>Please fill the following to resume reading</p>
                        <form name='leadform' className='contact-form' method='post' action=''>

                            <input type='text' placeholder='Full Name*' id='p-name' name='name' value={formData.userName} onChange={(e) => setFormData({ ...formData, userName: e.target.value })} />

                            <div className='tpu-info'>Please enter your correct contact information to view the sample paper</div>

                            <input type='email' placeholder='Email*' id='p-email' name='email' value={formData.userEmail} onChange={(e) => setFormData({ ...formData, userEmail: e.target.value })} />

                            <input type='text' placeholder='Phone Number*' id='p-number' name='phone' value={formData.userPhone} onChange={(e) => setFormData({ ...formData, userPhone: e.target.value })} />

                            <input type='text' placeholder='What do you need help with?' id='p-subject' name='subject' value={formData.userSubject} onChange={(e) => setFormData({ ...formData, userSubject: e.target.value })} />

                            <div className='tpu-info'>Please enter correct phone number and email address to receive OTP on your phone & email.</div>

                            <button type='submit' onClick={sendData}>{buttonText}</button>

                            <div className='tpu-binfo'>Verification is necessary to avoid bots.</div>
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}

export default Calculator
