import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { GiBookmarklet } from "react-icons/gi";
import { Link } from 'react-router-dom';


const Sidebar = ({cat}) => {

  const [posts, setPosts] = useState([]);

  useEffect(()=>{
    const fetchData = async ()=>{
      try{
        const res = await axios.get(`/posts/?cat=${cat}`)
        setPosts(res.data)
      }catch(err){
        console.log(err)
      }
    };
    fetchData()
  }, [cat]);

  // const posts = [
  //   {
  //     id: 1,
  //     title: "Lorem Ipsum is simply dummy text",
  //     desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //     img: "images/post.png",
  //   },
  //   {
  //     id: 2,
  //     title: "Lorem Ipsum is simply dummy text",
  //     desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //     img: "images/post.png",
  //   },
  //   {
  //     id: 3,
  //     title: "Lorem Ipsum is simply dummy text",
  //     desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //     img: "images/post.png",
  //   },
  //   {
  //     id: 4,
  //     title: "Lorem Ipsum is simply dummy text",
  //     desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  //     img: "images/post.png",
  //   },
  // ]

  const scriptURL = 'https://script.google.com/macros/s/AKfycbwbP_L4P2bs6HdQCtkMDVtiovdK7iFsr0UQ2DLiaojJJj0hvS1pk7Ii6PpwrdPT3LVD3w/exec'
			const form = document.forms['sidebarForm']

      const [uname, setUname] = useState("")
      const [phone, setPhone] = useState("")
      const [email, setEmail] = useState("")
      

		  
			const handleSubmit=(e)=>{
			  e.preventDefault()
			  fetch(scriptURL, { method: 'POST', body: new FormData(form)})
				.then(alert("Thank you! your form is submitted successfully." ))
        .then(setUname(""), setEmail(""), setPhone(""))
				.catch(error => console.error('Error!', error.message))
			}


  return (
    <div>
      <div className='postlist'>
        <h2>Other posts you may like</h2>
        {posts.slice(0,4).reverse().map(post=>(
          <div className='lpost' key={post.id}>
           <span><GiBookmarklet className='openbook'/></span>
            <h3>{post.title}</h3>
            <Link to={`/post/${post.id}`} className='read-more'><p>Read More</p></Link>
          </div>
        ))}
      </div>


      <form className='sidebarform' method='post' action='' onSubmit={handleSubmit} name='sidebarForm'>
        <h3 className='formheading'>Get 15% Discount Today</h3>
        <input type='text' placeholder='Name' name='u_Name' value={uname} onChange={(e)=>setUname(e.target.value)}/>
        <input type='text' placeholder='Phone' name='u_Number' value={phone} onChange={(e)=>setPhone(e.target.value)}/>
        <input type='email' placeholder='Email' name='u_Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
        <button type='submit'>Submit</button>
      </form>
    </div>
  )
}

export default Sidebar
