import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx9901 = () => {
  const newSamples = [
    {
      image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
      title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
      description: "A health promotion plan is a preventative effort to enhance the quality of ....",
      samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
    },
    {
      image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
      title: "NURS FPX 4060 Assessment 2 Community Resources",
      description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
      samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
    },
    {
      image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
      title: "NURS FPX 4060 Assessment 2 Community Resources",
      description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
      samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
    },
    {
      image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
      title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
      description: "A health promotion plan is a preventative effort to enhance the quality of ....",
      samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
    }

  ]

  return (
    <>
      <Helmet>
        <title>NURS FPX 9901 Assessment 3</title>
        <meta name='description'
          content='Discover essential details on NURS FPX 9901 Assessment 3, including criteria, objectives, and submission guidelines. Contact us now.' />
        <meta name='keywords' content='NURS FPX 9901 Assessment 3' />
      </Helmet>

      <div className='hero-section'>
        <div className='main-box'>
          <h1>NURS FPX 9901 Assessment 3:< br /><span> Doctoral Project Implementation Plan</span></h1>
          {/* <p>Hi. I’m (Student), and currently, I want to recommend in-service training that encourages the medical staff < br />to approach patient treatment more proactively. </p>
                    <div className='sample-hero-links'>
                        <a href="#">Online Course Services</a>
                        <a href="#">Free Samples</a>
                        <a href="#">Online Course Services</a>
                        <a href="#">Online Course Services</a>
                    </div> */}
        </div>
      </div>

      <div className='sample-main-container'>
        <div className='main-container-sl'>
          <img src="images/NURSFPX9901.png" alt="" />
          <div className='main-container-sl-content'>
            <p><strong>Name:</strong> Student</p>
            <p><strong>Organization:</strong> Mercy Hospital Integrated Stabilization Unit (ISU).</p>
            <p><strong>Date:</strong></p>
            <p><strong>Project Title:</strong> The C-SSRS and a comprehensive crisis response training program can therefore assist in enhancing mental health.</p>
            <p><strong>PICO or PICO(T) Question:</strong></p>
            <p>What is the difference between implementing a full crisis intervention program with the Columbia-Suicide Severity Rating Scale (C-SSRS) (I) and the current practice (C) for psychiatric nurses dealing with patients in crisis (P) on the 30-day readmission rates of these patients (O) in 12 weeks (T)?</p>
            <p><strong>Project Description</strong></p>
            <p>The QI project is to address the issue of patients being readmitted at the project site more often. Higher return rates were attributed to inadequate management of mental health issues. According to information obtained from the project site, the rate of return was between 27 percent as stated by Burnett Brenda on February 12, 2024. 6 and 28. 4 percent. The readmission rate at the project site was higher than that at the standard site, which was 13. 9 per 100 index admissions (AHRQ, n. d. ). The staff does not utilize effective measures on how to respond to mental health crisis in the current arrangement. This is because the staff is not trained in handling mental health issues and there are no crisis intervention plans or suicide risk assessment procedures in place (Personal Communication, Burnett Brenda, February 12, 2024) which result in high rates of returns. The plan is to employ an effective strategy, a comprehensive crisis intervention program, with the C-SSRS to assess suicide risk.</p>
            <p>The project was mostly about using the C-SSRS in a crisis response program. It is important to note that the rate of return in a healthcare setting is lower when a good crisis intervention program is employed (Gipson et al. 202). Crisis intervention training programs assist patients in managing mental health crises, thus reducing the likelihood of readmission to the hospital (Wang &amp; Gupta, 2020). It is also easier to address mental health needs of staff when they have been trained in crisis management. According to the Substance Abuse and Mental Health Services Administration (SAMHSA), in order to enhance the quality of care in mental health cases, staff should be trained on how to manage crisis (SAMHSA, n. d. ). In the words of Evans et al. (2023), applying the C-SSRS in the patient screening process also enhances the safety of patients and their health. As stated by Gipson et al. (2023), the C-SSRS also assists in determining the patient&rsquo;s suicide risk. This results in improved health outcomes and a lower rate of return. Applying the C-SSRS in the patient assessment process assists in identifying and managing the patients who are at risk of suicide, thus reducing the frequency of readmissions.</p>
            <p>With the help of C-SSRS and a crisis management intervention program, the aim is to reduce the readmission of mental health patients within 30 days at the project site. Training of staff in crisis management and the utilization of C-SSRS enable mental health patients to receive better care hence reducing the rate of readmission. Another project goal is the enhancement of C-SSRS, which assists in assessing the risk of suicide (Sreeram et al. , 2023). Thus, applying the efficient techniques for mental health issues should enhance the health and well-being of the patients.</p>
            <p>The aim of the project is to reduce the 30-day readmission rate of mental health patients by 10% in the project site. The first step is to have a plan on how to make changes that will improve the situation. In the second part, which is all about improving the quality of patient care, changes will be made. In the third step, the project results will be analyzed to determine the effectiveness of the solution. Lastly, modifications will be done on the planned plan so as to achieve the desired outcomes. This is because the PDSA model is simple and practical for this project as it is cyclical and understandable on a smaller scale.</p>
            <p>This project requires both quantitative and qualitative data, and there are numerous methods to collect information for this project. According to Adu et al. (2024), the quantitative data will consist of how often patients return, how well the staff adhere to the crisis intervention plan, and how the C-SSRS is incorporated into the patient assessment process. The qualitative data comprise of the views and experiences of the patients and healthcare workers on the intervention. An EHR system tracks information such as how many times the patient requires readmission, how compliant the patient is with the intervention plan, and how frequently the patient uses the C-SSRS. To determine the effectiveness of the solution, data will be collected before and after the event.</p>
            <p>In order to determine the effectiveness of the proposed solution, it is crucial to employ the appropriate data analysis technique. Quantitative data such as rates and figures can be used to describe the outcome of the project. The readmission rates from the pre-intervention and post-intervention periods will provide the numbers necessary to determine the effectiveness of the intervention (Wigal &amp; Tran, 2022). The more detailed analysis also reveals how many employees have C-SSRS and participate in the crisis intervention training. However, the feedback helps us understand the level of satisfaction the patient has with the solution, which can be quantified using progress reports.</p>
            <p>The project is related to nursing because mental nurses play a significant role in the assessment of patients. Crisis management programs and the use of C-SSRS assist psychiatric nurses in identifying the needs of a patient and reduce hospital readmissions. The staff will get the more information about the crisis management program and C-SSRS during the training classes under the guidance of a qualified teacher who is proficient in it (SAMSHA, n. d. ). As the leader of the team, I will ensure that everything is in order so that we can achieve the objectives we have set for ourselves.</p>
            <table className='table'>
              <thead>
                <tr>
                  <td>
                    <p><strong>Objective(s)</strong></p>
                  </td>
                  <td>
                    <p><strong>Key Action Step(s</strong></p>
                  </td>
                  <td>
                    <p><strong>Expected Outcome(s)</strong></p>
                  </td>
                  <td>
                    <p><strong>Data Evaluation and Measurement(s)</strong></p>
                  </td>
                  <td>
                    <p><strong>Person/Area Responsible(s)</strong></p>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>Implementing training sessions on crisis management to reduce customer returns.</p>
                  </td>
                  <td>
                    <p>Identify the practice gap at the project site that contributes to the difficulty of determining what the patients&rsquo; mental health needs are.</p>
                    <p>Select the nurses who will be directly involved in the care services</p>
                    <p>Adoption of measures to address the mental health care needs of patients (Gipson et al. , 2023).</p>
                  </td>
                  <td>
                    <p>Bring the rate of return down to 10% by ensuring that the right care is provided to the patients (Murray et al. , 2021).</p>
                  </td>
                  <td>
                    <p>We will employ descriptive data such as frequency to determine the difference in the return rate before and after the change (Owusu et al. , 2022).</p>
                  </td>
                  <td>
                    <p>S. P. , a qualified teacher, will conduct training classes regarding the enhancement of crisis management in the healthcare sector.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Include the use of C-SSRS to enhance the process of assessing patients for suicidal risk.</p>
                  </td>
                  <td>
                    <p>Identify the need for the right tools to assess the suicide risk</p>
                    <p>situational interviews to know the challenges that the nurses encounter when attempting to assess the risk of suicide.</p>
                    <p>For enhanced patient screening, C-SSRS is being employed (Sotoudeh et al. , 2020).</p>
                  </td>
                  <td>
                    <p>According to Evans et al. (2023), proper patient assessments can increase patient satisfaction and health outcomes by up to 90%.</p>
                  </td>
                  <td>
                    <p>For this purpose, we will use descriptive statistics to find out the extent to which the staff adhere to the C-SSRS protocol during the screening process (Bjureberg et al. , 2021).</p>
                  </td>
                  <td>
                    <p>A nurse, T. L. , who is a psychiatrist, will use the C-SSRS to determine the patients who have the highest risk of suicide.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Reduce the rates of readmissions by ensuring that the staff implement the crisis management plan effectively.</p>
                  </td>
                  <td>
                    <p>designing an effective training program to enhance healthcare crisis management</p>
                    <p>A competent trainer will be employed to facilitate the training (SAMSHA, n. d. ).</p>
                    <p>Emphasize that employees have to adhere to the training schedule.</p>
                  </td>
                  <td>
                    <p>The rate of return decreases by 10% if the staff implements the crisis management training well (Murray et al. , 2021).</p>
                  </td>
                  <td>
                    <p>In order to determine how well the staff adhered to the crisis management plan after the intervention, descriptive statistics will be employed (Sotoudeh et al. , 2020).</p>
                  </td>
                  <td>
                    <p>J. S. , the head of the nursing department, will ensure that the C-SSRS is used by the nursing team when conducting the assessment of the patient.&nbsp;</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Allocating the Budget</p>
                  </td>
                  <td>
                    <p>Identifying the tools that will be required in order to perform the task (Wong &amp; Headrick, 2020).</p>
                    <p>To ensure that the parties appreciate the importance of the idea in order to get their support for it.</p>
                  </td>
                  <td>
                    <p>An adequate fund will be provided to ensure that the job is completed effectively in 12 weeks (Wong &amp; Headrick, 2020).</p>
                  </td>
                  <td>
                    <p>According to Smith and Levy (2023), a budget analysis plan can assist you in getting the necessary tools to implement your plan.</p>
                  </td>
                  <td>
                    <p>F. C. The person in charge of administration will arrange the necessary tools required for the task.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Putting the project together</p>
                  </td>
                  <td>
                    <p>A detailed procedure on how to identify the needs of the project is outlined below.</p>
                    <p>State the objectives and expected outcomes of addressing the issues arising from the higher return rate.</p>
                    <p>Choosing the right structure to ensure that everything goes as planned (AHRQ, n. d. ).</p>
                  </td>
                  <td>
                    <p>Completing the project in 12 weeks to evaluate its effectiveness (Wong &amp; Headrick, 2020).</p>
                  </td>
                  <td>
                    <p>According to Jones et al. (2023), it is crucial to get feedback from the team to determine the growth and the success of a project.</p>
                  </td>
                  <td>
                    <p>In her position as the team leader, A. O. will be responsible for ensuring that the job is done with ease.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Getting and analyzing data</p>
                  </td>
                  <td>
                    <p>The plan for collecting the data will help get both quantitative and qualitative data.</p>
                    <p>The data collected is then stored in the correct system such as an electronic health record.</p>
                    <p>To determine the effectiveness of a strategy, statistical research is applied (Tayefi et al. , 2021).</p>
                  </td>
                  <td>
                    <p>For instance, in a mental health setting, the target is to reduce the patient&rsquo;s readmission rate to 10% (Murray et al. , 2021).</p>
                  </td>
                  <td>
                    <p>To compare the two groups in terms of the return rate in percentage, descriptive statistics will be used (Owusu et al. , 2022).</p>
                  </td>
                  <td>
                    <p>M. P. will be the data scientist who will be responsible for data analysis.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Spreading the word about the project</p>
                  </td>
                  <td>
                    <p>The findings on following crisis management guidelines and the use of C-SSRS in mental health incidents will be discussed.</p>
                  </td>
                  <td>
                    <p>Implementing C-SSRS in patient screening protocols has brought positive changes to the health of the community by 90% (Pumariega et al. , 2020).</p>
                  </td>
                  <td>
                    <p>Health and happiness can be assessed by conducting polls in the community (Heckert et al. , 2020).</p>
                  </td>
                  <td>
                    <p>Mental health D. P. will present the findings of the project to those who can assist in the reduction of mental health issues in the general population.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>How long the project will last</p>
                  </td>
                  <td>
                    <p>To achieve the desired result (Gipson et al. , 2023), ensure that the strategy is implemented in all mental health facilities.</p>
                  </td>
                  <td>
                    <p>Crisis management strategy and C-SSRS were employed to reduce the rate of return by 10% (Murray et al. , 2021).</p>
                  </td>
                  <td>
                    <p>According to Jones et al. (2023), community-based surveys will assist in determining the number of individuals who require readmission to a mental health facility.</p>
                  </td>
                  <td>
                    <p>Social worker E. W. will convey important information about the crisis management strategy to the community to enhance their comprehension.</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h1>References</h1>
            <p>Adu, M. K., Dias, R. L., Donkor, G. O., Ezeanozie, N., Sridharan, S., Morrison, J., Simon, P., Taylor, B., MacKinnon, M., Gossen, S., Awara, M., White, M., Shalaby, R., Agyapong, B., Eboreime, E., Wang, J., Feng, C., Wozney, L., Koto, P., &amp; Warford, J. (2024). Reducing wait times and avoiding unnecessary use of high-cost mental health services through a Rapid Access and Stabilization Program: Protocol for a program evaluation study. <em>BioMed Central Health Services Research</em>, <em>24</em>(1), 1&ndash;11. <a href="https://doi.org/10.1186/s12913-024-10697-7">https://doi.org/10.1186/s12913-024-10697-7</a></p>
            <p>AHRQ. (n.d.). <em>Plan-Do-Study-Act (PDSA) directions and examples</em>. Ahrq.gov <a href="https://www.ahrq.gov/health-literacy/improve/precautions/tool2b.html">https://www.ahrq.gov/health-literacy/improve/precautions/tool2b.html</a></p>
            <p>Bjureberg, J., Dahlin, M., Carlborg, A., Edberg, H., Haglund, A., &amp; Runeson, B. (2021). Columbia-suicide severity rating scale screen version: Initial screening for suicide risk in a psychiatric emergency department. <em>Psychological Medicine</em>, <em>52</em>(16), 1&ndash;9. <a href="https://doi.org/10.1017/s0033291721000751">https://doi.org/10.1017/s0033291721000751</a></p>
            <p>Evans, T. R., Burns, C., Williams, G., Miller, D., Harold, B., Gurnett, P., Jagodzinski, L., Smith, J., Milligan, W., &amp; Markowski, M. (2023). A systematic scoping review on the evidence behind debriefing practices for the wellbeing/emotional outcomes of healthcare workers. <em>Frontiers in Psychiatry, 14</em>(1). <a href="https://doi.org/10.3389/fpsyt.2023.1078797">https://doi.org/10.3389/fpsyt.2023.1078797</a></p>
            <p>Gharaati Sotoudeh, H., Alavi, S. S., Akbari, Z., Jannatifard, F., &amp; Artounian, V. (2020). The effect of a brief crisis intervention package on improving quality of life and mental health in patients with COVID-19. <em>Iranian Journal of Psychiatry</em>, <em>15</em>(3), 205&ndash;212. <a href="https://doi.org/10.18502/ijps.v15i3.3812">https://doi.org/10.18502/ijps.v15i3.3812</a></p>
            <p>Gipson, P. Y., Agarwala, P., Opperman, K. J., Horwitz, A., &amp; King, C. A. (2023). Columbia-suicide severity rating scale. <em>Pediatric Emergency Care, 9</em>(3), 1. <a href="https://doi.org/10.1097/pec.0000000000000225">https://doi.org/10.1097/pec.0000000000000225</a></p>
            <p>Jones, N., Decker, V. B., &amp; Houston, A. (2023). De-Escalation training for managing patient aggression in high-incidence care areas. <em>Journal of Psychosocial Nursing and Mental Health Services, 61</em>(8), 1&ndash;8. <a href="https://doi.org/10.3928/02793695-20230221-02">https://doi.org/10.3928/02793695-20230221-02</a></p>
            <p>Owusu, E., Oluwasina, F., Nkire, N., Lawal, M. A., &amp; Agyapong, V. I. O. (2022). Readmission of patients to acute psychiatric hospitals: Influential factors and interventions to reduce psychiatric readmission rates. <em>Healthcare</em>, <em>10</em>(9), 1808. <a href="https://doi.org/10.3390/healthcare10091808">https://doi.org/10.3390/healthcare10091808</a></p>
            <p>Powsner, S., Goebert, D., Richmond, J. S., &amp; Takeshita, J. (2023). Suicide risk assessment, management, and mitigation in the emergency setting. <em>Focus</em>, <em>21</em>(1), 8&ndash;17. <a href="https://doi.org/10.1176/appi.focus.20220072">https://doi.org/10.1176/appi.focus.20220072</a></p>
            <p>Pumariega, A. J., Good, K., Posner, K., Millsaps, U., Romig, B., Stavarski, D., Rice, R., Gehret, M. J., Riley, K., Wasser, T. E., Walsh, G., &amp; Yarger, H. (2020). Systematic suicide screening in a General Hospital Setting: Process and initial results. <em>World Social Psychiatry, 2</em>(1), 31. <a href="https://doi.org/10.4103/WSP.WSP_26_1">https://doi.org/10.4103/WSP.WSP_26_1</a></p>
            <p>SAMHSA. (n.d.). <em>Columbia suicide severity rating scale (C-SSRS)</em>. Samhsa.gov. <a href="https://www.samhsa.gov/resource/dbhis/columbia-suicide-severity-rating-scale-c-ssrs">https://www.samhsa.gov/resource/dbhis/columbia-suicide-severity-rating-scale-c-ssrs</a></p>
            <p>Sreeram, A., Cross, W. M., &amp; Townsin, L. (2023). Mental health nurses&rsquo; attitudes towards mental illness and recovery‐oriented practice in acute inpatient psychiatric units: A non‐participant observation study. <em>International Journal of Mental Health Nursing, 32</em>(4), 1112&ndash;1128. <a href="https://doi.org/10.1111/inm.13152">https://doi.org/10.1111/inm.13152</a></p>
            <p>Tayefi, M., Ngo, P., Chomutare, T., Dalianis, H., Salvi, E., Budrionis, A., &amp; Godtliebsen, F. (2021). Challenges and opportunities beyond structured data in analysis of electronic health records. <em>Wiley Interdisciplinary Reviews</em><em>(WIREs) Computational Statistics, 13</em>(6). <a href="https://doi.org/10.1002/wics.154">https://doi.org/10.1002/wics.154</a></p>
            <p>Wang, D., &amp; Gupta, V. (2020). <em>Crisis intervention</em>. Nih.gov. <a href="https://www.ncbi.nlm.nih.gov/books/NBK559081">https://www.ncbi.nlm.nih.gov/books/NBK559081</a></p>
            <p>Wigal, Dr. K., &amp; Tran, Mr. T. (2022, May 13). <em>We are what we Measure: Using key performance indicators (KPIs) to monitor &amp; evaluate progress toward organizational goals (Session 3 of 3) at HCMUTE | BUILD-IT Vietnam</em>. Builditvietnam.org. <a href="https://builditvietnam.org/event/we-are-what-we-measure-using-key-performance-indicators-kpis-monitor-evaluate-progress-2">https://builditvietnam.org/event/we-are-what-we-measure-using-key-performance-indicators-kpis-monitor-evaluate-progress-2</a></p>
          </div>
        </div>
        <div className='main-container-sr'>
          <div className='main-container-related-post'>
            <h2>Related Post</h2>
            <ul>
              <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
              <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
              <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
              <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
              <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
            </ul>
          </div>
          <div className='main-container-services'>
            <h2>Services</h2>
            <ul>
              <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
              <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
              <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
              <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
              <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
              <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
              <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
              <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
            </ul>
          </div>
          <div className='sample-more-details'>
            <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
              <Carousel.Item>
                <span className='sample-carousel'>
                  <p>Turnitin Originality</p>
                </span>
              </Carousel.Item>
              <Carousel.Item>
                <span className='sample-carousel'>
                  <p>Powerpoint Presentations</p>
                </span>
              </Carousel.Item>
              <Carousel.Item>
                <span className='sample-carousel'>
                  <p>Speech Notes</p>
                </span>
              </Carousel.Item>
              <Carousel.Item>
                <span className='sample-carousel'>
                  <p>Privacy Policy</p>
                </span>
              </Carousel.Item>
              <Carousel.Item>
                <span className='sample-carousel'>
                  <p>Video & Audio Assessment</p>
                </span>
              </Carousel.Item>
              <Carousel.Item>
                <span className='sample-carousel'>
                  <p>Unlimited Revisions</p>
                </span>
              </Carousel.Item>
            </Carousel>
          </div>
          <Form />
          <div className='main-container-category'>
            <h2>Categories</h2>
            <ul>
              <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
              <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
              <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
              <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
              <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
              <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
              <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
              <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
            </ul>
          </div>

        </div>

      </div>
      <div className='freesamples services'>
        <h2>RELATED SAMPLES</h2>
        {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
        <div className='sampleboxes'>
          {newSamples.map((sample) => {
            return (

              <div className='singlebox'>
                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                <div className='text'>
                  <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                  <p>{sample.description}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>


    </>
  )
}

export default Nursfpx9901
