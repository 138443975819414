import React from 'react'
import { Accordion, Container } from 'react-bootstrap'
import { AccordionBody, AccordionHeader, AccordionItem } from 'react-headless-accordion'
import { TiArrowSortedDown } from 'react-icons/ti'

const Bsnfaqs = () => {
  return (
    <div className='bsn-faqs'>
        <Container>
            <div className='bsn-faqs-inner'>
                <div className='bsn-faqs-image'></div>
                    <Accordion className='accordian-boxes-faqs'>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>What kind of academic assistance do you provide?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Our platform offers a wide range of assistance with nursing coursework, assignment writing, personalized tutoring, providing study material, essay writing, nursing thesis writing, nursing report writing, literature review, taking quizzes and taking online classes on your behalf.
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Who provides the assistance at your platform?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Our platform has a team of expert nursing professionals having years of experience in the field of nursing. They are well-versed in knowledge, skills and expertise related to the latest nursing technologies and academic practices.
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Is my personal information secure at your platform?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Yes, we have strict privacy policy. All the material shared by you will remain confidential. We follow all the safety measures to protect your personal information at our very best.                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>How quickly can I get your help?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                We are available 24/7 at your service. You can get assistance whenever you need it. Our team will provide quick and efficient support to you. We assure you that you will never miss your deadlines.                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>What is the pricing for your academic helping services?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Our pricings depends on the complexity, work amount and the deadlines of your assignments or coursework. We offer affordable rates and ensure that our services are offered to all deserving nursing students.                                </div>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>    
            </div>
        </Container>
    </div>
  )
}

export default Bsnfaqs