import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx6008assessment1 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 6008 Assessment 1 Identifying a Local Health Care Economic Issue</title>
                <meta name='description'
                    content='Are you looking support for NURS FPX 6008 Assessment 1 Identifying a Local Health Care Economic Issue? Online course services is here to provide complete information about this class. Contact us now.' />
                <meta name='keywords' content='NURS FPX 6008 Assessment 1 Identifying a Local Health Care Economic Issue' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 6008 Assessment 1 < br /><span>Identifying a Local Health Care Economic Issue</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/NURSFPX6008Assessment1.png" alt="" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>NURS FPX 6008 Assessment 1 Identifying a Local Health Care Economic Issue</strong></em></li>
                        </ul>
                        <h2><strong>Slide 2:</strong></h2>
                        <ul>
                            <li>
                                <h3>Stakeholder Meeting</h3>
                            </li>
                        </ul>
                        <p>The stakeholder meeting will discuss the various facets of how EHR will be implemented in Vila Health. The meeting will also describe the benefits of the EHR system, explain how the system will work, and cover areas like near-miss events and adverse events to deliver quality and safe patient care (Holmgren et al., 2020).</p>
                        <h2><strong>Slide 3:</strong></h2>
                        <ul>
                            <li>
                                <h3>Key Stakeholders</h3>
                            </li>
                        </ul>
                        <p>The stakeholders are divided into two types: external and internal. The stakeholders are crucial in determining the success of the implementation of the EHR system. They help in the decision-making and ratification of plans that affect the implementation of those plans in an organization. It is important to note that they are the ones who will approve of every course of action taken.</p>
                        <p>The main parties who should participate in the meeting for a technology acquisition are hospital owners, board of directors, investors including pharmaceuticals, and any healthcare worker, for instance, the pharmacist, nurses, physicians, surgeons, psychiatrist, administration staff, IT department, sales executive, and managerial staff. These stakeholders (owners, investors, and board of directors) were selected on the basis of their involvement in the implementation of the plan. Their approval and decision affect the EHR implementation in the hospital, according to Maurer et al., 2022.</p>
                        <p>The most significant impact on the success of the project relies on the side of the healthcare providers who are directed at managing and achieving complete eradication of near-miss events and adverse events through EHR systems. Since the healthcare providers who will be implementing the EHR system will have the ability and proficiency in its usage, the lives of patients will be safe by minimizing the errors that may cause life threats (Khairat et al., 2020).</p>
                        <h2><strong>Slide 4:</strong></h2>
                        <ul>
                            <li>
                                <h3>Who Will Champion the Project</h3>
                            </li>
                        </ul>
                        <p>The physician will champion the project within the department. Furthermore, nursing informaticists or IT specialists are best positioned to help healthcare providers transition to using new technology (EHR) systems. This is because these individuals are proficient in using technological aids and have undergone numerous courses and certifications regarding their practical use. Along with this, these individuals are present within the healthcare organization to help the healthcare providers solve queries and ambiguities regarding the use of technology, as it is their responsibility. They are equipped with Evidence-Based Practice (EBP) knowledge and experience in integrating healthcare with technology (Khairat et al., 2020).</p>
                        <h2><strong>Slide 5:</strong></h2>
                        <ul>
                            <li>
                                <h3>Knowledge Gaps</h3>
                            </li>
                        </ul>
                        <p>Healthcare providers' lack of knowledge about using the EHR system results in its ineffective implementation. Hospital investors and owners' lack of knowledge about the economic, social, and financial benefits associated with the EHR system will also hinder its implementation.</p>
                        <h2><strong>Slide 6:</strong></h2>
                        <ul>
                            <li>
                                <h3>Draft a Meeting Announcement and Agenda</h3>
                            </li>
                        </ul>
                        <p>The main issues to be discussed during the meeting will concern the potential advantages of implementing the EHR system and the rationale for how it will reduce healthcare inequalities due to preventable errors. The meeting will take place in the Vila Health conference room, and all the stakeholders will be in attendance.&nbsp;</p>
                        <p>The primary purpose of holding the meeting will be the determination of the various areas that require clarification and definition before actual work can begin. The goal and objectives of this meeting will be aimed at improving the quality and safety of care, decreasing medication errors, including drug-drug interaction and overdosage, patient readmission rates, determining which age group of patients would be at risk of particular diseases due to their genotype differences or co-morbidity and also to assess the health status of the patient receiving treatment (Ashfaq et al., 2019). The agenda will be to concentrate on the efficient use of the EHR system employed by the nurses, as these are the frontline healthcare workers who are legally obliged to obtain patient data and feed this into the system and then use the trend analysis to identify the cause of healthcare disparities and bring attention to factors that led to poor healthcare delivery to the patients. Lectures and training will be held now and then to update the nurses concerning the EHR system (Ting et al., 2021).</p>
                        <p>In addition to this, the other issues that will be on the agenda of the meeting are the safety of patient's information and the right of patients to privacy. The agenda will draw awareness of how healthcare providers need to work hand in hand for the success of the EHR system. The use of the EHR system will seek to enhance patients' adherence to the health care plan and regime since the constant evaluation of patient health will be conducted. The terms of the agenda are unambiguous and straightforward, as the latter outlines the areas that should be focused on and worked on. An agenda will act more as a guide to things that need to be covered as a team during the meeting.</p>
                        <ul>
                            <li>
                                <h3>Assumptions</h3>
                            </li>
                        </ul>
                        <p>The meeting agenda assumes that healthcare providers do not know how to integrate healthcare with technology and are not proficient in establishing the relation between the data to predict the trends of healthcare disparities in the specific patient population (Aguirre et al., 2019). Hence, the healthcare providers will be educated through conferences and sessions.</p>
                        <h2><strong>Slide 7:</strong></h2>
                        <ul>
                            <li>
                                <h3>New Technology to Enhance Patient Outcomes and Organizational Effectiveness</h3>
                            </li>
                        </ul>
                        <p>The meeting agenda presupposes that healthcare providers are unaware that healthcare can be linked to technology and lack the skills to determine the correlation between the data in order to forecast the tendencies of disparities in the said client population (Aguirre et al., 2019). Thus, the healthcare providers will be educated through conferences and sessions.</p>
                        <p>With the implementation of the EHR system, the benefits of patient health will be enhanced through patient-centered, safe, and quality care. Patient satisfaction levels will also increase, and readmission rates will decrease, which in turn will enhance Vila Health's social, financial, and economic status.</p>
                        <h2><strong>Slide 8: </strong>&nbsp;</h2>
                        <ul>
                            <li>
                                <h3><strong>Goals of the Plan </strong></h3>
                            </li>
                        </ul>
                        <p>The SMART (Specific, measurable, achievable, relevant, and time-bound) goals of the plan include the following: The SMART (Specific, measurable, achievable, relevant, and time-bound) goals of the plan include the following:</p>
                        <p>The objective of this DPOG is that after 30 days of implementing the EHR system (time-bound), the medication error rate will reduce, and 30-day readmission rates among patients will also decrease (specific and measurable).</p>
                        <h2><strong>Slide 9: </strong>&nbsp;</h2>
                        <p>It will improve the quality and safety of the care provided to the patients (measurable, feasible, timely), as the EHR system has an inbuilt warning and alarm system. It will also eliminate the chances of having an adverse event or near-miss (measurable).&nbsp;</p>
                        <p>The percentage of patient satisfaction will rise (attainable) since the goal is to deliver efficient, affordable, patient-oriented, and secure services (related, understandable, and quantitative).</p>
                        <h2><strong>Slide 10:</strong></h2>
                        <ul>
                            <li>
                                <h3>Capabilities of Technology</h3>
                            </li>
                        </ul>
                        <p>In terms of the critical outcomes of the agenda, the EHR system is considerably efficient. The system can guarantee quality and safe care as the updated artificial intelligence system that is installed is expected to be in a position to determine and rate the kind of interaction through patient&rsquo;s records and current state / prescribed medication. It means that the EHR system is able to decide on the trends of the data and forecast the healthcare disparities of the patients. With this, it is also responsive to any events of hostile nature or emergent situations that may take place. It can, for instance, help in avoiding any harm to the patient through the generation of a sequence of warnings to the health care practitioners.</p>
                        <p><strong>Slide 11:</strong></p>
                        <ul>
                            <li>
                                <h3>Criteria for Evaluating Organizational Effectiveness</h3>
                            </li>
                        </ul>
                        <p>We can use the PDSA (Plan-Do-Study-Act) cycle to determine how efficient the organization&rsquo;s EHR system is. The data can also be used through the EHR system to evaluate and measure the decrease in medication errors, adverse events, near-miss events, patient readmission rates, and decreased mortality and morbidity rates of the patient population. It will show whether the EHR system is sound, how the health care providers will be able to manage it, incorporate health care services with it, and the positive impact on the health of patients. However, if data is different from the expected results, adjustments will be made to optimize the use of the EHR system. Its goal is to enhance organization efficiency when adopting the EHR system; the system must be convenient for healthcare workers (McNicholas et al., 2019).</p>
                        <h2><strong>Slide 12 &amp; 13:</strong></h2>
                        <ul>
                            <li>
                                <h3>Identify Outcome Measures&nbsp;</h3>
                            </li>
                        </ul>
                        <p>The following are more captures of quality and safety outcomes.</p>
                        <p>New conceptualization and effective coordination between the healthcare providers. Less hazards and potential mishaps are impacting patients as well as healthcare workers or consumers of healthcare services. It has reduced readmission rates. Greater adherence of patients to health-related care/ management plans/ regimens. Minimize the time taken to deliver health care services. Availability of home and telemonitoring services. Based on these experiences, the following increased access can be outlined:</p>
                        <p>One of the significantly increased access can be referred to as increased access to remote monitoring services. In line with this, this paper posits that the decreased cost of therapy translates to a higher population utilization of healthcare facilities. Prevention of cases of confusion or misunderstanding resulting from hard-to-read prescriptions written by the doctor. This means that more patients, resulting in higher patient satisfaction, will come back for treatment of other co-morbidities/other healthcare problems (Adane et al., 2019). The data on the outcome measures indicate happy patients who received safe, quality, and patient-centered health care services. It also refers to the lowered readmission of patients within 30 days after discharge from the hospital and enhanced patients&rsquo; adherence to the required healthcare services.</p>
                        <ul>
                            <li>
                                <h3>Quality of Existing Data</h3>
                            </li>
                        </ul>
                        <p>The perceived quality of data was substantially bad in the case of data collected before compared to when collected through the new method. According to the available data, there is a rise in some medication errors caused by misdiagnosis, prescription incoherent writing of the healthcare providers, wrong medication dispensed due to dispensing errors, or administration errors have provided data that pinpoints the problem of repeated 30-day readmission rates for patients owing to incorrect guidelines. It was apparent that lack of communication between healthcare providers, compounded by an inability to communicate the prescription to the patient, meant that healthcare provision was limited by the patient&rsquo;s failure to understand the prescription. Complementing this, mortality and morbidity rates went up, and hence, patients&rsquo; dissatisfaction also rose. These were views reflected in the data by poor patient health outcomes as well as poor financial performance that the hospital was experiencing.</p>
                        <h2><strong>Slide 14:</strong></h2>
                        <ul>
                            <li>
                                <h3>Patient Confidentially and Privacy Concerns</h3>
                            </li>
                        </ul>
                        <h4>Security Concerns</h4>
                        <p>Security issues that will be of concern to the patient will entail the following: Patient rights and information security. As a result of the implementation of the EHR system, patients will wonder whether their information will ever be protected from theft and unnecessary disclosure. Risk factors include misuse of the online platform for improper dissemination of patient information, identity theft, and forgery issues, give the patient a blow in terms of his privacy (Basil et al., 2022).</p>
                        <h4><strong>&nbsp;Allay the Concerns</strong></h4>
                        <p>These issues will be solved by using VPN connections, twice checking, and giving permission to access content only to the authorized person. The principle of private and confidential information will be solved according to the HIPPA and HITECH Act. The following guidelines will be used while implementing the measures to protect patient information: They will make sure that any participant contravening such standards is punished through fines of about dollars, going as high as one million dollars, besides facing life imprisonment. What makes the EHR system use specific coding for transferring patient data? This helps to safeguard patient information.</p>
                        <h2><strong>Slide 15:</strong></h2>
                        <ul>
                            <li>
                                <h3>Steps and Timeline</h3>
                            </li>
                        </ul>
                        <p>The following steps will be taken for the implementation of the plan:</p>
                        <p>&nbsp;Proposal and Approval of the Plan. Distribution of the Resources and the Roles &amp; Responsibilities. Introduction and launch of the EHR system. Conveying Health education to the health care professionals through Conferences and Sessions within 14 days. The Scrutiny and Appraisal of the Success of the Plan.</p>
                        <ul>
                            <li>
                                <h3><strong>&nbsp;Assumptions </strong></h3>
                            </li>
                        </ul>
                        <p>The timeline also allows for a buffer period, which will help eradicate errors or delays so that the system may be well implemented. The timeline also has an advantage in the learning period of the healthcare providers needed to learn and adopt three EHR systems.</p>
                        <h2><strong>Slide 16:</strong></h2>
                        <ul>
                            <li>
                                <h3>Conclusion</h3>
                            </li>
                        </ul>
                        <p>The purpose of the meeting will be discussed in accordance with the meeting agenda. It will outline the importance, pros, and cons of adopting EHR in healthcare delivery in relation to improving the quality of patient care and healthcare providers. The hospital's social, financial, and economic position will also be enhanced, and health disparities will be reduced.&nbsp;Read more about our sample&nbsp;<a href="https://onlinecourseservices.us/nurs-fpx-6008-assessment-2-need-analysis-for-change">NURS FPX 6008 Assessment 2 Need Analysis for Change</a>&nbsp;for complete information about this class.</p>
                        <h2><strong>Slide 17:</strong></h2>
                        <ul>
                            <li>
                                <h3>Reference</h3>
                            </li>
                        </ul>
                        <p>Adane, K., Gizachew, M., &amp; Kendie, S. (2019). The role of medical data in efficient patient care delivery: A review.&nbsp;<em>Risk Management and Healthcare Policy</em>,&nbsp;<em>12</em>, 67&ndash;73.</p>
                        <p><a href="https://doi.org/10.2147/RMHP.S179259">https://doi.org/10.2147/RMHP.S179259</a></p>
                        <p>Aguirre, R. R., Suarez, O., Fuentes, M., &amp; Sanchez-Gonzalez, M. A. (2019). Electronic health record implementation: A review of resources and tools.&nbsp;<em>Cureus</em>,&nbsp;<em>11</em>(9), e5649.</p>
                        <p><a href="https://doi.org/10.7759/cureus.5649">https://doi.org/10.7759/cureus.5649</a></p>
                        <p>Ashfaq, A., Sant'Anna, A., Lingman, M., &amp; Nowaczyk, S. (2019). Readmission prediction using deep learning on electronic health records.&nbsp;<em>Journal of Biomedical Informatics</em>,&nbsp;<em>97</em>, 103256.</p>
                        <p><a href="https://doi.org/10.1016/j.jbi.2019.103256">https://doi.org/10.1016/j.jbi.2019.103256</a></p>
                        <p>Basil, N. N., Ambe, S., Ekhator, C., &amp; Fonkem, E. (2022). Health records database and inherent security concerns: A review of the literature.&nbsp;<em>Cureus</em>,&nbsp;<em>14</em>(10), e30168.</p>
                        <p><a href="https://doi.org/10.7759/cureus.30168">https://doi.org/10.7759/cureus.30168</a></p>
                        <p>Holmgren, A. J., Co, Z., Newmark, L., Danforth, M., Classen, D., &amp; Bates, D. (2020). Assessing the safety of electronic health records: A national longitudinal study of medication-related decision support.&nbsp;<em>BMJ Quality &amp; Safety</em>,&nbsp;<em>29</em>(1), 52&ndash;59.</p>
                        <p><a href="https://doi.org/10.1136/bmjqs-2019-009609">https://doi.org/10.1136/bmjqs-2019-009609</a></p>
                        <p>Khairat, S., Xi, L., Liu, S., Shrestha, S., &amp; Austin, C. (2020). Understanding the association between electronic health record satisfaction and the well-being of nurses: A survey study.&nbsp;<em>JMIR Nursing</em>,&nbsp;<em>3</em>(1), e13996.</p>
                        <p><a href="https://doi.org/10.2196/13996">https://doi.org/10.2196/13996</a></p>
                        <p>Maurer, M., Mangrum, R., Hilliard-Boone, T., Amolegbe, A., Carman, K. L., Forsythe, L., Mosbacher, R., Lesch, J. K., &amp; Woodward, K. (2022). Understanding the influence and impact of stakeholder engagement in patient-centred outcomes research: A qualitative study.&nbsp;<em>Journal of General Internal Medicine</em>,&nbsp;<em>37</em>(Suppl 1), 6&ndash;13.</p>
                        <p><a href="https://doi.org/10.1007/s11606-021-07104-w">https://doi.org/10.1007/s11606-021-07104-w</a></p>
                        <p>McNicholas, C., Lennox, L., Woodcock, T., Bell, D., &amp; Reed, J. E. (2019). Evolving quality improvement support strategies to improve Plan-Do-Study-Act cycle fidelity: A retrospective mixed-methods study.&nbsp;<em>BMJ Quality &amp; Safety</em>,&nbsp;<em>28</em>(5), 356&ndash;365.</p>
                        <p><a href="https://doi.org/10.1136/bmjqs-2017-007605">https://doi.org/10.1136/bmjqs-2017-007605</a></p>
                        <p>Rosenbloom, S. T., Smith, J. R. L., Bowen, R., Burns, J., Riplinger, L., &amp; Payne, T. H. (2019). Updating HIPAA for the electronic medical record era.&nbsp;<em>Journal of The American Medical Informatics Association: JAMIA</em>,&nbsp;<em>26</em>(10), 1115&ndash;1119.</p>
                        <p><a href="https://doi.org/10.1093/jamia/ocz090">https://doi.org/10.1093/jamia/ocz090</a></p>
                        <p>Tapuria, A., Porat, T., Kalra, D., Dsouza, G., Xiaohui, S., &amp; Curcin, V. (2021). Impact of patient access to their electronic health record: Systematic review.&nbsp;<em>Informatics for Health &amp; Social Care</em>,&nbsp;<em>46</em>(2), 192&ndash;204.</p>
                        <p><a href="https://doi.org/10.1080/17538157.2021.1879810">https://doi.org/10.1080/17538157.2021.1879810</a></p>
                        <p>Ting, J., Garnett, A., &amp; Donelle, L. (2021). Nursing education and training on electronic health record systems: An integrative review.&nbsp;<em>Nurse Education in Practice</em>,&nbsp;<em>55</em>, 103168.</p>
                        <p><a href="https://doi.org/10.1016/j.nepr.2021.103168">https://doi.org/10.1016/j.nepr.2021.103168</a></p>
                        <p>Gerich, H., &amp; Peltonen, L. M. (2022). Assessment of health service quality through electronic health record - a scoping review.&nbsp;<em>Studies in Health Technology and Informatics</em>,&nbsp;<em>294</em>, 520&ndash;524.</p>
                        <p><a href="https://doi.org/10.3233/SHTI220513">https://doi.org/10.3233/SHTI220513</a></p>


                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Nursfpx6008assessment1
