import React, { useState,useEffect } from 'react'

const Callta = () => {
    const [buttonText, setButtonText] = useState('SUBIMT');


    const [formData, setFormData] = useState({
        userName: "",
        userEmail: "",
        userNumber: "",
        userSubject: "",
        ip:"",
    })

    const getip = async () => {
        const response = await fetch('https://api.ipify.org');
        const data = await response.text();
        setFormData({ ...formData, ip: data })
      }
      useEffect(() => {
        getip()
      }, [])

    const ctaData = async (e) => {
        e.preventDefault()
        if (formData.userName === "" || formData.userEmail === "" || formData.userNumber === "" || formData.userSubject === "") {
            alert("please fill all fields correctly")
        }
        else{
            const res = await fetch("https://slack.onlinecourseservices.us/ctadata", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }, body: JSON.stringify({
                    formData
                })
            })
            console.log(res);
    
            const data = await res.json();
            if (data.status === "error") {
                console.log("error")
            } else {
                setButtonText(buttonText === 'SUBIMT' ? 'SUBIMTTED' : 'SUBIMT');
                setTimeout(function () {
                    setButtonText("SUBIMT");
                }, 1500);
                setFormData({ ...formData, userName: "", userEmail: "", userNumber: "", userSubject: "" })
            }
        }
        
    }



    return (
        <>
            <h2>GET COMPREHENSIVE HELP <br />IN CAPELLA FLEXPATH PROGRAMS!</h2>
            <div className='contactform'>
                <input type='text' placeholder='Full Name' className='inputfield' value={formData.userName} onChange={(e)=>setFormData({...formData, userName: e.target.value})}></input>
                <input type='email' placeholder='Your Email' className='inputfield' value={formData.userEmail} onChange={(e)=>setFormData({...formData, userEmail: e.target.value})}></input>
                <input type='text' placeholder='Contact Number' className='inputfield' value={formData.userNumber} onChange={(e)=>setFormData({...formData, userNumber: e.target.value})}></input>
                <input type='text' placeholder='Subject' className='inputfield' value={formData.userSubject} onChange={(e)=>setFormData({...formData, userSubject: e.target.value})}></input>
                <input type='submit' value={buttonText} className='inputbtn' onClick={ctaData}></input>
            </div>
        </>
    )

}

export default Callta
