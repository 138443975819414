import React from 'react'
import Phdbehaviourhero from './Phdbehaviourcomponent/Phdbehaviourhero'
import Phdbehaviourdegree from './Phdbehaviourcomponent/Phdbehaviourdegree'
import Bsnfeatures from '../Nursing/bsnpagecomponent/Bsnfeatures'
import Bannertwo from '../../homepage/Bannertwo'
import Bsnfaqs from '../Nursing/bsnpagecomponent/Bsnfaqs'

const Phdbehaviour = () => {
  return (
    <div>
      <Phdbehaviourhero/>
      <Phdbehaviourdegree/>
      <Bsnfeatures/>
      <Bannertwo/>
      <Bsnfaqs/>
    </div>
  )
}

export default Phdbehaviour
