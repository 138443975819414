import React from 'react'
import { Container } from 'react-bootstrap'

const Msclinicalhero = () => {
  return (
    <div className='bsn-hero'>
      <Container>
        <div className='bsn-hero-title'>
            <h1>MS in Clinical Psychology</h1>
            <div className='bsn-line'></div>
            <h2>MS in Clinical Psychology</h2>
        </div>
      </Container>
    </div>
  )
}

export default Msclinicalhero
