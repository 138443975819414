import React from 'react'
import { Container } from 'react-bootstrap'

const Mspsyhero = () => {
  return (
    <div className='bsn-hero'>
      <Container>
        <div className='bsn-hero-title'>
            <h1>MS in Psychology</h1>
            <div className='bsn-line'></div>
            <h2>Get Degree in MS in Psychology</h2>
        </div>
      </Container>
    </div>
  )
}

export default Mspsyhero
