import axios from 'axios';
import React, { useState } from 'react'
import Container from 'react-bootstrap/esm/Container';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom';

const Create = () => {

  const state = useLocation().state

  const [value, setValue] = useState(state?.title || "");
  const [title, setTitle] = useState(state?.desc || "");
  const [file, setFile] = useState(null);
  const [cat, setCat] = useState(state?.cat || "");
  const [matadesc, setMatadesc] = useState(state?.matadesc);

  const navigate = useNavigate()

  const upload = async ()=>{
    try{
      const formData = new FormData();
      formData.append("file", file)
      const res = await axios.post("/upload", formData)
      return res.data;
    }catch(err){
      console.log(err)
    }
  }

  const handleClick = async e =>{
    e.preventDefault()
    const imgUrl = await upload()

    try{
      state ? await axios.put(`/posts/${state.id}`, {
        title,matadesc,desc:value,cat,img:file ? imgUrl : "",
      }) 
      : await axios.post(`/posts/`, {
        title,matadesc,desc:value,cat,img:file ? imgUrl : "",
      })
      navigate("/samples")
    }catch(err){
      console.log(err)
    }
  }

  console.log(value);
  return (
    <Container>
      <Helmet>
      <title>Create Post</title>
    </Helmet>
      <div className='write' id='create'>
        <div className='content'>
          <input type='text' value={title} placeholder='Title' onChange={e=>setTitle(e.target.value)}/>
          <input type='text' value={matadesc} placeholder='Mata Description' onChange={e=>setMatadesc(e.target.value)}/>
          <div className='editorcontainor'>
          <ReactQuill className='editor' theme="snow" value={value} onChange={setValue} />
          </div>
        </div>
        <div className='createmenu'>
          <div className='item'>
            <h2>Publish</h2>
            <span>
              <b>Status: </b> Draft
            </span>
            <span>
              <b>Visibility: </b> Public
            </span>
            <input style={{display: "none"}} type='file' name='' id='file' onChange={e=>setFile(e.target.files[0])}/>
            <label className='file' htmlFor='file'>Upload Image</label>
            <div className='buttons'>
              <button>Save as a draft</button>
              <button onClick={handleClick}>Publish</button>
            </div>
          </div>
          <div className='item'>
            <h2>Category</h2>
            <div className='cat'>
            <input type='radio' checked={cat === "bsn"} name='cat' value='BSN' id='bsn' onChange={e=>setCat(e.target.value)}/>
            <label htmlFor='bsn'>BSN</label>
            </div>
            <div className='cat'>
            <input type='radio' checked={cat === "msn"} name='cat' value='MSN' id='msn' onChange={e=>setCat(e.target.value)}/>
            <label htmlFor='msn'>MSN</label>
            </div>
            <div className='cat'>
            <input type='radio' checked={cat === "dnp"} name='cat' value='DNP' id='dnp' onChange={e=>setCat(e.target.value)}/>
            <label htmlFor='dnp'>DNP</label>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Create
