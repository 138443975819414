import React from 'react'
import Bsnhero from './bsnpagecomponent/Bsnhero';
import Degreedetail from './bsnpagecomponent/Degreedetail';
import Bsnfeatures from './bsnpagecomponent/Bsnfeatures';
import Bannerone from '../../homepage/Bannerone';
import Bsnfaqs from './bsnpagecomponent/Bsnfaqs';

const Bsn = () => {
  return (
    <>
      <Bsnhero/>
      <Degreedetail/>
      <Bsnfeatures/>
      <Bannerone/>
      <Bsnfaqs/>
    </>
  )
}

export default Bsn
