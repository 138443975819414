import React from 'react'
import Bspsyhero from './Bspsycomponent/Bspsyhero'
import Bspsydegreedetail from './Bspsycomponent/Bspsydegreedetail'
import Bsnfaqs from '../Nursing/bsnpagecomponent/Bsnfaqs'
import Bannerone from '../../homepage/Bannerone'
import Bsnfeatures from '../Nursing/bsnpagecomponent/Bsnfeatures'

const Bspsy = () => {
  return (
    <div>
      <Bspsyhero/>
      <Bspsydegreedetail/>
      <Bsnfeatures/>
      <Bannerone/>
      <Bsnfaqs/>
    </div>
  )
}

export default Bspsy
