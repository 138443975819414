import React from 'react';
import { Container } from 'react-bootstrap';

const Bsnhero = () => {
  return (
    <div className='bsn-hero'>
      <Container>
        <div className='bsn-hero-title'>
            <h1>RN to BSN</h1>
            <div className='bsn-line'></div>
            <h2>Bachelor of Science in Nursing</h2>
        </div>
      </Container>
    </div>
  )
}

export default Bsnhero