import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx6021assessment2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 6021 Assessment 2 Change Strategy and Implementation</title>
                <meta name='description'
                    content='Are you looking for NURS FPX 6021 Assessment 2 Change Strategy and Implementation. Contact us now and get full support by professionals.' />
                <meta name='keywords' content='NURS FPX 6021 Assessment 2 Change Strategy and Implementation' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 6021 Assessment 2 < br /><span>Change Strategy and Implementation</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/NURSFPX6021Assessment2.png" alt="" />
                    <div className='main-container-sl-content'>
                        <h2>Change Strategy and Implementation</h2>
                        <p>In diabetic mellitus patients, there is a predisposition to secondary bacterial infections. Diabetes and high blood glucose levels cause the immune system to be more compromised as it allows the growth of other infections known as secondary infections (Berbudi et al., 2020). Urinary tract infections, foot infections, and respiratory tract infections are the frequent infections found in diabetic patients (Romesh, 2022).</p>
                        <p>Shockingly, diabetic patients are twice as much at risk as non-diabetic patients of getting secondary infections. The treatment of secondary infections is antibiotics regimens according to where the infection is induced (Lakshmi et al., 2022). The use of antibiotics assists in preventing secondary infections, but improper use of antibiotics has led to antibiotic resistance among patients (Woldemariam et al., 2019). The change strategies are listed below in this assessment: a logical treatment aimed at boosting the immune system and pharmacological treatment. Improvement of the immune system is beneficial in handling secondary infections in diabetic patients.</p>
                        <ul>
                            <li>
                                <h3>Case study</h3>
                            </li>
                        </ul>
                        <p>Mr. Alex is a 66-year-old patient with Type II diabetes. The effects of diabetes, such as complications, reduced the immune system of Mr. Alex. Secondary infections are very likely to occur in him. In the previous month, he was having MT for a urinary tract infection, which is prevalent among diabetic patients (Yenehun et al., 2021). Doctors are making use of antibiotics, but the continued infections are causing an increase in the level of antibiotic resistance.</p>
                        <p>These conditions are making his health deteriorate. He also has not been in good shape mentally since last year because he buried his young son in a car accident. The psychological support intervention will help improve his immune system, hence decreasing the number of secondary infections by reducing the cases of type II diabetes occasioned by instances such as the uptake of antibiotics.&nbsp;</p>
                        <h2>Data Table Reflecting the Current and Desired States</h2>
                        <ul>
                            <li>
                                <h3>Clinical Outcomes</h3>
                            </li>
                        </ul>
                        <p>Diabetes in Mr. Alex interfered with the immune system and caused an inclination to secondary infections. His state of mind can be regarded as poor, which, in turn, affected his immunological status. The immune system is known to be interfered with through mental illness (Ishikawa &amp; Furuyashiki, 2022). Overuse of antibiotics causes antibiotic resistance in Mr. Alex. The cross-over trial showed that Diabetic patients exhibit High Resistance to vancomycin (Toniolo et al., 2019). This is why we need to search for change strategies by which the immune system can be enhanced to minimize secondary infections in Mr. Alex.</p>
                        <ul>
                            <li>
                                <h3><strong>Areas of Uncertainty </strong></h3>
                            </li>
                        </ul>
                        <p>Mr. Alex now has unstable mental health. Thus, he cannot be relied on to manage his glycemic conditions frequently. He requires the nurses' appropriate help and attention throughout the day. Therefore, recognizing diabetes self-management education and support (DSMES) will be helpful to Mr. Alex as this service offers support for the self-management of diabetes to patients (Lockwood &amp; Ward, 2015).</p>
                        <h2>Proposed Change Strategies</h2>
                        <p>The high level of glucose in the diabetic patient&rsquo;s body impairs the immune cells and thus makes the patient more prone to infections. Another issue is that Antibiotic Resistance is also high among them (Toniolo et al., 2019). Therefore, in the above change strategies, psychological methods have been recommended as part of glycemic control and immunity build-up to reduce the chances of developing secondary infections in diabetic patients.</p>
                        <p>The patient will be encouraged to eat healthy foods and engage in exercises in an effort to keep the rate of physical activity high so as to monitor low hyperglycemia. Concerning exercising, the patient should engage in exercise for about 25-30 minutes per day. Amanat et al. (2020) have shown that patients with type 2 diabetes can benefit from exercise since it enhances insulin sensitivity, thereby contributing to the decrease of hyperglycemia and the ability of the immune system to be protected from the ravaging effects of hyperglycemia. There is evidence that one should apply psychological techniques to enhance the immune response (Schakel et al., 2019).</p>
                        <h2>Justification of Change Strategies</h2>
                        <p>In this paper, five sets of change strategies have been proposed, and empirical evidence supports them. There is evidence that supports the idea of consuming healthy foods and getting adequate physical activity that assists in controlling hyperglycemia (Mirahmadizadeh et al., 2020). The author Vasile (2020) noted that psychological interventions enhance the function of immunity and decrease inflammation. This pointed out that through modification of psychological actions, the failure of the immune system was minimized to 18% (Vasile, 2020). Massey et al. (2019) point out that in addition to glycemic control, other aspects of the psychological condition of diabetic patients are linked to the process of lowering hyperglycemia. As a result, these strategies will go a long way in helping to reduce secondary infections in diabetic patients.</p>
                        <p>Imperfect tools, imperfect outcomes: a literature review on others&rsquo; perspectives on proposed change strategies. Rarely does the patient feel right about receiving psychological intervention. Since diabetic patient frequently consults nurse practitioners, friendly and believable relationships can assist in the provision of psychological support to diabetic patients (du Pon et al., 2019)</p>
                        <p>Speaking of the change strategies that imply improvement in quality concerning safety and equitable care, it is necessary to mention that the topic is rather broad and should be discussed in greater detail. Management of change will help improve the approach toward hyperglycemia in order to decrease secondary infections and increase patient safety (Massey et al., 2019). Psychological interventions also offer a chance to look at all aspects of the health issue and dispense equal treatment. Psychological interventions enhance immune responses and might also be helpful in lowering incidences of secondary infection. Therefore, it minimizes the use of antibiotics and medical expenses associated with medication. It will also add to and enhance the quality of liveliness of the entire population. Direct patient care Delivery is centered and values every patient who seeks the services of the health facility with the aim of improving the patient experience. As such, every aspect of the Quadruple Aim is covered.</p>
                        <ul>
                            <li>
                                <h3><strong>Assumptions </strong></h3>
                            </li>
                        </ul>
                        <p>The change strategies that have been proposed in the care plan were meant to enhance the quality of care for Mr. Alex based on the physiological and psychological dimensions of health issues.</p>
                        <h2>Interprofessional Considerations</h2>
                        <p>Patient outcomes will be directed by nurses, physicians, dietitians, and psychologists to implement healthy behaviors and lifestyles. It will assist in the provision of care to patients that is centered on them. Health Information Technology helps in supporting the proposed change strategies since it enhances communication between care delivery and consumers (Vos et al., 2020). promotes order and togetherness in healthcare organizations and, in this way, improves the performance of healthcare personnel. As mentioned by Bachynsky (2019), numerous studies have highlighted in one of the works that interprofessional care enhances safety and quality of care.</p>
                        <ul>
                            <li>
                                <h3><strong>Assumptions </strong></h3>
                            </li>
                        </ul>
                        <p>Implementing a plan requires the concerted effort of all healthcare workers. Collaboration among practitioners of different fields is the only way to deliver patient-centered care. All healthcare professionals must be dedicated.</p>
                        <h2>Conclusion</h2>
                        <p>The chance of a secondary infection is relatively high in diabetic patients. Psychotherapy, counselling, dietary advice, and encouragement to adopt healthier lifestyles will enhance the immune response and general well-being of the patient. Effective communication, hence, calls for interprofessional collaboration when considering change strategies. The majority of healthcare practitioners, including nurses, physicians, and psychologists, have to cohesively enhance patient safety.</p>
                        <p><strong><em>If you need complete information about class 5004, click below to view a related sample:</em></strong></p>
                        <p><span ><a  href="https://onlinecourseservices.us/nurs-fpx-5004-assessment-1-pledge-of-academic-honesty"><em>NURS FPX 5004 Assessment 1</em></a></span></p>
                        <p><span ><a  href="https://onlinecourseservices.us/nurs-fpx-5004-assessment-2"><em>NURS FPX 5004 Assessment 2</em></a></span></p>
                        <p><span ><a  href="https://onlinecourseservices.us/nurs-fpx-5004-assessment-3"><em>NURS FPX 5004 Assessment 3</em></a></span></p>
                        <h2>References</h2>
                        <p>Amanat, S., Ghahri, S., Dianatinasab, A., Fararouei, M., &amp; Dianatinasab, M. (2020). Exercise and Type 2 Diabetes.&nbsp;Physical Exercise for Human Health, 91&ndash;105.</p>
                        <p><a href="https://doi.org/10.1007/978-981-15-1792-1_6">https://doi.org/10.1007/978-981-15-1792-1_6</a></p>
                        <p>&zwnj;Bachynsky, N. (2019). Implications for policy: The triple Aim, quadruple Aim, and interprofessional collaboration.&nbsp;Nursing Forum,&nbsp;55(1), 54&ndash;64.</p>
                        <p><a href="https://doi.org/10.1111/nuf.12382">https://doi.org/10.1111/nuf.12382</a></p>
                        <p>&zwnj;Berbudi, A., Rahmadika, N., Tjahjadi, A. I., &amp; Ruslami, R. (202relativelype 2 Diabetes and its impact on the immune system.&nbsp;Current Diabetes Reviews,&nbsp;16(5), 442&ndash;449.</p>
                        <p><a href="https://doi.org/10.2174/1573399815666191024085838">https://doi.org/10.2174/1573399815666191024085838</a></p>
                        <p>&zwnj;Doupis and general well-being, C., EfthymEffective&amp; Kokkinos, A., (2020, calls for in diabetes management.&nbsp;Diabetes Therapy,&nbsp;11(3), 619.</p>
                        <p><a href="https://doi.org/10.1007/s13300-020-00768-3">https://doi.org/10.1007/s13300-020-00768-3</a></p>
                        <p>&zwnj;American Diabetes Association. (2019). 5. Facilitating behavior change and well-being to improve health outcomes: Standards of medical care in diabetes&mdash;2020. Diabetes Care, 43(Supplement 1), S48&ndash;S65. </p>
                        <p><a href="https://doi.org/10.2337/dc20-s005">https://doi.org/10.2337/dc20-s005</a></p>
                        <p>Amiel, S. A., Potts, L., Goldsmith, K., Jacob, P., Smith, E. L., Gonder-Frederick, L., Heller, S., Toschi, E., Brooks, A., Kariyawasam, D., Choudhary, P., Stadler, M., Rogers, H., Kendall, Berbudi, A., Rahmadika, N., Tjahjadi, A. I., &amp; Ruslami, R. (2020). type 2 diabetes and its impact on the immune system.&nbsp;Current Diabetes Reviews,&nbsp;16(5), 442&ndash;449.</p>
                        <p><a href="https://doi.org/10.2174/1573399815666191024085838">https://doi.org/10.2174/1573399815666191024085838</a></p>
                        <p>&zwnj;du Pon, E., Wildeboer, A. T., van Dooren, A. A., Bilo, H. J. G., Kleefstra, N., &amp; van Dulmen, S. (2019). Active participation of patients with type 2 diabetes in consultations with their primary care practice nurses &ndash; what helps and what hinders: a qualitative study.&nbsp;BMC Health Services Research,&nbsp;19(1).</p>
                        <p><a href="https://doi.org/10.1186/s12913-019-4572-5">https://doi.org/10.1186/s12913-019-4572-5</a></p>
                        <p>Ishikawa, Y., &amp; Furuyashiki, T. (2022). The impact of stress on immune systems and its relevance to mental illness.&nbsp;Neuroscience Research,&nbsp;175, 16&ndash;24.</p>
                        <p><a href="https://doi.org/10.1016/j.neures.2021.09.005">https://doi.org/10.1016/j.neures.2021.09.005</a></p>
                        <p>Lakshmi Nagendra, Boro, H., &amp; Velmurugan Mannar. (2022).&nbsp;Bacterial infections in diabetes. Nih.gov; MDText.com, Inc. </p>
                        <p><a href="https://www.ncbi.nlm.nih.gov/books/NBK579762/">https://www.ncbi.nlm.nih.gov/books/NBK579762/</a></p>
                        <p>M., Sevdalis, N., Bakolis, I., &amp; de Zoysa, N. (2022). A parallel randomized controlled trial of the hypoglycemia awareness restoration program for adults with type 1 diabetes and problematic hypoglycemia despite optimized self-care (HARPdoc).&nbsp;Nature Communications,&nbsp;13(1). </p>
                        <p><a href="https://doi.org/10.1038/s41467-022-29488-x">https://doi.org/10.1038/s41467-022-29488-x</a></p>
                        <p>Massey, C. N., Feig, E. H., Duque-Serrano, L., Wexler, D., Moskowitz, J. T., &amp; Huffman, J. C. (2019). Well-being interventions for individuals with diabetes: A systematic review.&nbsp;Diabetes Research and Clinical Practice,&nbsp;147, 118&ndash;133. </p>
                        <p><a href="https://doi.org/10.1016/j.diabres.2018.11.014">https://doi.org/10.1016/j.diabres.2018.11.014</a></p>
                        <p>Mirahmadizadeh, A., Khorshidsavar, H., Seif, M., &amp; Sharifi, M. H. (2020). Adherence to medication, diet, and physical activity and the associated factors amongst patients with Type&nbsp;2 Diabetes.&nbsp;Diabetes Therapy,&nbsp;11(2), 479&ndash;494. </p>
                        <p><a href="https://doi.org/10.1007/s13300-019-00750-8">https://doi.org/10.1007/s13300-019-00750-8</a></p>
                        <p>Romesh Khardori, MD, PhD, FACP. (2022, December 31). Infection in patients with diabetes mellitus: background, pathophysiology and etiology, ear, nose, and throat infections. Medscape.com; Medscape. </p>
                        <p><a href="https://emedicine.medscape.com/article/2122072-overview">https://emedicine.medscape.com/article/2122072-overview</a></p>
                        <p>Schakel, L., Veldhuijzen, Dieuwke&nbsp;S., Crompvoets, Paige&nbsp;I., Bosch, Jos&nbsp;A., Cohen, S., van Middendorp, H., Joosten, Simone&nbsp;A., Ottenhoff, Tom&nbsp;H. M., Visser, Leo&nbsp;G., &amp; Evers, Andrea&nbsp;W. M. (2019). Effectiveness of stress-reducing interventions on the response to challenges to the immune system: A meta-analytic review.&nbsp;Psychotherapy and Psychosomatics,&nbsp;88(5), 274&ndash;286. </p>
                        <p><a href="https://doi.org/10.1159/000501645">https://doi.org/10.1159/000501645</a></p>
                        <p>Shields, G. S., Spahr, C. M., &amp; Slavich, G. M. (2020). Psychosocial interventions and immune system function.&nbsp;JAMA Psychiatry,&nbsp;77(10), 1031.</p>
                        <p><a href="https://doi.org/10.1001/jamapsychiatry.2020.0431">https://doi.org/10.1001/jamapsychiatry.2020.0431</a></p>
                        <p>&zwnj;Toniolo, A., Cassani, G., Puggioni, A., Rossi, A., Colombo, A., Onodera, T., &amp; Ferrannini, E. (2019). The diabetes pandemic and associated infections: Suggestions for clinical microbiology.&nbsp;Reviews in Medical Microbiology,&nbsp;30(1), 1&ndash;17.</p>
                        <p><a href="https://doi.org/10.1097/mrm.0000000000000155">https://doi.org/10.1097/mrm.0000000000000155</a></p>
                        <p>Vasile, C. (2020). Mental health and immunity (Review).&nbsp;Experimental and Therapeutic Medicine,&nbsp;20(6), 1&ndash;1. </p>
                        <p><a href="https://doi.org/10.3892/etm.2020.9341">https://doi.org/10.3892/etm.2020.9341</a></p>
                        <p>Vos, J. F. J., Boonstra, A., Kooistra, A., Seelen, M., &amp; van Offenbeek, M. (2020). The influence of electronic health record use on collaboration among medical specialties.&nbsp;BMC Health Services Research,&nbsp;20(1). </p>
                        <p><a href="https://doi.org/10.1186/s12913-020-05542-6">https://doi.org/10.1186/s12913-020-05542-6</a></p>
                        <p>Woldemariam, H. K., Geleta, D. A., Tulu, K. D., Aber, N. A., Legese, M. H., Fenta, G. M., &amp; Ali, I. (2019). Common uropathogens and their antibiotic susceptibility pattern among diabetic patients.&nbsp;BMC Infectious Diseases,&nbsp;19(1).</p>
                        <p><a href="https://doi.org/10.1186/s12879-018-3669-5">https://doi.org/10.1186/s12879-018-3669-5</a></p>
                        <p>Yenehun Worku, G., Belete Alamneh, Y., &amp; Erku Abegaz, W. (2021). Prevalence of bacterial urinary tract infection and antimicrobial susceptibility patterns among diabetes mellitus patients attending Zewditu Memorial Hospital, Addis Ababa, Ethiopia. Infection and Drug Resistance, Volume 14, 1441&ndash;1454.</p>
                        <p><a href="https://doi.org/10.2147/idr.s298176">https://doi.org/10.2147/idr.s298176</a></p>


                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Nursfpx6021assessment2
