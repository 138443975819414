import React, { useEffect, useState } from 'react'


const Newpop = () => {

  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal);
  }

  useEffect(() => {
    setTimeout(() => {
      toggleModal();
    }, 6000)
  }, [])

  const [formData, setFormData] = useState({
    userName: "",
    userEmail: "",
    userPhone: "",
    userSubject: "",
    ip: "",
  })

  const getIp = async () => {
    const response = await fetch('https://api.ipify.org');
    const data = await response.text();
    setFormData({ ...formData, ip: data })
  }
  useEffect(() => {
    getIp()
  }, [])

  const [buttonText, setButtonText] = useState('Proceed');

  const sendData = async (e) => {
    const res = await fetch("https://slack.onlinecourseservices.us/formdata", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }, body: JSON.stringify({
        formData
      })
    },
    toggleModal())

    console.log(res);

    const data = await res.json();
    if (data.status === "error") {
      console.log("error")
    } else {
      setButtonText(buttonText === 'Proceed' ? 'Submitted Successfully' : 'Proceed');
      setTimeout(function () {
        setButtonText("Proceed");
      }, 1500);
      setFormData({ ...formData, userName: "", userEmail: "", userPhone: "", userSubject: "" })
    }

  }
  const [errors, setErrors] = useState({})

  const handleSubmit = (e) => {
    e.preventDefault()
    const validationErrors = {}
    if (!formData.userName.trim()) {
      validationErrors.userName = "Username is required"
    }
    if (!formData.userEmail.trim()) {
      validationErrors.userEmail = "Email is required"
    } else if (!/\S+@\S+\.\S+/.test(formData.userEmail)) {
      validationErrors.userEmail = "Email is not valid"
    }
    if (!formData.userPhone.trim()) {
      validationErrors.userPhone = "Phone number is required"
    }
    if (!formData.userSubject.trim()) {
      validationErrors.userSubject = "Subject is required"
    }
    setErrors(validationErrors)
    if (Object.keys(validationErrors).length === 0) {
      sendData();
      console.log("submitted")
    }
  }

  return (
    <>
      {modal && (
        <div className='popup-container'>
          <div className='new-popup'>
            <div className='ocs2-calculator-outerbox'>
              <div className='calculator-top'>
                <div className='discount'><p>Get 15% off today</p></div>
                <p className='discount-desc'>You're progressing on the path to ultimate achievement.</p>
              </div>

              <div className='calculator-bottom'>
                <p className='action'>Kindly complete the form provided below</p>
                <form name='leadform' className='contact-form' method='post' action=''>
                  <div className='form-top'>
                    <span>
                      <label for="u-name">Full Name*</label>
                      <input type='text' placeholder='Full Name' id='u-name' name='name' value={formData.userName} onChange={(e) => setFormData({ ...formData, userName: e.target.value })} />
                      {errors.userName && <span className='error-text'>{errors.userName}</span>}
                    </span>
                    <span>
                      <label for="u-email">Email*</label>
                      <input type='email' placeholder='Email' id='u-email' name='email' value={formData.userEmail} onChange={(e) => setFormData({ ...formData, userEmail: e.target.value })} />
                      {errors.userEmail && <span className='error-text'>{errors.userEmail}</span>}
                    </span>
                  </div>
                  <label for="u-email">Phone Number*</label>
                  <input type='text' placeholder='Phone Number' id='u-number' name='phone' value={formData.userPhone} onChange={(e) => setFormData({ ...formData, userPhone: e.target.value })} />
                  {errors.userPhone && <span className='error-text'>{errors.userPhone}</span>}
                  <br />
                  <label for="u-email">Subject*</label>
                  <input type='text' placeholder='What do you need help with?' id='u-subject' name='subject' value={formData.userSubject} onChange={(e) => setFormData({ ...formData, userSubject: e.target.value })} />
                  {errors.userSubject && <span className='error-text'>{errors.userSubject}</span>}
                  <button type='submit' onClick={handleSubmit}>{buttonText}</button>
                </form>
                <p className='form-guarentee'>100% Money Back Guarentee</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Newpop
