import React from 'react';
import { Container } from 'react-bootstrap';

const Msnhero = () => {
  return (
    <div className='bsn-hero'>
      <Container>
        <div className='bsn-hero-title'>
            <h1>MSN</h1>
            <div className='bsn-line'></div>
            <h2>Master of Science in Nursing</h2>
        </div>
      </Container>
    </div>
  )
}

export default Msnhero