import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx5004a1 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 5004 Assessment 1: Pledge of Academic Honesty</title>
                <meta name='description'
                    content='Craft your Pledge of Academic Honesty with precision! Optimize Pledge of Academic Honesty efficiently. Get started now!' />
                <meta name='keywords' content='nurs fpx 5004 assessment 1' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 5004 Assessment 1:< br /><span> Pledge of Academic Honesty</span></h1>
                    {/* <p>Hi. I’m (Student), and currently, I want to recommend in-service training that encourages the medical staff < br />to approach patient treatment more proactively. </p>
                    <div className='sample-hero-links'>
                        <a href="#">Online Course Services</a>
                        <a href="#">Free Samples</a>
                        <a href="#">Online Course Services</a>
                        <a href="#">Online Course Services</a>
                    </div> */}
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/NURSFPX5004A1FI.png" alt="" />
                    <div className='main-container-sl-content'>
                        <p><strong>Looking for guidance with your </strong><strong>NURS FPX 5004 Assessment 1? Our experts are here to assist you. Reach out to us for support today.</strong></p>
                        <h2>Pledge of Academic Honesty</h2>
                        <p>All the work I do for this class and all my classes will be entirely and completely my own. This means I will be the sole author of it. This also means that as far as I know, these will not be the words, sentences, and paragraphs of anyone else, unless I am directly quoting, paraphrasing, or summarizing the work of someone else.</p>
                        <p>I understand that using any part of a peer&rsquo;s discussion post without proper citation is considered plagiarism. I understand that using a peer&rsquo;s discussion post as a template for my own post, and only slightly modifying words, is also plagiarism.</p>
                        <p>I will alert my instructor if another learner has misrepresented my work or the work of others. I understand that we all have a role to play in protecting the integrity of the Capella degree we are all working so hard to achieve.</p>
                        <img src="images/NURSFPX5004A1MI.png" alt="" />
                        <p>If I am quoting someone directly, I will add quotation marks; provide some context for the quotation; and format the quote according to APA, including both in-paragraph citation with page number and a full reference citation at the end.</p>
                        <p>If I am paraphrasing or summarizing work from another source, I will cite it both within the text itself and as a reference at the end of my work. When paraphrasing or summarizing, I will use words other than the words being paraphrased, and I will change the basic sentence structure.</p>
                        <p>Review the following:</p>
                        <ul>
                            <li><a href="https://campustools.capella.edu/redirect.aspx?linkid=4953">Academic Honesty &amp; APA Style and Formatting</a>.</li>
                            <li><a href="https://campustools.capella.edu/redirect.aspx?linkid=3436">University Policy 3.01.01: Academic Integrity and Honesty [PDF]</a>.</li>
                            <li><a href="https://campustools.capella.edu/redirect.aspx?linkid=3573">Maintaining Academic Honesty</a>.</li>
                            <li><a href="https://campustools.capella.edu/redirect.aspx?linkid=3719">Avoiding Plagiarism</a>.</li>
                        </ul>
                        <p>If I use any part of someone else&rsquo;s work in a way that violates any of the above statements, then I understand that I will be plagiarizing; a <em>very serious</em> act of academic dishonesty per Capella University&rsquo;s policies. If any instructor discovers an act of academic dishonesty, I acknowledge that the instructor will:</p>
                        <ul>
                            <li>Contact me directly about the alleged act of academic dishonesty.</li>
                            <li>Allow me to respond and demonstrate that plagiarism did not occur.</li>
                            <li>Impose sanctions, including, but not limited to: zero points for the unit, a failing final grade, et cetera.</li>
                            <li>Officially report the academic honesty violation to Capella per Capella University&rsquo;s academic policies.</li>
                        </ul>
                        <p>If I have any questions about this pledge, I will contact my instructor prior to signing it. By typing my full name below, I understand and agree to comply with all of the above rules from this day on, all of which are consistent with Capella University&rsquo;s academic policies.</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="1400">
                                        <p>Please type your name here:____________________</p>
                                    </td>
                                    <td width="1200">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td width="1200">
                                        <p>Today&rsquo;s date:____________________</p>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <p class="text-wrap"><strong>Require help with your Assessments(<a href="https://onlinecourseservices.us/nurs-fpx-4000-assessment-3">NURS FPX 4000 Assessment 3</a>) ? Our expert team is standing by to assist you. Get in touch with us now</strong></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx5004a1
