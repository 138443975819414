import React from 'react'
import Msschoolhero from './Msschoolcomponent/Msschoolhero'
import Msschooldegreedetail from './Msschoolcomponent/Msschooldegreedetail'
import Bsnfeatures from '../Nursing/bsnpagecomponent/Bsnfeatures'
import Bannertwo from '../../homepage/Bannertwo'
import Bsnfaqs from '../Nursing/bsnpagecomponent/Bsnfaqs'

const Msschool = () => {
  return (
    <div>
      <Msschoolhero/>
      <Msschooldegreedetail/>
      <Bsnfeatures/>
      <Bannertwo/>
      <Bsnfaqs/>
    </div>
  )
}

export default Msschool
