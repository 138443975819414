import React from 'react'
import Psydschoolhero from './Psydschoolcomponent/Psydschoolhero'
import Psydschooldegreedetail from './Psydschoolcomponent/Psydschooldegreedetail'
import Bsnfeatures from '../Nursing/bsnpagecomponent/Bsnfeatures'
import Bannertwo from '../../homepage/Bannertwo'
import Bsnfaqs from '../Nursing/bsnpagecomponent/Bsnfaqs'

const Psydschool = () => {
  return (
    <div>
      <Psydschoolhero/>
      <Psydschooldegreedetail/>
      <Bsnfeatures/>
      <Bannertwo/>
      <Bsnfaqs/>
    </div>
  )
}

export default Psydschool
