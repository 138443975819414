import React from 'react'
import Mspsyhero from './Mspsycomponent/Mspsyhero'
import Mspsydegreedetail from './Mspsycomponent/Mspsydegreedetail'
import Bsnfeatures from '../Nursing/bsnpagecomponent/Bsnfeatures'
import Bannertwo from '../../homepage/Bannertwo'
import Bsnfaqs from '../Nursing/bsnpagecomponent/Bsnfaqs'

const Mspsy = () => {
  return (
    <div>
      <Mspsyhero/>
      <Mspsydegreedetail/>
      <Bsnfeatures/>
      <Bannertwo/>
      <Bsnfaqs/>
    </div>
  )
}

export default Mspsy
