import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { Link } from 'react-router-dom'
import 'react-tabs/style/react-tabs.css';
import { Helmet } from 'react-helmet';
import {FaStar, FaRegArrowAltCircleRight,FaPhoneAlt } from "react-icons/fa";
import Container from 'react-bootstrap/esm/Container';
import Carousel from 'react-grid-carousel';
import Newpop from '../../components/Newpop';

const Mhafpx5014 = () => {
  const newSamples = [
    {
        image: "https://onlinecourseservices.us/images/MHAFPX5006A1FI.png",
        title: "MHA FPX 5001 Assessment 1 Health Care Finance...",
        description: "Looking for guidance with your MHA FPX 5006 Assessment 1? ...",
        samplelink: "https://onlinecourseservices.us/mha-fpx-5006-assessment-1",
    },
    {
        image: "https://onlinecourseservices.us/images/MHAFPX5006A2FI.png",
        title: "MHA FPX 5001 Assessment 2 Billing Policies and...",
        description: "Looking for guidance with your MHA FPX 5006 Assessment 2?...",
        samplelink: "https://onlinecourseservices.us/mha-fpx-5006-assessment-2",
    },
    {
        image: "https://onlinecourseservices.us/images/MHAFPX5006A3FI.png",
        title: "MHA FPX 5001 Assessment 3 Cost-Benefit Analysis",
        description: "Looking for guidance with your MHA FPX 5006 Assessment 3?...",
        samplelink: "https://onlinecourseservices.us/mha-fpx-5006-assessment-3",
    },
    {
        image: "https://onlinecourseservices.us/images/MHAFPX5006A4FI.png",
        title: "MHA FPX 5001 Assessment 4 Operating Budget Proposal",
        description: "Looking for guidance with your MHA FPX 5006 Assessment 4?...",
        samplelink: "https://onlinecourseservices.us/mha-fpx-5006-assessment-4",
    },

]

const newServices = [
    {
        icon: "fa-solid fa-user-pen icon",
        title: "American Writers",
        Desc: "Hire our competent writers who are masterminds in crafting high-scoring and excellent assignment solutions.",
    },
    {
        icon: "fa-solid fa-arrow-trend-up icon",
        title: "High-Scoring Solutions",
        Desc: "Get excellent solutions for nursing coursework. Our writing experts enable you to become successful.",
    },
    {
        icon: "fa-solid fa-book-open icon",
        title: "Research Experts",
        Desc: "TTake well-researched material and enjoy good marks with the praise of professors.",
    },
    {
        icon: "fa-solid fa-text-height icon",
        title: "Good Formatting",
        Desc: "Buy assignment solutions that are well-formatted, well-cited, and well-written. ",
    },
]

const orderprocess = [
    {
        icon: "fa-solid fa-chalkboard-user icon",
        title: "Provide Guidelines",
        Desc: "Fill out the form with all the details.",
    },
    {
        icon: "fa-regular fa-money-bill-1 icon",
        title: "Make Payment",
        Desc: "Make your payment through a credit card and bank account.",
    },
    {
        icon: "fa-solid fa-person-chalkboard icon",
        title: "Get experts",
        Desc: "Our writers will contact you and assist you in your assessments!",
    },
]

const newFeatures = [
    {
        icon: "fa-solid fa-chart-line icon",
        title: "Good Performance",
        Desc: "Our assignment solutions promote the student’s academic performance. We are devoted to helping you!",
    },
    {
        icon: "fa-solid fa-pen-to-square icon",
        title: "Professional Writers",
        Desc: "Hire our highly experienced writers to offer excellent assignment writing help services.",
    },
    {
        icon: "fa-solid fa-headset icon",
        title: "Complete Support",
        Desc: "Our academic experts offer full support in the assignment writing process. Students only receive the quality-written solutions.",
    },
    {
        icon: "fa-solid fa-house-laptop icon",
        title: "Flawless Services",
        Desc: "We offer high-quality assignment writing help services. We offer plagiarism-free and revised content.",
    },
]
return (
    <>
        <Helmet>
            <title>MHA FPX 5014 Healthcare Quality, Risk, and Regulatory Compliance</title>
            <meta name='description'
                content='Ace your Elevate your learning with MHA FPX 5014 Healthcare Quality, Risk, and Regulatory Compliance. Get expert assistance for success. Affordable rates.' />
            <meta name='keywords' content='MHA FPX 5014 Healthcare Quality, Risk, and Regulatory Compliance' />
        </Helmet>
        <div className='s-hero1'>
            <div className='ocs2-main-herosection'>
                <h1><span className='ocs2-topHeading'>MHA FPX 5014 <br />HEALTHCARE QUALITY, RISK, AND REGULATORY COMPLIANCE</span></h1>
                <p className='rating-text'>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                <div className='ocs2-ratting1'>
                    <div className='ocs2-ratting-provider1'>
                        <SiTrustpilot className='ocs2-trustpilot-icon1' />
                        <div className='ocs2-ratting-text'>
                            <span className='ocs2-ratting-stars1'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                            <p>TrustPilot</p>
                        </div>
                    </div>
                    <div className='ocs2-ratting-provider1'>
                        <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon1' />
                        <div className='ocs2-ratting-text'>
                            <span className='ocs2-ratting-stars1'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                            <p>OnlineCourseServices</p>
                        </div>
                    </div>
                    <div className='ocs2-ratting-provider1'>
                        <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon1' />
                        <div className='ocs2-ratting-text'>
                            <span className='ocs2-ratting-stars1'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                            <p>Sitejabber</p>
                        </div>
                    </div>
                </div>
                <div className='sample-list-btn'>
                    <Link to={"/order"} className='ocs2-orderNow-btnn'> <button className='ocs2-orderNow'>Order Now  &nbsp;<FaRegArrowAltCircleRight /></button></Link>
                </div>
            </div>

        </div>

        <div className='services s-section'>
            <div className='s-right'>
                <span>Our Features</span>
                <h2>WHY CHOOSE US?</h2>
                <p>Online Course Services provides top-notch MHA FPX 5014 Healthcare Quality, Risk, and Regulatory Compliance writing assistance tailored specifically for MHA students at Capella University. Our seasoned team of experts comprises dedicated writers, intellectuals, and mentors adept at guiding you through your MHA journey. With a wealth of experience in assisting countless MHA students, we specialize in helping individuals excel in their MHA Leadership coursework. From comprehensive support with assignments to navigating through assessments, our professional services are renowned for delivering exceptional results. Elevate your academic performance and achieve high scores with our specialized MHA FPX 5014 Healthcare Quality, Risk, and Regulatory Compliance today!</p>
                <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer&nbsp;<FaRegArrowAltCircleRight /></button></Link></div>
            </div>
            <div className='s-left'>
                {newFeatures.map((newserivce) => {
                    return (
                        <div className='s-single'>
                            <i class={newserivce.icon}></i>
                            <h3>{newserivce.title}</h3>
                            <p>{newserivce.Desc}</p>
                        </div>
                    )
                })}
            </div>
        </div>
        <div className='process services freesamples'>
            <h2>HOW IT WORKS?</h2>
            <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
            <div className='processboxes'>
                {orderprocess.map((order) => {
                    return (

                        <div className='singleprocess'>
                            <i class={order.icon}></i>
                            <h3>{order.title}</h3>
                            <p>{order.Desc}</p>
                        </div>
                    )
                })}
            </div>
        </div>

        <div className='freesamples services'>
            <h2>GET CAPELLA MHA FLEXPATH ASSESSMENT SOLUTIONS!</h2>
            <p>We provide excellent Capella University MHA FlexPath assessment solutions for students in their Capella MHA FlexPath Courses.</p>
            <div className='sampleboxes'>
                {newSamples.map((sample) => {
                    return (

                        <div className='singlebox'>
                            <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                            <div className='text'>
                                <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                <p>{sample.description}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
        <div className='ocs2-bannerone'>
    <div className='ocs2-bannerone-image'>
      <img src='images/bannerone.png' alt='' className='ocs2-bannerone-image-box'/>
    </div>
    <div className='ocs2-bannerone-detail'>
      <p className='ocs2-bannerone-detail-title'>ENJOY OUR EXCELLENT CAPELLA MHA <br/>FLEXPATH PAPER TUTORING SERVICES</p>
      <p className='ocs2-bannerone-detail-text'>Looking to excel in your MHA Leadership course, specifically MHA FPX 5014 Healthcare Quality, Risk, and Regulatory Compliance? Our Capella University flex path assistance services are here to ensure your success.</p>
      <div className='ocs2-orderNow-box'>
        <Link to={"/order"} className='ocs2-orderNow-btnn'><button className='ocs2-orderNow'>Order Now &nbsp;<FaRegArrowAltCircleRight/></button></Link>
        <img src='images/time.png' alt='' className='ocs2-orderNow-time'/>
        <div className='ocs2-bannerone-phoneNo'>
          <FaPhoneAlt className='ocs2-bannerone-phoneNo-icon'/>
          <span>
            <p>Call us at</p>
            <strong>+1(669) 209-0161</strong>
          </span>
        </div>
      </div>
    </div>
  </div>
        <div className='services s-section'>
            <div className='s-left'>
                {newServices.map((newserivce) => {
                    return (
                        <div className='s-single'>
                            <i class={newserivce.icon}></i>
                            <h3>{newserivce.title}</h3>
                            <p>{newserivce.Desc}</p>
                        </div>
                    )
                })}
            </div>
            <div className='s-right'>
                <span>Our Professional Writing Services!</span>
                <h2>OUR SERVICES</h2>
                <p>When it comes to unparalleled support and top-tier assistance with your MHA FPX 5014 Healthcare Quality, Risk, and Regulatory Compliance coursework, our online course services stand out as the premier choice. Our dedicated team of academic experts, tutors, and mentors is poised to guide you through every facet of your MHA journey. Whether it's crafting insightful assessments, polishing your leadership papers, or providing adept solutions for exams, our professionals are well-equipped to elevate your learning experience. Trust in our track record of excellence and let us steer you towards success in your MHA Leadership pursuits. With our commitment to quality and your aspirations in mind, we ensure that every step of your MHA FPX 5014 Healthcare Quality, Risk, and Regulatory Compliance journey is met with confidence and proficiency.</p>
                <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer&nbsp;<FaRegArrowAltCircleRight /></button></Link></div>
            </div>
        </div>
        <div className='sample-list-category'>
            <div className='sample-list-btn'>
                <Link to={"/order"} className='ocs2-orderNow-btnn'> <button className='ocs2-orderNow'>Get Free MHA FPX Samples  &nbsp;<FaRegArrowAltCircleRight /></button></Link>
            </div>
            <h4><span className='heading'>LOOKING TO FINISH YOUR CAPELLA MHA FLEXPATH</span></h4>
            <h2>WE ASSIST WITH CAPELLA MHA CLASSES!</h2>
            <Container>
                <p>
                    <ul>
                        <li><a href="https://onlinecourseservices.us/mha-fpx-5006-assessment-1">MHA FPX 5006 Assessment 1 Health Care Finance Overview</a></li>
                        <li><a href="https://onlinecourseservices.us/mha-fpx-5006-assessment-2">MHA FPX 5006 Assessment 2 Billing Policies and Procedures</a></li>
                        <li><a href="https://onlinecourseservices.us/mha-fpx-5006-assessment-3">MHA FPX 5006 Assessment 3 Cost-Benefit Analysis</a></li>
                        <li><a href="https://onlinecourseservices.us/mha-fpx-5006-assessment-4">MHA FPX 5006 Assessment 4 Operating Budget Proposal</a></li>
                    </ul>
                    <strong>And Many More.....</strong>
                </p>
            </Container>
        </div>
        <Container>
  <div className='ocs2-testimonials' id='testimonial'>
    <h2><span>LISTEN TO</span><strong> WHAT OUR SATISFIED STUDENTS SAY<br/>ABOUT US</strong></h2>
    <div className='ocs2-testimpnials-list'>
    <Carousel cols={3} rows={1} gap={25} hideArrow={true} showDots={true} autoplay={5000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
        <Carousel.Item>
            <img className='ocs2-img' src='images/usericon.png' alt=''/>
            <div className='ocs2-user-card'>
                <p className='c-id'>Student ID: #432</p>
                <hr/>
                <span className='ocs2-user-card-icon'><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/></span>
                <p><span>Writer: </span><strong>Alina</strong></p>
                <h3>MHA Assessment</h3>
                <p className='ocs2-user-card-details'>The tutors were beneficial in my Capella's FlexPath program. They provided splendid tutoring sessions! I graduated feeling empowered and prepared to tackle new challenges in the mha area.</p>
            </div>
        </Carousel.Item>
        <Carousel.Item>
        <img className='ocs2-img' src='images/usericon.png' alt=''/>
            <div className='ocs2-user-card'>
                <p className='c-id'>Student ID: #452</p>
                <hr/>
                <span className='ocs2-user-card-icon'><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/></span>
                <p><span>Writer: </span><strong>Michael</strong></p>
                <h3>MHA Paper</h3>
                <p className='ocs2-user-card-details'>I am thankful to the team of online course services for providing comprehensive help. They 
enabled me to pass Capella's MHA FlexPath degree with 90% marks. </p>
            </div>
        </Carousel.Item>
        <Carousel.Item>
        <img className='ocs2-img' src='images/usericon.png' alt=''/>
            <div className='ocs2-user-card'>
                <p className='c-id'>Student ID: #632</p>
                <hr/>
                <span className='ocs2-user-card-icon'><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/></span>
                <p><span>Writer: </span><strong>James</strong></p>
                <h3>MHA Thesis</h3>
                <p className='ocs2-user-card-details'>I managed to pass Capella's FlexPath program with excellent grades. It was possible due to 
the help of their MHA FlexPath writers. </p>
            </div>
        </Carousel.Item>
        <Carousel.Item>
        <img className='ocs2-img' src='images/usericon.png' alt=''/>
            <div className='ocs2-user-card'>
                <p className='c-id'>Student ID: #535</p>
                <hr/>
                <span className='ocs2-user-card-icon'><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/></span>
                <p><span>Writer: </span><strong>Viviane Sousa</strong></p>
                <h3>Capstone Project</h3>
                <p className='ocs2-user-card-details'>I am very happy to receive 95% grades in my MHA Capstone Project. I am very thankful to the team of Capella Flexpath expert writers.</p>
            </div>
        </Carousel.Item>
        <Carousel.Item>
        <img className='ocs2-img' src='images/usericon.png' alt=''/>
            <div className='ocs2-user-card'>
                <p className='c-id'>Student ID: #574</p>
                <hr/>
                <span className='ocs2-user-card-icon'><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/></span>
                <p><span>Writer: </span><strong>Leslie Alexander</strong></p>
                <h3>Research Paper</h3>
                <p className='ocs2-user-card-details'>Online Course Services provide the best research paper writing services for Capella University flex path students. I am happy to get 98% marks on my research paper.</p>
            </div>
        </Carousel.Item>
        <Carousel.Item>
        <img className='ocs2-img' src='images/usericon.png' alt=''/>
            <div className='ocs2-user-card'>
                <p className='c-id'>Student ID: #956</p>
                <hr/>
                <span className='ocs2-user-card-icon'><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/><FaStar className='ocs2-user-card-star'/></span>
                <p><span>Writer: </span><strong>Robert Fox</strong></p>
                <h3>Dissertation</h3>
                <p className='ocs2-user-card-details'>I am thankful to Capella MHA Flex path writers for helping me with my dissertation. Online Course Services is the best platform for mha students. I recommend their academic help services to everyone!</p>
            </div>
        </Carousel.Item>
    </Carousel>
    </div>
  </div>
  </Container>
  <Newpop />
    </>

)

}

export default Mhafpx5014
