import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";

const MsnFlexpath = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Assessment Writers",
            Desc: "Hire our professional Capella MSN flex path writers. Get compliments from your professors!",
        },
        {
            icon: "fa-solid fa-pen-to-square icon",
            title: "Writing Help",
            Desc: "We provide comprehensive Capella Flex Path MSN writing services for Capella University MSN  students!",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Courses Help",
            Desc: "We have nursing experts to help you in your Capella Flex path MSN courses.",
        },
        {
            icon: "fa-solid fa-graduation-cap icon",
            title: "Thesis Help",
            Desc: "Get comprehensive support through Capella Flex Path MSN Thesis Writing services!",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a Capella University MSN intellectuals team to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We are always available to help you with your Capella FlexPath MSN coursework!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to Capella University MSN students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek complete support from Capella FlexPath MSN nursing experts.",
        },
    ]
    return (
        <>
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
                    <div className='sherotop-left'>
                    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
                    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
                    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
                    </div>
                    <div className='cal-box'><Calculator/></div> 
                </div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>TAKE THE GUIDANCE IN CAPELLA<br /> MSN FLEX PATH COURSES</span><br />
                                </h1>
                                <h2>DO YOU WANT CAPELLA UNIVERSITY MSN HELP?</h2>
                            <p className='ocs2-topText'>Our Capella MSN Flex Path nursing writers team will help you with your RN to MSN Capella University coursework!</p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Expert</h2>
                            <p>We have skilled teams of Capella FlexPath MSN writers to guide you in your coursework.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Get Coursework Solutions</h2>
                            <p>We have a team of Capella RN to MSN  FlexPath writers to help you!</p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET CAPELLA FLEX PATH MSN PAPERS!</h2>
                <p>We provide excellent Capella RN to MSN  FlexPath writing services for Capella University students in their Capella MSN FlexPath Courses.</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Prestigious Services</span>
                    <h2>CAPELLA UNIVERSITY MSN COURSE WRITING SERVICES</h2>
                    <p>Online course services are well known for offering excellent and high-quality Capella MSN FlexPath course writing services. Our professional team of academic writers, tutors, and mentors will help you in your RN to MSN Capella University courses. Our intellectuals are well-versed in assisting the students in their Capella Flex path MSN assessments, nursing papers, and exam solutions. Students receive comprehensive help in their online coursework for the Capella RNto MSN  FlexPath degree. Students trust our high-scoring Capella Flex Path MSN writing services.</p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>Avail Professional Capella Msn Flex Path Writing Services!</strong></Tab>
                        <Tab><strong>Online Class Help For RN To MSN Capella University Students.</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p><strong>Excelling in Your Capella MSN FlexPath Program</strong><br />

                            Many nurses prefer online training to enhance their careers in the fast-paced world. Capella University's MSN FlexPath software provides help in earning your Master of Science in Nursing. However, navigating the coursework and tests can be challenging, especially for busy running specialists. This is when Online Course Services may be your partner in success, providing professional help tailored to your desires.
                            <br /><br />
                            <strong>Why pick out Online Course Services?</strong>
                            <br />
                            RN to MSN Capella University writers are dedicated to assisting college students in their educational adventures. Capella FlexPath MSN intellectuals provide several services, including tutoring, writing, and evaluation assistance. Our Capella MSN FlexPath course help is specially offered to Capella FlexPath MSN program students. We will help you succeed in your coursework and help you achieve your instructional dreams.
                            <br /><br />
                            <strong>Capella MSN FlexPath Course Help</strong>
                            <br />
                            The Capella FlexPath MSN program is known for its rigorous coursework and high educational requirements. Capella RNto MSN FlexPath assessment help can provide you with professional assistance. We will help you to overcome the complexities of the program. Whether you want help with course substances or completing assignments, our experienced Capella FlexPath MSN tutors and academic experts are available.
                            <br /><br />
                            <strong>Capella University MSN Class Help</strong>
                            <br />
                            With Capella University MSN class help, you can get personalized help on your Capella University MSN lessons. Our Capella FlexPath MSN tutors are well-versed in the curriculum. Capella MSN FlexPath course help might offer the guide you need to excel in your coursework. Whether you're struggling with a specific concept or want help with time control, we are here to help you.</p>
                    </TabPanel>
                    <TabPanel>
                        <p><strong>RN to MSN Capella University Writers</strong><br />
                            Take Capella MSN FlexPath course help as a Capella University student for a better future. Transitioning from an RN to an MSN program may be challenging. With the assistance of RN to MSN Capella University writers, you can easily experience transition. Their team of Capella FlexPath MSN tutors specializes in helping RNs in their MSN program. We are professional in imparting expert guidance and support every step of the way.
                            <br /><br />
                            <strong>Capella FlexPath MSN Tutors</strong>
                            <br />
                            FlexPath programs require excessive self-motivation and guidance. Occasionally, you may want more support to stay on the right track. Online Course Services offer personalized Capella MSN FlexPath course help and tutoring services to help you stay focused on your Capella FlexPath MSN degree program. Their Capella FlexPath MSN tutors can provide you with the guidance to complete your coursework.
                            <br /><br />
                            <strong>Capella RN to MSN FlexPath Assessment Help</strong>
                            <br />
                            Assessments are a critical part of the FlexPath application, and Online Course Services let you prepare it correctly. We will provide Capella MSN FlexPath course help with knowledge evaluation necessities, growing an examination plan, or reviewing your Capella FlexPath MSN assessments. Our professional RN to MSN Capella University writers are here to help you succeed.
                            <br /><br />
                            <strong>  Conclusion</strong>
                            <br />
                            The Capella MSN FlexPath program offers a convenient way to earn your Master of Science in Nursing. It can also be very challenging. With the assistance of Capella FlexPath MSN tutors, you can get the help you want to excel in your coursework and acquire your academic desires. We will help you understand study materials, get ready for exams, or complete assignments. Our specialists are here to help you in becoming successful.</p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Features</span>
                    <h2>WHY CHOOSE US?</h2>
                    <p>Online Course Services offers comprehensive Capella MSN FlexPath Writing services for nursing students. We have great teams of Capella University MSN writers, intellectuals, and mentors to help you. We have assisted millions of Capella University MSN students in their Capella RN to MSN  FlexPath coursework. Our professional Capella Flex Path MSN writing services are very famous among Capella University students. We have helped millions of nursing students with their Capella Flex path MSN assessments. We have well-known RN to MSN Capella University intellectuals for complete assistance in Capella Flex path MSN courses. We will handle all assessments related to your coursework and much more! Avail high scores in your coursework with our help right now!</p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Do you offer Capella MSN FlexPath writing services?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! Our Capella MSN Flex path writers offer complete guidance in Capella Flex path MSN courses. 
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I hire a tutor for my Capella University MSN courses?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We have excellent Capella MSN Flexpath nursing tutors to help you in your coursework.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you do RN to MSN Capella University classes for me? </h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We are offering helpful class-taking services to Capella MSN Flexpath nursing students.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I avail of Capella FlexPath MSN tuition at a discount?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We are offering comprehensive tutoring sessions to students in their courses. Take help from our skilled tutors, writers, and mentors.</div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you help me with my Capella MSN Flex path exams?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We have a team of highly intellectual Capella MSN Flex Path exam experts to provide exam help.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you help with the Capella RN to MSN  FlexPath thesis?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>
                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! Our team of competent thesis writers will help you with your nursing thesis.                               </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you write the Capella University MSN Flexpath assessment for me?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We will provide flawless and high-quality assessment solutions for you.                                </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I get a personal tutor for my Capella MSN Flex Path courses?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We offer personal tutors for Capella MSN Flex Path nursing students at a fantastic discount.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>

                        </Accordion>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default MsnFlexpath
