import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";

const Capellacapstonebsn = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Project Writers",
            Desc: "Hire our professional BSN Capstone Project writers. Get high grades with exceptional performance!",
        },
        {
            icon: "fa-solid fa-book-open-reader icon",
            title: "Research Help",
            Desc: "We provide a team of professional researchers for exceptional research in your Nursing Capstone Project!",
        },
        {
            icon: "fa-solid fa-pen-to-square icon",
            title: "Writing Classes",
            Desc: "We have skilled professors to offer writing classes for writing a flawless Capstone project.",
        },
        {
            icon: "fa-solid fa-graduation-cap icon",
            title: "Thesis Help",
            Desc: "Get exceptional guidance from writing experts by seeking Thesis Writing services! ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a Capella Capstone Project BSN writing team to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We are always ready to assist you with your Nursing Capstone Project anytime!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We guarantee that your nursing capstone projects are always plagiarism-free.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek comprehensive guidance in the BSN Capstone Project from nursing experts.",
        },
    ]
    return (
        <>
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
                    <div className='sherotop-left'>
                    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
                    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
                    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
                    </div>
                    <div className='cal-box'><Calculator/></div> 
                </div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>CAPELLA CAPSTONE PROJECT BSN HELP</span><br />
                               </h1>
                                <h2>ARE YOU SEARCHING FOR NURSING <br />CAPSTONE PROJECT WRITERS?</h2>
                            <p className='ocs2-topText'>Enjoy a high-scoring BSN Capstone Project written by professional Nursing Capstone Project writers.

Secure your future right now!</p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Writers</h2>
                            <p>Our skilled Capella Capstone Project BSN writers will guide you.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Get Project Solutions</h2>
                            <p>Get high-scoring BSN Capstone Project solutions written by professionals! </p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET NURSING CAPSTONE PROJECT SOLUTIONS!</h2>
                <p>Enjoy excellent grades in your BSN Capstone Project with the help of our exceptional writing services for Capella University students in their projects.</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Intellectual Services</span>
                    <h2>NURSING CAPSTONE PROJECT WRITING SERVICES</h2>
                    <p>We have a track record of offering excellent and high-quality Capella Capstone Project BSN writing services. Our professional team of BSN Capstone Project writers, tutors, and mentors. We will help you in your Capella Capstone Project BSN writing journey. Our intellectual writers are well-versed in assisting students in their Capstone projects. We have supported the writers with their Nursing Capstone Project, nursing papers, and exam solutions. Students receive comprehensive help in their online BSN and DNP coursework and the BSN Capstone Project. Students trust our high-scoring BSN Capstone Project writing services.</p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>Excel at Capella Capstone Project BSN</strong></Tab>
                        <Tab><strong>Enhance Your Nursing Capstone Project Experience with our Support Services</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p>Are you a nursing student at Capella University ready for your BSN Capstone Project? Tutors Academy provides specialized academic assistance services to help you succeed in this critical undertaking. Our experienced tutors are devoted to providing the aid you need to excel in your Nursing Capstone Project.
                        <br /> <br />
                            <strong>Capella Capstone Project BSN Support</strong><br />
                            The Capella BSN Capstone Project is a fruit of your nursing schooling, requiring you to illustrate your knowledge and abilities in a healthcare setting. Online Course Services gives comprehensive support that will help you efficaciously throughout your BSN Capstone Project, such as assistance with research, writing, and presentation skills.
                            <br /><br />
                            <strong>Expert Nursing Tutors</strong>
                            <br />
                            Our tutors are skilled nursing professionals who deeply understand the BSN Capstone Project requirements at Capella University. They can offer valuable insights and comments to help you expand a first-rate undertaking that meets the academic requirements of your program.
                            <br /><br />
                            <strong>Personalized Academic Support</strong>
                            <br />
                            At Online Course Services, every individual has a right to fulfill their academic goals. That is why our academic assistance offerings are personalized to suit your needs. Our tutors are here to offer help with the Nursing Capstone Project, research methods, information evaluation, and project control.
                            <br /><br />
                            <strong>Flexible Learning Solutions</strong>
                            <br />
                            We provide flexible BSN Capstone Project solutions to deal with your busy schedule. Whether you choose one-on-one tutoring classes, group a look at periods, or asynchronous online help, We have all the assets and knowledge to fulfill your requirements.
                            <br /><br />
                            <strong>Achieve Your Academic Goals with Online Course Services</strong>
                            <br />
                            Refrain from letting the demanding situations of the Capella Capstone Project BSN stand in your way. You may overcome obstacles and achieve your academic and professional goals with our educational assistance services. Contact us today to learn how we can help you with your Nursing Capstone Project at Capella University.
                            </p>
                    </TabPanel>
                    <TabPanel>
                        <p>Preparing for your BSN Capstone Project at Capella University may be a frightening mission. With our educational assistance, you can confidently overcome it. Our experienced tutors are here to provide the aid you need to excel on your Nursing Capstone Project. <br /><br />
                            <strong>Personalized Support for Your BSN Capstone Project</strong><br />
                            The BSN Capstone Project is a big part of your nursing schooling, requiring you to apply your understanding and abilities to a real-global healthcare difficulty. We offer personalized help to expand and execute your BSN Capstone Project writing process.  Our Nursing Capstone Project writing services include assistance with research and information analysis.
                            <br /><br />
                            <strong>Expert Nursing Tutors</strong>
                            <br />
                            Our tutors are experienced nursing specialists with a deep knowledge of the BSN Capstone Project requirements at Capella University. They can provide professional guidance and feedback to help you in your Nursing Capstone Project. We will help you produce an outstanding task that meets the educational requirements of your program.
                            <br /><br />
                            <strong>Flexible Learning Options</strong>
                            <br />
                            We recognize that every student has particular knowledge of choices and schedules. That's why we offer bendy gaining knowledge of alternative techniques for writing a Nursing Capstone Project. This process includes one-on-one tutoring and multiple other options for seeking help from professionals.
                            <br /><br />
                            <strong>Comprehensive Academic Assistance Services</strong>
                            <br />
                            Online Course Services provide professional writing help to students in their Nursing Capstone Project. We assist students in developing their research questions and accomplishing literature evaluations. Enjoy the resources and expertise to help you succeed in your BSN Capstone Project.
                            <br /><br />
                            <strong>Take Your Nursing Capstone Project to the Next Level</strong>
                            <br />
                            Refrain from permitting the challenges of the BSN Capstone Project to ensure you return. With Tutors Academy's educational help services, you can take your assignment to the following degree and attain your academic and expert goals. Contact us these days to research how we will help your Nursing Capstone Project at Capella University.
                            </p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Qualities!</span>
                    <h2>WHY CHOOSE US?</h2>
                    <p>Online Course Services is well-known as the mastermind of writing exceptional Nursing Capstone Projects. We have a team of professionals offering flawless Capella Capstone Project BSN writing services. Our comprehensive BSN Capstone Project Writing services for nursing students. We have great nursing Capstone project writers, intellectuals, and mentors to help you. We have assisted millions of Capella University BSN students in their BSN Capstone Project. Our professional BSN Capstone Project writing services are very famous among Capella University students. We have helped millions of nursing students with their Nursing Capstone Project. Enjoy high scores in your nursing Capstone project with our help!</p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Do you offer Capella Capstone Project BSN writing services?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! Our Nursing Capstone Project writers offer complete guidance in the BSN Capstone Project.  
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I hire a tutor for my Nursing Capstone Project?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We have teams of BSN Capstone Project tutors to help you with your coursework.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you do the BSN Capstone Project for me?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We offer helpful BSN Capstone Project writing services to Capella BSN Flexpath nursing students.  
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I avail of BSN Capstone Project writing services at a discount?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We are offering exceptional Nursing Capstone Project writing services for students.</div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you help me with my BSN Capstone Project?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! Our highly intellectual Capella Capstone Project BSN writing experts will help you throughout the writing process. 
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you help with the Nursing Capstone Project writing tutorial?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>
                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! Our team of competent BSN Capstone Project tutors and writers will help you write a high-quality Capstone project.                                </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you write the Capella University BSN assessment for me?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! Our writers offer flawless and high-quality BSN assessment solutions for you.</div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I get a personal tutor for my Nursing Capstone Project?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We have exceptional tutors to answer all your requirements about the BSN Capstone Project.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>

                        </Accordion>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Capellacapstonebsn
