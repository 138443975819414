import React, { useState } from 'react'

// const popup = document.getElementById('popupBox');
//     window.onload = function(){
//         setTimeout(function(){
//           const mypopup = {
//             display: "block"
//            };
//         }, 2000)
//     }

const Popup = () => {

  const scriptURL = 'https://script.google.com/macros/s/AKfycbwbP_L4P2bs6HdQCtkMDVtiovdK7iFsr0UQ2DLiaojJJj0hvS1pk7Ii6PpwrdPT3LVD3w/exec'
			const form = document.forms['popup']

  const [modal, setModal] = useState(true);
  const toggleModal = (e) =>{

    e.preventDefault()
			  fetch(scriptURL, { method: 'POST', body: new FormData(form)})
				// .then(alert("Thank you! your form is submitted successfully." ))
				// // .then(() => {  window.location.reload(); })
				.catch(error => console.error('Error!', error.message))

        setModal(!modal)
  }

  return (
    <>
    {modal && (
      <div className='popup-main-box' id='popupBox'>
      <div className='popup-inner-box'>
          <div className='popup-left'></div>
          <div className='popup-right'>
              <span className='popup-form-title'>Fill the form to access this sample paper</span>
              <form action='' name='popup' className='popup-form' method='post' onSubmit={toggleModal}>
                  <input type='text' name='userName' placeholder='Name' required id='u-name'/>
                  <input type='text' name='userNumber' placeholder='Phone Number' required id='u-num'/>
                  <input type='email' name='userEmail' placeholder='Email' required id='u-email'/>
                  <button type='submit'>Submit</button>
              </form>
          </div>
      </div>
    </div>
    )}
      
    </>
  )
}

export default Popup
