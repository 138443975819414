import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx6109assessment3 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 6109 Assessment 3</title>
                <meta name='description'
                    content='Explore comprehensive insights and expert analysis in NURS FPX 6109 Assessment 3. Enhance your nursing knowledge now with our support.' />
                <meta name='keywords' content='NURS FPX 6109 Assessment 3' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 6109 Assessment 3< br /><span>Educational Technologies Comparison</span></h1>

                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/NURSFPX6109Assessment3.png" alt="" />
                    <div className='main-container-sl-content'>
                        <h2>Educational Technologies Comparison</h2>
                        <p>In comparing two similar educational technologies, it is necessary to consider the main characteristics, advantages, and the skills that are required for both technologies in an unbiased manner. It is crucial to identify the particular distinctions regarding the tools and the context in which each tool is most effective (Yeboah et al. , 2020). This test is aimed at comparing Canvas and Blackboard which are both platforms that incorporate the use of augmented reality (AR) in their design to identify which of the two is more suitable for training nurses. However, the comparison of technologies is not only done based on the features of the technologies; it is also the ideas that are used to make the comparison, which can have a huge impact on the decision (Dubrowski et al. , 2021).</p>
                        <h2>Description of Technology &ndash; Features, Capabilities, and Benefits</h2>
                        <p>Virtual/Augmented Reality (AR) is an online platform that is geared towards the visualization or simulation of real life data in form of graphics. In learning technologies, it is applied to help teachers in organizing and facilitating instruction, monitoring students&rsquo; progress, and improve communication and collaboration between nurse students and teachers (Bradley, 2021). In the online learning platform, Canvas and Blackboard employ AR based technology to enable the students to visualize what they are learning. Both of these learning methods incorporate various kinds of AR to enhance the degree of realism of the lesson or a particular event so that the nursing students are able to comprehend them. Both types of LMS employ the use of AR, however, they do so in different manners. Blackboard incorporates Marker-Based AR while Canvas incorporates Superimposition-Based AR. Thus, while both of these AR are different in terms of their functioning and the way they assist students in their learning process in general.</p>
                        <h2>Analysis</h2>
                        <p>Comparing the two technologies, it can be seen that Canvas AR is more useful in creating organization of course contents and making it easier for students to communicate to one another and collaborate in nursing school. The marker-based AR has numerous options within Blackboard, thus making it less specialized, generalizable, and applicable to the context of nursing education. In the area of nursing and other related fields where practical training, practical experience, and skills are paramount, Blackboard AR is quite useful. Both Blackboard and Canvas employ AR but because Blackboard employs a form of AR that is fundamentally different from that of Canvas, they operate in vastly different ways. However, you do not have to select technologies based on whether they are feature and function technologies or not. The decision as to which of the two technologies outlined above is to be used depends on the learning objectives, the manner in which lessons are delivered, and the requirements of individual learners. The technology selected should also enhance effective collaboration between the nursing students and teachers (Siraj et al. , 2022). This has also been made due to some aspects of nursing education that are not characteristic of other schools.</p>
                        <h2>Assumptions</h2>
                        <p>The concepts that were employed in the comparison could also have significant impact on the decision-making process. For instance, Blackboard AR would be preferable to Canvas if it is believed that its use in nursing education is grounded in the development of hands-on experience and practical skills (Siraj et al. , 2022). It was also believed that Canvas, like Blackboard, employed AR in all the classes. Comparing the Canvas AR and Blackboard AR, it was found that the AR application is highly varied in both and can be used for either professional practice or education practice (Elfeky &amp; Elbyaly, 2023).</p>
                        <h2>Benefits and Limitations of Comparing LMS and SimMan</h2>
                        <p>Canvas AR and Blackboard AR are both forms of an educational tool that can assist nursing students in their learning process (Yeboah et al. , 2020). While both games employ AR technology in a similar fashion, there are key distinctions between the two versions of AR that must be considered in order to compare them.</p>
                        <h2>Benefits</h2>
                        <p>The one positive of comparing Canvas AR and Blackboard AR is that it allows teachers to discover what technology is most effective in various classrooms. Canvas enables the nurse students to learn through classes, AR visualizations and other activities similar to lab sessions (Brohard et al. , 2021). Yeboah et al. (2020) have elaborated that instructors may select the appropriate technology that aligns with specific learning objectives or goals by evaluating the advantages and options of one technology to another. Canvas AR, on the other hand, utilizes a more superior and enhanced type of AR that offers high-quality models for the students that can effectively address all their learning needs and lessons (Malhotra et al. , 2022).</p>
                        <h2>Limitations</h2>
                        <p>That said, there are also some issues with viewing Canvas AR and Blackboard AR as two learning technologies. However, one issue that learners are likely to encounter when using the comparison approach is that the facilitator may not consider the individual differences in learners and how they learn (Brohard et al. , 2021). For instance, while some students might appreciate Blackboard AR&rsquo;s current and interactive features, others might benefit more from Canvas AR&rsquo;s hands-on learning and process visualization features. These are some of the things that nurse educators should consider when choosing the technology to use with the students (Yeboah et al. , 2020). Another issue with the comparison of Canvas AR and Blackboard AR is that it does not consider other educational technology that is more effective for medical education (Al-Balas et al. , 2020). While teaching complex medical procedures, it may be more effective to use AR technology, but it is challenging to understand how to navigate through Canvas AR and Blackboard AR (Malhotra et al. , 2022). Online talk groups, on the other hand, might be more suitable for collaborative learning of medical concepts and the critical thinking skills that the Canvas AR presents because they allow for more elaborate comments and peer feedback. As has been mentioned earlier, there is evidence that the issue of fit exists in some instances, although it and are two different forms of the same kind of technology. Some people suggest that Canvas AR can only work in organizations that require an online learning system for the courses with the user-friendly interface. Nevertheless, Blackboard AR can only be employed in the context of skill-based learning that requires an emphasis on group learning and testing (Elfeky &amp; Elbyaly, 2023).</p>
                        <p>Understanding the difference between Canvas AR and Blackboard AR will assist you in selecting the most suitable tool for nursing school, but you should also familiarize yourself with the advantages and disadvantages of the comparison (Nguyen et al. , 202). To that end, when nursing teachers are selecting instructional technology for their nursing courses, they should consider the strengths and weaknesses of each technology, as well as the students&rsquo; requirements and modality of learning. Moreover, it is recommended that nursing teachers should be ready to experiment other types of technologies that can be effective in some instances (Lall et al. , 2019).</p>
                        <h2>Selection of Suitable Educational Technology</h2>
                        <p>It is important to note that the design of an educational technology is a critical factor for its ability to teach and learn medical situations (Radianti et al. , 2020). In determining the best time a given technology will be employed, one needs to consider the suitability of the technology and the needs and aspirations of the school environment.</p>
                        <p>When it comes to a tool like Canvas AR, for example, there are several situations that could be perfect for it. Canvas AR and Blackboard AR are effective means for organizing the content and disseminating it, developing discussions, and monitoring the work and progress of the nursing students as well as their grades (Dubrowski et al. , 2021). Therefore, Canvas was selected as an AR- based learning management system that can address the needs of the nursing students by creating classes that can be easily explained through visual means. On the other side, the Blackboard AR had been rejected as it appeared to be more relevant to the academic nursing and less to the modern active aspect of nursing courses (Elfeky &amp; Elbyaly, 2023).</p>
                        <p>Canvas AR is beneficial for the nursing education that has a large part online or blended learning part as it has conversations, classes, and online tests (Malhotra et al. , 2022). For instance, when nursing students require to access content from the course and interact with teachers and other learners when they are not in class, the most effective solutions are AR-based tools such as Canvas and Blackboard. Canvas AR systems can also provide feedback to the teachers about the students&rsquo; work, track their performance over time, or facilitate group work for the students (Brohard et al. , 2021). Providing nursing students, who attend most of their classes online, with a common platform to discuss and access their classes may help them to feel more engaged (Mejia, 2020).</p>
                        <p>However, there are also some things that cannot be done when employing Canvas AR in training nurses. For instance, some students may feel that the platform is too much or complicated to handle especially if they have not embraced the use of technology in school work. While the Blackboard AR can be designed and function in any way possible, the same might not be true for a Canvas AR and its effectiveness in any given course or teaching style. For instance, it may not be effective when used for courses that employ practical assignments or that heavily incorporate multimedia materials (Dubrowski et al. , 2021). These are the things that we have to consider into account, together with the purpose and requirements of the class and its learners, in order to determine whether Canvas AR is ideal for a certain teaching and learning context. This way, it can determine which technology will be most suitable to use in the learning process and to capture the interest of the students (Mejia, 2020). Taking all these crucial factors into account, it can be concluded that Canvas AR is the most suitable type of AR application for nursing education based on the current review by Nguyen et al. (2023).</p>
                        <h2>Teaching and Learning Technology</h2>
                        <p>Canvas AR (Superimposition-based) therefore has been selected as the teaching and learning method that could replace Blackboard AR (Marker-based) in a certain nurse education program. This technology enables the nursing students to practice and enhance their clinical skills in a simulated environment without much risk. It may also be employed in a variety of nursing educational institutions like graduate school for nurses, nursing skills training facilities, and continuing education for practicing nurses. All these are relevant for the development of high-level nursing training (Edward &amp; Chukwuka, 2022). On the other hand, Blackboard AR is also a teaching tool for nursing that can be implemented in all types of classes at the basic level, intermediate level, and advanced level. Teaching nursing using Canvas AR is effective because students have many opportunities to learn and develop, including using augmented reality and completing tasks (Brohard et al. , 2021).</p>
                        <h2>Incorporation of Canvas AR</h2>
                        <p>The first step in integrating Canvas AR for a nursing education programme is to identify the learning objectives that can be achieved through modeling. This entails reviewing the program&rsquo;s lessons to determine areas where Canvas AR games and Superimposition-based AR images can enhance learning (Brohard et al. , 2021). Blackboard AR can provide a poor level of modeling of real-life nursing situations through simple visualizations made by markers and object recognition if it is to offer a poor level of nursing education to the students, as the study of Nguyen et al. , 202&lt; demonstrated. Based on this, Canvas AR was considered as the technology that can assist nurse students to acquire skills like administration of medicines, assessment of the patient vital signs, and communication with patients through simple teaching and illustrations (Edward &amp; Chukwuka, 2022).</p>
                        <p>After the learning goals have been identified, the next step is to determine which mode of Canvas AR is most suitable to the goals set. It involves the consideration of the models that are available and selection of the technology that is most appropriate for the needs of the program in question. For example, a program that is aimed at training students on how to administer drugs may select a career simulation application rather than the applications discussed above. In this way, students can also take tests and think about what they have done with injections, oral medications, or intravenous lines (Mejia, 2020). On the other hand, the Canvas AR-based learning system chosen for the form of learning technology of AR can express important visual effects of administering the medicine and the various ways it can be administered (Brohard et al. , 2021).</p>
                        <p>The next step is to integrate the selected Canvas AR into the list of classes offered to the students. It entails deciding which of the classes will apply AR and designing scenarios that align with learning objectives (Hatzivasilis et al. , 2020). Visualizing events has to be beneficial and realistic in the way it teaches which is similar to that of real life clinical practice.</p>
                        <p>In the introduction phase, it is recommended to teach nursing teachers how to use the Canvas AR well (Malhotra et al. , 2022). During these training classes, one can learn how to use the technology for running the simulations. Furthermore, it is recommended that the teachers should be trained on how to engage the nursing students in discussion after the simulations to facilitate learning and feedback (Mollart et al. , 2020).</p>
                        <p>The steps involved in integrating Canvas AR technology in a nurse education program includes: setting learning objectives, selecting the appropriate modeling technology, integrating it into the curriculum, and preparing the teachers (Smart et al. , 2020). The application of AR in Canvas for the simulation can assist the nursing students to learn more and prepare them for real life clinical practice (Edward &amp; Chukwuka, 2022). Further, Canvas AR refers to new forms of Canvas that involve pictures, movies, images, and models to enhance learning (Elfeky &amp; Elbyaly, 2023). It is not as easy as other regular AR-based modeling tools that are available in the market like Blackboard AR. It is likely that Canvas AR could be more beneficial in making the education of nurses better (Malhotra et al. , 2020). This is because it offers the nursing students more opportunities to learn and an interactive client experience that is not typical of the Blackboard form of AR, namely Marker-based (Brohard et al. , 2021).</p>
                        <h2>Conclusion</h2>
                        <p>Here are some of the similarities between Blackboard and Canvas LMS in terms of features, benefits as well as skills. Despite these, they are appropriate for differing teaching and learning contexts in nursing education. It is also important to note that the use of these tools in the process of studying in nursing school is helpful to make students more interested in the process of learning, to encourage them to learn actively and to succeed in the tests. One disadvantage of comparing similar technologies is that one&rsquo;s prejudice or prior assumptions about them can influence the decision-making process, as the strengths and weaknesses of each technology are not always easy to distinguish. But Canvas AR was preferred to Blackboard as the technology to be implemented in nursing education because the former has more course options and better visualization.</p>
                        <h2>References</h2>
                        <p>Al-Balas, M., Al-Balas, H. I., Jaber, H. M., Obeidat, K., Al-Balas, H., Aborajooh, E. A., Al-Taher, R., &amp; Al-Balas, B. (2020). Distance learning in clinical medical education amid COVID-19 pandemic in Jordan: Current situation, challenges, and perspectives. <em>BMC Medical Education</em>, <em>20</em><em>(1)</em>. <a href="https://doi.org/10.1186/s12909-020-02257-4">https://doi.org/10.1186/s12909-020-02257-4</a></p>
                        <p>Bradley, V. M. (2021). Learning management system (LMS) use with online instruction. <em>International Journal of Technology in Education</em><em>, 4(1)</em>, 68&ndash;92. <a href="https://eric.ed.gov/?id=EJ1286531">https://eric.ed.gov/?id=EJ1286531</a></p>
                        <p>Brohard, C., Moreland, E., Shammas, S., &amp; Tonsul, R. (2021). From development to implementation. <em>Journal of Hospice &amp; Palliative Nursing</em>, <em>(6)</em>, 504&ndash;511. <a href="https://doi.org/10.1097/njh.0000000000000798">https://doi.org/10.1097/njh.0000000000000798</a></p>
                        <p>Dubrowski, A., Kapralos, B., Peisachovich, E., Da Silva, C., &amp; Torres, A. (2021). A model for an online learning management system for simulation-based acquisition of psychomotor skills in health professions education. <em>Cureus</em>. <a href="https://doi.org/10.7759/cureus.14055">https://doi.org/10.7759/cureus.14055</a></p>
                        <p>Edward, M. I., &amp; Chukwuka, L. (2022). <em>Simulation in nursing education: Implications For nurse educators and nursing practice</em>. Semanticscholar. <a href="https://www.semanticscholar.org/paper/SIMULATION-IN-NURSING-EDUCATION%3A-IMPLICATIONS-FOR-Edward-Chukwuka/df1a2c9c38927d7be581ef7e2df3bbd7342a0046">https://www.semanticscholar.org/paper/SIMULATION-IN-NURSING-EDUCATION%3A-IMPLICATIONS-FOR-Edward-Chukwuka/df1a2c9c38927d7be581ef7e2df3bbd7342a0046</a></p>
                        <p>Elfeky, A. I. M., &amp; Elbyaly, M. Y. H. (2023). Examining the effects of virtual classroom use inside learning management systems on enhancing student satisfaction. <em>Ann. For. Res, 66(1)</em>, 1980-1990. <a href="https://www.e-afr.org/article/view-2023/pdf/1980.pdf">https://www.e-afr.org/article/view-2023/pdf/1980.pdf</a></p>
                        <p>Hatzivasilis, G., Ioannidis, S., Smyrlis, M., Spanoudakis, G., &amp; Frati, F. (2020). Modern aspects of cyber-security training and continuous adaptation of programmes to trainees. <em>Applied Sciences</em>, <em>10</em><em>(16)</em>, 5702. <a href="https://doi.org/10.3390/app10165702">https://doi.org/10.3390/app10165702</a></p>
                        <p>Lall, P., Rees, R., Law, G. C. Y., Dunleavy, G., Cotič, Ž., &amp; Car, J. (2019). Influences on the implementation of mobile learning for medical and nursing education: Qualitative systematic review by the digital health education collaboration. <em>Journal of Medical Internet Research</em>, <em>21</em><em>(2)</em>. <a href="https://doi.org/10.2196/12895">https://doi.org/10.2196/12895</a></p>
                        <p>Malhotra, A., Brady, D., Kreys, E., Silva, J., Feng, X., &amp; Yang, C. (2020). Development, implementation, and assessment of a comprehensive, integrated, and multimodal interprofessional education (CIM-IPE) program. <em>Journal of Interprofessional Education &amp; Practice</em>, <em>21</em>, 1&ndash;13. <a href="https://doi.org/10.1016/j.xjep.2020.100356">https://doi.org/10.1016/j.xjep.2020.100356</a></p>
                        <p>Malhotra, A., Oh, S., Jin, Z., &amp; Feng, X. (2022). Closing the integration gap: A pilot for incorporating foundational sciences, DEI-decision making, empathy, and communication for congestive heart failure and arrhythmia management by pharmacy students. <em>Pharmacy</em>, <em>10</em><em>(4)</em>, 77. <a href="https://doi.org/10.3390/pharmacy10040077">https://doi.org/10.3390/pharmacy10040077</a></p>
                        <p>Mejia, C. (2020). Using voice thread as a discussion platform to enhance student engagement in a hospitality management online course. <em>Journal of Hospitality, Leisure, Sport &amp; Tourism Education</em>, <em>26</em>, 100236. <a href="https://doi.org/10.1016/j.jhlste.2019.100236">https://doi.org/10.1016/j.jhlste.2019.100236</a></p>
                        <p>Mollart, L., Newell, R., Geale, S. K., Noble, D., Norton, C., &amp; O'Brien, A. P. (2020). Introduction of patient Electronic Medical Records (EMR) into undergraduate nursing education: An integrated literature review. <em>Nurse Education Today</em>, <em>94</em><em>(94)</em>, 104517. <a href="https://doi.org/10.1016/j.nedt.2020.104517">https://doi.org/10.1016/j.nedt.2020.104517</a></p>
                        <p>Nguyen, W., Fromer, I., Remskar, M., &amp; Zupfer, E. (2023). Development and implementation of video-recorded simulation scenarios to facilitate case-based learning discussions for medical students&rsquo; virtual anesthesiology clerkship. <em>Mededportal</em>, <em>19</em>, 1&ndash;11. <a href="https://doi.org/10.15766/mep_2374-8265.11306">https://doi.org/10.15766/mep_2374-8265.11306</a></p>
                        <p>Radianti, J., Majchrzak, T. A., Fromm, J., &amp; Wohlgenannt, I. (2020). A systematic review of immersive virtual reality applications for higher education: Design elements, lessons learned, and research agenda. <em>Computers &amp; Education</em>, <em>147</em><em>(0360-1315)</em>. <a href="https://doi.org/10.1016/j.compedu.2019.103778">https://doi.org/10.1016/j.compedu.2019.103778</a></p>
                        <p>Siraj, S., Sivanathan, M., Abdo, S., Micallef, J., Gino, B., &amp; Pereira, C. (2022). Hands-on practice on sustainable simulators in the context of training for rural and remote practice through a fundamental skills workshop. <em>Cureus</em>, <em>14</em><em>(9)</em>. <a href="https://doi.org/10.7759/cureus.28840">https://doi.org/10.7759/cureus.28840</a></p>
                        <p>Smart, D., Ross, K., Carollo, S., &amp; Williams-Gilbert, W. (2020). Contextualizing instructional technology to the demands of nursing education. <em>CIN: Computers, Informatics, Nursing</em><em>, 38(1)</em>, 18&ndash;27. <a href="https://doi.org/10.1097/cin.0000000000000565">https://doi.org/10.1097/cin.0000000000000565</a></p>
                        <p>Yeboah, A., Kim, Y., Sallar, A. M., &amp; Kiramba, L. K. (2020). Exploring the use of digital technologies from the perspective of diverse learners in online learning environments. <em>Online Learning</em><em>, 24(4)</em>, 42&ndash;63. <a href="https://eric.ed.gov/?id=EJ1277763">https://eric.ed.gov/?id=EJ1277763</a></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Nursfpx6109assessment3
