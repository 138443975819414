import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";

const Dnpflexpath = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Research Writers",
            Desc: "Get help from skilled Capella DNP FlexPath research writers. They are always ready for help.",
        },
        {
            icon: "fa-solid fa-school icon",
            title: "Class Help",
            Desc: "Our tutors offer Capella University DNP FlexPath class-taking services for Capella DNP FlexPath students!",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Courses Help",
            Desc: "Take help from Capella DNP FlexPath professionals in their Capella DNP FlexPath courses.",
        },
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Tutoring Help",
            Desc: "Take help from our tutors, who are Capella DNP FlexPath Curriculum professionals. ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a Capella DNP FlexPath intellectuals team to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We can always help you with your Capella DNP FlexPath Curriculum and coursework!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to Capella University DNP FlexPath students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek complete support from Capella University DNP FlexPath nursing experts.",
        },
    ]
    return (
        <>
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
                    <div className='sherotop-left'>
                    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
                    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
                    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
                    </div>
                    <div className='cal-box'><Calculator/></div> 
                </div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>SEEK CAPELLA UNIVERSITY DNP FLEXPATH HELP</span><br />
                                </h1>
                                <h2>ARE YOU LOOKING FOR A CAPELLA <br />DNP FLEXPATH TUTOR?</h2>
                            <p className='ocs2-topText'>Enjoy exceptional grades in your Capella DNP FlexPath courses with the help of Capella DNP FlexPath Curriculum experts.</p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Professionals</h2>
                            <p>Take help from professional tutors, mentors, and writers to excel in your Capella University DNP FlexPath coursework.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Get Nursing Papers</h2>
                            <p>Get excellent and well-researched Capella DNP FlexPath nursing papers!</p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET CAPELLA DNP FLEXPATH ASSESSMENT SOLUTIONS!</h2>
                <p>We provide excellent Capella University DNP FlexPath assessment solutions for students in their Capella DNP FlexPath Courses.</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Educational Services</span>
                    <h2>CAPELLA DNP FLEXPATH COURSE ASSISTING SERVICES</h2>
                    <p>We are famed for offering superb, top-quality writing services for the Capella DNP FlexPath students. Our team of academic writers, tutors, and mentors is devoted to supporting you in your Capella University DNP FlexPath courses. Our experts are relatively professional in assisting students with their Capella DNP FlexPath exams, nursing papers, and examination solutions. Students get aid in their online coursework according to the Capella DNP FlexPath Curriculum. Our high-scoring Capella DNP FlexPath writing services are available for the Capella University DNP FlexPath students.</p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>Excelling at Capella DNP FlexPath degree program</strong></Tab>
                        <Tab><strong>Unlock Your Potential in Capella University DNP FlexPath Courses</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p>Are you pursuing a Doctor of Nursing Practice (DNP) diploma at Capella University? Online Course Services provides specialized academic help to help you excel in the rigorous Capella DNP FlexPath program. With our support, you can confidently manage the challenging Capella DNP FlexPath Curriculum and attain your educational goals.
                        <br /> <br />
                            <strong>Capella DNP FlexPath Curriculum Support</strong><br />

                            The Capella DNP FlexPath curriculum includes advanced nursing exercise abilities and management talents. We afford comprehensive help to help you grasp the curriculum. We will provide comprehensive assistance with Capella DNP FlexPath assignments and complex courses.
                            <br /><br />
                            <strong>Personalized Academic Support</strong>
                            <br />
                            At Online Course Services, we recognize that each student has precise Capella DNP FlexPath learning requirements. Our academic help services are designed to suit the requirements of Capella University DNP FlexPath students. We will help you with Capella DNP FlexPath research, scholarly writing, and tutoring sessions.
                            <br /><br />
                            <strong>Expert Tutors with DNP Expertise</strong>
                            <br />
                            Our Capella University DNP FlexPath tutors are experts in their respective fields. They have in-depth information about the Capella DNP FlexPath Curriculum at Capella University. They can provide insights to help you be triumphant academically and professionally.
                            <br /><br />
                            <strong>Flexible Learning Solutions</strong>
                            <br />
                            We provide Capella DNP FlexPath learning solutions to deal with your busy schedule. We will help you select one-on-one tutoring sessions for Capella University DNP FlexPath. Our tutors and mentors will provide complete Capella DNP FlexPath online assistance. We have the assets and Capella DNP FlexPath Curriculum details to fulfill your needs.
                            <br /><br />
                            <strong>Capella DNP FlexPath Academic Coaching</strong>
                            <br />
                            In addition to Capella DNP FlexPath Curriculum help, we provide Capella DNP FlexPath educational coaching to help you. We will enable you to expand your critical competencies and strategies. Our Capella University DNP FlexPath coaches can help you in achieving your goals. We will let you stay organized and deal with complex Capella DNP FlexPath courses slowly and successfully. We guarantee your success in the Capella DNP FlexPath program.
                            <br /><br />
                            <strong>Enhance Your Capella DNP FlexPath Help Experience</strong>
                            <br />
                            Refrain from allowing the demanding situations of the Capella DNP FlexPath program to keep you back. With our Capella DNP FlexPath educational help services, you can overcome barriers and achieve your professional goals. Contact us these days to research how we will guide your Capella DNP FlexPath journey at Capella University.
                            </p>
                    </TabPanel>
                    <TabPanel>
                        <p>Are you feeling overwhelmed by the demands of the Capella DNP FlexPath Curriculum? We are here to provide the academic assistance you need to succeed. Our comprehensive Capella University DNP FlexPath help services answer the complexities of the Capella DNP FlexPath curriculum. We guarantee you will have the support essential to excelling in your studies. <br /><br />
                            <strong>Personalized Support for the Capella DNP FlexPath Curriculum</strong><br />
                            The Capella DNP FlexPath curriculum is challenging. So, we are assisting you in your Capella DNP FlexPath courses. Our team of skilled Capella DNP FlexPath tutors affords personalized aid to help you comprehend complicated standards. Our Capella DNP FlexPath writers and tutors will help you complete your assignments and prepare for tests.
                            <br /><br />
                            <strong>Expert Guidance from DNP Professionals</strong>
                            <br />
                            Our Capella University DNP FlexPath tutors are the most straightforward specialists in their respective fields. They enjoy the Capella DNP FlexPath program tutoring. They can provide precious insights and recommendations to help you reach your educational and expert endeavours.
                            <br /><br />
                            <strong>Flexible Learning Options</strong>
                            <br />
                            We apprehend that each student has unique mastering preferences and schedules. We provide flexible learning options, including one-on-one tutoring, organization examination periods, and online resources. So you can get the assistance that suits you.
                            <br /><br />
                            <strong>Comprehensive Academic Assistance Services</strong>
                            <br />
                            Online Course Services gives a massive range of Capella University DNP FlexPath educational help services. We aim to guide you throughout your Capella DNP FlexPath adventure. We will help you with Capella DNP FlexPath Curriculum writing and studies. We strive to help you overcome the challenges and complexities and protect your time. We have everything you need to be successful.
                            <br /><br />
                            <strong>Take the Next Step Toward Success with Tutors Academy</strong>
                            <br />
                            Refrain from allowing the challenges of the Capella DNP FlexPath studies to hold you back. With our academic help services, you can release your complete potential and attain your educational and professional desires. Contact us nowadays to learn more about how we can assist you in being triumphant in the Capella University DNP FlexPath program.
                            </p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Features</span>
                    <h2>WHY CHOOSE US?</h2>
                    <p>Online Course Services provides complete Capella DNP FlexPath writing services for nursing students enrolled in the Capella University DNP FlexPath program. Our crew comprises professional Capella DNP FlexPath writers, intellectuals, and mentors. They are dedicated to assisting you at some stage in your educational adventure. Over the years, we have supported millions of Capella University DNP FlexPath students with their Capella DNP FlexPath coursework. Our expert Capella DNP FlexPath writing services are very beneficial for the students of Capella University. We have a track record of assisting nursing students in their Capella DNP FlexPath exams. Our team of renowned Capella DNP FlexPath Curriculum intellectuals assists nursing students. Let us cope with the exam stress, ensuring you gain excessive scores. Reach out to us now to elevate your overall educational performance!</p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Do you offer Capella DNP FlexPath writing services?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! Our Capella DNP Flex path writers offer complete guidance in DNP papers.  
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I hire a tutor for my Capella University DNP FlexPath courses?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We have excellent Capella DNP FlexPath nursing tutors to help you in your courses.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you do Capella University DNP FlexPath classes for me?  </h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We are offering helpful class-taking services to Capella DNP FlexPath nursing students. 
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I avail of Capella DNP FlexPath tuition at a discount?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We offer comprehensive Capella University DNP FlexPath tutoring sessions to students in their courses. Take help from our skilled tutors, writers, and mentors.</div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you help me with my Capella DNP FlexPath exams?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We have a team of highly intellectual Capella DNP Flex Path exam experts to help with exams.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you help with the Capella DNP FlexPath thesis?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>
                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! Our team of competent thesis writers will help you with your nursing thesis.                                </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can you write the Capella DNP FlexPath assessment for me?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We will provide flawless and high-quality assessment solutions for you.                                </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I get a personal tutor for my Capella DNP FlexPath courses?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                    Yes! We offer personal tutors for Capella MSN Flex Path nursing students at a fantastic discount.
                                    </div>
                                </AccordionBody>
                            </AccordionItem>

                        </Accordion>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Dnpflexpath
