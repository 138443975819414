import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";
import { Helmet } from 'react-helmet';

const Reliablenursinghomeworkhelp = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Research Writers",
            Desc: "Get help from skilled Capella DNP FlexPath research writers. They are always ready for help.",
        },
        {
            icon: "fa-solid fa-school icon",
            title: "Class Help",
            Desc: "Our tutors offer Capella University DNP FlexPath class-taking services for Capella DNP FlexPath students!",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Courses Help",
            Desc: "Take help from Capella DNP FlexPath professionals in their Capella DNP FlexPath courses.",
        },
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Tutoring Help",
            Desc: "Take help from our tutors, who are Capella DNP FlexPath Curriculum professionals. ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a Capella DNP FlexPath intellectuals team to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We can always help you with your Capella DNP FlexPath Curriculum and coursework!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to Capella University DNP FlexPath students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek complete support from Capella University DNP FlexPath nursing experts.",
        },
    ]
  return (
    <>
    <Helmet>
        <title>Reliable Nursing Homework Help With Online Course Services</title>
          <meta name='description'
          content='Get expert nursing homework help from qualified tutors to ace your assignments and excel in your nursing studies. Contact us now'/>
          <meta name='keywords' content='nursing homework help, nursing paper help, nursing papers online'/>
    </Helmet>
    <div className='s-hero services'>
        {/* <div className='s-hero-top'>
    <div className='sherotop-left'>
    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
    </div>
    <div className='cal-box'><Calculator/></div> 
</div> */}
        <div className='ocs2-main-box'>

            <div className='ocs2-main' id='hero'>
                <div className='ocs2-mainHeading'>
                    <h1><span className='ocs2-topHeading'>RELIABLE NURSING HOMEWORK HELP WITH <br />ONLINE COURSE SERVICES</span></h1>
                       <h2>TOP NURSING HOMEWORK HELP TIPS <br/> YOU NEED TO SUCCEED</h2> 
                    <p className='ocs2-topText'>Online Course Services is a reliable academic resource that provides you with Nursing Homework Help. If you are looking for help with a nursing assignment we are here to give you guidance and nursing paper help.</p>
                    <ul>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                    </ul>
                    <div className='ocs2-banner-capella'>
                        <div className='ocs2-banner-capella-path'>
                            <span>
                                <h3 className='f-path-pointer'>Flex Path</h3>
                                <p>Use your experience to complete courses.</p>
                            </span>
                        </div>
                        <div className='ocs2-banner-capella-path'>
                            <span>
                                <h3 className='f-path-pointer'>Guided Path</h3>
                                <p>Enjoy the flexibility to study anywhere.</p>
                            </span>
                        </div>
                    </div>
                    <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                    <div className='ocs2-ratting'>
                        <div className='ocs2-ratting-provider'>
                            <SiTrustpilot className='ocs2-trustpilot-icon' />
                            <div>
                                <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                <p>TrustPilot</p>
                            </div>
                        </div>
                        <div className='ocs2-ratting-provider'>
                            <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                            <div>
                                <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                <p>OnlineCourseServices</p>
                            </div>
                        </div>
                        <div className='ocs2-ratting-provider'>
                            <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                            <div>
                                <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                <p>Sitejabber</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='ocs2-form'>
                    <Calculator />
                </div>
            </div>

        </div>
        <div className='s-cta'>
            <div className='s-cta-inner'>
                <div className='s-cta-inner-box'>
                    <h2>Hire Professionals</h2>
                    <p>Take help from professional tutors, mentors, and writers to excel in your Capella University DNP FlexPath coursework.</p>
                </div>
                <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
            </div>
            <div className='s-cta-inner'>
                <div className='s-cta-inner-box'>
                    <h2>Get Nursing Papers</h2>
                    <p>Get excellent and well-researched Capella DNP FlexPath nursing papers!</p>
                </div>
                <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
            </div>
        </div>
    </div>


    <div className='freesamples services'>
        <h2>GET CAPELLA DNP FLEXPATH ASSESSMENT SOLUTIONS!</h2>
        <p>We provide excellent Capella University DNP FlexPath assessment solutions for students in their Capella DNP FlexPath Courses.</p>
        <div className='sampleboxes'>
            {newSamples.map((sample) => {
                return (

                    <div className='singlebox'>
                        <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                        <div className='text'>
                            <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                            <p>{sample.description}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>


    <div className='services s-section'>
        <div className='s-left'>
            {newServices.map((newserivce) => {
                return (
                    <div className='s-single'>
                        <i class={newserivce.icon}></i>
                        <h3>{newserivce.title}</h3>
                        <p>{newserivce.Desc}</p>
                    </div>
                )
            })}
        </div>
        <div className='s-right'>
            <span>Our Tutoring Services</span>
            <h2>A COMPLETE SOLUTION FOR NURSING HOMEWORK HELP</h2>
            <p>Here on our platform students will get academic help for various courses. In our tutoring services, the priority is understanding the concepts and better memorization for successful nursing homework completion. If you are searching online platforms for nursing papers online our platform Online Course Services will provide you with valuable information.</p>
            <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
        </div>
    </div>

    <div className='services cta'>
        <Calltoaction />
    </div>
    <div className='services'>
        <Tabs>
            <TabList>
                <Tab><strong>Benefits of Seeking Nursing Homework Help</strong></Tab>
                <Tab><strong>Get Online Course Services Support and Guidance</strong></Tab>
            </TabList>

            <TabPanel>
                <p>If you are a nursing student and confident about achieving a good grade this means you are an intelligent and hardworking student. However, there is always a need for guidance and Nursing Homework guidance. <br /> <br />
                    <strong>Guidance and support by Online Courses Service Writers</strong><br />
                    Our tutors are always available for students who are appearing in nursing papers online for help and any type of guidance.
                    <br /> <br />
                    <strong>Experienced writers to help with nursing assignment</strong><br />
                    If students want writing help with nursing assignments our professional and experienced writers will write student’s nursing homework to help them out.
                    <br /> <br />
                    <strong>Academic Support</strong><br />
                    At OCS each student is important. we offer personalized assistance to help every student achieve their academic goals.
                    <br /> <br />
                    <strong>Nursing Paper Help and Solutions</strong><br />
                    Our tutors and writers not only provide Nursing Paper Help but also a permanent solution.
                    <br /> <br />
                    <strong>Achieve your Goals with Our Services</strong><br />
                    With Online course services, students will be prepared before appearing for nursing papers online.
                </p>
            </TabPanel>
            <TabPanel>
                <p>No matter how much a student is intelligent there is always a need of guidance and mentorship.
                <br /> <br />
                <strong>Comprehensive Guidance and Services</strong><br />
                OCS provides students with guidance and help to achieve good academic grades.
                </p>
            </TabPanel>
        </Tabs>
    </div>

    <div className='process services freesamples'>
        <h2>HOW IT WORKS?</h2>
        <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
        <div className='processboxes'>
            {orderprocess.map((order) => {
                return (

                    <div className='singleprocess'>
                        <i class={order.icon}></i>
                        <h3>{order.title}</h3>
                        <p>{order.Desc}</p>
                    </div>
                )
            })}
        </div>
    </div>


    <div className='services s-section'>
        <div className='s-right'>
            <span>Our Features</span>
            <h2>WHY CHOOSE US FOR NURSING HOMEWORK HELP</h2>
            <p>There is always a reason behind choosing someone for the services you are looking for. Here the reasons are professionalism reliability and quality of the services. Students often feel isolated during online classes as compared to the students who are attending classes physically. For those students, we provide our services and give them the confidence to appear in nursing papers online. Our tutors and writers have years of experience working in the medical and nursing fields. We always hire people with a background in the relevant course and field.</p>
            <div><button>Hire Writer</button></div>
        </div>
        <div className='s-left'>
            {newFeatures.map((newserivce) => {
                return (
                    <div className='s-single'>
                        <i class={newserivce.icon}></i>
                        <h3>{newserivce.title}</h3>
                        <p>{newserivce.Desc}</p>
                    </div>
                )
            })}
        </div>
    </div>
    <Stestimonials />
    <Container>
        <div className='ocs2-faqs'>
            <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
            <div className='ocs2-faqs-boxes'>
                <div className='accordian-box-image'>
                    <img src='images/faqs.png' alt='' />
                </div>

                <Accordion className='accordian-boxes'>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}> What types of nursing homework do you assist with?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Any type of assignment that is assigned to you by your professor teaching you that course.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>What is your policy on plagiarism and ensuring originality in nursing homework?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            The policy on plagiarism is as per the laws. We ensure all nursing homework is original and properly referenced.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Can I get help with urgent nursing homework assignments or last-minute deadlines?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Yes, you can get urgent help Terms and conditions will apply.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>How do I communicate with the nursing homework help team or my assigned tutor?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            There will be a communication means and source will be shared between you and the team.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>What measures do you have in place to ensure confidentiality and privacy of my nursing homework tasks?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            We always instruct our tutors and writers to keep the student's details and data confidential.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>How qualified are the experts assisting with nursing assignments?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>
                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Our tutors and writers are highly qualified, possessing the same educational background and expertise in the fields to which they are assigned.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Do you offer assistance with understanding concepts or only with completing assignments?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>
                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Yes, our tutors are there to make your concept clear and guide you.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Can I request revisions if I'm not satisfied with the completed assignment?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>
                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Yes, you can request revision as per the discussions made in starting of the project.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                   
                </Accordion>
            </div>
        </div>
    </Container>
</>
  )
}

export default Reliablenursinghomeworkhelp
