import React from 'react'

const Thankyou = () => {
  return (
    <div>
      Thank
    </div>
  )
}

export default Thankyou
