import React from 'react'
import { FaUserEdit, FaSchool } from "react-icons/fa";
import { FaRegPenToSquare, FaRegHandshake, FaReact, FaUserGraduate, FaSchoolCircleCheck, FaHandHoldingDollar } from "react-icons/fa6";
import Carousel from 'react-grid-carousel';
import { ImBooks } from "react-icons/im";
import { BsGraphUpArrow, BsBank2, BsGraphUp } from "react-icons/bs";
import { GiMedicines,GiDna2 } from "react-icons/gi";




import {FaCalculator, FaChartColumn, FaFirstOrderAlt, FaUsersGear, FaTv, FaRegHospital, FaMarker } from "react-icons/fa6";
import Container from 'react-bootstrap/esm/Container';


const Services = () => {
  return (
    <>
    <Container>
      <div className='ocs2-services' id='services'>
        <h2 className='ocs2-services-heading'><strong>WE PROVIDE THE BEST NURSING ASSESSMENT HELP THAT FULFILS ALL</strong><br/>
        <span>THE DEMANDING UNIVERSITY STANDARDS AND REQUIREMENTS</span></h2>

        <div className='ocs2-services-box'>
            <div className='ocs2-services-name'>
                <p>We offer the best Capella MSN Flexpath coursework writing services.</p>
                <div className='ocs2-services-name-grid'>
                    <span>Essays</span>
                    <span>Assignments</span>
                    <span>Homework</span>
                    <span>Research Papers</span>
                    <span>Thesis</span>
                    <span>Term Papers</span>
                    <span>Book Report</span>
                    <span>Lab Report</span>
                    <span>Case Study</span>
                    <span>Dissertation</span>
                    <span>Presentation</span>
                    <span className='ocs2-services-name-grid-last'>and many more…</span>
                </div>
            </div>
            <div className='ocs2-services-icons'>
                <div className='ocs2-services-card1'>
                    <FaUserEdit className='ocs2-services-card1-icon'/>
                    <h3>Custom Writing</h3>
                    <p>Our certified Capella Flexpath nursing writers provide high-quality and 100% original content precisely per your requirements.</p>
                </div>
                <div className='ocs2-services-card2'>
                    <FaRegPenToSquare className='ocs2-services-card2-icon'/>
                    <h3>Editing & Proofreading</h3>
                    <p>Hire our certified Capella dnp program editors to get a flawless Capella RN-MSN flex path final document.</p>
                </div>
            </div>
        </div>

        <p>Our proficient team can proficiently handle over 90+ subject areas and 10+ academic citation styles.</p>
        <div className='ocs2-services-more-details-box'>
            {/* <div className='ocs2-services-completed'>
                <div className='ocs2-services-completed-box'>
                    <h2>150K+</h2>
                    <p>Orders<br/>Completed</p>
                </div>
                <div className='ocs2-services-completed-box'>
                    <h2>1200+</h2>
                    <p>Certified<br/>Writers</p>
                </div>
                <div className='ocs2-services-completed-box'>
                    <h2>94.9%</h2>
                    <p>Customer<br/>Satisfaction Rate</p>
                </div>
                <div className='ocs2-services-completed-box'>
                    <h2>92%</h2>
                    <p>Returning<br/>Clients</p>
                </div>
            </div> */}
            <div className='ocs2-services-more-details'>
            <Carousel cols={5} rows={2} gap={15} hideArrow={true} showDots={true} autoplay={5000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaSchool className='ocs2-carousel-icon'/>
                        <p>History</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaCalculator className='ocs2-carousel-icon'/>
                        <p>Finance</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaChartColumn className='ocs2-carousel-icon'/>
                        <p>Statistical Analysis</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaFirstOrderAlt className='ocs2-carousel-icon'/>
                        <p>Psychology</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaUsersGear className='ocs2-carousel-icon'/>
                        <p>Sociology</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaTv className='ocs2-carousel-icon'/>
                        <p>Technology</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaRegHospital className='ocs2-carousel-icon'/>
                        <p>Alternative Medicine</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaMarker className='ocs2-carousel-icon'/>
                        <p>Arts</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <ImBooks className='ocs2-carousel-icon'/>
                        <p>Literature</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaRegHandshake className='ocs2-carousel-icon'/>
                        <p>Business</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaReact className='ocs2-carousel-icon'/>
                        <p>Chemistry</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <BsGraphUpArrow className='ocs2-carousel-icon'/>
                        <p>Economics</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaUserGraduate className='ocs2-carousel-icon'/>
                        <p>Philosophy</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaSchoolCircleCheck className='ocs2-carousel-icon'/>
                        <p>Education</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaHandHoldingDollar className='ocs2-carousel-icon'/>
                        <p>Business Management</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <BsBank2 className='ocs2-carousel-icon'/>
                        <p>Ethics</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <GiMedicines className='ocs2-carousel-icon'/>
                        <p>Medicine and Health</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <BsGraphUp className='ocs2-carousel-icon'/>
                        <p>Statistics</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <GiDna2 className='ocs2-carousel-icon'/>
                        <p>Biology</p>
                    </span>
                </Carousel.Item>
                <Carousel.Item>
                    <span className='ocs2-carousel'>
                        <FaUsersGear className='ocs2-carousel-icon'/>
                        <p>Management</p>
                    </span>
                </Carousel.Item>
                </Carousel>
            </div>
        </div>
    </div>
    </Container>
    </>
  )
}

export default Services
