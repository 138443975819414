import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx8014a2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 8014 Assessment 2: Global Issue Problem Description</title>
                <meta name='description'
                    content='Craft your NURS FPX 8014 Assessment 2: Global Issue Problem Description with precision! Optimize Global Issue Problem Description efficiently. Get started now!' />
                <meta name='keywords' content='nurs fpx 8014 assessment 2' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 8014 Assessment 2:< br /><span>Global Issue Problem Description</span></h1>
                    {/* <p>Hi. I’m (Student), and currently, I want to recommend in-service training that encourages the medical staff < br />to approach patient treatment more proactively. </p>
                    <div className='sample-hero-links'>
                        <a href="#">Online Course Services</a>
                        <a href="#">Free Samples</a>
                        <a href="#">Online Course Services</a>
                        <a href="#">Online Course Services</a>
                    </div> */}
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/NURSFPX8014A2FI.png" alt="" />
                    <div className='main-container-sl-content'>
                        <p><strong>Looking for guidance with your NURS FPX 8014 Assessment 2? Our experts are here to assist you. Reach out to us for support today.</strong></p>
                        <h2>Global Issue Problem Description</h2>
                        <p>Diabetes is a chronic disease that affects the way the body uses glucose, or blood sugar.&nbsp;Glucose is needed by your cells as an energy source, and this process requires the hormone insulin, which is synthesized by the pancreas to transport glucose from your circulation into your cells.&nbsp;In patients with diabetes, the body produces either a very little amount of insulin, or the insulin that is produced is not used by the body effectively.&nbsp;In that case, the level of blood glucose increases and the risk of atherosclerosis, stroke, renal disease, neuropathy, and visual impairment rises, among others.&nbsp;Diabetes can also increase the probability of getting other diseases such infections, slow wound healing, and even some forms of cancer (Sneha &amp; Gangil, 2019).</p>
                        <p>Diabetes in people aged 20-79 will have around 463 million worldwide by 2019 (Porwal et al., 2020).&nbsp;As diabetes is associated with increased morbidity and mortality rates, worse quality of life and a significant financial burden on both people and healthcare systems, this is a major public health concern.&nbsp;The risk factors of diabetes are genetic inheritance and life style factors of which obesity, improper diet and physical inactivity are stated to be important by Kaur and Kumari (2018).&nbsp;The study aims to examine the problem of diabetes in Mauritius and develop an evidence-based approach to improve outcomes for the entire population.</p>
                        <img src="images/NURSFPX8014A2MI.png" alt="" />
                        <h2>Analysis of an International Health Issue</h2>
                        <p>Diabetes is a global health issue that affects millions of individuals all over the world.&nbsp;World Health Organization (WHO) (Organization, 2019) estimated that by 2019, 463 million of people worldwide would be living with diabetes.&nbsp;The amount of these people is anticipated to reach 700 million by 2045.&nbsp;Diabetes is a chronic condition that disrupts the body&rsquo;s ability to metabolize glucose with the consequence of high blood sugar levels.&nbsp;Other severe complications that may develop from this condition are ischemic heart diseases, chronic renal failure, blindness, and lower limb amputation.&nbsp;Moreover, diabetes increases mortality rate (Kaur &amp; Kumari, 2018).</p>
                        <p>Diabetes is a problem in all nations and affects people and communities.&nbsp;However, the burden of the disease is not distributed equally, with low and middle-income countries (LMICs) taking a heavy load of the impact.&nbsp;Over 75% of diabetics in 2019 resided in low- and middle-income countries.&nbsp;Diabetes puts marginalized population including immigrants, low-income people, and aboriginals in higher risk.&nbsp;Mogre et al. (2019) explain that countries with high levels of obesity and tendencies to an unhealthy lifestyle which includes extended sitting and consuming sugar and fat are mostly affected by diabetes.</p>
                        <p>Diabetes disparities between countries involve variations in the availability of healthcare and early detection and prevention approaches such as education.&nbsp;Socioeconomic variable, including education and poverty, also play a big role in diabetes management and prevalence.&nbsp;For example, individuals with low income are more prone to develop diabetes and suffer because they have little or no access to health care facilities and nutritious food.&nbsp;Diabetes disparities also impact poor and indigenous communities due to historical injustice and ongoing prejudice.&nbsp;For international diabetes prevention and care to be equitable and sustainable, these disparities need to be addressed (Kaur &amp; Kumari, 2018).<br /> Social or Political Ramifications of Health Issues if not Addressed</p>
                        <p>Diabetes is a chronic medical disease that millions of people around the world suffer with.&nbsp;Elevated blood sugar is an effect of the body&rsquo;s inability to produce or use insulin.&nbsp;If diabetes will not be controlled properly it will bring a lot of social and political dimensions (Hill-Briggs et al., 2020). Stigmatization and prejudice can lead to social isolation and a decreased quality of life among people with diabetes.&nbsp;Their health situation may also contribute to limited employment, education, and income alternatives.&nbsp;Diabetes may also affect the mental wellbeing of an individual by causing stress, anxiety, and sadness (Kaur &amp; Kumari, 2018).<br /> Uncontrolled diabetes may have a number of sequelae, including neuropathy, blindness, and amputations that substantially decrease a patient&rsquo;s quality of life (Powers et al., 2020). Diabetes is frequently considered a life style condition resulting from bad eating habits and inactivity, leaving patients to suffer from stigma and prejudice (Mogre et al., 2019). Diabetic patients are however, social isolation because of the restrictions placed on their food, exercise routine and other activities (Mogre et al., 2019). Diabetes is very expensive to maintain and hence patients and their families may come through financial trauma. (Kaur &amp; Kumari, 2018) Minority and low-income communities have higher rates of diabetes, which may cause healthcare inequalities (Kaur &amp; Kumari, 2018).</p>
                        <h3>Political Ramifications</h3>
                        <p>Economic wise, the cost of treating diabetes can significantly influence the political of a nation.&nbsp;It may cause the healthcare system to be overloaded, the occurrence of workplace absenteeism, and productivity decreases.&nbsp;Governments may have to spend on expensive treatment investments or set up regulations to manage and prevent the disease (Hill-Briggs et al., 2020). The incidence of diabetes is growing and is a condition that is expensive to treat, thus, the healthcare expenditure is likely to be overexerted by this disease (Powers et al., 2020). Uncontrolled diabetes can have an economic burden through absenteeism and low productivity (Powers et al., 2020). The various services that diabetes-related problems may demand, especially Medicare, Social Security and disability benefits could stress the system (Kaur &amp; Kumari, 2018). Diabetes adversely affects specific communities and thus aggravates the prevailing health inequities when it comes to the disease (Mogre et al., 2019).</p>
                        <p>The insurance business may be affected by the increase in insurance costs and premiums as a result of the increasing rate of diabetes (Powers et al., 2020). Based on a study published in Diabetes Care, the US loses around $327 billion in economic terms every year due to diabetes (Ali et al., 2020).&nbsp;Moreover, the research by Ali et al (2020) published in the Journal of Occupational and Environmental Medicine, was associated with low productivity and high absenteeism.&nbsp;It has been found through a study conducted by the American Diabetes Association that in ethnicity, income, and in education, the population has a disproportionately high risk of developing diabetes (Baptista et al., 2019).<br /> Analysis of Data Regarding the Socioeconomic, Political, and Cultural Influences</p>
                        <p>Men in Mauritius who are between ages of forty and fifty years are the most probable candidates of having diabetes.&nbsp;In 2019, the International Diabetes Federation (IDF) reported that 22.3% of 20-79-year-old Mauritians had diabetes (Saeedi et al., 2019).&nbsp;Diabetes is influenced by education, wealth, and occupation in Mauritius.&nbsp;Low education, poverty and nutrition and exercise link to diabetes.&nbsp;Socioeconomic factors: Urbanization is a major contributor to high diabetes prevalence in Mauritius.&nbsp;Cities house more than 40% of the population, which promotes sedentary lifestyle and poor nutrition, according to the World Bank.&nbsp;Poverty makes diabetes more common in Mauritius due to the inability to purchase food and treatment.</p>
                        <p>The Gapminder data shows that there are economic, political, and cultural factors, which influence Diabetes in Mauritius.&nbsp;The rate of diabetes grows as the country becomes more urbanized and develops economically.&nbsp;This figure reflects the percentage of 8.6 that of Mauritian population lived under the poverty line, implying poverty might be related to the high prevalence of diabetes.&nbsp;To finally, traditional foods that are eaten in Mauritius are high in fat and salt that triggers diabetes. Cultural diet and exercise may cause diabetes.&nbsp;The typical diet of Mauritius is high-fat and high-carb and no active lifestyles contribute to a high risk of developing diabetes.&nbsp;Urbanization and healthcare determine the rates of diabetes in Mauritius.&nbsp;Urbanizaion may cause diabetes through the change of diet and lifestyle.&nbsp;Inadequate healthcare and diabetes treatment in rural areas can aggravate problems (Mogre et al., 2019).</p>
                        <p>The rates of diabetes in Mauritius depend on the social status.&nbsp;Diabetes is prevalent among poor and uneducated people although they have less of the preventive and curative measures.&nbsp;Political systems in Mauritius could have an effect on diabetes prevalence by encouraging healthy lifestyles and healthcare.&nbsp;The sugar is taxed by the Mauritian government and diabetes is screened for.&nbsp;National solutions may be found to socioeconomic and cultural diabetes factors.&nbsp;The occurrence of diabetes in Mauritius is influenced by social, cultural, and political factors.&nbsp;Targeting these variables through treatment and laws may decrease diabetes in the entire country (Porwal et al., 2020).<br /> Future Changes which can Increase or Decrease effects on the Diabetes</p>
                        <p>The future is expected to bring a lot of transformations to Mauritius and the impact of these changes on people with diabetes will depend on a variety of political, economic, and cultural factors.&nbsp;But let&rsquo;s suppose that politicians and other stakeholders actually do more to promote access to healthy foods, healthcare, physical activities, and correct income disparities.&nbsp;Then, it could bring down the prevalence of diabetes in the nation (Porwal et al., 2020).</p>
                        <h2>Evaluation of Strategies used historically to address the Global Health Issue</h2>
                        <p>Diabetes is a global health threat, which had been handled in various ways previously. Some of the methods include teaching, study, care, protection, and policies. However, the success rate of these strategies is contingent upon the political, economic, social and environmental conditions under which they are used (Mogre et al., 2019). The approaches of preventing diabetes have been aimed at reducing the risks that accompany it such as being obese, inactivity, poor diet and smoking. One such strategy is community-oriented initiatives that teach people to lead healthy lifestyles, influence them to exercise, and assist them in quitting smoking. The World Health Organization (WHO) developed the Global Diabetes Compact in order to prevent, diagnose, and treat diabetes more effectively through partnerships and innovation (Mapa-Tassou et al., 2019).</p>
                        <p>The strategies have been aimed at making many more people informed about diabetes and its consequences and educating people with diabetes about their health. The main research aims have been to determine why diabetes occurs, what its mechanism is, and what new medicines and treatments could be, which can cure this disease. Some of these ways are practical research, clinical research, and fundamental research. In order to promote diabetes research, international groups such as the International Diabetes Federation, and the WHO have also established research networks and projects (Mogre et al., 2019). The approaches to policy making have been about making the policies fairer and more in line with social justice and also making the prevention and management of diabetes easier. More specifically, these tactics involve legislation, regulations, and the public agenda such as the call for everyone to be covered by health insurance, addressing social determinants, and reducing health disparities. Majority of governments have also passed laws pertaining to discrimination and disability rights laws (Mapa-Tassou et al., 2019) in efforts to protect the rights of people with diabetes.</p>
                        <h3>Strategies in Mauritius</h3>
                        <p>According to the International Diabetes Federation, 7.4% of adults in Mauritius had diabetes in 2019.&nbsp;That is the equivalent of about 114,900 individuals who have the condition.&nbsp;Diabetes is a persistent condition that prevents the body from accurately regulating the levels of sugar in the blood.&nbsp;Bad complications such as heart disease, stroke, and blindness can occur when it is not managed correctly (Dawonauth et al., 2019). In the past, Mauritius has started many activities to combat diabetes which nowadays has become a global health issue.&nbsp;Different stakeholders like government, healthcare workers, and community-based organizations were included in these various tactics.&nbsp;The primary approach of Mauritius to diabetes is through public health programs that help educate people of the disease and what make them more prone to it.&nbsp;The government has initiated programs for all, but those to people at risk are emphasized, such as the overweight or people with family history of diabetes.&nbsp;The ads are aimed at educating people on the necessity of living a healthy life which is possible to achieve when a person follows a directed diet and exercises regularly, and people should also understand the need to get screened regularly to identify diabetes in its early stages (Dawonauth et al., 2019).</p>
                        <p>National diabetes program is another approach which has been applied in Mauritius.&nbsp;This program aims to enhance the care provided to people with diabetes and prevent the development of the disease.&nbsp;The program includes establishing diabetes clinics, training health care professionals, and delivering diabetes education and self-management programs (Mahomoodally et al., 2019).&nbsp;Further, the government has regulations to restrict the sale and use of sugary and unhealthy foods.&nbsp;Some of the things that can be done are imposing taxes on soft drinks and making it difficult to advertise and sell unhealthy foods (Mapa-Tassou et al., 2019).</p>
                        <p>Some of these tactics have contributed to the fight against diabetes in Mauritius, but a lot of social, economic, political, and environmental problems need to be addressed.&nbsp;For example, the prevalence of diabetes in the low-income areas may be attributed to factors which include poverty, joblessness, and lack of ease in the access to medical care.&nbsp;Political instability and poor governance are also barriers in the implementation of effective programmes to prevent and treat diabetes (Shukla et al., 2021). Diabetes can also be a consequence of factors such as the environment, global warming, air pollution, and being exposed to poisons.&nbsp;To address the above-discussed issues, the government, healthcare providers, and other community-based organizations will have to collaborate on various fronts.&nbsp;In general, the most effective approach to the global health problem of diabetes in Mauritius is to explore all its causes, which include social, economic, political, and environmental issues that affect people (Mahomoodally et al., 2019).</p>
                        <h3>Current Prospects</h3>
                        <p>Mauritius has achieved a lot in treating diabetes as a public health issue.&nbsp;The government has facilitated the availability of free equipment and medicine as well as services for monitoring and managing the disease for people with diabetes.&nbsp;The Ministry of Health has also launched the National Diabetes Prevention and Control Program to create awareness of diabetes to many and promote healthy living.&nbsp;Of course, community education, information strategies, and training of medical personnel to provide quality diabetic care are feature of the program.&nbsp;Various services provided by the private health care companies to the patients with diabetes include regular checkups, education, and therapy.&nbsp;Additionally, the government has also encouraged private sector to participate in programmes promoting prevention and management of diabetes (Shukla et al., 2021).</p>
                        <h3>Predictions for Future Success</h3>
                        <p>Diabetes is still a major issue in Mauritius although some progress has been made.&nbsp;The rising number of people with diabetes is a problem that could turn into a crisis at this time of the COVID-19 outbreak; thus, we need to act fast to curb the number of the people with diabetes.&nbsp;Comprehensive diabetes care is one of the things that can be done.&nbsp;This approach involves screening, diagnosing, treating, and prevention (Dawonauth et al., 2019).&nbsp;In addition, the government and private health care providers should collaborate to increase availability and accessibility of diabetes care services.&nbsp;Healthy living practices such as frequent physical activity, proper nutrition, and maintaining weight should also be promoted.&nbsp;Knowledge campaigns, community participation, and support groups can all aid in this (Mapa-Tassou et al., 2019).</p>
                        <h3>Gaps</h3>
                        <p>There are a few holes that need to be covered in the strategies to address diabetes on the country.&nbsp;People are uninformed about diabetes in terms of its causes and effects and importance of avoiding and controlling it.&nbsp;Providing of diabetes check, diagnosis, and treatment may be a difficult task in some parts of Mauritius because it is not possible to reach medical services.&nbsp;The government may not sufficiently contribute to diabetes in the form of research, prevention, and treatment funds (Morrell et al., 2019).Parties that have an interest in addressing diabetes such as the health care workers, legislators, and local associations may not have a lot of collaboration.&nbsp;This high incidence of diabetes in Mauritius may be due to poor eating habits, for instance over consumption of processed and sweetened foods.&nbsp;It is also probable that strategies for promoting good eating practices should be designed and implemented (Chan Sun et al., 2022).</p>
                        <h2>Educational Resource or Project Proposal</h2>
                        <p><strong>Title:</strong> "A based on evidence method for giving Mauritius's towns the tools they need to avert and regulate diabetes"</p>
                        <h3>Background</h3>
                        <p>In Mauritius, diabetes is becoming a serious issue as it has affected more that 20% of the population.&nbsp;Poor diet, sedentary lifestyle, and obesity are some of the social determinants of health that are related to the growing number of diabetes patients.&nbsp;Two projects initiated by the Mauritian government to address the menace of diabetes are the National Diabetes Program and the Non-Communicable Diseases (NCDs) Strategic Plan.&nbsp;However, in order to address particular requirements of various categories in Mauritius, researchers require approaches that are based on facts rather than generalizations (Shukla et al., 2021).</p>
                        <h3>Setting</h3>
                        <p>The concept will be implemented in some of the towns in Mauritius.&nbsp;The areas will be selected because they have a large population of people who have diabetes and other health problems which are caused by social determinants such as poverty, low level of education and lack of access to health care access.&nbsp;The project&rsquo;s local partners will be supported by community-based groups, health centers, and local government officials (Morrell et al., 2019).</p>
                        <h3>Conclusion</h3>
                        <p>The &ldquo;Mauritian Communities Empowered to Prevent and Control Diabetes&rdquo; is a fact-based, not small scale project in Mauritius intended to help the country fight its problem of diabetes.&nbsp;The project is targeted at lowering the incidence of Diabetes and its impacts among the people in Mauritius by targeting the high risk groups and resolving the social determinants of health.&nbsp;The project is applicable in different locations and as an example in countries that are experiencing similar problems.&nbsp;For the project to be successful and endure, stakeholders like the Mauritius&rsquo; Ministry of Health, local health officials, and community leaders should support the project.</p>
                        <h2>Summary of the Practicum Experience</h2>
                        <p>I was given an opportunity to propose to a public health worker a project idea on diabetes during my training.&nbsp;I was afraid that presenting the plan will be scary at first, but I studied a lot, and I was sure I knew a lot of the subject.&nbsp;During the talk, I was able to clearly explain the project's goal and importance: to develop teaching tools which would educate people with diabetes on how to live with the condition appropriately (Ahn &amp; Choi, 2019). All in all, the public health officer was amenable to the proposal and provided very useful recommendations.&nbsp;I was pleased with the way in which the presentation went and the opportunity that has been given to me to present my knowledge and contribute to the public health field.&nbsp;Should I have an opportunity to deliver a presentation at such a meeting again, I might use some more visual aids to enhance the presentation, as Ahn and Choi (2019) state.</p>
                        <h2>Conclusion</h2>
                        <p>To sum up, nowadays diabetes is a big issue.&nbsp;This problem needs a comprehensive approach which contains prevention and control of diabetes.&nbsp;Healthy living and eating, health and awareness programs, diabetes checks and early detection, affordable and good-quality healthcare, and effective diabetes control are also mentioned.&nbsp;The government, health care providers, civil society organizations, and people should unite to fight Diabetes in Mauritius.&nbsp;Consistent efforts and a holistic strategy may help to decrease diabetes and improve health of Mauritius and its people.</p>
                        <p>.</p>
                        <h2>References</h2>
                        <p class="text-wrap">Ahn, Y.-H., &amp; Choi, J. (2019). Incivility experiences in clinical practicum education among nursing students. <em>Nurse Education Today</em>, <em>73</em>, 48&ndash;53. <u>https://doi.org/10.1016/j.nedt.2018.11.015</u></p>
                        <p class="text-wrap">Ali, S. N., Dang-Tan, T., Valentine, W. J., &amp; Hansen, B. B. (2020). Evaluation of the clinical and economic burden of poor glycemic control associated with therapeutic inertia in patients with type&nbsp;2 diabetes in the United States. <em>Advances in Therapy</em>, <em>37</em>(2), 869&ndash;882.</p>
                        <p class="text-wrap"><u>https://doi.org/10.1007/s12325-019-01199-8</u></p>
                        <p class="text-wrap">Baptista, M. C., Burton, W. N., Nahas, A. K., Wang, Y.-P., Viana, M. C., &amp; Andrade, L. H. (2019). Absenteeism and presenteeism associated with common health conditions in brazilian workers. <em>Journal of Occupational and Environmental Medicine</em>, <em>61</em>(4), 303&ndash;313.</p>
                        <p class="text-wrap"><u>https://doi.org/10.1097/jom.0000000000001538</u></p>
                        <p class="text-wrap">Chan Sun, M., Landinaff, M. A. S., &amp; Thoplan, R. (2022). Use of biochemical markers for diabetes prevention in the new decade. <em>Physical Sciences Reviews</em>, <em>0</em>(0). <u>https://doi.org/10.1515/psr-2021-0166</u></p>
                        <p class="text-wrap">Dawonauth, L., Jeeawoody, R. B., Jankee, S., &amp; L&rsquo;Omelette, A. D. (2019). High rate of early onset preeclampsia in Mauritius. <em>Journal of Reproductive Immunology</em>, <em>136</em>, 102611. <u>https://doi.org/10.1016/j.jri.2019.08.002</u></p>
                        <p class="text-wrap">Diabetes.co.uk. (2019, January 15). How many people have diabetes -<em> Diabetes Prevalence Numbers</em>. Diabetes.co.uk.</p>
                        <p class="text-wrap"><u>https://www.diabetes.co.uk/diabetes-prevalence.html</u></p>
                        <p class="text-wrap">Hill-Briggs, F., Adler, N. E., Berkowitz, S. A., Chin, M. H., Gary-Webb, T. L., Navas-Acien, A., Thornton, P. L., &amp; Haire-Joshu, D. (2020). Social determinants of health and diabetes: a scientific review. <em>Diabetes Care</em>, <em>44</em>(1), 258&ndash;279. <u>https://doi.org/10.2337/dci20-0053</u></p>
                        <p class="text-wrap">Kaur, H., &amp; Kumari, V. (2018). Predictive modelling and analytics for diabetes using a machine learning approach. <em>Applied Computing and Informatics</em>. <u>https://doi.org/10.1016/j.aci.2018.12.004</u></p>
                        <p class="text-wrap">Mahomoodally, M. F., Protab, K., &amp; Aumeeruddy, M. Z. (2019). Medicinal plants brought by Indian indentured immigrants: A comparative review of ethnopharmacological uses between Mauritius and India. <em>Journal of Ethnopharmacology</em>, <em>234</em>, 245&ndash;289. <u>https://doi.org/10.1016/j.jep.2019.01.012</u></p>
                        <p class="text-wrap">Mapa-Tassou, C., Katte, J.-C., Mba Maadjhou, C., &amp; Mbanya, J. C. (2019). Economic Impact of Diabetes in Africa. <em>Current Diabetes Reports</em>, <em>19</em>(2). <u>https://doi.org/10.1007/s11892-019-1124-7</u></p>
                        <p class="text-wrap">Mogre, V., Johnson, N. A., Tzelepis, F., Shaw, J. E., &amp; Paul, C. (2019). A systematic review of adherence to diabetes self‐care behaviours: Evidence from low‐ and middle‐income countries. <em>Journal of Advanced Nursing</em>, <em>75</em>(12), 3374&ndash;3389. <u>https://doi.org/10.1111/jan.14190</u></p>
                        <p class="text-wrap">Morrell, S., Taylor, R., Nand, D., &amp; Rao, C. (2019). Changes in proportional mortality from diabetes and circulatory disease in Mauritius and Fiji: possible effects of coding and certification. <em>BMC Public Health</em>, <em>19</em>(1).</p>
                        <p class="text-wrap"><u>https://doi.org/10.1186/s12889-019-6748-7</u></p>
                        <p class="text-wrap">Mungloo-Dilmohamud, Z., Heenaye-Mamode Khan, M., Jhumka, K., Beedassy, B. N., Mungloo, N. Z., &amp; Pe&ntilde;a-Reyes, C. (2022). Balancing data through data augmentation improves the generality of transfer learning for diabetic retinopathy classification. <em>Applied Sciences</em>, <em>12</em>(11), 5363.</p>
                        <p class="text-wrap"><u>https://doi.org/10.3390/app12115363</u></p>
                        <p class="text-wrap">Organization, W. H. (2019). Global action plan on physical activity 2018-2030: More active people for a healthier world. In <em>Google Books</em>. World Health Organization. <u>https://books.google.com.pk/books?hl=en&amp;lr=&amp;id=RnOyDwAAQBAJ&amp;oi=fnd&amp;pg=PA48&amp;dq=World+Health+Organization+(WHO)</u></p>
                        <p class="text-wrap">Porwal, P., Pachade, S., Kokare, M., Deshmukh, G., Son, J., Bae, W., Liu, L., Wang, J., Liu, X., Gao, L., Wu, T., Xiao, J., Wang, F., Yin, B., Wang, Y., Danala, G., He, L., Choi, Y. H., Lee, Y. C., &amp; Jung, S.-H. (2020). IDRiD: Diabetic retinopathy &ndash; Segmentation and grading challenge. <em>Medical Image Analysis</em>, <em>59</em>, 101561. <a href="https://doi.org/10.1016/j.media.2019.101561">https://doi.org/10.1016/j.media.2019.101561</a></p>
                        <p class="text-wrap">Powers, M. A., Bardsley, J. K., Cypress, M., Funnell, M. M., Harms, D., Hess-Fischl, A., Hooks, B., Isaacs, D., Mandel, E. D., Maryniuk, M. D., Norton, A., Rinker, J., Siminerio, L. M., &amp; Uelmen, S. (2020). Diabetes self-management education and support in adults with type 2 diabetes: a consensus report of the american diabetes association, the association of diabetes care and education specialists, the academy of nutrition and dietetics, the american academy of family physicians, the american academy of pas, the american association of nurse practitioners, and the american pharmacists association. <em>Diabetes Care</em>, <em>43</em>(7), dci200023. <u>https://doi.org/10.2337/dci20-0023</u></p>
                        <p class="text-wrap">Saeedi, P., Petersohn, I., Salpea, P., Malanda, B., Karuranga, S., Unwin, N., Colagiuri, S., Guariguata, L., Motala, A. A., Ogurtsova, K., Shaw, J. E., Bright, D., &amp; Williams, R. (2019). Global and regional diabetes prevalence estimates for 2019 and projections for 2030 and 2045: Results from the international diabetes federation diabetes atlas, 9th edition. <em>Diabetes Research and Clinical Practice</em>, <em>157</em>(157), 107843. <u>https://doi.org/10.1016/j.diabres.2019.107843</u></p>
                        <p class="text-wrap">Shukla, A., Jain, S., Mohammed, N., Hussain, T., &amp; Banerjee, I. (2021). Lived Experiences of Patients with Coronary Artery Disease: A Qualitative study from a cardiac center of a regional hospital in mauritius. <em>Global Journal of Medical, Pharmaceutical, and Biomedical Update</em>, <em>16</em>.</p>
                        <p class="text-wrap"><u>https://doi.org/10.25259/GJMPBU_21_2021</u></p>
                        <p class="text-wrap">Sneha, N., &amp; Gangil, T. (2019). Analysis of diabetes mellitus for early prediction using optimal features selection. <em>Journal of Big Data</em>, <em>6</em>(1).</p>
                        <p class="text-wrap"><u>https://doi.org/10.1186/s40537-019-0175-6</u></p>
                        <p class="text-wrap"><strong>Require help with your Assessments(<a href="https://onlinecourseservices.us/nurs-fpx-8014-assessment-1">NURS FPX 8014 Assessment 1: Non-Governmental Agencies Involved in Global Issues</a>) ? Our expert team is standing by to assist you. Get in touch with us now</strong></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx8014a2
