import React from 'react';
import {FaRegArrowAltCircleRight, FaPhoneAlt} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Bannertwo = () => {
  return (
    <>
      <div className='ocs2-bannerone'>
        <div className='ocs2-bannerone-image'>
          <img src='images/bannerone.png' alt='' className='ocs2-bannerone-image-box'/>
        </div>
        <div className='ocs2-bannerone-detail'>
          <p className='ocs2-bannerone-detail-title'>PLACE YOUR ORDER TODAY FOR PROFESSIONAL NURSING ASSESSMENT HELP, <br/>CATAPULTING YOUR ACADEMIC SUCCESS!</p>
          <p className='ocs2-bannerone-detail-text'>Never succumb to the academic load! Our exceptional Capella Flexpaath nurses are available to help you! They are capable of delivering great assistance to Capella University flex path students. Reserve your slot today!</p>
          <div className='ocs2-orderNow-box'>
          <Link to={"/order"} className='ocs2-orderNow-btnn'><button className='ocs2-orderNow'>Order Now &nbsp;<FaRegArrowAltCircleRight/></button></Link>
          <img src='images/time.png' alt='' className='ocs2-orderNow-time'/>
          <div className='ocs2-bannerone-phoneNo'>
            <FaPhoneAlt className='ocs2-bannerone-phoneNo-icon'/>
            <span>
              <p>Call us at</p>
              <strong>+1(669) 209-0161</strong>
            </span>
          </div>
        </div>
        </div>
      </div>
    </>
  )
}

export default Bannertwo
