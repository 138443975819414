import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";
import { Helmet } from 'react-helmet';

const Boostacademicperformance = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Research Writers",
            Desc: "Get help from skilled Capella DNP FlexPath research writers. They are always ready for help.",
        },
        {
            icon: "fa-solid fa-school icon",
            title: "Class Help",
            Desc: "Our tutors offer Capella University DNP FlexPath class-taking services for Capella DNP FlexPath students!",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Courses Help",
            Desc: "Take help from Capella DNP FlexPath professionals in their Capella DNP FlexPath courses.",
        },
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Tutoring Help",
            Desc: "Take help from our tutors, who are Capella DNP FlexPath Curriculum professionals. ",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a Capella DNP FlexPath intellectuals team to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We can always help you with your Capella DNP FlexPath Curriculum and coursework!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to Capella University DNP FlexPath students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek complete support from Capella University DNP FlexPath nursing experts.",
        },
    ]
  return (
    <>
    <Helmet>
        <title>Boost Academic Performance With Our Online Coursework Help</title>
          <meta name='description'
          content='Access top-notch online coursework help for academic success. Expert assistance tailored to your needs.'/>
          <meta name='keywords' content='coursework writing help online, help with coursework writing,online coursework help,nursing coursework help online'/>
    </Helmet>
    <div className='s-hero services'>
        {/* <div className='s-hero-top'>
    <div className='sherotop-left'>
    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
    </div>
    <div className='cal-box'><Calculator/></div> 
</div> */}
        <div className='ocs2-main-box'>

            <div className='ocs2-main' id='hero'>
                <div className='ocs2-mainHeading'>
                    <h1><span className='ocs2-topHeading'>BOOST ACADEMIC PERFORMANCE WITH OUR <br />ONLINE COURSEWORK HELP</span></h1>
                       <h2>BENEFITS OF ONLINE COURSES SERVICES <br/> COURSEWORK HELP</h2> 
                    <p className='ocs2-topText'>Online Course Services is a reputable platform providing tutoring and writing services following academic standards. For students who are looking for Online Coursework Help, we provide nursing coursework help online. Students can contact us to get help with coursework writing as well.</p>
                    <ul>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
                        <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
                    </ul>
                    <div className='ocs2-banner-capella'>
                        <div className='ocs2-banner-capella-path'>
                            <span>
                                <h3 className='f-path-pointer'>Flex Path</h3>
                                <p>Use your experience to complete courses.</p>
                            </span>
                        </div>
                        <div className='ocs2-banner-capella-path'>
                            <span>
                                <h3 className='f-path-pointer'>Guided Path</h3>
                                <p>Enjoy the flexibility to study anywhere.</p>
                            </span>
                        </div>
                    </div>
                    <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                    <div className='ocs2-ratting'>
                        <div className='ocs2-ratting-provider'>
                            <SiTrustpilot className='ocs2-trustpilot-icon' />
                            <div>
                                <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                <p>TrustPilot</p>
                            </div>
                        </div>
                        <div className='ocs2-ratting-provider'>
                            <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                            <div>
                                <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                <p>OnlineCourseServices</p>
                            </div>
                        </div>
                        <div className='ocs2-ratting-provider'>
                            <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                            <div>
                                <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                <p>Sitejabber</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='ocs2-form'>
                    <Calculator />
                </div>
            </div>

        </div>
        <div className='s-cta'>
            <div className='s-cta-inner'>
                <div className='s-cta-inner-box'>
                    <h2>Hire Professionals</h2>
                    <p>Take help from professional tutors, mentors, and writers to excel in your Capella University DNP FlexPath coursework.</p>
                </div>
                <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
            </div>
            <div className='s-cta-inner'>
                <div className='s-cta-inner-box'>
                    <h2>Get Nursing Papers</h2>
                    <p>Get excellent and well-researched Capella DNP FlexPath nursing papers!</p>
                </div>
                <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
            </div>
        </div>
    </div>


    <div className='freesamples services'>
        <h2>GET CAPELLA DNP FLEXPATH ASSESSMENT SOLUTIONS!</h2>
        <p>We provide excellent Capella University DNP FlexPath assessment solutions for students in their Capella DNP FlexPath Courses.</p>
        <div className='sampleboxes'>
            {newSamples.map((sample) => {
                return (

                    <div className='singlebox'>
                        <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                        <div className='text'>
                            <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                            <p>{sample.description}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>


    <div className='services s-section'>
        <div className='s-left'>
            {newServices.map((newserivce) => {
                return (
                    <div className='s-single'>
                        <i class={newserivce.icon}></i>
                        <h3>{newserivce.title}</h3>
                        <p>{newserivce.Desc}</p>
                    </div>
                )
            })}
        </div>
        <div className='s-right'>
            <span>Our Academic Services</span>
            <h2>PROFESSIONAL ONLINE COURSEWORK HELP SERVICES</h2>
            <p>Our tutoring and writing services are making hundreds and thousands of students' lives easier. We have professional staff with years of experience teaching in reputable educational institutes around the world. Our expert academic writers are offering students help with coursework writing and assignments.</p>
            <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
        </div>
    </div>

    <div className='services cta'>
        <Calltoaction />
    </div>
    <div className='services'>
        <Tabs>
            <TabList>
                <Tab><strong>How Can You Get Coursework Writing Help Online</strong></Tab>
                <Tab><strong>Get Online Course Services Support and Guidance</strong></Tab>
            </TabList>

            <TabPanel>
                <p>Students who find difficulty in their studies or feel panic when the exams are near must ask for Online Coursework Help. Instead of suffering alone and regretting later for not seeking help, it is better to hire a professional or contact a platform like Online Course Services to get Coursework Writing Help Online. <br /> <br />
                    <strong>Guidance and support by Online Courses Service</strong><br />
                    Students can reach out to our tutors and writers any time for Online Course related Help <br /> <br />
                    <strong>Professional and Experienced Academic Staff</strong><br />
                    Our academic team consists of experienced professionals who are highly skilled in their field. <br /> <br />
                    <strong>Academic Support</strong><br />
                    For any type of help related to the course, assignment, or homework our academic support is available.<br /> <br />
                    <strong>Nursing Paper Help and Solutions</strong><br />
                    Our tutors understand the depth of the subject and provide Online Coursework Help in depth. <br /> <br />
                    <strong>Goals with Our Services</strong><br />
                    With Online course services, tutoring, and writing services students will be able to achieve good grades.
                </p>
            </TabPanel>
            <TabPanel>
                <p>We will make your concept clear and provide you with in-depth knowledge.
                <br /> <br />
                <strong>Comprehensive Guidance and Services</strong><br />
                We also provide students with comprehensive guidance related to their subject and field.
                </p>
            </TabPanel>
        </Tabs>
    </div>

    <div className='process services freesamples'>
        <h2>HOW IT WORKS?</h2>
        <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
        <div className='processboxes'>
            {orderprocess.map((order) => {
                return (

                    <div className='singleprocess'>
                        <i class={order.icon}></i>
                        <h3>{order.title}</h3>
                        <p>{order.Desc}</p>
                    </div>
                )
            })}
        </div>
    </div>


    <div className='services s-section'>
        <div className='s-right'>
            <span>Our Features</span>
            <h2>HOW ONLINE COURSEWORK HELP WILL SAVE STUDENTS TIME AND ENERGY?</h2>
            <p>Online coursework assistance can save students time and energy by offering convenient access to expert assistance, allowing them to efficiently tackle challenging assignments without struggling alone. Through personalized support and guidance, students can clarify concepts, receive feedback, and streamline their learning process, reducing the time spent on trial and error.<br />

Additionally, online platforms often provide resources such as study materials, tutorials, and revision aids, which can further enhance comprehension and accelerate progress. By leveraging these services, students can allocate their time more effectively, focusing on mastering concepts and achieving their academic goals while conserving valuable energy for other important tasks and responsibilities.</p>
            <div><button>Hire Writer</button></div>
        </div>
        <div className='s-left'>
            {newFeatures.map((newserivce) => {
                return (
                    <div className='s-single'>
                        <i class={newserivce.icon}></i>
                        <h3>{newserivce.title}</h3>
                        <p>{newserivce.Desc}</p>
                    </div>
                )
            })}
        </div>
    </div>
    <Stestimonials />
    <Container>
        <div className='ocs2-faqs'>
            <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
            <div className='ocs2-faqs-boxes'>
                <div className='accordian-box-image'>
                    <img src='images/faqs.png' alt='' />
                </div>

                <Accordion className='accordian-boxes'>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}> What types of coursework can I get help with online?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            You can all types of coursework help on our platform.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Are there any guarantees or assurances regarding the satisfaction of the coursework help provided?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            The coursework help will be provided as per the requirements provided by the student.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Do you offer support for understanding course materials or just help with assignments?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            If a student wants service to make his/her concept clear we will recommend him to avail of our tutoring services along with the assignment writing services.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>How experienced are the writers who provide help with coursework writing?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Our writers and tutors are highly qualified and experienced in their fields.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}> What is the process for accessing virtual coursework help services?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>

                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            To know the process you can visit our website or contact us at +1(669) 209-0161.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>Can I receive assistance with urgent nursing coursework assignments or tight deadlines?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>
                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Yes, We also provide urgent coursework services. However, terms and conditions apply.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>How qualified are the tutors or experts providing nursing coursework help online?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>
                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            Our tutors providing nursing coursework help are mostly with a nursing background.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader className='accordian-header'>
                            <h3 className={`accordion-title`}>What specific advantages does online coursework writing help offer in terms of quality and timeliness?</h3>
                            <TiArrowSortedDown className='accordian-header-icon' />
                        </AccordionHeader>
                        <AccordionBody className='accordian-body-ans'>
                            <div className="accordion-body">
                            A student will be less burdened and he or she will get more time for preparation. With the guidance of the Online Courses Services provider tutor and Online Coursework help the quality of the thinking-up will be also improved.
                            </div>
                        </AccordionBody>
                    </AccordionItem>
                   
                </Accordion>
            </div>
        </div>
    </Container>
</>

  )
}

export default Boostacademicperformance
