import React from 'react'
import { FaRegHandPointRight } from "react-icons/fa";
import Calculator from './Calculator';
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import Container from 'react-bootstrap/esm/Container';


const Hero = () => {
  return (
    <>
    <div className='ocs2-main-box'>
    <Container>
      <div className='ocs2-main' id='hero'>
        <div className='ocs2-mainHeading'>
          <h1><span className='ocs2-topHeading'>GET CAPELLA UNIVERSITY FLEXPATH HELP</span><br />
            <span>ENJOY EXCELLENT NURSING ASSESSMENT HELP</span></h1>
          <p className='ocs2-topText'>Avail High-Scoring solutions for the Capella DNP program!<br />
          Get the requisite assistance from our expert at flexible prices.<br/>
          Get comprehensive guidance from Capella Flexpath nursing experts.</p>
          <ul>
            <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% Plagiarism free content</li>
            <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Hassle free and timely delivery</li>
            <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Free revisions</li>
            <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; 100% confidentiality</li>
          </ul>
          <div className='ocs2-banner-capella'>
            <div className='ocs2-banner-capella-path'>
              <span>
                <h3 className='f-path-pointer'>Flex Path</h3>
                <p>Use your experience to complete courses.</p>
              </span>
            </div>
            <div className='ocs2-banner-capella-path'>
              <span>
                <h3 className='f-path-pointer'>Guided Path</h3>
                <p>Enjoy the flexibility to study anywhere.</p>
              </span>
            </div>
          </div>
          <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
          <div className='ocs2-ratting'>
            <div className='ocs2-ratting-provider'>
              <SiTrustpilot className='ocs2-trustpilot-icon' />
              <div>
                <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                <p>TrustPilot</p>
              </div>
            </div>
            <div className='ocs2-ratting-provider'>
              <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
              <div>
                <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                <p>OnlineCourseServices</p>
              </div>
            </div>
            <div className='ocs2-ratting-provider'>
              <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
              <div>
                <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                <p>Sitejabber</p>
              </div>
            </div>
          </div>

        </div>
        <div className='ocs2-form'>
          <Calculator />
        </div>
      </div>
      </Container>
      </div>
    </>
  )
}

export default Hero
